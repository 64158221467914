import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  color: #aeb9cd;
  background: #f8f8fb;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-top: 25px;
  border: 1px solid transparent;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: #003ca6;
    `}

  input {
    background: transparent;
    border: none;
    width: 100%;
    font-size: 18px;
    &::placeholder {
      color: #666360;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #aeb9cd !important;
    }
  }
`;

export const ContainerIcon = styled.div`
  background-color: transparent;
  width: 59px;
  border-radius: 10px 0 0 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #aeb9cd;
  }
`;

export const ContainerInput = styled.div`
  background: transparent;
  flex: 1;
  padding: 12px;
  padding-left: 0px;
`;

export const ContainerError = styled.div`
  margin-top: 5px;
  width: 100%;
  text-align: center;
  color: #c53030;
`;
