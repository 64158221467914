import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface btnprops {
  isLoading: boolean;
}

export const Container = styled.button<btnprops>`
  background-color: #003ca6;
  border: 2px solid #fff;
  color: #fff;
  height: 49px;
  width: 170px;
  padding: 0 30px;
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  margin-left: 15px;
  font-size: 18px;

  @media (max-width: 768px) {
    margin-top: 15px;
    margin-left: 0px;
    height: 40px;
    font-size: 16px;
    width: 160px;
  }

  svg {
    margin-right: 6px;
  }

  text-transform: uppercase;
  &:hover {
    background: ${shade(0.2, '#003CA6')};
    border-color: ${shade(0.2, '#fff')};
  }

  ${props =>
    props.isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: progress;
      &:hover {
        background: #003ca6;
      }
    `}
`;

export const Spinner = styled.div`
  width: 70px;
  text-align: center;

  & > div {
    width: 14px;
    height: 14px;
    background-color: rgb(255, 255, 255);

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  & .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;
