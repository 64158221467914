import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  freightTableTariffId: 0,
  currentListPage: 1,
};

export const freightTableTariffSlice = createSlice({
  name: 'freightTableTariff',
  initialState,
  reducers: {
    setFreightTableTariffId: (state, { payload: freightTableTariffId }) => {
      state.freightTableTariffId = !freightTableTariffId
        ? initialState.freightTableTariffId
        : freightTableTariffId;
    },
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});

export interface FreightTableTariffData {
  id?: number;
  freight_table_id: number;
  is_region: boolean;
  region_origin_id?: number;
  gris_min?: number;
  region_destination_id?: number;
  origin_id?: number;
  destination_id?: number;
  advalorem: number | string;
  gris: number | string;
  load_value: number | string;
  discharge_value: number | string;
  collect_value: number | string;
  delivery_value: number | string;
  dispatch_value: number | string;
  toll_type_id: number | string;
  toll_value: number | string;
  min_value: number | string;
  status: string;
  freight_table?: {
    id: number;
    comments: string;
    description: string;
    icms_type: string;
  };
  tollType?: {
    id: number;
    name: string;
  };
  origin?: {
    id: number;
    name: string;
  };
  destination?: {
    id: number;
    name: string;
  };
  region_origin?: {
    id: number;
    name: string;
  };
  region_destination?: {
    id: number;
    name: string;
  };
}

interface ResponseData {
  data: FreightTableTariffData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGet {
  data: FreightTableTariffData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGetClient {
  data: FreightTableTariffData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGetAll {
  data: {
    data: FreightTableTariffData[];
    total_pages: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getFreightTableTariff = async (
  freight_table_tariff_id: number,
): Promise<ResponseDataGet> => {
  const response = await axios.get(
    `/local/freight_table_tariff/${freight_table_tariff_id}`,
  );
  return response;
};

export const getFreightTableTariffByFreightTable = async (
  params: any,
): Promise<ResponseDataGetAll> => {
  const response = await axios.get(`/local/freight_table_tariff`, {
    params,
  });
  return response;
};

export const aproveFreightTableTariff = async (
  id: number,
): Promise<ResponseData> => {
  const response = await axios.patch(
    `/local/freight_table_tariff/aprove/${id}`,
  );
  return response;
};

export const getFreightTableTariffClientRoute = async (
  clientid: number,
  originId: number,
  destinationId: number,
): Promise<ResponseDataGetClient> => {
  const response = await axios.get(
    `/local/freight_tables_clients/client/${clientid}/${originId}/${destinationId}`,
  );
  return response;
};

export const addNewFreightTableTariff = async (
  freight_table_tariff: FreightTableTariffData,
): Promise<ResponseData> => {
  const response = await axios.post(
    '/local/freight_table_tariff',
    freight_table_tariff,
  );
  return response;
};

export const updateFreightTableTariff = async (
  id: number,
  freightTableTariff: FreightTableTariffData,
): Promise<ResponseData> => {
  const response = await axios.put(
    `/local/freight_table_tariff/${id}`,
    freightTableTariff,
  );
  return response;
};

export const {
  setFreightTableTariffId,
  setCurrentListPage,
} = freightTableTariffSlice.actions;

export default freightTableTariffSlice.reducer;
