import styled from 'styled-components';
import { shade } from 'polished';

export const CardContainer = styled.button`
  width: calc((100% - 30px) / 3);
  background-color: #003ca6;
  padding: 15px;
  box-shadow: rgba(0, 60, 166, 0.2) 6px 6px 16px;
  height: 160px;
  margin-right: 10px;
  margin-top: 15px;
  border-radius: 6px;
  border: none;
  transition: background-color 0.4s linear;

  &:hover {
    background-color: ${shade(0.2, '#003ca6')};
  }

  @media (max-width: 926px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 640px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const TrackingTitleFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TrackingCodeSpan = styled.span`
  font-size: 22px;
  color: #fff;
  font-weight: 600;
`;

export const TrackingCodeDiv = styled.div`
  height: 30px;
  padding: 2px 15px;
  border-radius: 32px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonAccessButton = styled.button`
  border-radius: 32px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: none;
  margin-right: 10px;
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#ffffff')};
  }
`;

export const StatusSpan = styled.span`
  font-size: 22px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
`;

export const StatusDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  span {
    margin-left: 5px;
    font-size: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  svg {
    color: #ffffff;
    width: 25px;
    height: 25px;
  }
`;

export const DateSpan = styled.span`
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
`;
