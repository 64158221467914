import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ComponentProps {
  size: string;
}

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: 600px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ListFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    margin-top: 15px;
    a {
      flex: 1;
      background-color: #afd2ff;
      border-radius: 25px;
      color: #003CA6;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    button {
      background: transparent;
      border-radius: 50%;
      margin-left: 5px;
      border: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: all 0.3s linear;

      &:hover {
        background-color: #afd2ff;
        color: #003CA6;
      }
    }
  }
`;

export const ButtonAddFile = styled.button`
  margin-top: 35px;
  background: transparent;
  color: #262626;
  border: 1px solid #90dcec;
  border-radius: 29px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 40px;
  font-size: 18px;
  text-transform: uppercase;
  transition: background-color 0.3s linear;

  &:hover {
    background-color: #90dcec;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  margin-top: 20px;

  div {
    flex: 1;
    padding: 0;
    display: flex;
    justify-content: flex-start;

    > div {
      color: #666360;
      font-weight: 400;
      font-size: 17px;
      margin-left: 10px;
    }

    @media (max-width: 768px) {
      flex: auto;
      width: 100%;

      > div {
        margin-top: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`;

export const ContainerTitle = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: #003CA6;
    border: none;
    color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    border-radius: 25px;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }

    svg {
      margin-right: 3px;
    }
  }
`;
