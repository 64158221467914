import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  currentListPage: 1,
};

export const clientDocumentsSlice = createSlice({
  name: 'clientDocuments',
  initialState,
  reducers: {
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});

export interface ClientInformationData {
  id: number;
  company_name: string;
  name: string;
  document_number: string;
}

export interface ClientDocumentsTrackingData {
  id: number;
  client_id: number;
  client: ClientInformationData;
  series: string;
  code: string;
  branch_id: number;
  type: string;
  date_doc: Date;
  dockey: string;
  value: string;
}

interface MerchandiseTrackingResponse {
  data: {
    data: ClientDocumentsTrackingData[];
    per_page: number;
    total_pages: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ViewTrackingResponse {
  data: ClientDocumentsTrackingData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getClientDocumentByCode = async (
  code: string,
): Promise<ViewTrackingResponse> => {
  const merchandiseTracking = await axios.get(
    `/local/client_documents/${code}`,
  );
  return merchandiseTracking;
};

export const getClientDocuments = async (
  params: any,
): Promise<MerchandiseTrackingResponse> => {
  const merchandiseTracking = await axios.get(`/local/client_documents`, {
    params,
  });
  return merchandiseTracking;
};

export const downloadClientDocument = async (
  idDocument: number,
): Promise<any> => {
  const document = await axios.get(
    `/local/client_documents/download/${idDocument}`,
  );
  return document;
};

export const downloadCSV = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(
    `/local/client_documents/grouped_excel`,
    {
      params,
      responseType: 'blob',
    },
  );
  return fileResponse;
};

export const downloadPDF = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/client_documents/grouped_pdf`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export const { setCurrentListPage } = clientDocumentsSlice.actions;

export default clientDocumentsSlice.reducer;
