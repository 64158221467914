export function formatValueFromCurrencyInput(value: string): any {
  const newValue = parseFloat(value.replace('.', '').replace(',', '.'));
  return isNaN(newValue) === true ? undefined : newValue;
}

export function formatValueToCurrencyInput(value: number): any {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(value)
    .replace('.', '');
}
