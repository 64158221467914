import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: calc(100vh - 80px);
  }
`;

export const Section = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  min-height: 100vh;
  width: calc(100% - 100px);
  max-width: calc(100% - 100px);
  margin-left: 100px;
  background-color: #f8f8fb;
  @media (max-width: 768px) {
    margin-top: 80px;
    min-height: calc(100vh - 80px);
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
`;
