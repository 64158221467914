import React from 'react';

import { TableContainer, Table, Header, Row, RowChild } from './styles';

interface TableData {
  fontHeaderSize?: string;
  fontHeaderSizeResponsive?: string;
  fontDataSize?: string;
  fontDataSizeResponsive?: string;
  header: string[];
  data: Array<Array<string>>;
}

const TableList: React.FC<TableData> = ({
  fontHeaderSize = '18px',
  fontHeaderSizeResponsive = '12px',
  fontDataSize = '15px',
  fontDataSizeResponsive = '18px',
  header,
  data,
}) => {
  let key = 0;

  return (
    <TableContainer>
      {data.length > 0 ? (
        <Table>
          <Header
            fontHeaderSize={fontHeaderSize}
            fontHeaderSizeResponsive={fontHeaderSizeResponsive}
          >
            {header.map(title => (
              <div key={title}>{title}</div>
            ))}
          </Header>
          {data.map(item => {
            let i = -1;
            return (
              <Row
                fontDataSize={fontDataSize}
                fontDataSizeResponsive={fontDataSizeResponsive}
                fontHeaderSizeResponsive={fontHeaderSizeResponsive}
                key={`${key}-row`}
              >
                {header.map(title => {
                  i++;
                  key++;
                  return (
                    <RowChild
                      key={key}
                      data-title={title}
                      fontDataSize={fontDataSize}
                      fontDataSizeResponsive={fontDataSizeResponsive}
                      fontHeaderSizeResponsive={fontHeaderSizeResponsive}
                      breakWord={item[i]?.length > 20}
                    >
                      {item[i]}
                    </RowChild>
                  );
                })}
              </Row>
            );
          })}
        </Table>
      ) : (
        <h4 className="text-center">Nenhum dado encontrado</h4>
      )}
    </TableContainer>
  );
};

export default TableList;
