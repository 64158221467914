import styled from 'styled-components';

export const Container = styled.header`
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;

  margin-bottom: 20px;
  @media (max-width: 768px) {
    border-radius: 10px;
    margin-top: 50px;
    font-size: 15px;
    background-color: transparent;
    color: inherit;
    box-shadow: none;
    margin-bottom: 0px;
    padding-top: 0px;
  }
`;

export const Title = styled.h1`
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Rajdhani', sans-serif;
  color: #003CA6;
`;

export const Separator = styled.hr`
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: rgba(0,0,0,0.1);
`;
