/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { useDebounce } from 'use-debounce';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import Input from '../../components/Input';
import { ContainerSize, ButtonType, AddRegisterButton } from './styles';
import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';
import Pagination from '../../components/Pagination';
import {
  getOcurrences,
  OcurrenceData,
  postOcurrence,
  updateOcurrence,
  setOcurrenceId,
  getOcurrence,
} from '../../features/ocurrences';
import { getOcurrencesIntegration } from '../../features/ocurrencesIntegration';
import TableList from '../../components/TableList';
import Filter from '../../components/Filter';
import Autocomplete from '../../components/Autocomplete';
import Modal from '../../components/Modal';

interface optionsData {
  label: string;
  value: string;
}

const ListClient: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const [searchOcurrences, setSearchOcurrences] = useState('');
  const [optionsOcurrences, setOptionsOcurrences] = useState<optionsData[]>([]);
  const [valueSearchOcurrence] = useDebounce(searchOcurrences, 1000);
  const [isLoadingOcurrence, setIsLoadingOcurrence] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState('Cadastrar');
  const { showLoading, hideLoading } = useLoading();
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const { ocurrenceId } = useSelector((state: RootStateOrAny) => ({
    ocurrenceId: state.features.ocurrences.ocurrenceId,
  }));

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const handleOcurrenceInputChange = (newValue: string): void => {
    if (newValue !== searchOcurrences) {
      setIsLoadingOcurrence(true);
    }
    setSearchOcurrences(newValue);
  };

  useEffect(() => {
    (async () => {
      const ocurrences = await getOcurrencesIntegration({
        name: valueSearchOcurrence,
      });

      setOptionsOcurrences([]);
      ocurrences.forEach((item: any): any => {
        setOptionsOcurrences(state => [
          ...state,
          {
            value: item.id,
            label: `${item.id} - ${item.name}`,
          },
        ]);
      });
      setIsLoadingOcurrence(false);
    })();
  }, [valueSearchOcurrence]);

  const handleCreateOcurrence = useCallback((): void => {
    (formRef.current as any).reset();

    const select_code = formRef!.current!.getFieldRef('code_integration');
    select_code.select.clearValue();

    const select_status = formRef!.current!.getFieldRef('status');
    select_status.select.clearValue();
    dispatch(setOcurrenceId(0));
    openModal();
  }, [setSearchOcurrences, dispatch, setOcurrenceId]);

  const handleUpdateOcurrence = useCallback(
    async (id: number): Promise<void> => {
      (formRef.current as any).reset();

      const select_code = formRef!.current!.getFieldRef('code_integration');
      select_code.select.clearValue();

      const select_status = formRef!.current!.getFieldRef('status');
      select_status.select.clearValue();

      showLoading();

      const response = await getOcurrence(id);

      if (response.status !== 200) {
        addToast({
          title: 'Erro',
          description: 'Ocorreu um erro ao buscar essa ocorrência',
          type: 'error',
        });
      } else {
        const ocurrence = response.data;
        (formRef.current as any).setFieldValue('name', ocurrence.name);
        (formRef.current as any).setFieldValue('status', {
          label: ocurrence.status,
          value: ocurrence.status,
        });
        (formRef.current as any).setFieldValue('code_integration', {
          label: `${ocurrence.ocurrence_integration.id} - ${ocurrence.ocurrence_integration.name}`,
          value: ocurrence.code_integration,
        });
        dispatch(setOcurrenceId(ocurrence.id));
        openModal();
      }

      hideLoading();
    },
    [setSearchOcurrences],
  );

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback((data: Obj): void => {
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        searchData[key.replace('-', '.')] = value;
      }
    }
    setOptionsSearch(searchData);
  }, []);

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const ocurrences = await getOcurrences({
        page,
        ...optionsSearch,
      });
      setResults([]);
      ocurrences.data.forEach((ocurrence: OcurrenceData) => {
        setResults(state => [
          ...state,
          [
            ocurrence.id,
            ocurrence.name,
            ocurrence.status,
            new Date(ocurrence.created_at).toLocaleString('pt-BR', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }),
            <div className="options-table-list">
              <button
                type="button"
                onClick={() => {
                  handleUpdateOcurrence(ocurrence.id);
                }}
              >
                <FaEdit size={20} color="#ffffff" />
              </button>
            </div>,
          ],
        ]);
      });
      const arrayPages = [];
      for (let i = 0; i < ocurrences.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);

      hideLoading();
    },
    [showLoading, optionsSearch, hideLoading],
  );

  const handleSubmitOcurrence = useCallback(
    async (data: any): Promise<void> => {
      try {
        const schemaValidation = Yup.object().shape({
          code_integration: Yup.string().required(
            'Selecione a ocorrência do Rodopar',
          ),
          status: Yup.string().required('Selecione o status'),
          name: Yup.string().required('Informe o nome da ocorrência'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        showLoading();
        let response;
        if (ocurrenceId > 0) {
          response = await updateOcurrence(ocurrenceId, data);
        } else {
          response = await postOcurrence(data);
        }

        if (response.status === 200) {
          addToast({
            title: 'Sucesso',
            description: `Ocorrência ${
              ocurrenceId > 0 ? `atualizada` : `cadastrada`
            } com sucesso`,
            type: 'success',
          });
          dispatch(setOcurrenceId(response.data.id));
        } else {
          addToast({
            title: 'Erro',
            description: `Não foi possível ${
              ocurrenceId > 0 ? `atualizar` : `cadastrar`
            } a ocorrência`,
            type: 'error',
          });
        }

        getData(1);

        hideLoading();
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (formRef.current as any).setErrors(errors);
        }
      }
    },
    [ocurrenceId, dispatch, showLoading, hideLoading, addToast, getData],
  );

  useEffect(() => {
    getData(1);
  }, [optionsSearch]);

  useEffect(() => {
    if (ocurrenceId > 0) {
      setButtonText('Alterar');
    } else {
      setButtonText('Cadastrar');
    }
  }, [ocurrenceId]);

  return (
    <PageContainer>
      <MainTitle>Ocorrências</MainTitle>

      <TableList
        header={['Código', 'Nome', 'Status', 'Data de cadastro', 'Editar']}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
      />
      <AddRegisterButton type="button" onClick={handleCreateOcurrence}>
        <IoMdAdd size={30} />
      </AddRegisterButton>
      <Filter onSubmit={handleSubmitForm}>
        <Input name="ocurrences-name" type="text" placeholder="Nome" />
        <Input name="ocurrences-status" type="text" placeholder="Status" />
        <Input
          name="ocurrences-created_at>"
          type="date"
          placeholder="Data Inicial"
        />
        <Input
          name="ocurrences-created_at<"
          type="date"
          placeholder="Data Final"
        />
      </Filter>
      <Modal
        isOpen={showModal}
        id="modal-ocurrence"
        onClose={() => setShowModal(false)}
        title={`${buttonText} Ocorrência`}
        backgroundColor="#F8F8FB"
        fontColor="#3a3a3a"
      >
        <div>
          <Form
            ref={formRef}
            initialData={{}}
            onSubmit={handleSubmitOcurrence}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            noValidate
          >
            <ContainerSize size="100%" style={{ display: 'block' }}>
              <Autocomplete
                name="code_integration"
                options={optionsOcurrences}
                placeholder="Ocurrência Rodopar"
                changeValue={null}
                onInputChange={handleOcurrenceInputChange}
                isLoading={isLoadingOcurrence}
              />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input name="name" placeholder="Nome" />
            </ContainerSize>
            <ContainerSize size="100%" style={{ display: 'block' }}>
              <Autocomplete
                name="status"
                options={[
                  { label: 'Andamento', value: 'Andamento' },
                  { label: 'Cancelamento', value: 'Cancelamento' },
                  { label: 'Finalização', value: 'Finalização' },
                ]}
                placeholder="Status"
                changeValue={null}
              />
            </ContainerSize>
            <ButtonType type="submit">{buttonText}</ButtonType>
          </Form>
        </div>
      </Modal>
    </PageContainer>
  );
};

export default ListClient;
