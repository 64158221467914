import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  freightTableItemId: 0,
};

export const freightTableItensSlice = createSlice({
  name: 'freightTableItens',
  initialState,
  reducers: {
    setFreightTableItemId: (state, { payload: freightTableItemId }) => {
      state.freightTableItemId = !freightTableItemId
        ? initialState.freightTableItemId
        : freightTableItemId;
    },
  },
});

export interface FreightTableItemData {
  id?: number;
  freight_table_tariff_id: number;
  starting: string;
  finishing: string;
  value: number;
  track_type_id: number;
  status: string;
  trackType?: {
    id: number;
    name: string;
  };
}

interface ResponseData {
  data: FreightTableItemData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGet {
  data: FreightTableItemData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGetAll {
  data: {
    data: FreightTableItemData[];
    total_pages: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getFreightTableItem = async (
  freight_table_item_id: number,
): Promise<ResponseDataGet> => {
  const response = await axios.get(
    `/local/freight_table_itens/${freight_table_item_id}`,
  );
  return response;
};

export const getFreightTableItemByTariff = async (
  page: number,
  freight_table_tariff_id: number,
): Promise<ResponseDataGetAll> => {
  const response = await axios.get(`/local/freight_table_itens`, {
    params: { freight_table_tariff_id, page },
  });
  return response;
};

export const aproveFreightTable = async (id: number): Promise<ResponseData> => {
  const response = await axios.patch(`/local/freight_table_itens/aprove/${id}`);
  return response;
};

export const addNewFreightTableItem = async (
  freight_table_item: FreightTableItemData,
): Promise<ResponseData> => {
  const response = await axios.post(
    '/local/freight_table_itens',
    freight_table_item,
  );
  return response;
};

export const updateFreightTableItem = async (
  id: number,
  freightTableItem: FreightTableItemData,
): Promise<ResponseData> => {
  const response = await axios.put(
    `/local/freight_table_itens/${id}`,
    freightTableItem,
  );
  return response;
};

export const { setFreightTableItemId } = freightTableItensSlice.actions;

export default freightTableItensSlice.reducer;
