import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  currentListPage: 1,
};

export const merchandiseTrackingSlice = createSlice({
  name: 'merchandiseTracking',
  initialState,
  reducers: {
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});

export interface MerchandiseTrackingData {
  id: number;
  client_id: number;
  collection_order_id: number;
  code: string;
  code_rodopar: string;
  document_type: string;
  document_number: string;
  date_hour: Date;
  status: string;
  place: string;
  place_destination: string;
  receiver_doc: string;
  receiver_name: string;
  expected_date: Date;
  invoice_date: Date;
  doc_date: Date;
  occurrence: string;
}

interface MerchandiseTrackingResponse {
  data: {
    data: MerchandiseTrackingData[];
    per_page: number;
    total_pages: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ViewTrackingResponse {
  data: MerchandiseTrackingData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getTrackingByCode = async (
  code: string,
  type: string,
): Promise<ViewTrackingResponse> => {
  const merchandiseTracking = await axios.get(
    `/local/merchandise_tracking/view_tracking/${code}/${type}`,
  );
  return merchandiseTracking;
};

export const getTrackingByCodeAndClientId = async (
  code: string,
  client_id: string,
): Promise<ViewTrackingResponse> => {
  const merchandiseTracking = await axios.get(
    `/local/merchandise_tracking/view_tracking_client/${code}/${client_id}`,
  );
  return merchandiseTracking;
};

export const getTrackingInfoByCode = async (
  code: string,
): Promise<ViewTrackingResponse> => {
  const merchandiseTracking = await axios.get(`/local/tracking/info/${code}`);
  return merchandiseTracking;
};

export const getTrackingsByDocumentNumber = async (
  document: string,
  nfNumber: string,
): Promise<any> => {
  const merchandiseTracking = await axios.get(
    `/local/tracking/client/${document}/${nfNumber}`,
  );
  return merchandiseTracking;
};

export const getTrackings = async (
  params: any,
): Promise<MerchandiseTrackingResponse> => {
  const merchandiseTracking = await axios.get(`/local/merchandise_tracking`, {
    params,
  });
  return merchandiseTracking;
};

export const getTrackingsGrouped = async (
  params: any,
): Promise<MerchandiseTrackingResponse> => {
  const merchandiseTracking = await axios.get(
    `/local/merchandise_tracking/grouped`,
    {
      params,
    },
  );
  return merchandiseTracking;
};

export const downloadCSV = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(
    `/local/merchandise_tracking/grouped_excel`,
    {
      params,
      responseType: 'blob',
    },
  );
  return fileResponse;
};

export const downloadPDF = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(
    `/local/merchandise_tracking/grouped_pdf`,
    {
      params,
      responseType: 'blob',
    },
  );
  return fileResponse;
};

export const { setCurrentListPage } = merchandiseTrackingSlice.actions;

export default merchandiseTrackingSlice.reducer;
