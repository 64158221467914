import React, { InputHTMLAttributes, useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { VscFiles } from 'react-icons/vsc';

import { Fileuploadarea } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const CustomModal: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Fileuploadarea>
      <VscFiles size={20} />
      <span>Adicionar arquivos</span>
      <small>Você também pode arrasta-los</small>
      <input type="file" id={fieldName} ref={inputRef} name={name} {...rest} />
    </Fileuploadarea>
  );
};

export default CustomModal;
