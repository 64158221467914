import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: #003CA6;
  color: #ffffff;
  height: 40px;
  width: 100%;
  padding: 0 30px;
  border: 0;
  border-radius: 29px;
  margin-top: 30px;
  font-weight: 500;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  text-transform: uppercase;
  &:hover:not([disabled]) {
    background: ${shade(0.2, '#003CA6')};
  }

  img {
    width: 20px;
    height: 20px;
    margin: 3px;
  }

  &[disabled] {
    opacity: 0.7;
    cursor: unset;
  }
`;
