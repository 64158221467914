import { createSlice } from '@reduxjs/toolkit';
import { fetch, merge } from '../../entities/salesman';
import axios from '../../app/axios';

const initialState = {
  salesmanId: 0,
  salesmanUser: null,
};

export const salesmanSlice = createSlice({
  name: 'salesman',
  initialState,
  reducers: {
    setSalesmanId: (state, { payload: salesmanId }) => {
      state.salesmanId = !salesmanId ? initialState.salesmanId : salesmanId;
    },
  },
});

export const { setSalesmanId } = salesmanSlice.actions;

export const fetchSalesman = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchSalesmanWithParams = async (params: any): Promise<any> => {
  const { data: salemans } = await axios.get('/local/salesman', { params });
  return salemans;
};

export const getSalesmanByUserId = async (userId: number): Promise<any> => {
  const { data: salesman } = await axios.get(`/local/salesman`, {
    params: { user_id: userId },
  });
  return salesman;
};

export const fetchSalesmanByUserId = (params: any) => (dispatch: any): any => {
  axios.get('/local/salesman', { params }).then(response => {
    dispatch(merge(response.data.data));
    dispatch(setSalesmanId(response.data.data.id));
  });
};

export const fetchSalesmanByUserIdAsync = async (params: any): Promise<any> => {
  const salesman = await axios.get('/local/salesman', { params });
  return salesman.data.data[0];
};

export const fetchSalesmanByName = async (params: any): Promise<any> => {
  const { data: salesman } = await axios.get('/local/salesman', {
    params,
  });
  return salesman.data;
};

export default salesmanSlice.reducer;
