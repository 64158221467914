import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  vehicleId: 0,
  totalPages: 0,
};

export const transporterVehiclesSlice = createSlice({
  name: 'transporterVehicles',
  initialState,
  reducers: {
    setVehicleId: (state, { payload: vehicleId }) => {
      state.vehicleId = !vehicleId ? initialState.vehicleId : vehicleId;
    },
    setTotalPages: (state, { payload: totalPages }) => {
      state.totalPages = !totalPages ? initialState.totalPages : totalPages;
    },
  },
});

export const { setVehicleId, setTotalPages } = transporterVehiclesSlice.actions;

interface TransporterData {
  id: number;
  user_id: number;
  name: string;
}
export interface VehicleData {
  id: number;
  transporter_id: number;
  license_plate: string;
  type: string;
  status: 'Ativo' | 'Inativo';
  created_at: Date;
  transporter: TransporterData;
}

interface ResponseVehicle {
  data: VehicleData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseVehicles {
  data: VehicleData[];
  per_page: number;
  total_pages: number;
}

export const getVehicle = async (id: number): Promise<ResponseVehicle> => {
  const vehicle = await axios.get(`/local/transporters_vehicles/${id}`);
  return vehicle;
};

export const postVehicle = async (
  data: Omit<VehicleData, 'id' | 'transporter' | 'created_at'>,
): Promise<Omit<ResponseVehicle, 'transporter'>> => {
  const response = await axios.post('/local/transporters_vehicles', data);
  return response;
};

export const updateVehicle = async (
  id: number,
  data: Omit<VehicleData, 'id' | 'transporter' | 'created_at'>,
): Promise<Omit<ResponseVehicle, 'transporter'>> => {
  const response = await axios.put(`/local/transporters_vehicles/${id}`, data);
  return response;
};

export const getVehicles = async (params: any): Promise<ResponseVehicles> => {
  const { data: vehicles } = await axios.get('/local/transporters_vehicles', {
    params,
  });
  return vehicles;
};

export const deleteVehicle = async (id: number): Promise<any> => {
  const response = await axios.delete(`/local/transporters_vehicles/${id}`);
  return response;
};

export default transporterVehiclesSlice.reducer;
