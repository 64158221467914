import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  header {
    padding: 0;
    margin: 0;
    width: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    div {
      justify-content: center;
      margin-top: 15px;
    }
  }
`;

export const ContainerButton = styled.div`
  flex: 1;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ButtonType = styled.button`
  background-color: #90dcec;
  color: #232323;
  border: none;
  border-radius: 29px;
  padding: 11px 30px;
  margin-top: 25px;

  &:hover {
    background-color: ${shade(0.2, '#90dcec')};
  }

  &:disabled,
  &[disabled] {
    cursor: unset;
    background-color: #003CA6;
    color: #fff;

    &:hover {
      background-color: #003CA6;
    }
  }
`;

export const SpecialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
  }
`;

export const TitleSpan = styled.span`
  font-size: 20px;

  > img {
    width: 24px;
    height: 24px;
    vertical-align: sub;
  }
`;

export const ListStyled = styled.div`
  font-size: large;
  display: flex;
  list-style: none;
  width: 100%;
  height: 48px;
`;

export const FilterContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const SpanItemStyled = styled.span`
  display: inline-block;
  text-align: center;
  font-family: normal normal normal 35px/47px Ambit;
  font-size: 32px;
`;

export const ButtonEdit = styled.a``;

export const ButtonAcess = styled.button`
  background: #90dcec 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 300%;
  cursor: pointer;

  width: 40px;
  height: 40px;
  padding: 2px;
  vertical-align: sub;

  margin: 15px 10px;

  position: relative;
  bottom: 12px;

  &:hover {
    background: ${shade(0.2, '#003CA6')};
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
`;

export const AddRegisterButton = styled.button`
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  margin-bottom: 60px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #003CA6;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;

interface ComponentProps {
  size: string;
}

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-right: 0px;
    margin-left: 0px;
  }

  > img {
    vertical-align: sub;
    width: 30px;
    height: 30px;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}
  padding-right: 15px;
  padding-left: 15px;

  display: flex;

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  form {
    width: 100%;
  }

  a {
    margin-top: 10px;
    width: 60px;
    height: 60px;
    background-color: #90dcec;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: backlground-color 0.2s linear;

    &:hover {
      background-color: #3066ad;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const ExhibitionField = styled.div`
  color: #666360;
  background: rgba(0, 60, 160, 0.18);
  border-radius: 10px;
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

export const ExhibitionFieldText = styled.div`
  background: transparent;
  flex: 1;
  padding: 12px;
`;

export const CointainerIcons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    background-color: #90dcec;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    width: 40px;
    height: 40px;
    padding: 5px;

    transition: all 0.2s linear;

    &:hover {
      background: ${shade(0.2, '#003CA6')};
      border-radius: 50%;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  a:first-child {
    background-color: transparent;
    &:hover {
      background: ${shade(0.2, '#003CA6')};
      border-radius: 50%;
    }
  }
`;
