import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
`;

export const TitleSpan = styled.span`
  font-size: 20px;
  padding: 0;
`;

export const DocInformation = styled.div`
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    padding: 5px 6px;
    background-color: #f8f8fb;
    border-radius: 8px;
    margin-top: 10px;
  }
`;
