import React from 'react';

import { ToastProvider } from './toast';
import { LoadingProvider } from './loading';
import { DialogProvider } from './dialog';

const AppProvider: React.FC = ({ children }) => (
  <LoadingProvider>
    <ToastProvider>
      <DialogProvider>{children}</DialogProvider>
    </ToastProvider>
  </LoadingProvider>
);

export default AppProvider;
