import styled from 'styled-components';
import { shade } from 'polished';

export const Background = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff 0% 0% no-repeat padding-box;

  display: flex;
  justify-content: space-between;

  svg {
    vertical-align: sub;
    margin-right: 3px;
  }
`;

export const Row = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  margin-top: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    & + div {
      margin-left: 0;
    }
    padding-right: 0;
    padding-left: 0;
  }
`;

export const BackButton = styled.button`
  border: none;
  background-color: #003ca6;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50%;

  color: #ffffff;
  transition: background-color 0.2s ease;

  cursor: pointer;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;

export const ContainerFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  margin-top: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    & + div {
      margin-left: 0;
    }
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ContainerSizeAutoComplete = styled.div`
  width: 50%;

  padding-right: 15px;
  padding-left: 15px;
  margin-top: auto;
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ContainerRadio = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-top: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    & + div {
      margin-left: 0;
    }
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Title = styled.h1`
  padding: 15px 35px;
  width: fit-content;
  font-size: 21px;
  font-weight: 500;
  color: white;
  background-color: #003ca6;
  border-radius: 20px;
`;

export const Avatar = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  position: relative;

  img {
    width: 150px;
    height: 150px;
    border-radius: 300%;
    padding: 1% 0.5%;
    margin: auto;
  }

  .edit-button {
    width: 40px;
    border-radius: 160px;
    padding: 6px;
    height: 40px;
    background-color: #003ca6;
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  svg {
    position: absolute;
    right: 9px;
    top: 12px;
    color: #fff;
  }
`;
export const ButtonAddClient = styled.button`
  background-color: #003ca6;
  color: #ffffff;
  border-radius: 50%;
  margin-top: 25px;
  border: 0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;

  &:hover {
    background-color: ${shade('0.2', '#afd2ff')};
    color: ${shade('0.2', '#003CA6')};
  }
`;

export const ListFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    margin-top: 15px;
    a {
      flex: 1;
      background-color: #003ca6;
      border-radius: 25px;
      color: #ffffff;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    span {
      flex: 1;
      background-color: #ffffff;
      border-radius: 25px;
      color: #003ca6;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    button {
      background: transparent;
      border-radius: 50%;
      margin-left: 5px;
      border: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #003ca6;
      transition: all 0.3s linear;

      &:hover {
        background-color: #afd2ff;
      }
    }
  }
`;

export const ListClients = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    margin-top: 15px;
    a {
      flex: 1;
      background-color: #ffffff;
      border-radius: 25px;
      color: #003ca6;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    button {
      background: transparent;
      border-radius: 50%;
      margin-left: 5px;
      border: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #003ca6;
      transition: all 0.3s linear;

      &:hover {
        background-color: #afd2ff;
      }
    }
  }
`;

export const InputPic = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
