import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
`;

export const SpecialContainer = styled.div`
  width: 100%;
  /*height: 100%; */
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .form-content {
    flex: 1;
    height: 100%;
    order: 1;

    @media (max-width: 768px) {
      order: 2;
      width: 100%;
    }
  }

  .client-total {
    width: 250px;
    height: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;
    order: 2;

    @media (max-width: 768px) {
      width: 100%;
      order: 1;
    }

    .span-total {
      width: 214px;
      height: 154px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      margin: 20px;
      border-radius: 12px;
      font-size: 25px;
      background-color: #003CA6;
      box-shadow: rgba(0, 60, 166, 0.2) 6px 6px 16px;
      color: #F8F8FB;

      .number {
        font-weight: 600;
        font-size: 50px;
      }
    }

    a {
      padding: 7px 0px;
      width: 187px;
      font-size: 21px;
      background-color: #003CA6;
      color: #fff;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s linear;

      &:hover {
        background-color: ${shade(0.2, '#003CA6')};
      }

      svg {
        margin-right: 3px;
      }
    }
  }
`;

export const TitleSpan = styled.span`
  font-size: 20px;
  padding: 0;

  > img {
    width: 24px;
    height: 24px;
    vertical-align: sub;
  }
`;

export const ListStyled = styled.div`
  font-size: large;
  display: flex;
  list-style: none;
  width: 100%;
  height: 48px;
`;

export const FilterContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const SpanItemStyled = styled.span`
  display: inline-block;
  text-align: center;
  font-family: normal normal normal 35px/47px Ambit;
  font-size: 32px;
`;

export const ButtonAcess = styled.button`
  background: #90dcec 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 300%;
  cursor: pointer;

  width: 40px;
  height: 40px;
  padding: 2px;
  vertical-align: sub;

  margin: 15px 10px;

  position: relative;
  bottom: 12px;

  &:hover {
    background: ${shade(0.2, '#003CA6')};
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
`;

interface ComponentProps {
  size: string;
}

export const Row = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;

  .column-titles {
    width: 100%;
    display: flex;
    padding: 10px 0;

    text-align: center;
    font-size: 20px;

    .title {
      margin-top: 20px;
    }
  }

  img {
    vertical-align: sub;
    width: 30px;
    height: 30px;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}
  padding: 0 15px;

  display: flex;
  justify-content: space-evenly;

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ExhibitionField = styled.div`
  color: #666360;
  background: rgba(0, 60, 160, 0.18);
  border-radius: 10px;
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

export const ExhibitionFieldText = styled.div`
  background: transparent;
  flex: 1;
  padding: 12px;
`;

export const CointainerIcons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    background-color: #90dcec;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    width: 40px;
    height: 40px;
    padding: 5px;

    transition: all 0.2s linear;

    &:hover {
      background: ${shade(0.2, '#003CA6')};
      border-radius: 50%;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  a:first-child {
    background-color: transparent;
    &:hover {
      background: ${shade(0.2, '#003CA6')};
      border-radius: 50%;
    }
  }
`;
