import { createSlice } from '@reduxjs/toolkit';
import { fetch } from '../../entities/branches';
import axios from '../../app/axios';

const initialState = {
  branchId: 0,
};

export const branchSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    setbranchId: (state, { payload: companyId }) => {
      state.branchId = !companyId ? initialState.branchId : companyId;
    },
  },
});

export const fetchBranches = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchBranchesByName = async (params: any): Promise<any> => {
  const { data: branches } = await axios.get('/local/branches', {
    params,
  });
  return branches.data;
};

export default branchSlice.reducer;
