import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from '../../entities/clients';
import axios from '../../app/axios';

const initialState = {
  clientId: 0,
  totalPages: 0,
  clientCubageFactor: 0,
  emails: [],
  currentListPage: 1,
};

export const clientsSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientId: (state, { payload: clientId }) => {
      state.clientId = !clientId ? initialState.clientId : clientId;
    },
    setCityId: (state, { payload: companyId }) => {
      state.clientId = !companyId ? initialState.clientId : companyId;
    },
    setTotalPages: (state, { payload: totalPages }) => {
      state.totalPages = !totalPages ? initialState.totalPages : totalPages;
    },
    setEmails: (state, { payload: emails }) => {
      state.emails = !emails ? initialState.emails : emails;
    },
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
    setCubageFactor: (state, { payload: clientCubageFactor }) => {
      state.clientCubageFactor = !clientCubageFactor
        ? initialState.clientCubageFactor
        : clientCubageFactor;
    },
  },
});

export const {
  setClientId,
  setTotalPages,
  setCubageFactor,
  setEmails,
  setCurrentListPage,
} = clientsSlice.actions;

export const setClientCreatedId = (id: number) => (dispatch: any): any => {
  dispatch(setClientId(id));
};

interface ClientData {
  id?: number;
  branch_id: number;
  city_id: number;
  company_type_id: number;
  company_name?: string;
  short_name?: string;
  fiscal_situation: string;
  code_rodopar?: number;
  state_registration?: string;
  cep: string;
  address: string;
  number: string;
  phone_number: string;
  district: string;
  is_active: boolean;
  salesman_id?: number;
  document_number: string;
  name: string;
}

interface UserClientData {
  id?: number;
  client_id: number;
  user_id: number;
}

interface UserClientResponseData {
  id?: number;
  client_id: number;
  user_id: number;
  user?: any;
  client?: any;
}

interface UserClientResponse {
  data: UserClientResponseData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ClientResponseData {
  id: number;
  branch_id: number;
  user_id: number;
  city_id: number;
  company_type_id: number;
  company_name: string;
  short_name: string;
  fiscal_situation: string;
  code_rodopar: number;
  state_registration: string;
  cep: string;
  address: string;
  number: string;
  phone_number: string;
  district: string;
  is_active: boolean;
  salesman_id: number;
  document_number: string;
  cubage_factor: number;
  name: string;
  reference: string;
  complement: string;
  user?: {
    email: string;
  };
}

interface ResponseClient {
  data: ClientResponseData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseClients {
  data: ClientResponseData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface AsyncData {
  id?: number;
  data: ClientData | any;
}

export const getClient = async (id: any): Promise<ResponseClient> => {
  const client = await axios.get(`/local/clients/${id}`);
  return client;
};

export const getClientEmail = async (params: any): Promise<any> => {
  const client = await axios.get(`/local/client_emails`, { params });
  return client;
};

export const getClientEmailById = async (id: number): Promise<any> => {
  const client = await axios.get(`/local/client_emails/${id}`);
  return client;
};

export const getUserClient = async (
  userId: number,
): Promise<UserClientResponse> => {
  const client = await axios.get(`/local/users_clients/user/${userId}`);
  return client;
};

export const fetchClients = (params: any) => (dispatch: any): any => {
  axios.get('/local/clients', { params }).then(response => {
    dispatch(merge(response.data.data));
    dispatch(setTotalPages(response.data.total_pages));
  });
};

export const fetchClientsByDocumentNumber = async (
  params: any,
): Promise<ResponseClients> => {
  const { data: client } = await axios.get('/local/clients', { params });
  return client;
};

export const fetchUserClientsByDocumentNumber = async (
  params: any,
): Promise<any> => {
  const { data: client } = await axios.get('/local/users_clients', { params });
  return client;
};

export const postClient = async (data: ClientData): Promise<ResponseClient> => {
  const response = await axios.post('/local/clients', data);
  return response;
};

export const postUserClient = async (
  data: UserClientData,
): Promise<ResponseClient> => {
  const response = await axios.post('/local/users_clients', data);
  return response;
};

export const deleteUserClient = async (id: any): Promise<any> => {
  const response = await axios.delete(`/local/users_clients/${id}`);
  return response;
};

export const addClientThunk = createAsyncThunk<any, AsyncData>(
  'clients/addClient',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/local/clients', info.data);
      dispatch(merge(response.data));
      dispatch(setClientId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const addClientEmailThunk = createAsyncThunk<any, AsyncData>(
  'clients/addClientEmail',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/local/client_emails', info.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateClientThunk = createAsyncThunk<any, AsyncData>(
  'clients/updateClient',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(`/local/clients/${info.id}`, info.data);
      dispatch(merge(response.data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateClientEmailThunk = createAsyncThunk<any, AsyncData>(
  'clients/updateClientEmail',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/local/client_emails/${info.id}`,
        info.data,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const addClient = (data: ClientData): any => async (
  dispatch: any,
): Promise<void> => {
  await axios.post('/local/clients', data).then(({ data: responseData }) => {
    dispatch(merge(responseData));
    dispatch(setClientId(responseData.id));
  });
};

export const updateClient = (data: ClientData): any => async (
  dispatch: any,
): Promise<void> => {
  await axios
    .put(`/local/clients/${data.id}`, data)
    .then(({ data: responseData }) => {
      dispatch(merge(responseData));
      dispatch(setClientId(responseData.id));
    });
};

export const getClients = async (params: any): Promise<any> => {
  const { data: clients } = await axios.get('/local/clients', { params });
  return clients;
};

export const deleteClientEmail = createAsyncThunk<any, AsyncData>(
  'clients/deleteClientEmail',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/local/client_emails/${info.id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export default clientsSlice.reducer;
