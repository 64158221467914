import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const FilterButton = styled.button`
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(0, 60, 166, 0.9);
  backdrop-filter: blur(3px);
  box-shadow: 0px 3px 5px -1px rgba(0, 60, 166, 0.2),
    0px 6px 10px 0px rgba(0, 60, 166, 0.14),
    0px 1px 18px 0px rgba(0, 60, 166, 0.12);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;

interface Props {
  isVisible: boolean;
}

export const FilterContainer = styled.div<Props>`
  width: 320px;
  background-color: #f8f8fb;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;

  ${props =>
    props.isVisible === false &&
    css`
      right: -320px;
      opacity: 0;
    `}
`;

export const FilterBackdrop = styled.div<Props>`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.25s ease;

  ${props =>
    props.isVisible === false &&
    css`
      opacity: 0;
      z-index: -5;
    `}
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #003ca6;
  color: #fff;
  position: relative;
  box-shadow: 0px 2px 17px 1px rgba(0, 0, 0, 0.2);

  button {
    border: none;
    background: transparent;
    position: absolute;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    transition: all 0.3s linear;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 0 15px;
  padding-bottom: 15px;

  form {
    width: 100%;
    min-height: calc(100% - 50px);
    display: block;
  }

  button[type='button'] {
    align-self: flex-end;
    border: none;
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    background-color: transparent;
    border: solid 1px #003ca6;
    color: #003ca6;
    text-transform: uppercase;
    font-size: 80%;
    transition: background-color 0.2s linear;
    margin-top: 30px;

    &:hover {
      background-color: #003ca6;
      color: #fff;
    }

    svg {
      margin-right: 5px;
    }
  }

  button[type='submit'] {
    align-self: flex-end;
    border: none;
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    background-color: #003ca6;
    color: #fff;
    text-transform: uppercase;
    font-size: 80%;
    transition: background-color 0.2s linear;
    margin-top: 30px;

    &:hover {
      background-color: ${shade(0.3, '#003ca6')};
    }
  }
`;
