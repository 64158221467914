import { createSlice } from '@reduxjs/toolkit';
import { fetch } from '../../entities/companyType';
import axios from '../../app/axios';

const initialState = {
  transporterId: 0,
};

export const transporterSlice = createSlice({
  name: 'companyType',
  initialState,
  reducers: {
    setTransporterId: (state, { payload: transporterId }) => {
      state.transporterId = !transporterId
        ? initialState.transporterId
        : transporterId;
    },
  },
});

export const fetchTransporterByName = async (params: any): Promise<any> => {
  const { data: transporters } = await axios.get('/local/transporters', {
    params,
  });
  return transporters.data;
};

export default transporterSlice.reducer;
