import React from 'react';
import { Switch } from 'react-router-dom';

import { useSelector, RootStateOrAny } from 'react-redux';
import Route from './Route';

import Login from '../pages/Login';
import PasswordRecovery from '../pages/PasswordRecovery';
import Dashboard from '../pages/Dashboard';
import RegisterClient from '../pages/client/RegisterClient';
import UserRegister from '../pages/UserRegister';
import FreightTable from '../pages/FreightTable';
import FreightTables from '../pages/FreightTable/List';
import ViewQuotation from '../pages/ViewQuotation';
import CreateQuotation from '../pages/CreateQuotation/index';
import CreateBusinessPerson from '../pages/BusinessPerson';
import FreightTableTariff from '../pages/FreightTableTariff';
import ListClient from '../pages/Clients/List';
import ListUsers from '../pages/ListUsers';
import ListSalesmans from '../pages/ListSalesmans';
import GatheringOrder from '../pages/GatheringOrder';
import ClientDocuments from '../pages/Clients/Documents';
import ClientInvoices from '../pages/Clients/Invoices';

import EventsEntry from '../pages/EventsEntry';
import MerchandiseTracking from '../pages/MerchandiseTracking';
import ListTrackings from '../pages/ListTrackings';
import ListTrackingsGrouped from '../pages/ListTrackingsGrouped';
import ListCollection from '../pages/ListCollection';
import LinkTransporterDocuments from '../pages/LinkTransporterDocuments';
import Reports from '../pages/Reports';
import LinkOcurrences from '../pages/LinkOcurrences';
import ListBusinessPerson from '../pages/BusinessPerson/List';
import TransporterDrivers from '../pages/TransporterDrivers';
import TransporterVehicles from '../pages/TransporterVehicles';
import TrackingPage from '../pages/Tracking';

const Routes: React.FC = () => {
  const { userType } = useSelector((state: RootStateOrAny) => ({
    userType: state.features.auth.type,
  }));
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/rastreio" component={TrackingPage} />
      <Route path="/esqueceu-senha" component={PasswordRecovery} />
      <Route
        path="/"
        exact
        component={userType === 'transporter' ? EventsEntry : Dashboard}
        userTypeAccess={['admin', 'salesman', 'client']}
      />

      <Route
        path="/usuario"
        exact
        component={UserRegister}
        userTypeAccess={['admin']}
      />
      <Route
        path="/usuario/:id"
        component={UserRegister}
        userTypeAccess={['admin']}
      />
      <Route
        path="/usuarios"
        component={ListUsers}
        userTypeAccess={['admin']}
      />
      <Route
        path="/vendedores"
        component={ListSalesmans}
        userTypeAccess={['admin']}
      />
      <Route
        path="/ocorrencias"
        component={LinkOcurrences}
        userTypeAccess={['admin']}
      />

      <Route
        path="/clientes"
        component={ListClient}
        userTypeAccess={['admin', 'salesman']}
      />
      <Route
        path="/cliente"
        exact
        component={RegisterClient}
        userTypeAccess={['admin', 'salesman']}
      />
      <Route
        path="/cliente/:id"
        component={RegisterClient}
        userTypeAccess={['admin', 'salesman']}
      />

      <Route
        path="/tabelas-de-frete"
        exact
        component={FreightTables}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/tabela-de-frete"
        exact
        component={FreightTable}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/tabela-de-frete/:id"
        exact
        component={FreightTable}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/parceiro-comercial"
        exact
        component={CreateBusinessPerson}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/parceiro-comercial/:id"
        exact
        component={CreateBusinessPerson}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/tabela-de-frete/:id/tarifas"
        exact
        component={FreightTableTariff}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/tabela-de-frete/:id/tarifas/:tariff"
        exact
        component={FreightTableTariff}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/consultar-cotacoes"
        component={ViewQuotation}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/criar-cotacoes"
        exact
        component={CreateQuotation}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/criar-cotacoes/:id"
        exact
        component={CreateQuotation}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/ordem-de-coleta"
        exact
        component={GatheringOrder}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/ordem-de-coleta/:id"
        exact
        component={GatheringOrder}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/consultar-mercadoria/:id/:type"
        exact
        component={MerchandiseTracking}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/consultar-mercadoria/:id/:type/:client_id"
        exact
        component={MerchandiseTracking}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/rastreios"
        exact
        component={ListTrackingsGrouped}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/consultar-mercadorias"
        exact
        component={ListTrackings}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/ordens-de-coleta"
        component={ListCollection}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/consultar-documentos"
        component={ClientDocuments}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/consultar-faturas"
        component={ClientInvoices}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/lancar-ocorrencias"
        component={EventsEntry}
        userTypeAccess={['admin', 'salesman', 'client', 'transporter']}
      />
      <Route
        path="/vincular-documentos-transportador"
        component={LinkTransporterDocuments}
        userTypeAccess={['admin', 'salesman', 'transporter']}
      />
      <Route
        path="/relatorios"
        component={Reports}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/listar-parceiros-comerciais"
        component={ListBusinessPerson}
        userTypeAccess={['admin', 'salesman', 'client']}
      />
      <Route
        path="/motoristas"
        component={TransporterDrivers}
        userTypeAccess={['admin', 'salesman', 'client', 'transporter']}
      />
      <Route
        path="/veiculos"
        component={TransporterVehicles}
        userTypeAccess={['admin', 'salesman', 'client', 'transporter']}
      />
    </Switch>
  );
};

export default Routes;
