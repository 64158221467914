import styled, { css } from 'styled-components';

interface ModalProps {
  isOpen: boolean;
}

interface ModalContentProps {
  backgroundColor?: string;
  fontColor?: string;
  width?: string;
}

export const Modal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1072;
  display: block;
  padding-right: 15px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  @media (max-width: 768px) {
    padding-right: 0;
  }

  ${props =>
    props.isOpen === false &&
    css`
      display: none;
    `}
`;

export const ModalDialog = styled.div`
  margin: 0.5rem;
  position: relative;
  pointer-events: none;
  min-height: 800px;
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /*
  background: rgb(0, 60, 166);
  background: linear-gradient(
    181deg,
    rgba(0, 60, 166, 1) 0%,
    rgba(111, 145, 205, 1) 100%
  ); */
  background: #005c97; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    270deg,
    rgba(0, 92, 151, 0.98),
    rgba(54, 55, 149, 0.98)
  );
  background: linear-gradient(
    270deg,
    rgba(0, 92, 151, 0.98),
    rgba(54, 55, 149, 0.98)
  );
  background-size: 200% 200%;

  animation: GradientAnimation 4s ease infinite;
  @keyframes GradientAnimation {
    0% {
      background-position: 76% 0%;
    }
    50% {
      background-position: 25% 100%;
    }
    100% {
      background-position: 76% 0%;
    }
  }

  @media (min-width: 576px) {
    min-height: 450px;
    max-width: 800px;
    margin: 1.75rem auto;
  }
`;

export const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: auto;

  background: transparent;
  ${props =>
    props.fontColor
      ? css`
          color: ${props.fontColor};
        `
      : css`
          color: #fff;
        `}
  ${props =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 100%;
        `}
  background-clip: padding-box;
  outline: 0;
`;

export const ModalHeader = styled.div`
  position: relative;
  text-align: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  color: inherit;

  h2 {
    margin-left: 20px;
    margin-right: 20px;
    color: inherit;
  }

  button {
    position: absolute;
    background: transparent;
    border: 0;
    padding: 10px;
    right: 16px;
    top: 11px;
    color: inherit;
  }
`;

export const ModalBody = styled.div`
  padding: 1rem;
`;

export const ModalBackdrop = styled.div<ModalProps>`
  ${props =>
    props.isOpen === false &&
    css`
      display: none;
    `}
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(5px);
`;

export const TextTitle = styled.span`
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: 30px;
  }
`;

export const TextSubtitle = styled.span`
  margin-top: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 23px;
  }
`;

export const YesNoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const NokButton = styled.button`
  cursor: pointer;
  margin-top: 15px;
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 28px;
  width: 150px;
  font-size: 32px;
  font-weight: 600;
  padding: 2px;
  text-align: center;
  transition: all 0.4s linear;
  @media (max-width: 768px) {
    font-size: 23px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const OkButton = styled.button`
  cursor: pointer;
  margin-top: 15px;
  background-color: #fff;
  color: #2a4698;
  border: none;
  border-radius: 28px;
  width: 150px;
  font-size: 32px;
  font-weight: 600;
  padding: 2px;
  text-align: center;
  transition: all 0.3s linear;
  @media (max-width: 768px) {
    font-size: 23px;
  }

  &:hover {
    box-shadow: -2px 0px 21px 0px rgba(255, 255, 255, 0.2);
  }
`;

export const TermsContainer = styled.div`
  background: rgba(0, 0, 0, 0.1);
  color: black;
  border-radius: 8px;
  width: 100%;
  height: 320px;
  padding: 20px;
  div {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 23px;

    p {
      margin-bottom: 20px;
    }

    ul {
      list-style: inside;
    }

    strong {
      font-weight: 700;
      font-size: 18px;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.2);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;
