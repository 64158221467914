/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { RiRoadMapLine, RiFileList2Line } from 'react-icons/ri';

import { Link, useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { SiMicrosoftexcel } from 'react-icons/si';
import { FaFilePdf } from 'react-icons/fa';
import download from 'downloadjs';
import {
  getTrackingsGrouped,
  getTrackingByCode,
  downloadCSV,
  downloadPDF,
  merchandiseTrackingSlice,
} from '../../features/merchandiseTracking';

import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';

import PageContainer from '../../components/PageContainer';
import TableList from '../../components/TableList';
import Filter from '../../components/Filter';
import Input from '../../components/Input';
import Pagination from '../../components/Pagination';

import MainTitle from '../../components/MainTitle';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../features/clients';
import Autocomplete from '../../components/Autocomplete';
import Modal from '../../components/Modal';
import { FloatButton } from './styles';

interface Obj {
  [key: string]: string;
}

const ListTrackingsGrouped: React.FC = () => {
  const {
    userType,
    userTypeId,
    userId,
    currentListPage,
    formValue,
    locationFilter,
  } = useSelector((state: RootStateOrAny) => ({
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    currentListPage: state.features.merchandiseTracking.currentListPage,
    formValue: state.features.filter.formValue,
    locationFilter: state.features.filter.location,
  }));
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [detailsResult, setDetailsResult] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? {} : formValue,
  );
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { setCurrentListPage } = merchandiseTrackingSlice.actions;
  const dispatch = useDispatch();

  const viewTrackEvents = useCallback(
    async (code: string): Promise<void> => {
      showLoading();
      const response = await getTrackingByCode(code, 'code');
      if (response.status !== 200) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao buscar os eventos.',
        });
      } else {
        const { data: trackings } = response;
        setDetailsResult([]);
        trackings.forEach(item => {
          setDetailsResult(state => [
            ...state,
            [
              item.code,
              item.document_number,
              new Date(item.date_hour).toLocaleString('pt-BR', {
                timeZone: 'UTC',
              }),
              item.status,
              item.occurrence,
            ],
          ]);
        });
        setShowModal(true);
      }

      hideLoading();
    },
    [showLoading, hideLoading, addToast],
  );

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const trackings = await getTrackingsGrouped({
        page,
        ...optionsSearch,
      });
      const arrayPages = [];
      for (let i = 0; i < trackings.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));
      const trackingsResult: Array<object> = [];

      trackings.data.data.forEach((item: any) => {
        trackingsResult.push([
          item.code_rodopar,
          <>
            {item.code}
            <br />
            {item.doc_date
              ? new Date(item.doc_date).toLocaleString('pt-BR', {
                  timeZone: 'UTC',
                })
              : item.doc_date}
          </>,
          <>
            {item.document_number}
            <br />
            {item.invoice_date
              ? new Date(item.invoice_date).toLocaleString('pt-BR', {
                  timeZone: 'UTC',
                })
              : item.invoice_date}
          </>,
          item.sendername,
          item.recipientname,
          `${item.place} -> ${item.place_destination}`,
          `${item.status} - ${new Date(item.date_hour).toLocaleString('pt-BR', {
            timeZone: 'UTC',
          })}`,
          <div className="options-table-list">
            <button
              type="button"
              onClick={() => {
                viewTrackEvents(item.code_rodopar);
              }}
              title="Visualizar detalhes"
            >
              <RiFileList2Line size={20} color="#ffffff" />
            </button>
            <Link
              to={`/consultar-mercadoria/${item.code_rodopar}/code`}
              title="Visualizar rastreio"
            >
              <RiRoadMapLine size={20} color="#ffffff" />
            </Link>
          </div>,
        ]);
      });
      setResults(trackingsResult);
      setIsLoadingPage(false);
      hideLoading();
      addToast({
        title: 'Rastreios carregados com sucesso',
        type: 'success',
      });
    },
    [showLoading, optionsSearch, hideLoading, addToast, viewTrackEvents],
  );

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } = await fetchClientsByDocumentNumber({
          document_number: valueSearchClient,
          ...isSalesman,
        });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } = await fetchUserClientsByDocumentNumber({
          'client.document_number': valueSearchClient,
          user_id: userId,
        });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [valueSearchClient]);

  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      const searchData: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          searchData[key.replace('-', '.')] = value;
        }
      }
      setOptionsSearch(searchData);
    },
    [setOptionsSearch],
  );

  useEffect(() => {}, [optionsClients]);

  const handleClickDownloadCSV = useCallback(async () => {
    showLoading();

    const response = await downloadCSV({
      ...optionsSearch,
    });
    const fileName = `trackings-${new Date().toLocaleDateString()}`;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`); // or any other extension
    document.body.appendChild(link);
    link.click();

    /*  download(
      `data:text/csv; charset=utf-8,${response.data}`,
      fileName,
      'text/csv; charset=utf-8',
    ); */
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  const handleClickDownloadPDF = useCallback(async () => {
    showLoading();

    const response = await downloadPDF({
      ...optionsSearch,
    });
    const fileName = `trackings-${new Date().toLocaleDateString()}.pdf`;

    download(response.data, fileName, 'application/pdf');
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  useEffect(() => {
    if (Object.keys(optionsSearch).length === 0) {
      getData(currentPage);
    } else {
      getData(1);
    }
  }, [optionsSearch]);

  return (
    <PageContainer>
      <MainTitle>Consultar Rastreios</MainTitle>
      <TableList
        header={[
          'Remessa',
          'N°. NFe - Data NFe',
          'N°. CTE - Data CTE',
          'Remetente',
          'Destinatário',
          'Origem e Destino',
          'Status - Data Ocorrência',
          '',
        ]}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
        isLoadingPage={isLoadingPage}
      />
      <Modal
        backgroundColor="#F8F8FB"
        fontColor="#3a3a3a"
        title="Detalhes do rastreio"
        isOpen={showModal}
        id="modal-track"
        onClose={() => setShowModal(false)}
      >
        <TableList
          header={['N°. NFe', 'N°. CTe', 'Data/Hora', 'Status', 'Obsevação']}
          data={detailsResult}
        />
      </Modal>
      <Filter onSubmit={handleSubmitForm}>
        <Autocomplete
          name="mt-client_id"
          options={optionsClients}
          placeholder="CPF/CNPJ"
          changeValue={null}
          onInputChange={handleClientInputChange}
          isLoading={isLoadingClients}
        />
        <Input type="text" name="mt-code_rodopar" placeholder="Remessa" />
        <Input type="text" name="mt-code" placeholder="N°. NFe" />
        <Input type="date" name="mt-doc_date>" placeholder="Data NFe" />
        <Input type="text" name="mt-document_number" placeholder="N°. CTe" />
        <Input type="date" name="mt-invoice_date>" placeholder="Data CTe" />
        <Input
          type="text"
          name="business_person_recipient-name"
          placeholder="Remetente"
        />
        <Input type="text" name="mt-place" placeholder="Origem" />
        <Input type="text" name="mt-place_destination" placeholder="Destino" />
        <Input type="text" name="mt-status" placeholder="Status" />
        <Input type="date" name="mt-date_hour>" placeholder="De" />
        <Input type="date" name="mt-date_hour<" placeholder="Até" />
      </Filter>
      <FloatButton
        type="button"
        title="Exportar em CSV"
        onClick={() => handleClickDownloadCSV()}
      >
        <SiMicrosoftexcel size={25} />
      </FloatButton>
      <FloatButton
        type="button"
        title="Exportar em PDF"
        onClick={() => handleClickDownloadPDF()}
        style={{ bottom: '154px' }}
      >
        <FaFilePdf size={25} />
      </FloatButton>
    </PageContainer>
  );
};

export default ListTrackingsGrouped;
