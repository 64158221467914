import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  eventId: 0,
  totalPages: 0,
};

export const transporterEventsSlice = createSlice({
  name: 'transporterEvents',
  initialState,
  reducers: {
    setEventId: (state, { payload: eventId }) => {
      state.eventId = !eventId ? initialState.eventId : eventId;
    },
    setTotalPages: (state, { payload: totalPages }) => {
      state.totalPages = !totalPages ? initialState.totalPages : totalPages;
    },
  },
});

export const { setEventId, setTotalPages } = transporterEventsSlice.actions;

interface InvoiceData {
  id: number;
  transporter_id: number;
  document_id: number;
  num_nfe: string;
  serial: string;
  nfe_key: string;
  date_issuance: Date;
  value_nfe: string;
  value_net_nfe: string;
  instructions: string;
}

interface OcurrenceData {
  id: number;
  name: string;
  status: string;
}

export interface EventData {
  id: number;
  invoice_id: number;
  ocurrence_id: number;
  comments: string;
  created_at: Date;
  invoice: InvoiceData;
  ocurrence: OcurrenceData;
}

export interface StackEventData {
  invoices: Array<string>;
  ocurrence_id: number;
  comments: string;
}

interface ResponseEvent {
  data: EventData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseStackEvent {
  data: {
    errors: Array<string>;
    success: Array<string>;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseEvents {
  data: EventData[];
  per_page: number;
  total_pages: number;
}

export const getEvent = async (id: number): Promise<ResponseEvent> => {
  const event = await axios.get(`/local/transporters_events/${id}`);
  return event;
};

export const postEvent = async (
  data: Omit<EventData, 'id' | 'invoice' | 'ocurrence' | 'created_at'>,
): Promise<Omit<ResponseEvent, 'invoice'>> => {
  const response = await axios.post('/local/transporters_events', data);
  return response;
};

export const postStackEvent = async (
  data: StackEventData,
): Promise<ResponseStackEvent> => {
  const response = await axios.post('/local/transporters_events/stack', data);
  return response;
};

export const updateEvent = async (
  id: number,
  data: Omit<EventData, 'id' | 'invoice' | 'ocurrence' | 'created_at'>,
): Promise<Omit<ResponseEvent, 'invoice'>> => {
  const response = await axios.put(`/local/transporters_events/${id}`, data);
  return response;
};

export const getEvents = async (params: any): Promise<ResponseEvents> => {
  const { data: events } = await axios.get('/local/transporters_events', {
    params,
  });
  return events;
};

export default transporterEventsSlice.reducer;
