/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import { createPassword, Authslice } from '../../features/auth/index';
import LogoTransreal from '../../assets/transreal_logo.svg';
import LogoTransrealAzul from '../../assets/transreal_logo_azul.svg';
import { Background, Container, Main, WellcomeContainer } from './styles';
import InputIcon from '../../components/InputIcon';
import Button from '../../components/Button';

interface FormInterface {
  [key: string]: string;
}

const PasswordRecovery: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setIsLoadingReset, setResetSuccess } = Authslice.actions;
  const { resetError, resetSuccess, isLoadingReset } = useSelector(
    (state: RootStateOrAny) => ({
      resetError: state.features.auth.resetError,
      resetSuccess: state.features.auth.resetSuccess,
      isLoadingReset: state.features.auth.isLoading,
    }),
  );

  const handleSubmit = useCallback(
    async (data: FormInterface) => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
      }

      dispatch(setIsLoadingReset(true));
      setIsLoading(true);
      dispatch(createPassword(data.email));
    },
    [dispatch],
  );

  useEffect(() => {
    if (resetError === true && isLoadingReset === false) {
      (document.querySelector('#email') as any).focus();
    }
  }, [resetError, isLoadingReset]);

  useEffect((): any => {
    if (resetSuccess === true) {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Confira seu email para definir uma nova senha',
      });
      dispatch(setIsLoadingReset(false));
      dispatch(setResetSuccess(false));
      history.push('/login');
    }
  }, [resetSuccess]);

  useEffect(() => {
    setIsLoading(isLoadingReset);
  }, [isLoadingReset]);

  return (
    <Background>
      <Main>
        <WellcomeContainer>
          <div>
            <h1>Bem-vindo ao web portal Trans Real!</h1>
            <p>Insira seu email para recuperar sua senha.</p>
          </div>
          <img src={LogoTransreal} width="120px" alt="Transreal" />
        </WellcomeContainer>
        <Container>
          <img src={LogoTransrealAzul} width="180px" alt="Transreal" />
          <Form
            ref={formRef}
            initialData={{}}
            onSubmit={handleSubmit}
            noValidate
          >
            <InputIcon
              icon={FiMail}
              id="email"
              name="email"
              type="email"
              placeholder="E-mail"
              disabled={isLoading}
            />
            {resetError === true && <span>Email inválido</span>}
            <Button type="submit" isLoading={isLoading}>
              Enviar
            </Button>
          </Form>
          <Link to="/login">Voltar</Link>
        </Container>
      </Main>
    </Background>
  );
};

export default PasswordRecovery;
