import React, { useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

import { Link } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import { Panel } from './styles';

const Reports: React.FC = () => {
  const { userType } = useSelector((state: RootStateOrAny) => ({
    userType: state.features.auth.type,
  }));

  useEffect(() => {}, [userType]);

  return (
    <PageContainer>
      <MainTitle>Faturamento</MainTitle>
      <Panel>
        {userType === 'admin' && (
          <>
            <Link to="/consultar-faturas">
              <span>Faturas Cliente</span>
            </Link>
            <Link to="/consultar-documentos">
              <span>Documentos Cliente</span>
            </Link>
          </>
        )}

        {userType === 'salesman' && (
          <>
            <Link to="/consultar-faturas">
              <span>Faturas Cliente</span>
            </Link>
            <Link to="/consultar-documentos">
              <span>Documentos Cliente</span>
            </Link>
          </>
        )}

        {userType === 'client' && (
          <>
            <Link to="/consultar-faturas">
              <span>Faturas</span>
            </Link>
            <Link to="/consultar-documentos">
              <span>Documentos</span>
            </Link>
          </>
        )}
      </Panel>
    </PageContainer>
  );
};

export default Reports;
