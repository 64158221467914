/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { VscFileSymlinkFile } from 'react-icons/vsc';
import { Form } from '@unform/web';
import { FaTrash } from 'react-icons/fa';
import {
  ContainerSize,
  ButtonAddFile,
  ListFiles,
  Bar,
  ButtonGoBack,
} from './styles';

import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import Modal from '../../components/Modal';
import UploadArea from '../../components/UploadArea';

import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';

import GatheringOrderEntry from './entry';
import { gatheringOrderSlice } from '../../features/gatheringOrder';
import {
  addNewFile,
  fetchFilesByOrder,
  removeFile,
} from '../../features/gatheringOrderFiles';

interface UserParams {
  id: string | undefined;
}

interface Documents {
  id: number;
  file_name: string;
  client_id: number;
  file_link: string;
}
const GatheringOrder: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const [files, setFiles] = useState<Array<Documents>>([]);
  const { params } = useRouteMatch<UserParams>();
  const { setGatheringOrderId } = gatheringOrderSlice.actions;
  const { gatheringOrderId, cubageFactor, statusGatheringOrder } = useSelector(
    (state: RootStateOrAny) => ({
      gatheringOrderId: state.features.gatheringOrder.gatheringOrderId,
      statusGatheringOrder: state.features.gatheringOrder.statusGatheringOrder,
      cubageFactor: state.features.client.clientCubageFactor,
    }),
  );
  /*  let partToRender = (
    <GatheringOrderEntry gatheringOrderId={gatheringOrderId} />
  );
  if (mainTitle === 'Ordem de Coleta - Itens') {
    partToRender = (
      <GatheringOrderItens
        isVisible={true}
        gatheringOrderId={gatheringOrderId}
      />
    );
  } else {
    partToRender = <GatheringOrderEntry gatheringOrderId={gatheringOrderId} />;
  } */

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (gatheringOrderId > 0) {
      (async () => {
        const gatheringOrderFile = await fetchFilesByOrder({
          collection_order_id: gatheringOrderId,
        });
        setFiles(gatheringOrderFile);
      })();
    }
  }, [gatheringOrderId]);

  useEffect(() => {
    if (params.id) {
      dispatch(setGatheringOrderId(params.id));
    } else {
      dispatch(setGatheringOrderId(0));
    }
  }, []);

  useEffect(() => {}, [cubageFactor]);

  const submitFiles = useCallback((): void => {}, []);

  const handleChangeAddDocs = useCallback(
    async (e: any): Promise<any> => {
      if (e.target.files.length > 0) {
        showLoading();
        const promises = [];
        const fileNames = Array.from(e.target.files).map(
          ({ name }: any) => name,
        );
        for (let i = 0; i < fileNames.length; i++) {
          promises.push(
            addNewFile(gatheringOrderId, e.target.files[i], fileNames[i]),
          );
        }
        await Promise.all(promises);
        const orderFiles = await fetchFilesByOrder({
          collection_order_id: gatheringOrderId,
        });
        setFiles(orderFiles);
        hideLoading();
      }
    },
    [gatheringOrderId, hideLoading, showLoading],
  );

  const removeDocument = useCallback(
    async (id: number): Promise<void> => {
      showLoading();
      await removeFile(gatheringOrderId, id);
      setFiles(state => state.filter(file => file.id !== id));
      addToast({
        title: 'Sucesso',
        type: 'success',
        description: 'Arquivo excluído com sucesso',
      });
      hideLoading();
    },
    [showLoading, gatheringOrderId, addToast, hideLoading],
  );

  return (
    <PageContainer>
      <MainTitle>Ordem de coleta</MainTitle>
      <Bar>
        <ButtonGoBack type="button" onClick={() => history.goBack()}>
          <FiArrowLeft size={30} />
        </ButtonGoBack>
        <ContainerSize size="70%" />
        <ContainerSize size="20%">
          {gatheringOrderId ? (
            <ButtonAddFile
              type="button"
              className="attachments-btn"
              onClick={openModal}
            >
              <span>Arquivos</span>
              <VscFileSymlinkFile size={20} />
            </ButtonAddFile>
          ) : (
            <></>
          )}
        </ContainerSize>
      </Bar>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Arquivos"
      >
        <div>
          <Form initialData={{}} onSubmit={submitFiles}>
            <UploadArea multiple name="anexos" onChange={handleChangeAddDocs} />
          </Form>
          <ListFiles>
            {files.map(file => (
              <div key={file.id}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  download={file.file_name}
                  href={file.file_link}
                >
                  {file.file_name}
                </a>
                <button
                  type="button"
                  onClick={() => {
                    removeDocument(file.id);
                  }}
                >
                  <FaTrash size={20} />
                </button>
              </div>
            ))}
          </ListFiles>
        </div>
      </Modal>
      <GatheringOrderEntry
        gatheringOrderId={gatheringOrderId}
        statusGatheringOrder={statusGatheringOrder}
        cubageFactor={cubageFactor}
      />
    </PageContainer>
  );
};

export default GatheringOrder;
