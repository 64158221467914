import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  tollTypeId: 0,
};

export const tollTypeSlice = createSlice({
  name: 'tollType',
  initialState,
  reducers: {
    setTollTypeId: (state, { payload: tollTypeId }) => {
      state.tollTypeId = !tollTypeId ? initialState.tollTypeId : tollTypeId;
    },
  },
});

export interface tollTypeData {
  id?: number;
  name: string;
}

interface ResponseData {
  data: tollTypeData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGet {
  data: tollTypeData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getTollType = async (
  tollTypeId: number,
): Promise<ResponseDataGet> => {
  const response = await axios.get(`/local/toll_types/${tollTypeId}`);
  return response;
};

export const fetchTollTypeByName = async (name: string): Promise<any> => {
  const { data: regions } = await axios.get('/local/toll_types', {
    params: { name },
  });
  return regions.data;
};

export const addNewTollType = async (
  tollType: tollTypeData,
): Promise<ResponseData> => {
  const response = await axios.post('/local/toll_types', tollType);
  return response;
};

export const updateTollType = async (
  id: number,
  tollType: tollTypeData,
): Promise<ResponseData> => {
  const response = await axios.put(`/local/toll_types/${id}`, tollType);
  return response;
};

export const { setTollTypeId } = tollTypeSlice.actions;

export default tollTypeSlice.reducer;
