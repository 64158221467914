/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  InputHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { Container, ContainerInput, ContainerError } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  onBlurParent?: any;
}

const CurrencyInput: React.FC<InputProps> = ({
  name,
  placeholder,
  onBlurParent,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    if (onBlurParent) {
      onBlurParent();
    }
    setIsFocused(false);
    setIsFilled(!!(inputRef.current as any).value);
  }, []);

  const handleInputKeyUp = useCallback((e: any): void => {
    const digits = '0123456789,';
    let input_temp;
    const input = e.target;
    let decimal = false;
    let decimals = 0;
    let first_digit = true;
    for (let i = 0; i < (input as any).value.length; i++) {
      input_temp = (input as any).value.substring(i, i + 1);
      if (digits.indexOf(input_temp) === 10) {
        if (decimal === false && first_digit === false) {
          decimal = true;
        } else {
          (input as any).value = (input as any).value.substring(0, i);
          break;
        }
      }
      if (decimal === true && digits.indexOf(input_temp) !== -1) {
        decimals++;
      }
      if (decimals === 4) {
        (input as any).value = (input as any).value.substring(0, i);
      }
      if (digits.indexOf(input_temp) === -1) {
        (input as any).value = (input as any).value.substring(0, i);
        break;
      }
      first_digit = false;
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        (inputRef.current as any).value = value;
        setIsFilled(!!(inputRef.current as any).value);
      },
      clearValue() {
        (inputRef.current as any).value = '';
        setIsFilled(!!(inputRef.current as any).value);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setIsFilled(!!(inputRef.current as any).value);
  }, [inputRef.current]);

  useEffect(() => {}, [isFilled]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
        <ContainerInput>
          <label htmlFor={fieldName}>
            {isFilled ? placeholder?.replace('*', '') : placeholder}
          </label>
          <input
            id={fieldName}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyUp={handleInputKeyUp}
            defaultValue={defaultValue}
            ref={inputRef}
            type="text"
            autoComplete="off"
            {...rest}
          />
        </ContainerInput>
      </Container>
      {error && <ContainerError>{error}</ContainerError>}
    </div>
  );
};

export default CurrencyInput;
