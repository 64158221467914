/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import download from 'downloadjs';

import { FaClipboardList, FaFilePdf } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { RiRoadMapLine } from 'react-icons/ri';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce/lib';
import XmlIcon from '../../../assets/XmlIcon.png';
import {
  getClientDocuments,
  downloadClientDocument,
  downloadCSV,
  downloadPDF,
  clientDocumentsSlice,
} from '../../../features/clientDocuments';

import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';

import PageContainer from '../../../components/PageContainer';
import TableList from '../../../components/TableList';
import Filter from '../../../components/Filter';
import Input from '../../../components/Input';
import Pagination from '../../../components/Pagination';

import MainTitle from '../../../components/MainTitle';
import { FloatButton } from '../../ListTrackingsGrouped/styles';
import { filterSlice } from '../../../features/filter';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../../features/clients';
import Autocomplete from '../../../components/Autocomplete';

import { DocInformation } from './styles';

interface Obj {
  [key: string]: string;
}

const ListClientDocuments: React.FC = () => {
  const {
    currentListPage,
    formValue,
    locationFilter,
    userType,
    userTypeId,
    userId,
  } = useSelector((state: RootStateOrAny) => ({
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    currentListPage: state.features.clientInvoices.currentListPage,
    formValue: state.features.filter.formValue,
    locationFilter: state.features.filter.location,
  }));
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? [] : formValue,
  );
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const { showLoading, hideLoading } = useLoading();
  const { setCurrentListPage } = clientDocumentsSlice.actions;
  const { setFormValue } = filterSlice.actions;

  const handleDownloadDocument = useCallback(
    async (id: number): Promise<any> => {
      showLoading();
      try {
        const file = await downloadClientDocument(id);
        if (file.status === 200) {
          download(
            `data:application/octet-stream;base64,${file.data.file}`,
            file.data.file_name,
            'application/octet-stream;base64',
          );
          addToast({
            title: 'Sucesso',
            type: 'success',
            description: 'Documento baixado com sucesso',
          });
        } else {
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Documento não encontrado',
          });
        }
      } catch {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Documento não encontrado',
        });
      }

      hideLoading();
    },
    [addToast, hideLoading, showLoading],
  );

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const clientDocuments = await getClientDocuments({
        page,
        ...optionsSearch,
      });
      const arrayPages = [];
      for (let i = 0; i < clientDocuments.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      dispatch(setCurrentListPage(page));
      setCurrentPage(page);

      const clientDocumentsResult: Array<object> = [];

      clientDocuments.data.data.forEach((item: any) => {
        clientDocumentsResult.push([
          <>
            {item.code} <br /> Série {item.series}
          </>,
          item.type,
          <DocInformation>
            <span>{item.situation === 'E' ? 'Emitido' : 'D'}</span>
            <span>Faturado: {item.billed}</span>
          </DocInformation>,
          new Date(item.date_doc).toLocaleString(),
          item.sender_location,
          item.receiver_location,
          item.dockey,
          <div className="options-table-list">
            <button
              type="button"
              onClick={() => {
                handleDownloadDocument(item.id);
              }}
              title="Baixar documento"
            >
              <img
                src={XmlIcon}
                alt="Xml"
                style={{ filter: 'invert(100%)' }}
                width="20"
              />
            </button>
            <Link
              to={`/consultar-mercadoria/${item.code}/document/${item.client_id}`}
              title="Visualizar rastreio"
            >
              <RiRoadMapLine size={20} color="#ffffff" />
            </Link>
          </div>,
        ]);
      });
      setResults(clientDocumentsResult);
      hideLoading();
      setIsLoadingPage(false);
    },
    [showLoading, optionsSearch, hideLoading, addToast, handleDownloadDocument],
  );

  const handleSubmitForm = useCallback((data: Obj): void => {
    setCurrentPage(1);
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        searchData[key.replace('-', '.')] = value;
      }
    }
    dispatch(setFormValue(searchData));
    setOptionsSearch(searchData);
  }, []);

  const handleClickDownloadCSV = useCallback(async () => {
    showLoading();
    try {
      const response = await downloadCSV({
        ...optionsSearch,
      });
      const fileName = `documentos-${new Date().toLocaleDateString()}`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Faturas!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  const handleClickDownloadPDF = useCallback(async () => {
    showLoading();
    try {
      const response = await downloadPDF({
        ...optionsSearch,
      });
      const fileName = `documentos-${new Date().toLocaleDateString()}.pdf`;

      download(response.data, fileName, 'application/pdf');
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Documentos!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  useEffect(() => {
    getData(currentPage);
  }, [optionsSearch]);

  useEffect(() => {}, [pages]);

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } = await fetchClientsByDocumentNumber({
          document_number: valueSearchClient,
          ...isSalesman,
        });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } = await fetchUserClientsByDocumentNumber({
          'client.document_number': valueSearchClient,
          user_id: userId,
        });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [userId, userType, userTypeId, valueSearchClient]);

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  return (
    <PageContainer>
      <MainTitle>
        {' '}
        <FaClipboardList />
        Consultar Documentos Emitidos
      </MainTitle>
      <TableList
        header={[
          'N° - Série',
          'Tipo',
          'Status',
          'Data/Hora',
          'Local Coleta',
          'Local Entrega',
          'Chave',
          '',
        ]}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
        isLoadingPage={isLoadingPage}
      />
      <Filter onSubmit={handleSubmitForm}>
        <Autocomplete
          name="client_documents-client_id"
          options={optionsClients}
          placeholder="Cliente"
          changeValue={null}
          onInputChange={handleClientInputChange}
          isLoading={isLoadingClients}
        />
        <Input
          type="text"
          name="client_documents-code"
          placeholder="N°. Documento"
        />
        <Input type="text" name="client_documents-series" placeholder="Série" />
        <Input type="text" name="client_documents-type" placeholder="Tipo" />
        <Input type="date" name="client_documents-date_doc>" placeholder="De" />
        <Input
          type="date"
          name="client_documents-date_doc<"
          placeholder="Até"
        />
      </Filter>
      <FloatButton
        type="button"
        title="Exportar em CSV"
        onClick={() => handleClickDownloadCSV()}
      >
        <SiMicrosoftexcel size={25} />
      </FloatButton>
      <FloatButton
        type="button"
        title="Exportar em PDF"
        onClick={() => handleClickDownloadPDF()}
        style={{ bottom: '154px' }}
      >
        <FaFilePdf size={25} />
      </FloatButton>
    </PageContainer>
  );
};

export default ListClientDocuments;
