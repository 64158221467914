import styled from 'styled-components';

export const PaginationContainer = styled.div`
  width: 100%;
  height: auto;

  .pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    outline: none;
  }
  .pagination > li {
    margin: 3px;
    margin-top: 10px;
  }
  .pagination > li > a {
    padding: 5px 10px;
    border: thin;
    border-radius: 8px;
    background-color: #e7ecf8;
    color: #809ed3;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    color: #aeb9cd;
    cursor: unset;
  }

  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    color: #aeb9cd;
  }

  .pagination > li > a,
  .pagination > li > span {
    background-color: #e7ecf8;
    color: #809ed3;
  }
`;
