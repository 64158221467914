/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { IoIosAddCircle } from 'react-icons/io';
import moment from 'moment';
import { SiMicrosoftexcel } from 'react-icons/si';
import { FaFilePdf } from 'react-icons/fa';
import download from 'downloadjs';
import {
  ViewContainer,
  Row,
  ContainerSize,
  TotalContainers,
  BadgeStatues,
  FloatButton,
  DocInformation,
} from './styles';
import Modal from '../../components/Modal';
import PageContainer from '../../components/PageContainer';
import TableList from '../../components/TableList';
import Pagination from '../../components/Pagination';
import { formatValueToCurrencyInput } from '../../utils/formatValueCurrencyInput';

import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';

import {
  fetchCollectionOrderWithParams,
  getInfosCollections,
} from '../../features/collectionOrder';

import Filter from '../../components/Filter';
import Input from '../../components/Input';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../features/clients';
import Autocomplete from '../../components/Autocomplete';
import MainTitle from '../../components/MainTitle';
import {
  setGatheringOrderId,
  gatheringOrderSlice,
  downloadCSV,
  downloadPDF,
} from '../../features/gatheringOrder';
import { getClientBusinessPersonbyName } from '../../features/clientBusinessPerson';
import { getBusinessPersonbyName } from '../../features/businessPerson';
import { filterSlice } from '../../features/filter';

const ListCollection: React.FC = () => {
  const {
    userType,
    userTypeId,
    userId,
    gatheringOrderId,
    currentListPage,
    formValue,
    locationFilter,
  } = useSelector((state: RootStateOrAny) => ({
    quotationId: state.features.quotation.quotationId,
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    gatheringOrderId: state.features.gatheringOrder.gatheringOrderId,
    currentListPage: state.features.gatheringOrder.currentListPage,
    formValue: state.features.filter.formValue,
    locationFilter: state.features.filter.location,
  }));
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? [] : formValue,
  );
  const [showModalFreightTable, setShowModalFreightTable] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const dispatch = useDispatch();
  const {
    setStatusGatheringOrder,
    setCurrentListPage,
  } = gatheringOrderSlice.actions;
  const { setFormValue } = filterSlice.actions;

  const [totalCollections, setTotalCollections] = useState(0);
  const [openCollections, setOpenCollections] = useState(0);
  const [finishedCollections, setFinishedCollections] = useState(0);
  const [averageCollections, setAverageCollections] = useState(0);
  const [updateData, setUpdateData] = useState(false);
  const [optionsBusinessPersonRem, setOptionsBusinessPersonRem] = useState<any>(
    [],
  );
  const [searchBusinessPersonRem, setSearchBusinessPersonRem] = useState('');
  const [valueSearchBusinessPersonRem] = useDebounce(
    searchBusinessPersonRem,
    1000,
  );

  const [optionsBusinessPersonDest, setOptionsBusinessPersonDest] = useState<
    any
  >([]);
  const [searchBusinessPersonDest, setSearchBusinessPersonDest] = useState('');
  const [valueSearchBusinessPersonDest] = useDebounce(
    searchBusinessPersonDest,
    1000,
  );
  const [isLoadingBusinessPersonRem, setIsLoadingBusinessPersonRem] = useState(
    false,
  );
  const [
    isLoadingBusinessPersonDest,
    setIsLoadingBusinessPersonDest,
  ] = useState(false);

  const optionsStatus = [
    {
      label: 'Aprovada',
      value: 'A',
    },
    {
      label: 'Reprovada',
      value: 'R',
    },
    {
      label: 'Aguardando Aprovação',
      value: 'I',
    },
  ];
  /*

  const handleDeleteCollect = useCallback(
    async (id: number): Promise<void> => {
      showLoading();
      const response = await deleteCollection(id);
      hideLoading();
      if (response.status === 200) {
        addToast({
          title: 'Ordem de coleta excluída com sucesso',
          type: 'success',
        });
        setUpdateData(true);
      } else {
        addToast({
          title: 'Erro ao excluir ordem de coleta',
          type: 'error',
        });
      }
    },
    [showLoading, hideLoading, setUpdateData],
  ); */

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const collections = await fetchCollectionOrderWithParams({
        page,
        ...optionsSearch,
      });
      const arrayPages = [];
      for (let i = 0; i < collections.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));

      const { data: infos } = await getInfosCollections({ ...optionsSearch });
      setTotalCollections(infos.total);
      setOpenCollections(infos.opened);
      setFinishedCollections(infos.closed);
      setAverageCollections(infos.average);
      hideLoading();
      addToast({
        title: 'Ordens de coletas carregadas com sucesso',
        type: 'success',
      });

      const collectionResult: Array<object> = [];

      collections.data.data.forEach((item: any) => {
        collectionResult.push([
          <>
            {item.id} <br />
            {moment.utc(item.issuance_date).format('DD/MM/YYYY')}
          </>,
          item.client.document_number,
          `${item.origin.name}-${item.origin.uf} -> ${item.destination.name}-${item.destination.uf}`,
          <DocInformation>
            <span>Volume: {formatValueToCurrencyInput(item.volume)}</span>
            <span>Peso: {formatValueToCurrencyInput(item.weight)}</span>
            <span>Cubagem: {formatValueToCurrencyInput(item.cubage)}</span>
            <span>
              Valor NFE: R$ {formatValueToCurrencyInput(item.nfe_value)}
            </span>
          </DocInformation>,
          item.status !== 'A' && item.status !== 'R' ? (
            <BadgeStatues type="waiting">Aguardando Aprovação</BadgeStatues>
          ) : item.status === 'A' ? (
            <BadgeStatues type="approved">Aprovada</BadgeStatues>
          ) : (
            <BadgeStatues type="canceled">Cancelada</BadgeStatues>
          ),
          item.status !== 'A' && item.status !== 'R' ? (
            <div className="options-table-list">
              <Link to={`/ordem-de-coleta/${item.id}`}>
                <AiOutlineFileSearch size={20} color="#ffffff" />
              </Link>
            </div>
          ) : (
            <div className="options-table-list">
              <Link to={`/ordem-de-coleta/${item.id}`}>
                <AiOutlineFileSearch size={20} color="#ffffff" />
              </Link>
            </div>
          ),
        ]);
      });
      setResults(collectionResult);
      setUpdateData(false);
      setIsLoadingPage(false);
    },
    [
      addToast,
      hideLoading,
      showLoading,
      optionsSearch,
      setUpdateData,
      setResults,
    ],
  );

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } = await fetchClientsByDocumentNumber({
          document_number: valueSearchClient,
          ...isSalesman,
        });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } = await fetchUserClientsByDocumentNumber({
          'client.document_number': valueSearchClient,
          user_id: userId,
        });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [userId, userType, userTypeId, valueSearchClient]);

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback((data: Obj): void => {
    setCurrentPage(1);
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        searchData[key.replace('-', '.')] = value;
      }
    }
    dispatch(setFormValue(searchData));
    setOptionsSearch(searchData);
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [optionsSearch]);

  useEffect(() => {
    if (updateData === true) {
      getData(currentPage);
    }
  }, [updateData]);

  useEffect(() => {
    (async () => {
      const allBusinessPersonRem: any = [];
      if (userType === 'saleman' || userType === 'client') {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });
        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });
        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonRem(allBusinessPersonRem);
      setIsLoadingBusinessPersonRem(false);
    })();
  }, [valueSearchBusinessPersonRem]);

  useEffect(() => {
    (async () => {
      const allBusinessPersonDest: any = [];

      if (userType === 'saleman' || userType === 'client') {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonDest(allBusinessPersonDest);
      setIsLoadingBusinessPersonDest(false);
    })();
  }, [valueSearchBusinessPersonDest]);

  useEffect(() => {
    dispatch(setGatheringOrderId(0));
    dispatch(setStatusGatheringOrder('I'));
  }, [dispatch, gatheringOrderId]);

  const handleBusinessPersonRemInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonRem) {
      setIsLoadingBusinessPersonRem(true);
    }
    setSearchBusinessPersonRem(newValue);
  };

  const handleBusinessPersonDestInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonDest) {
      setIsLoadingBusinessPersonDest(true);
    }
    setSearchBusinessPersonDest(newValue);
  };

  const handleClickDownloadCSV = useCallback(async () => {
    showLoading();

    try {
      const response = await downloadCSV({
        ...optionsSearch,
      });
      const fileName = `coletas-${new Date().toLocaleDateString()}`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Faturas!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  const handleClickDownloadPDF = useCallback(async () => {
    showLoading();

    try {
      const response = await downloadPDF({
        ...optionsSearch,
      });
      const fileName = `coletas-${new Date().toLocaleDateString()}.pdf`;
      download(response.data, fileName, 'application/pdf');
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Faturas!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  return (
    <PageContainer>
      <MainTitle>Ordens de Coleta</MainTitle>
      <TotalContainers>
        <div>
          <span>{totalCollections}</span>
          <span>TOTAL DE COLETAS</span>
        </div>

        <div>
          <span>{openCollections}</span>
          <span>COLETAS EM ABERTO</span>
        </div>

        <div>
          <span>{finishedCollections}</span>
          <span>COLETAS FINALIZADAS</span>
        </div>

        <div>
          <span>{averageCollections}</span>
          <span>MÉDIA DE COLETAS P/MÊS</span>
        </div>
      </TotalContainers>
      <ViewContainer>
        <Link to="/ordem-de-coleta">
          <IoIosAddCircle size={20} />
          Coleta
        </Link>
      </ViewContainer>

      <hr className="hr" />

      <TableList
        header={[
          'ID - Data',
          'CPF/CNPJ',
          'Origem e Destino',
          'Carga',
          'Status',
          'Opções',
        ]}
        data={results}
      />
      <Row>
        <ContainerSize size="100%">
          <Pagination
            pages={pages}
            currentPage={currentPage}
            onClickFunction={getData}
            isLoadingPage={isLoadingPage}
          />
        </ContainerSize>
      </Row>
      <Filter onSubmit={handleSubmitForm}>
        <Autocomplete
          name="collections_order-client_id"
          options={optionsClients}
          placeholder="Cliente"
          changeValue={null}
          onInputChange={handleClientInputChange}
          isLoading={isLoadingClients}
        />
        <Input
          type="string"
          name="clients-document_number"
          placeholder="CPF/CNPJ"
        />
        <Autocomplete
          name="collections_order-sender_id"
          placeholder="Remetente"
          options={optionsBusinessPersonRem}
          changeValue={null}
          isLoading={isLoadingBusinessPersonRem}
          onInputChange={handleBusinessPersonRemInputChange}
        />
        <Autocomplete
          name="collections_order-receiver_id"
          placeholder="Destinatario"
          options={optionsBusinessPersonDest}
          changeValue={null}
          isLoading={isLoadingBusinessPersonDest}
          onInputChange={handleBusinessPersonDestInputChange}
        />
        <Autocomplete
          name="collections_order-status"
          placeholder="Status"
          options={optionsStatus}
          changeValue={null}
        />
        <Input
          type="date"
          name="collections_order-issuance_date>"
          placeholder="De"
        />
        <Input
          type="date"
          name="collections_order-issuance_date<"
          placeholder="Até"
        />
      </Filter>
      <FloatButton
        type="button"
        title="Exportar em Excel"
        onClick={() => handleClickDownloadCSV()}
      >
        <SiMicrosoftexcel size={25} />
      </FloatButton>
      <FloatButton
        type="button"
        title="Exportar em PDF"
        onClick={() => handleClickDownloadPDF()}
        style={{ bottom: '154px' }}
      >
        <FaFilePdf size={25} />
      </FloatButton>
      <Modal
        backgroundColor="#F8F8FB"
        fontColor="#202020"
        isOpen={showModalFreightTable}
        title="Informações Tabela de Frete"
        id="modal-freight-table"
        onClose={() => setShowModalFreightTable(false)}
      >
        <div>teste</div>
      </Modal>
    </PageContainer>
  );
};

export default ListCollection;
