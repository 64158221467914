/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FaEdit } from 'react-icons/fa';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { GiReceiveMoney } from 'react-icons/gi';

import PageContainer from '../../components/PageContainer';
import filterSvg from '../../assets/Filter.svg';
import LupePng from '../../assets/Lupe2x.png';
import MainTitle from '../../components/MainTitle';
import Input from '../../components/Input';
import TableList from '../../components/TableList';

import {
  Row,
  TitleSpan,
  TitleContainer,
  SpecialContainer,
  ContainerSize,
} from './styles';

import { useLoading } from '../../hooks/loading';
import Pagination from '../../components/Pagination';
import ButtonForm from '../../components/ButtonForm';

import { fetchSalesmanWithParams } from '../../features/salesman';

import { formatValueToCurrencyInput } from '../../utils/formatValueCurrencyInput';

const ListClient: React.FC = () => {
  const formRef = React.useRef<FormHandles>(null);
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const { showLoading, hideLoading } = useLoading();

  interface SalesmanData {
    id: number;
    user_id: number;
    salesname: string;
    commission: number;
    branch: {
      name: string;
    };
    city: {
      name: string;
      uf: string;
    };
    created_at: Date;
  }

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const salesmans = await fetchSalesmanWithParams({
        page,
        ...optionsSearch,
      });
      const arraySalesman: Array<Array<any>> = [];
      salesmans.data.forEach((user: SalesmanData) => {
        arraySalesman.push([
          user.id,
          user.salesname,
          `${formatValueToCurrencyInput(user.commission)} %`,
          user.branch.name,
          `${user.city.name} - ${user.city.uf}`,
          new Date(user.created_at).toLocaleString('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }),
          <div className="options-table-list">
            {user.user_id === null && (
              <Link
                to={`/usuario?salesman_id=${user.id}&salesname=${user.salesname}`}
              >
                <AiOutlineUserAdd size={20} />
              </Link>
            )}
            <Link to="/">
              <GiReceiveMoney size={20} />
            </Link>
            <Link to="/">
              <FaEdit size={20} />
            </Link>
          </div>,
        ]);
      });
      setResults(arraySalesman);
      const arrayPages = [];
      for (let i = 0; i < salesmans.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);

      hideLoading();
    },
    [setPages, setCurrentPage, setResults, optionsSearch],
  );

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      const searchData: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          searchData[key] = value;
        }
      }
      setOptionsSearch(searchData);
    },
    [setOptionsSearch],
  );

  useEffect(() => {
    getData(1);
  }, [optionsSearch]);

  return (
    <PageContainer>
      <TitleContainer>
        <MainTitle>Consulta de Vendedores</MainTitle>
      </TitleContainer>

      <SpecialContainer>
        <TitleSpan>
          <img src={filterSvg} alt="Filter" />
          Filtros :
        </TitleSpan>
        <Form
          ref={formRef}
          initialData={{}}
          onSubmit={handleSubmitForm}
          noValidate
          autoComplete="new-password"
        >
          <Row>
            <ContainerSize size="30%">
              <Input name="salesman.salesname" type="text" placeholder="Nome" />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="salesman.created_at>"
                type="date"
                placeholder="Data Inicial"
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="salesman.created_at<"
                type="date"
                placeholder="Data Final"
              />
            </ContainerSize>
            <ContainerSize size="20%">
              <ButtonForm type="submit">
                <img src={LupePng} alt="Buscar" width="20px" height="20px" />
                Buscar
              </ButtonForm>
            </ContainerSize>
          </Row>
        </Form>
        <Row>
          <ContainerSize size="100%">
            <TableList
              header={[
                'Código',
                'Nome',
                'Comissão',
                'Filial',
                'Cidade',
                'Data',
                'Opções',
              ]}
              data={results}
            />
          </ContainerSize>
        </Row>
        <Row>
          <ContainerSize size="100%">
            <Pagination
              pages={pages}
              currentPage={currentPage}
              onClickFunction={getData}
            />
          </ContainerSize>
        </Row>
      </SpecialContainer>
    </PageContainer>
  );
};

export default ListClient;
