import styled from 'styled-components';

interface PanelProps {
  itemSize: string;
}

export const Panel = styled.div<PanelProps>`
  margin-top: 40px;
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
  a {
    width: calc(${props => props.itemSize} - 20px);
    margin: 10px;
    text-decoration: none;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #aeb9cd;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 3px;

    text-transform: uppercase;
    background: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6px; */
    transition: 0.3s all ease-in;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 6px;
      color: #1f3aa4;

      svg {
        color: #1f3aa4;
      }
    }

    svg {
      margin-bottom: 15px;
      width: 30%;
      height: auto;
      color: #e3e7f3;
      transition: 0.3s all ease-in;
    }

    @media (max-width: 740px) {
      width: 100%;
    }
  }
`;

export const WellcomeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  margin-top: 40px;
  padding: 30px 45px;
  border-radius: 12px;

  background: rgb(0, 60, 166);
  background: linear-gradient(
    90deg,
    rgba(0, 60, 166, 1) 0%,
    rgba(111, 145, 205, 1) 100%
  );

  > img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
  }

  > div {
    margin-left: 35px;
    color: #f8f8fb;

    h1 {
      font-size: 2.5rem;
      text-transform: capitalize;
    }

    p {
      font-size: 18px;
    }
  }

  @media (max-width: 600px) {
    align-items: flex-start;
    padding: 30px 20px;
    > div {
      margin-left: 15px;
      h1 {
        font-size: 1.5rem;
        text-transform: capitalize;
      }
    }
  }
`;
