import styled, { css } from 'styled-components';

import BagAvatar from '../../assets/bag-avatar.svg';

interface ItemProps {
  isOpened: boolean;
}

export const MenuContainer = styled.header`
  > img {
    width: 80px;
    margin-bottom: 30px;
    margin-top: 30px;
    position: relative;

    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  hr {
    width: 80px;
    background-color: rgba(253, 253, 253, 0.05);
    height: 1px;
    border-width: 0px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-left: 5px;
    display: none;

    @media (max-width: 768px) {
      display: block;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  min-height: 100vh;
  width: 100px;
  background-color: #003ca6;
  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 80px;
    min-height: 80px;
    box-shadow: 0px 2px 17px 1px rgba(0, 60, 166, 0.2);
  }

  > a.profile-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-radius: 0;

    @media (max-width: 768px) {
      display: flex;
      width: auto;
      padding: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      z-index: 4;
      bottom: 25px;
      right: 20%;
      width: 25px;
      height: 25px;
      background-image: url(${BagAvatar});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @media (max-width: 768px) {
        bottom: 25px;
        right: 22%;
        width: 15px;
        height: 15px;
      }
    }

    > img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      object-position: center center;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  button.logout-desktop {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      display: none;
    }
  }

  button.logout-mobile {
    display: none;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    width: 100%;
    border-radius: 0;
    padding: 20px 15px;
    margin: 0;
    span {
      text-align: left;
    }
    svg {
      size: 30px;
      height: 30px;
    }

    @media (max-width: 768px) {
      display: flex;
    }
  }

  a {
    width: 50%;
    border-radius: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    transition: 0.3s all linear;

    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0;
    }
  }

  a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const Items = styled.nav<ItemProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 0 !important;
    position: fixed;
    z-index: -2;
    margin-top: 50px;
    top: 0;
    bottom: 0;
    left: -80%;
    width: 80%;
    background-color: #003ca6;
    align-items: flex-start;
    opacity: 0;
    display: flex;
    transition: all 0.3s linear;
    ${props =>
      props.isOpened &&
      css`
        z-index: 9;
        opacity: 1;
        left: 0;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          margin-left: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(1px);
          z-index: 10;
        }
      `}
  }

  a {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 768px) {
      justify-content: flex-start;
      padding: 20px 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      width: 100%;
      svg {
        size: 30px;
        height: 30px;
      }
    }

    &:hover {
      span {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;

export const Tooltip = styled.span`
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 6;
  left: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 60, 166, 0.9);
  backdrop-filter: blur(3px);
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  min-width: 200px;
  transition: opacity 600ms, visibility 600ms;

  @media (max-width: 768px) {
    opacity: 1;
    visibility: visible;
    position: unset;
    display: inline-block;
    margin-left: 10px;
    margin-right: 0;
    background-color: transparent;
    padding: 0;
  }

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 60, 166, 0.9) transparent transparent;
    backdrop-filter: blur(3px);
  }
`;
