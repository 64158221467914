import styled from 'styled-components';

export const RadioGroup = styled.div`
  width: 100%;
  padding: 2% 0%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 700;

  > div {
    margin: 5px;
  }

  input {
    width: 22px;
    height: 22px;
    vertical-align: sub;
    margin-right: 4px;
  }

  .radio-control {
    width: fit-content;
    margin: 0 15px;
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: sub;
  }
`;

export const ErrorMessage = styled.div`
  width: 100%;
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;

  .error {
    color: #c53030;
    text-align: center;
  }
`;
