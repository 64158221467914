import React, { useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

import {
  FaTruckLoading,
  FaTruck,
  FaMapMarkerAlt,
  FaClipboardList,
} from 'react-icons/fa';
import { BiDollar, BiLinkAlt, BiAddToQueue } from 'react-icons/bi';
import { RiUserSearchLine } from 'react-icons/ri';
import { IoIosDocument, IoMdBusiness } from 'react-icons/io';

import { Link } from 'react-router-dom';
import { MdDriveEta } from 'react-icons/md';
import { BsPeopleFill } from 'react-icons/bs';
import PageContainer from '../../components/PageContainer';
import AvatarImg from '../../assets/avatar.png';

import { Panel, WellcomeContainer } from './styles';

const Dashboard: React.FC = () => {
  const { userName, userType, photoURL } = useSelector(
    (state: RootStateOrAny) => ({
      userName: state.features.auth.user,
      userType: state.features.auth.type,
      photoURL: state.features.auth.photoUrl,
    }),
  );

  useEffect(() => {}, [userType]);

  return (
    <PageContainer>
      <WellcomeContainer>
        <img src={photoURL || AvatarImg} alt="Avatar" />
        <div>
          <h1>Olá {userName.displayName},</h1>
          <p>Bem-vindo ao portal Trans Real!</p>
        </div>
      </WellcomeContainer>
      <Panel itemSize={userType === 'admin' ? '20%' : '20%'}>
        {userType === 'admin' && (
          <>
            <Link to="/consultar-cotacoes">
              <BiDollar />
              <span>Cotação</span>
            </Link>
            <Link to="/ordens-de-coleta">
              <FaTruckLoading />
              <span>Coleta</span>
            </Link>
            <Link to="/clientes">
              <RiUserSearchLine />
              <span>Cliente</span>
            </Link>
            <Link to="/rastreios">
              <FaMapMarkerAlt />
              <span>Rastreios</span>
            </Link>
            <Link to="/relatorios">
              <FaClipboardList />
              <span>Faturamento</span>
            </Link>
            <Link to="/tabelas-de-frete">
              <FaTruck />
              <span>Tabelas de Frete</span>
            </Link>
            <Link to="/lancar-ocorrencias">
              <IoIosDocument />
              <span>Documentos Transportador</span>
            </Link>
            <Link to="/vincular-documentos-transportador">
              <BiLinkAlt />
              <span>Vincular Transportador</span>
            </Link>
            <Link to="/ocorrencias">
              <BiAddToQueue />
              <span>Cadastrar Ocorrência</span>
            </Link>
            <Link to="/listar-parceiros-comerciais">
              <IoMdBusiness />
              <span>
                Remetente
                <br />
                Destinatário
              </span>
            </Link>
            <Link to="/motoristas">
              <BsPeopleFill />
              <span>
                Transportador
                <br />
                Motoristas
              </span>
            </Link>
            <Link to="/listar-parceiros-comerciais">
              <MdDriveEta />
              <span>
                Transportador
                <br />
                Veículos
              </span>
            </Link>
          </>
        )}

        {userType === 'salesman' && (
          <>
            <Link to="/consultar-cotacoes">
              <BiDollar />
              <span>Cotação</span>
            </Link>
            <Link to="/ordens-de-coleta">
              <FaTruckLoading />
              <span>Coleta</span>
            </Link>
            <Link to="/clientes">
              <RiUserSearchLine />
              <span>Cliente</span>
            </Link>
            <Link to="/rastreios">
              <FaMapMarkerAlt />
              <span>Rastreios</span>
            </Link>
            <Link to="/tabelas-de-frete">
              <FaTruck />
              <span>Tabelas de Frete</span>
            </Link>
            <Link to="/relatorios">
              <FaClipboardList />
              <span>Faturamento</span>
            </Link>
            <Link to="/listar-parceiros-comerciais">
              <IoMdBusiness />
              <span>
                Remetente
                <br />
                Destinatário
              </span>
            </Link>
          </>
        )}

        {userType === 'client' && (
          <>
            <Link to="/consultar-cotacoes">
              <BiDollar />
              <span>Cotação</span>
            </Link>
            <Link to="/ordens-de-coleta">
              <FaTruckLoading />
              <span>Coleta</span>
            </Link>
            <Link to="/rastreios">
              <FaMapMarkerAlt />
              <span>Rastreios</span>
            </Link>
            <Link to="/relatorios">
              <FaClipboardList />
              <span>Faturamento</span>
            </Link>
            <Link to="/listar-parceiros-comerciais">
              <IoMdBusiness />
              <span>
                Remetente
                <br />
                Destinatário
              </span>
            </Link>
          </>
        )}
      </Panel>
    </PageContainer>
  );
};

export default Dashboard;
