/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FaQuestionCircle } from 'react-icons/fa';
import { FiSend, FiClock, FiCheck } from 'react-icons/fi';
import { VscError } from 'react-icons/vsc';
import { useDebounce } from 'use-debounce/lib';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { IoIosAddCircle } from 'react-icons/io';
import moment from 'moment';
import { AppDispatch } from '../../../app/store';
import TableList from '../../../components/TableList';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
  getClient,
  clientsSlice,
} from '../../../features/clients';

import {
  getBusinessPersonbyName,
  getBusinessPerson,
  businessPersonSlice,
} from '../../../features/businessPerson';
import Textarea from '../../../components/Textarea';

import { getClientBusinessPersonbyName } from '../../../features/clientBusinessPerson';
import {
  addQuotation,
  updateQuotation,
  getQuotation,
  quotationSlice,
} from '../../../features/quotations';
import {
  addQuotationItem,
  quotationItemsSlice,
  ItensData,
} from '../../../features/quotationItens';

import getValidationErrors from '../../../utils/getValidationErrors';
import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';
import { useDialog } from '../../../hooks/dialog';

import {
  FormContainer,
  Row,
  ContainerSize,
  SpecialRow,
  FormButton,
  ContainerSizeAutoComplete,
  OCSContainer,
  ContainerMessage,
} from './styles';
import {
  formatValueToCurrencyInput,
  formatValueFromCurrencyInput,
} from '../../../utils/formatValueCurrencyInput';

import Autocomplete from '../../../components/Autocomplete';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';

import CheckImg from '../../../assets/checkmark.png';
import ReproveImg from '../../../assets/X Material Icon.svg';
import { cep } from '../../../utils/cep';
import { fetchCityByNameAndUF } from '../../../features/city';
import Modal from '../../../components/Modal';
import BusinessPersonForm from '../../../components/BusinessPersonForm';
import QuotationItens from '../Itens/index';
import { ErrorMessage } from '../../../components/Radio/styles';
import { getFreightTableTariffClientRoute } from '../../../features/freightTableTariff';

interface FormInterface {
  [key: string]: string;
}

interface EntryProps {
  quotationId: number;
  updateQuotationFlag: boolean;
  handleUpdateQuotation: any;
  statusQuotation: string;
}

const CreateQuotation: React.FC<EntryProps> = ({
  quotationId,
  updateQuotationFlag,
  handleUpdateQuotation,
  statusQuotation,
}) => {
  const formRef = useRef<FormHandles>(null);
  const modalFormRef = useRef<FormHandles>(null);
  const dispatch: AppDispatch = useDispatch();
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { setCubageFactor } = clientsSlice.actions;
  const { setItens } = quotationItemsSlice.actions;
  const [showModal, setShowModal] = useState(false);
  const [showFreightTables, setShowFreightTables] = useState(false);
  const [freightTablesClient, setFreightTablesClient] = useState<any>([]);
  const [freightTableTariffId, setFreightTableTariffId] = useState<number>(0);
  const [cubicMeters, setCubicMeters] = useState(0);
  const [disableAprove, setDisableAprove] = useState(false);
  const [optionsCity, setOptionsCity] = useState<any>([]);

  const [optionsClient, setOptionsClient] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [optionsBusinessPersonRem, setOptionsBusinessPersonRem] = useState<any>(
    [],
  );
  const [searchBusinessPersonRem, setSearchBusinessPersonRem] = useState('');
  const [valueSearchBusinessPersonRem] = useDebounce(
    searchBusinessPersonRem,
    1000,
  );

  const [optionsBusinessPersonDest, setOptionsBusinessPersonDest] = useState<
    any
  >([]);
  const [searchBusinessPersonDest, setSearchBusinessPersonDest] = useState('');
  const [valueSearchBusinessPersonDest] = useDebounce(
    searchBusinessPersonDest,
    1000,
  );
  const [isDraft, setIsDraft] = useState(false);

  const [isApproved, setIsApproved] = useState(false);
  const [isReproved, setIsReproved] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [quotationApproved, setQuotationApproved] = useState(false);
  const [isError, setIsErro] = useState(false);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isLoadingBusinessPersonRem, setIsLoadingBusinessPersonRem] = useState(
    false,
  );
  const [
    isLoadingBusinessPersonDest,
    setIsLoadingBusinessPersonDest,
  ] = useState(false);
  const [quotationValue, setQuotationValue] = useState(0);
  const [expectedDate, setExpectedDate] = useState('01/02/2021');
  const [phoneNumberMask, setPhoneNumberMask] = useState('(99) 9999-9999?');

  const [titleModal, setTitleModal] = useState('');
  const [showModalBp, setShowModalBp] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [clickCalculate, setClickCalculate] = useState(false);

  const [updateQuotationValue, setUpdateQuotationValue] = useState(false);

  const {
    setQuotationId,
    setStatusQuotation,
    setGeneratedCollect,
  } = quotationSlice.actions;
  const { setItensQuantity } = quotationItemsSlice.actions;
  const { createDialog } = useDialog();
  const {
    userType,
    userTypeId,
    userId,
    cubageFactor,
    bpId,
    itensQuotation,
    itensQuantity,
  } = useSelector((state: RootStateOrAny) => ({
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    cubageFactor: state.features.client.clientCubageFactor,
    bpId: state.features.businessPerson.bpId,
    itensQuotation: state.features.quotationItem.itensQuotation,
    itensQuantity: state.features.quotationItem.itensQuantity,
  }));

  let oldBusinessPersonId = 0;

  useEffect(() => {
    if (bpId > 0 && bpId !== oldBusinessPersonId) {
      oldBusinessPersonId = bpId;
      (async () => {
        showLoading();
        const businessPerson = await getBusinessPerson(bpId);
        (formRef.current as any).setFieldValue(titleModal, {
          value: bpId,
          label: businessPerson.data.name,
        });
        hideLoading();
        setShowModalBp(false);
      })();
    }
  }, [bpId]);

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } = await fetchClientsByDocumentNumber({
          document_number: valueSearchClient,
          ...isSalesman,
        });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.name ? client.name : client.company_name} - ${
              client.document_number
            }`,
          });
        });
      } else {
        const { data: clients } = await fetchUserClientsByDocumentNumber({
          'client.document_number': valueSearchClient,
          user_id: userId,
        });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${
              item.client.name ? item.client.name : item.client.company_name
            } - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClient(allClients);
      setIsLoadingClient(false);
    })();
  }, [userId, userType, userTypeId, valueSearchClient]);

  useEffect(() => {
    (async () => {
      const allBusinessPersonRem: any = [];
      if (userType === 'salesman' || userType === 'client') {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });

        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });

        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonRem(allBusinessPersonRem);
      setIsLoadingBusinessPersonRem(false);
    })();
  }, [userType, valueSearchBusinessPersonRem]);

  const handleLoadCepWithoutSender = useCallback(
    async (value: string, type: string) => {
      const cepSearch = value.replace('_', '');
      if (cepSearch.length === 10) {
        const fieldName = type.includes('origem') ? 'origem' : 'destino';
        const addressInputs = type.includes('origem') ? 'sender' : 'receiver';
        const searchAddress = await cep(cepSearch);
        if (typeof searchAddress.erro === 'undefined') {
          const cities = await fetchCityByNameAndUF({
            name: searchAddress.localidade,
            uf: searchAddress.uf,
          });
          const allCities: any = [];
          cities.forEach((city: any): any => {
            allCities.push({
              value: city.id,
              label: `${city.name}-${searchAddress.uf}`,
            });
          });
          setOptionsCity(allCities);
          (formRef.current as any).setFieldValue(
            fieldName,
            allCities[allCities.length - 1],
          );
          (formRef.current as any).setFieldValue(
            `${addressInputs}_address`,
            searchAddress.logradouro,
          );
          (formRef.current as any).setFieldValue(
            `${addressInputs}_district`,
            searchAddress.bairro,
          );

          (formRef.current as any).setFieldError(`cep-${fieldName}`, '');
        } else {
          (formRef.current as any).setFieldError(
            `cep-${fieldName}`,
            'CEP não encontrado',
          );
        }
      }
    },
    [],
  );

  useEffect(() => {
    (async () => {
      const allBusinessPersonDest: any = [];

      if (userType === 'salesman' || userType === 'client') {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonDest(allBusinessPersonDest);
      setIsLoadingBusinessPersonDest(false);
    })();
  }, [userType, valueSearchBusinessPersonDest]);

  useEffect(() => {
    dispatch(setItens([]));
    dispatch(setItensQuantity(0));
    if (quotationId > 0) {
      (async () => {
        showLoading();
        setExpectedDate('01/02/2021');
        const { data: quotation } = await getQuotation(Number(quotationId));
        dispatch(setStatusQuotation(quotation.status));
        const phoneNumber = quotation.contact_phone.replace(/[^0-9]/g, '');
        if (phoneNumber.length <= 10) {
          setPhoneNumberMask('(99) 9999-9999?');
        } else {
          setPhoneNumberMask('(99) 99999-9999');
        }
        setExpectedDate(
          moment.utc(quotation.expected_date).format('DD/MM/YYYY'),
        );
        (formRef.current as any).setFieldValue(
          'sender_address',
          quotation.sender_address,
        );
        (formRef.current as any).setFieldValue(
          'sender_number',
          quotation.sender_number,
        );
        (formRef.current as any).setFieldValue(
          'sender_complement',
          quotation.sender_complement,
        );
        (formRef.current as any).setFieldValue(
          'sender_district',
          quotation.sender_district,
        );
        (formRef.current as any).setFieldValue(
          'receiver_address',
          quotation.receiver_address,
        );
        (formRef.current as any).setFieldValue('comments', quotation.comments);
        (formRef.current as any).setFieldValue(
          'receiver_number',
          quotation.receiver_number,
        );
        (formRef.current as any).setFieldValue(
          'receiver_complement',
          quotation.receiver_complement,
        );
        (formRef.current as any).setFieldValue(
          'receiver_district',
          quotation.receiver_district,
        );
        (formRef.current as any).setFieldValue(
          'telefone',
          quotation.contact_phone,
        );

        (formRef.current as any).setFieldValue(
          'cep-origem',
          quotation.cep_origin,
        );
        (formRef.current as any).setFieldValue(
          'cep-destino',
          quotation.cep_destination,
        );
        (formRef.current as any).setFieldValue('pagador', {
          value: (quotation as any).client.id,
          label: `${(quotation as any).client.short_name}-${
            quotation.client.document_number
          }`,
        });
        if ((quotation as any).sender) {
          (formRef.current as any).setFieldValue('remetente', {
            value: (quotation as any).sender.id,
            label: `${(quotation as any).sender.name}-${
              (quotation as any).sender.unique_code
            }`,
          });
        } else {
          await handleLoadCepWithoutSender(quotation.cep_origin, 'origem');
        }

        if ((quotation as any).receiver) {
          (formRef.current as any).setFieldValue('destinatario', {
            value: (quotation as any).receiver.id,
            label: `${(quotation as any).receiver.name}-${
              (quotation as any).receiver.unique_code
            }`,
          });
        } else {
          await handleLoadCepWithoutSender(
            quotation.cep_destination,
            'destino',
          );
        }

        (formRef.current as any).setFieldValue('nfe', quotation.nf_key);
        (formRef.current as any).setFieldValue(
          'nfe-value',
          formatValueToCurrencyInput(quotation.nfe_value),
        );
        (formRef.current as any).setFieldValue(
          'quantidade',
          formatValueToCurrencyInput(quotation.quantity),
        );
        (formRef.current as any).setFieldValue(
          'nome-contato',
          quotation.contact_name,
        );
        (formRef.current as any).setFieldValue(
          'cubagem',
          formatValueToCurrencyInput(quotation.volume),
        );
        (formRef.current as any).setFieldValue(
          'peso',
          formatValueToCurrencyInput(quotation.weight),
        );
        let totalCubicMeters = 0;
        (quotation.itens as any).forEach((item: any) => {
          const calcCubicMeters = item.length * item.width * item.height;
          totalCubicMeters += calcCubicMeters;
        });
        setCubicMeters(totalCubicMeters);
        setIsDraft(quotation.status === 'D');
        setIsApproved(quotation.status === 'A');
        setQuotationApproved(quotation.status === 'A');
        setQuotationValue(quotation.value);
        dispatch(setGeneratedCollect(quotation.generated_collect));
        // hideLoading();
        if (!isLoadingPage && Number(quotation.value) === 0) {
          setShowModalError(true);
        }
        setIsLoadingPage(false);
      })();
    } else {
      dispatch(setItens([]));
    }
  }, [quotationId]);

  useEffect(() => {
    if (quotationId === 0) {
      let totalCubicMeters = 0;
      let totalQuantity = 0;
      let totalVolume = 0;
      let totalWeight = 0;
      let totalValue = 0;
      itensQuotation.forEach((item: any): any => {
        const calcCubicMeters = item.length * item.width * item.height;
        totalCubicMeters += calcCubicMeters;
        totalQuantity += item.quantity;
        totalVolume += item.weight_calculated;
        totalWeight += item.weight;
        totalValue += item.value;
      });
      (formRef.current as any).setFieldValue(
        'quantidade',
        formatValueToCurrencyInput(totalQuantity),
      );
      (formRef.current as any).setFieldValue(
        'cubagem',
        formatValueToCurrencyInput(totalVolume),
      );
      (formRef.current as any).setFieldValue(
        'peso',
        formatValueToCurrencyInput(totalWeight),
      );
      (formRef.current as any).setFieldValue(
        'peso-calculado',
        formatValueToCurrencyInput(totalWeight),
      );
      (formRef.current as any).setFieldValue(
        'nfe-value',
        formatValueToCurrencyInput(totalValue),
      );
      setCubicMeters(totalCubicMeters);
    }
  }, [itensQuotation]);

  const handleDisableAprove = (): void => {
    setDisableAprove(true);
  };

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClient(true);
    }
    setSearchClients(newValue);
  };

  const handleBusinessPersonRemInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonRem) {
      setIsLoadingBusinessPersonRem(true);
    }
    setSearchBusinessPersonRem(newValue);
  };

  const handleBusinessPersonDestInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonDest) {
      setIsLoadingBusinessPersonDest(true);
    }
    setSearchBusinessPersonDest(newValue);
  };

  const confirm = useCallback(async () => {
    const data = (formRef.current as any).getData();
    const dataReprove = (modalFormRef.current as any).getData();
    const quotationData = {
      client_id: data.pagador,
      origin_id: data.origem,
      destination_id: data.destino,
      payer_id: data.pagador,
      sender_id: data.remetente,
      receiver_id: data.destinatario,
      quantity: formatValueFromCurrencyInput(data.quantidade),
      weight: formatValueFromCurrencyInput(data.peso),
      volume: formatValueFromCurrencyInput(data.cubagem),
      value: quotationValue.toString().replace(',', '.'),
      nf_key: '',
      status: isDraft === true ? 'D' : isApproved === true ? 'A' : 'R',
      cep_origin: data['cep-origem'],
      cep_destination: data['cep-destino'],
      contact_phone: data.telefone,
      contact_name: data['nome-contato'],
      comments: data.comments,
      nfe_value: formatValueFromCurrencyInput(data['nfe-value']),
      sender_address: data.sender_address,
      sender_number: data.sender_number,
      sender_complement: data.sender_complement,
      sender_district: data.sender_district,
      receiver_address: data.receiver_address,
      receiver_number: data.receiver_number,
      receiver_complement: data.receiver_complement,
      receiver_district: data.receiver_district,
      reprove_reason: dataReprove.email,
      freightTableTariffId,
    };

    showLoading();
    if (quotationId > 0) {
      const Response = await dispatch(
        updateQuotation({ id: Number(quotationId), data: quotationData }),
      );
      if (updateQuotation.fulfilled.match(Response)) {
        dispatch(setStatusQuotation(Response.payload.status));
        addToast({ title: 'Cotação atualizada com sucesso', type: 'success' });
        setDisableAprove(false);
        hideLoading();
        setUpdateQuotationValue(true);
      } else {
        addToast({ title: 'Erro na atualização', type: 'error' });
        hideLoading();
      }
    } else {
      const Response = await dispatch(addQuotation({ data: quotationData }));
      if (addQuotation.fulfilled.match(Response)) {
        dispatch(setStatusQuotation(Response.payload.status));
        setExpectedDate(
          moment.utc(Response.payload.expected_date).format('DD/MM/YYYY'),
        );
        addToast({ title: 'Cotação criada com sucesso', type: 'success' });
        if (itensQuotation.length > 0) {
          const itensRedux: ItensData[] = itensQuotation;

          for (const itemRedux of itensRedux) {
            const {
              quantity,
              comments,
              height,
              length,
              nf_number,
              product_name,
              serial,
              value,
              weight,
              weight_calculated,
              width,
            } = itemRedux;

            const newItem = {
              quantity,
              comments,
              height,
              length,
              nf_number,
              product_name,
              serial,
              value,
              weight,
              weight_calculated,
              width,
              quotation_id: Response.payload.id,
              freightTableTariffId,
            };

            // eslint-disable-next-line no-await-in-loop
            await dispatch(addQuotationItem({ data: newItem }));
          }
          dispatch(setItens([]));
        }

        hideLoading();
        dispatch(setQuotationId(Response.payload.id));
        setIsLoadingPage(false);
        setDisableAprove(false);
      } else {
        hideLoading();
        addToast({ title: 'Erro na criação', type: 'error' });
      }
    }
    setQuotationApproved(isApproved);
  }, [
    addToast,
    dispatch,
    hideLoading,
    isDraft,
    quotationId,
    quotationValue,
    itensQuotation,
    setItens,
    addQuotationItem,
    showLoading,
    isApproved,
    setQuotationApproved,
    freightTableTariffId,
  ]);

  const canceled = useCallback(() => {}, []);

  const handleSubmit = useCallback(
    async (data: FormInterface) => {
      if (quotationApproved === false) {
        try {
          (formRef.current as any).setErrors({});
          const schemaValidation = Yup.object().shape({
            pagador: Yup.string().required('Pagador obrigatório'),
            telefone: Yup.string(),
            remetente: Yup.string(),
            'cep-origem': Yup.string(),
            origem: Yup.string().required('Origem obrigatório'),
            destinatario: Yup.string(),
            'cep-destino': Yup.string(),
            destino: Yup.string().required('Destino obrigatório'),
            cubagem: Yup.string().required('Cubagem obrigatório'),
            peso: Yup.string().required('Peso obrigatório'),
            quantidade: Yup.string().required('Quantidade obrigatória'),
            'nfe-value': Yup.string().required('Valor Mercadoria obrigatória'),
            sender_address: Yup.string(),
            receiver_address: Yup.string(),
            sender_number: Yup.string(),
            receiver_number: Yup.string(),
            sender_complement: Yup.string().notRequired(),
            receiver_complement: Yup.string().notRequired(),
            sender_district: Yup.string(),
            receiver_district: Yup.string(),
            'nome-contato': Yup.string(),
          });

          if (itensQuotation.length === 0 && itensQuantity === 0) {
            setIsErro(true);

            await schemaValidation.validate(data, {
              abortEarly: false,
            });
          } else {
            await schemaValidation.validate(data, {
              abortEarly: false,
            });
            let textDialog = '';
            showLoading();
            const freightTables = await getFreightTableTariffClientRoute(
              Number(data.pagador),
              Number(data.origem),
              Number(data.destino),
            );
            hideLoading();
            if (freightTables.data.length < 2) {
              if (quotationId === 0) {
                textDialog = 'Deseja calcular a cotação?';
              } else if (isApproved === true) {
                textDialog =
                  'Deseja aprovar a cotação? Após aprovada, a Cotação não poderá ser atualizada.';
              } else if (isReproved === true) {
                textDialog =
                  'Deseja reprovar a cotação? Após reprovada, a Cotação não poderá ser atualizada.';
              } else {
                textDialog = 'Deseja recalcular a cotação?';
              }

              if (freightTables.data.length === 1) {
                setFreightTableTariffId(Number(freightTables.data[0].id));
              } else {
                setIsLoadingPage(false);
                createDialog({
                  text: textDialog,
                  textButtonCancel: 'Não',
                  textButtonConfirm: 'Sim',
                  icon: FaQuestionCircle,
                  onConfirm: confirm,
                  onCancel: canceled,
                });
              }
            } else {
              const arrayFt: Array<Array<any>> = [];
              freightTables.data.forEach(item => {
                arrayFt.push([
                  item.freight_table?.id,
                  item.freight_table?.description,
                  item.freight_table?.icms_type === 'PA'
                    ? 'Padrão'
                    : item.freight_table?.icms_type === 'IN'
                    ? 'Incluso'
                    : 'Isento',
                  item.is_region ? 'Região' : 'Linha',
                  item.freight_table?.comments,
                  <div className="options-table-list">
                    <button
                      type="button"
                      onClick={() => {
                        setFreightTableTariffId(item.id ? item.id : 0);
                      }}
                    >
                      <FiCheck size={20} color="#ffffff" />
                    </button>
                  </div>,
                ]);
              });
              setShowFreightTables(true);
              setFreightTablesClient(arrayFt);
            }
          }
        } catch (err) {
          if (err.inner) {
            const errors = getValidationErrors(err);
            const elementRef = (formRef.current as any).getFieldRef(
              err.inner[0].path as string,
            );
            const position = elementRef.offsetTop + 35;
            window.scroll({ top: position, behavior: 'smooth' });
            elementRef.focus();
            (formRef.current as any).setErrors(errors);
          }
        }
      }
    },
    [
      canceled,
      confirm,
      createDialog,
      isDraft,
      quotationId,
      isApproved,
      quotationApproved,
      isError,
      setIsErro,
      itensQuantity,
      isReproved,
      setFreightTableTariffId,
    ],
  );

  useEffect(() => {
    let textDialog = '';
    if (freightTableTariffId) {
      if (quotationId === 0) {
        textDialog = 'Deseja calcular a cotação?';
      } else if (isApproved === true) {
        textDialog =
          'Deseja aprovar a cotação? Após aprovada, a Cotação não poderá ser atualizada.';
      } else if (isReproved === true) {
        textDialog =
          'Deseja reprovar a cotação? Após reprovada, a Cotação não poderá ser atualizada.';
      } else {
        textDialog = 'Deseja recalcular a cotação?';
      }
      setShowFreightTables(false);
      setIsLoadingPage(false);
      createDialog({
        text: textDialog,
        textButtonCancel: 'Não',
        textButtonConfirm: 'Sim',
        icon: FaQuestionCircle,
        onConfirm: confirm,
        onCancel: canceled,
      });
    }
  }, [freightTableTariffId]);

  const handleSubmitEmail = useCallback(
    async (data: FormInterface) => {
      try {
        (modalFormRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          email: Yup.string().required('Motivo de Reprovação obrigatório'),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        setShowModal(false);
        await handleSubmit((formRef.current as any).getData());
        /*  showLoading();
        const Response = await dispatch(
          sendEmailQuotation({
            data: {
              userId,
              message: data.email,
              title: data.assunto,
              subject: data.assunto,
            },
          }),
        );
        if (sendEmailQuotation.fulfilled.match(Response)) {
          addToast({ title: 'Email enviado com sucesso', type: 'success' });
        } else {
          addToast({ title: 'Erro ao enviar e-mail', type: 'error' });
        } */
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (modalFormRef.current as any).setErrors(errors);
        }
      }
      hideLoading();
    },
    [addToast, dispatch, showModal, setShowModal],
  );

  const handleInputCep = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.value) {
        const cepSearch = e.currentTarget.value.replace('_', '');
        if (cepSearch.length === 10) {
          const fieldName = e.currentTarget.id.includes('origem')
            ? 'origem'
            : 'destino';
          const addressInputs = e.currentTarget.id.includes('origem')
            ? 'sender'
            : 'receiver';
          e.currentTarget.blur();
          showLoading();
          const searchAddress = await cep(cepSearch);
          if (typeof searchAddress.erro === 'undefined') {
            const cities = await fetchCityByNameAndUF({
              name: searchAddress.localidade,
              uf: searchAddress.uf,
            });
            const allCities: any = [];
            cities.forEach((city: any): any => {
              allCities.push({
                value: city.id,
                label: `${city.name}-${searchAddress.uf}`,
              });
            });
            setOptionsCity(allCities);
            (formRef.current as any).setFieldValue(fieldName, allCities[0]);
            addToast({
              type: 'success',
              title: 'CEP encontrado',
            });
            (formRef.current as any).setFieldValue(
              `${addressInputs}_address`,
              searchAddress.logradouro,
            );
            (formRef.current as any).setFieldValue(
              `${addressInputs}_district`,
              searchAddress.bairro,
            );
            (formRef.current as any).setFieldError(`cep-${fieldName}`, '');

            hideLoading();
          } else {
            hideLoading();
            (formRef.current as any).setFieldError(
              `cep-${fieldName}`,
              'CEP não encontrado',
            );
            addToast({
              type: 'error',
              title: 'Erro',
              description: 'CEP não encontrado',
            });
          }
        }
      }
    },
    [addToast, hideLoading, showLoading],
  );

  const handleLoadCepInfo = useCallback(
    async (cepValue: string, fieldName: string) => {
      if (cepValue) {
        const cepSearch = cepValue.replace('_', '');
        if (cepSearch.length === 10 || cepSearch.length === 8) {
          showLoading();
          const searchAddress = await cep(cepSearch);
          if (typeof searchAddress.erro === 'undefined') {
            const addressInputs = fieldName.includes('origem')
              ? 'sender'
              : 'receiver';
            const cities = await fetchCityByNameAndUF({
              name: searchAddress.localidade,
              uf: searchAddress.uf,
            });
            const allCities: any = [];
            cities.forEach((city: any): any => {
              allCities.push({
                value: city.id,
                label: `${city.name}-${searchAddress.uf}`,
              });
            });
            setOptionsCity(allCities);
            (formRef.current as any).setFieldValue(
              fieldName,
              allCities[allCities.length - 1],
            );

            (formRef.current as any).setFieldValue(
              `${addressInputs}_address`,
              searchAddress.logradouro,
            );
            (formRef.current as any).setFieldValue(
              `${addressInputs}_district`,
              searchAddress.bairro,
            );
            hideLoading();
          } else {
            hideLoading();
            (formRef.current as any).setFieldError(
              `cep-${fieldName}`,
              'CEP não encontrado',
            );
          }
        }
      }
    },
    [addToast, hideLoading, showLoading],
  );

  const handleBusinessPersonRemChange = useCallback(
    async (value: any): Promise<void> => {
      if (value) {
        showLoading();
        const cepResponse = await getBusinessPerson(value.value);
        (formRef.current as any).setFieldValue(
          'cep-origem',
          cepResponse.data.cep,
        );
        (formRef.current as any).setFieldValue(
          'sender_number',
          cepResponse.data.number,
        );

        (formRef.current as any).setFieldValue(
          'sender_complement',
          cepResponse.data.complement,
        );
        await handleLoadCepInfo(cepResponse.data.cep, 'origem');
      }
    },
    [handleLoadCepInfo, isLoadingPage],
  );

  const handleBusinessPersonDestChange = useCallback(
    async (value: any): Promise<void> => {
      if (value) {
        showLoading();
        const cepResponse = await getBusinessPerson(value.value);
        (formRef.current as any).setFieldValue(
          'cep-destino',
          cepResponse.data.cep,
        );
        (formRef.current as any).setFieldValue(
          'receiver_number',
          cepResponse.data.number,
        );
        (formRef.current as any).setFieldValue(
          'receiver_complement',
          cepResponse.data.complement,
        );

        await handleLoadCepInfo(cepResponse.data.cep, 'destino');
      }
    },
    [handleLoadCepInfo, isLoadingPage],
  );

  const handleClientChangeCubageFactor = useCallback(
    async (value: any): Promise<void> => {
      if (value) {
        const cubage = await getClient(value.value);
        dispatch(setCubageFactor(cubage.data.cubage_factor));
      }
    },
    [dispatch, setCubageFactor],
  );

  const handlerChangeBrazilianPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMask('(99) 9999-9999?');
      } else {
        setPhoneNumberMask('(99) 99999-9999');
      }
    },
    [],
  );

  useEffect(() => {
    (async () => {
      if (
        (quotationId && updateQuotationFlag) ||
        (quotationId && updateQuotationValue)
      ) {
        showLoading();
        const { data: quotation } = await getQuotation(Number(quotationId));
        (formRef.current as any).setFieldValue(
          'nfe-value',
          formatValueToCurrencyInput(quotation.nfe_value),
        );
        (formRef.current as any).setFieldValue(
          'quantidade',
          formatValueToCurrencyInput(quotation.quantity),
        );
        (formRef.current as any).setFieldValue(
          'cubagem',
          formatValueToCurrencyInput(quotation.volume),
        );
        (formRef.current as any).setFieldValue(
          'peso',
          formatValueToCurrencyInput(quotation.weight),
        );
        (formRef.current as any).setFieldValue(
          'peso-calculado',
          formatValueToCurrencyInput(quotation.weight),
        );

        let totalCubicMeters = 0;
        (quotation.itens as any).forEach((item: any) => {
          const calcCubicMeters = item.length * item.width * item.height;
          totalCubicMeters += calcCubicMeters;
        });
        setCubicMeters(totalCubicMeters);

        setQuotationValue(quotation.value);
        handleUpdateQuotation(false);
        if (Number(quotation.value) === 0 && clickCalculate === true) {
          setShowModalError(true);
          setClickCalculate(false);
        }
        hideLoading();
        setUpdateQuotationValue(false);
      }
    })();
  }, [updateQuotationFlag, updateQuotationValue]);

  const { setBusinessPersonId } = businessPersonSlice.actions;

  const handleClickAddBp = useCallback(
    (type: string): void => {
      dispatch(setBusinessPersonId(0));
      setTitleModal(type);
      setShowModalBp(true);
    },
    [dispatch, setBusinessPersonId, setTitleModal, setShowModalBp],
  );

  useEffect(() => {
    if (itensQuotation.length > 0) {
      setIsErro(false);
    }
  }, [isError, itensQuotation]);

  return (
    <FormContainer>
      <Modal
        backgroundColor="#ffffff"
        fontColor="#666666"
        isOpen={showModal}
        title="Motivo Reprovação"
        id="contact-modal"
        onClose={() => setShowModal(false)}
      >
        <div>
          <Form
            ref={modalFormRef}
            initialData={{}}
            onSubmit={handleSubmitEmail}
          >
            <Row>
              <ContainerSize size="100%">
                <Textarea
                  name="email"
                  placeholder="Digite o motivo da reprovação"
                />
              </ContainerSize>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <FormButton type="submit">
                <span>
                  <FiSend />
                  Salvar
                </span>
              </FormButton>
            </Row>
          </Form>
        </div>
      </Modal>
      <Form
        ref={formRef}
        initialData={{
          'nfe-value': '0',
          peso: '0',
          quantidade: '0',
          cubagem: '0',
        }}
        onSubmit={handleSubmit}
        noValidate
      >
        <Row>
          <ContainerSize size="20%">
            <OCSContainer>ID Cotação: {quotationId}</OCSContainer>
          </ContainerSize>
          {quotationId ? (
            <ContainerSize size="40%">
              <OCSContainer>
                Status Cotação:{' '}
                {statusQuotation === 'A'
                  ? 'Aprovada'
                  : statusQuotation === 'R'
                  ? 'Cancelada'
                  : 'Aguardando Aprovação'}
              </OCSContainer>
            </ContainerSize>
          ) : (
            <></>
          )}
        </Row>
        <Row style={{ marginBottom: '35px' }}>
          <ContainerSizeAutoComplete size="50%">
            <Autocomplete
              name="pagador"
              placeholder="Pagador *"
              options={optionsClient}
              changeValue={handleClientChangeCubageFactor}
              isLoading={isLoadingClient}
              onInputChange={handleClientInputChange}
              isDisabled={statusQuotation === 'A' || statusQuotation === 'R'}
            />
          </ContainerSizeAutoComplete>
          <ContainerSize size="25%">
            <Input
              name="nome-contato"
              type="text"
              placeholder="Nome Contato"
              disabled={statusQuotation === 'A' || statusQuotation === 'R'}
            />
          </ContainerSize>
          <ContainerSize size="25%">
            <InputMask
              name="telefone"
              type="text"
              placeholder="Telefone"
              mask={phoneNumberMask}
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              onKeyUp={handlerChangeBrazilianPhone}
              maskChar=""
              disabled={statusQuotation === 'A' || statusQuotation === 'R'}
            />
          </ContainerSize>
        </Row>
        <SpecialRow style={{ marginBottom: '35px' }}>
          <Row>
            <div className="special-title">
              <h4>Local de Coleta</h4>
              {statusQuotation === 'A' || statusQuotation === 'R' ? (
                <></>
              ) : (
                <button
                  type="button"
                  onClick={() => handleClickAddBp('remetente')}
                >
                  <IoIosAddCircle size={16} />
                  Remetente
                </button>
              )}
            </div>
            <ContainerSizeAutoComplete size="50%">
              <Autocomplete
                name="remetente"
                placeholder="Remetente"
                options={optionsBusinessPersonRem}
                changeValue={handleBusinessPersonRemChange}
                isLoading={isLoadingBusinessPersonRem}
                onInputChange={handleBusinessPersonRemInputChange}
                isDisabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSizeAutoComplete>
            <ContainerSize size="15%">
              <InputMask
                name="cep-origem"
                type="text"
                placeholder="CEP Origem *"
                mask="99.999-999"
                onKeyUp={handleInputCep}
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSizeAutoComplete size="35%">
              <Autocomplete
                name="origem"
                placeholder="Origem *"
                options={optionsCity}
                changeValue={null}
                isDisabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSizeAutoComplete>
          </Row>
          <Row>
            <ContainerSize size="25%">
              <Input
                name="sender_address"
                type="text"
                placeholder="Endereço"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="sender_number"
                type="text"
                placeholder="Número"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="sender_complement"
                type="text"
                placeholder="Complemento"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="sender_district"
                type="text"
                placeholder="Bairro"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
          </Row>
        </SpecialRow>

        <SpecialRow>
          <Row>
            <div className="special-title">
              <h4>Local de Entrega</h4>
              {statusQuotation === 'A' || statusQuotation === 'R' ? (
                <></>
              ) : (
                <button
                  type="button"
                  onClick={() => handleClickAddBp('destinatario')}
                >
                  <IoIosAddCircle size={16} />
                  Destinatário
                </button>
              )}
            </div>
            <ContainerSizeAutoComplete size="50%">
              <Autocomplete
                name="destinatario"
                placeholder="Destinatario"
                options={optionsBusinessPersonDest}
                changeValue={handleBusinessPersonDestChange}
                isLoading={isLoadingBusinessPersonDest}
                onInputChange={handleBusinessPersonDestInputChange}
                isDisabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSizeAutoComplete>
            <ContainerSize size="15%">
              <InputMask
                name="cep-destino"
                type="text"
                placeholder="CEP Destino *"
                mask="99.999-999"
                onKeyUp={handleInputCep}
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSizeAutoComplete size="35%">
              <Autocomplete
                name="destino"
                placeholder="Destino *"
                options={optionsCity}
                changeValue={null}
                isDisabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSizeAutoComplete>
          </Row>
          <Row>
            <ContainerSize size="25%">
              <Input
                name="receiver_address"
                type="text"
                placeholder="Endereço"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="receiver_number"
                type="text"
                placeholder="Número"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="receiver_complement"
                type="text"
                placeholder="Complemento"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="receiver_district"
                type="text"
                placeholder="Bairro"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
          </Row>
          <Row>
            <ContainerSize size="100%">
              <Input
                name="comments"
                type="text"
                placeholder="Observações"
                disabled={statusQuotation === 'A' || statusQuotation === 'R'}
              />
            </ContainerSize>
          </Row>
        </SpecialRow>
        {cubageFactor ? (
          <Row style={{ marginTop: '35px', marginBottom: '35px' }}>
            <QuotationItens
              quotationId={quotationId}
              cubageFactor={cubageFactor}
              handleChangeUpdateQuotation={handleUpdateQuotation}
              statusQuotation={statusQuotation}
              disableAprove={handleDisableAprove}
            />
          </Row>
        ) : (
          <></>
        )}
        <ErrorMessage>
          {isError && itensQuantity === 0 && (
            <span className="error">
              Necessário informar pelo menos um item para o cálculo da Cotação
            </span>
          )}
        </ErrorMessage>
        <Row>
          <ContainerSize size="16.666666%">
            <Input
              name="nfe-value"
              type="text"
              placeholder="R$ Valor Total"
              readOnly
            />
          </ContainerSize>
          <ContainerSize size="16.666666%">
            <Input
              name="quantidade"
              type="text"
              placeholder="Quantidade Total"
              readOnly
            />
          </ContainerSize>
          <ContainerSize size="16.666666%">
            <Input
              name="cubagem"
              type="text"
              placeholder="Cubagem Total"
              readOnly
            />
          </ContainerSize>
          <ContainerSize size="16.666666%">
            <Input
              name="peso"
              type="text"
              placeholder="Peso Kg Total"
              readOnly
            />
          </ContainerSize>
          <ContainerSize size="16.666666%">
            <Input
              name="m3"
              type="text"
              placeholder="Metros Cúbicos"
              readOnly
              value={formatValueToCurrencyInput(cubicMeters)}
            />
          </ContainerSize>
          <ContainerSize size="16.666666%">
            <Input
              name="peso-calculado"
              type="text"
              placeholder="Peso Calculado"
              readOnly
            />
          </ContainerSize>
        </Row>
        <Row>
          {quotationId > 0 ? (
            <ContainerSize
              size="35%"
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '20px',
                }}
              >
                <span style={{ fontSize: '18px', fontWeight: 200 }}>
                  Previsão de Entrega
                </span>
                <div>
                  <FiClock size={25} style={{ marginRight: '10px' }} />
                  <span style={{ fontWeight: 400, fontSize: '30px' }}>
                    {expectedDate}
                  </span>
                </div>
              </div>
            </ContainerSize>
          ) : (
            <></>
          )}
          {quotationId > 0 ? (
            <ContainerSize
              size="35%"
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '20px',
                }}
              >
                <span style={{ fontSize: '18px', fontWeight: 200 }}>
                  Valor Cotação
                </span>
                <div>
                  <span style={{ fontWeight: 400, fontSize: '30px' }}>
                    R$ {formatValueToCurrencyInput(quotationValue)}
                  </span>
                </div>
              </div>
            </ContainerSize>
          ) : (
            <></>
          )}
          <ContainerSize size="30%" style={{ justifyContent: 'flex-end' }}>
            {statusQuotation !== 'A' &&
            statusQuotation !== 'R' &&
            quotationId > 0 ? (
              <FormButton
                type="submit"
                onClick={() => {
                  setIsApproved(false);
                  setIsReproved(false);
                  setIsDraft(true);
                  setClickCalculate(true);
                }}
              >
                Recalcular cotação
              </FormButton>
            ) : (
              <></>
            )}
          </ContainerSize>
        </Row>
        {quotationId > 0 ? (
          <Row>
            <ContainerMessage>
              <span>
                * Valor apresentado está sujeito à confirmação das informações
                consideradas e a existência de taxas, tarifas, cubagem para sua
                região e impostos atuais no momento da emissão do respectivo
                conhecimento de transporte
              </span>
            </ContainerMessage>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <div className="value-row">
            {statusQuotation !== 'A' &&
            statusQuotation !== 'R' &&
            quotationId === 0 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <FormButton
                  type="submit"
                  onClick={() => {
                    setIsDraft(true);
                    setClickCalculate(true);
                  }}
                >
                  Calcular Cotação
                </FormButton>
              </div>
            ) : (
              <></>
            )}

            {statusQuotation !== 'A' &&
            statusQuotation !== 'R' &&
            disableAprove === false &&
            quotationId > 0 &&
            Number(quotationValue) > 0 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <FormButton
                  type="submit"
                  onClick={() => {
                    setIsDraft(false);
                    setIsReproved(false);
                    setIsApproved(true);
                  }}
                >
                  <span>
                    <img
                      src={CheckImg}
                      alt="Aprovar"
                      style={{ filter: 'invert(100%)' }}
                    />
                    Aprovar
                  </span>
                </FormButton>
                <FormButton
                  type="button"
                  onClick={() => {
                    setIsDraft(false);
                    setIsReproved(true);
                    openModal();
                  }}
                >
                  <span>
                    <img src={ReproveImg} alt="Reprovar" />
                    Cancelar
                  </span>
                </FormButton>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Row>
      </Form>

      <Modal
        backgroundColor="#F8F8FB"
        fontColor="#202020"
        isOpen={showFreightTables}
        title="Escolha a tabela de frete para Calcular a Cotação"
        id="modal-freight-table"
        onClose={() => setShowFreightTables(false)}
      >
        <Row>
          <ContainerSize size="100%">
            <TableList
              header={['Tabela de Frete', 'ICMS', 'Tipo', 'Observações', '']}
              data={freightTablesClient}
            />
          </ContainerSize>
        </Row>
      </Modal>

      <Modal
        backgroundColor="#F8F8FB"
        fontColor="#202020"
        isOpen={showModalBp}
        title={`Cadastrar ${titleModal}`}
        id="modal-business-person"
        onClose={() => setShowModalBp(false)}
      >
        {showModalBp && <BusinessPersonForm />}
      </Modal>

      <Modal
        backgroundColor="#F8F8FB"
        fontColor="#202020"
        width="300px"
        isOpen={showModalError}
        title="Cálculo não efetuado"
        id="modal-error"
        onClose={() => setShowModalError(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <VscError
            size={100}
            color="#FF6E3F"
            style={{ marginBottom: '40px' }}
          />
          <strong style={{ fontSize: '22px' }}>
            Não foi possível calcular a cotação, nenhuma tarifa cadastrada para
            o trecho escolhido
          </strong>
        </div>
      </Modal>
    </FormContainer>
  );
};

export default CreateQuotation;
