/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { FiFilter } from 'react-icons/fi';
import { VscClose } from 'react-icons/vsc';
import { IoIosCloseCircle } from 'react-icons/io';

import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FilterButton,
  FilterContainer,
  HeaderContainer,
  BodyContainer,
  FilterBackdrop,
} from './styles';
import { filterSlice } from '../../features/filter';

interface Obj {
  [key: string]: string;
}

interface FilterProps {
  onSubmit: (data: Obj) => any;
}

const Filter: React.FC<FilterProps> = ({ children, onSubmit }) => {
  const { filter, location } = useSelector((state: RootStateOrAny) => ({
    filter: state.features.filter.filter,
    location: state.features.filter.location,
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const { setFilter, setLocation, setFormValue } = filterSlice.actions;
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [submitedForm, setSubmitedForm] = useState<boolean>(false);
  const formRef = React.useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    (data: Obj) => {
      onSubmit(data);
      const objectFilter: object = {};
      Object.keys(data).forEach(item => {
        if (
          Object.keys(formRef.current?.getFieldRef(item)).includes('select')
        ) {
          Object.assign(objectFilter, {
            [item]: formRef.current?.getFieldRef(item).state.value,
          });
        } else {
          Object.assign(objectFilter, {
            [item]: data[item],
          });
        }
      });
      dispatch(setFilter(JSON.stringify(objectFilter)));
      setFilterVisible(false);
      setSubmitedForm(true);
    },
    [onSubmit, setFilterVisible, setSubmitedForm],
  );

  const handleReset = useCallback(() => {
    (formRef.current as any).reset();
    (children as any).forEach((item: any) => {
      if (item.type.name === 'Autocomplete') {
        const select = formRef?.current?.getFieldRef(item.props.name);
        select.select.clearValue();
      }
    });
    onSubmit({});
    setFilterVisible(false);
    setSubmitedForm(false);
    dispatch(setFormValue({}));
    dispatch(setFilter(''));
  }, [onSubmit, setFilterVisible, setSubmitedForm]);

  const onClose = useCallback(() => {
    setFilterVisible(false);
  }, [setFilterVisible]);

  useEffect(() => {
    if (location !== history.location.pathname) {
      dispatch(setFilter(''));
      dispatch(setLocation(history.location.pathname));
      dispatch(setFormValue({}));
    }
  }, [history.location]);

  useEffect(() => {
    if (filterVisible === true && filter !== '') {
      setSubmitedForm(true);
      const data = JSON.parse(filter);
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          formRef!.current!.setFieldValue(key, value);
        }
      }
    }
  }, [filterVisible]);

  return (
    <>
      <FilterButton
        type="button"
        onClick={() => {
          setFilterVisible(true);
        }}
      >
        <FiFilter size={25} />
      </FilterButton>
      <FilterContainer isVisible={filterVisible}>
        <HeaderContainer>
          <h4>Filtrar</h4>
          <button type="button" onClick={onClose}>
            <VscClose size={20} />
          </button>
        </HeaderContainer>
        <BodyContainer>
          <Form
            ref={formRef}
            initialData={{}}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="new-password"
          >
            {children}
            {submitedForm === true && (
              <button type="button" onClick={handleReset}>
                <IoIosCloseCircle size={15} />
                limpar
              </button>
            )}
            <button type="submit">filtrar</button>
          </Form>
        </BodyContainer>
      </FilterContainer>
      <FilterBackdrop onClick={onClose} isVisible={filterVisible} />
    </>
  );
};

export default Filter;
