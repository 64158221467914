/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { IoIosWarning, IoIosAddCircle } from 'react-icons/io';
import { FaTrash, FaTimesCircle, FaTruck } from 'react-icons/fa';
import { HiClipboardList } from 'react-icons/hi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useDebounce } from 'use-debounce/lib';
import { FiTrash } from 'react-icons/fi';
import download from 'downloadjs';
import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import TableList from '../../components/TableList';
import Pagination from '../../components/Pagination';
import Filter from '../../components/Filter';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import ButtonForm from '../../components/ButtonForm';
import UploadArea from '../../components/UploadArea';
import Radio from '../../components/Radio';
import Autocomplete from '../../components/Autocomplete';
import Textarea from '../../components/Textarea';
import CurrencyInput from '../../components/CurrencyInput';

import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';
import { useDialog } from '../../hooks/dialog';
import {
  formatValueFromCurrencyInput,
  formatValueToCurrencyInput,
} from '../../utils/formatValueCurrencyInput';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ContainerSize,
  Row,
  FormButton,
  ModalContainer,
  TableListOptions,
  TableListOptionsButton,
  ContainerAddModal,
  ModalFormButton,
  ModalButtonRow,
  ModalListItem,
  CheckButton,
  InfoContainer,
  ModalButton,
  AssociateModalFormButton,
  ModalContainerTitle,
  WarningText,
  RowCenter,
  ChangeStatusButton,
} from './styles';

import DownloadImg from '../../assets/532.png';
import ArchiveImg from '../../assets/archive-24px.png';
import AddImg from '../../assets/AddUser.png';
import AttachFileImg from '../../assets/attach_file-24px.svg';
import ViewFileImg from '../../assets/view-file.png';
import {
  addNewFile,
  deleteTransporterFiles,
  getEventsEntries,
  getTransporterFiles,
  downloadCSV,
  downloadPDF,
} from '../../features/eventsEntry';
import { getOcurrences } from '../../features/ocurrences';
import { getVehicle, getVehicles } from '../../features/transporterVehicles';
import { getDriver, getDrivers } from '../../features/transporterDrivers';
import InputMask from '../../components/InputMask';
import {
  associateDriver,
  loadDriverVehicleInfo,
} from '../../features/transporterInvoices';
import {
  postEvent,
  getEvents,
  postStackEvent,
} from '../../features/transporterEvents';

interface invoiceItem {
  id: number;
  isFinished: boolean;
}

const EventsEntry: React.FC = () => {
  const [results, setResults] = useState<Array<any>>([]);
  const [fileResults, setFileResults] = useState<Array<any>>([]);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const [
    isLoadingInvoiceDriverVehicle,
    setIsLoadingInvoiceDriverVehicle,
  ] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [valueSearchType] = useDebounce(searchType, 1000);
  const [optionsType, setOptionsType] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filePages, setFilePages] = useState<Array<number>>([]);
  const [currentFilePage, setCurrentFilePage] = useState<number>(1);
  const [statusButton, setStatusButton] = useState<string>('Abertos');

  const [ocurrencesInvoicePages, setOcurrencesInvoicePages] = useState<
    Array<number>
  >([]);
  const [
    currentOcurrencesInvoicePage,
    setCurrentOcurrencesInvoicePage,
  ] = useState<number>(1);

  const [phoneNumberMask, setPhoneNumberMask] = useState('(99) 9999-9999?');

  const [searchLicensePlate, setSearchLicensePlate] = useState('');
  const [valueSearchLicensePlate] = useDebounce(searchLicensePlate, 1000);
  const [optionsLicensePlate, setOptionsLicensePlate] = useState<Array<any>>(
    [],
  );

  const [searchDriver, setSearchDriver] = useState('');
  const [valueSearchDriver] = useDebounce(searchDriver, 1000);
  const [optionsDriver, setOptionsDriver] = useState<Array<any>>([]);

  const { showLoading, hideLoading } = useLoading();
  const { addToast } = useToast();
  const { createDialog } = useDialog();
  const modalFormRef = useRef<FormHandles>(null);
  const associateModalFormRef = useRef<FormHandles>(null);
  const formStack = useRef<FormHandles>(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAssociateModal, setShowAssociateModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [showListOcurrencesModal, setShowListOcurrencesModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(0);
  const [invoiceChosed, setInvoiceChosed] = useState(0);
  const [ocurrencesInvoice, setOcurrencesInvoice] = useState<Array<any>>([]);

  const [savedInvoices, setSavedInvoices] = useState<invoiceItem[]>([]);

  const [id_nota, setIdNota] = useState<number>(0);
  const [id_cte, setIdCTe] = useState<number>(0);
  const [docType, setDocType] = useState<string>('');

  const [idNfeFile, setIdNfeFile] = useState<number>(0);
  const [idCteFile, setIdCteFile] = useState<number>(0);

  const [stackDocs, setStackDocs] = useState<Array<string>>([]);
  const [stackDocsList, setStackDocsList] = useState<Array<Array<string>>>([]);
  const [showModalStack, setShowModalStack] = useState(false);

  const getDataFiles = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const transporterFiles = await getTransporterFiles({
        page,
        ...{
          'transporter_files.invoice_id': idNfeFile,
          'transporter_files.document_id': idCteFile,
        },
      });
      const arrayTransporterFiles: Array<Array<any>> = [];

      transporterFiles.data.data.forEach((item: any) => {
        arrayTransporterFiles.push([
          <ModalListItem
            href={item.file_link}
            target="_blank"
            download={item.file_name}
          >
            {item.file_name}
          </ModalListItem>,
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              type="button"
              onClick={() => {
                createDialog({
                  text: 'Deseja realmente excluir este arquivo?',
                  textButtonCancel: 'Não',
                  textButtonConfirm: 'Sim',
                  icon: IoIosWarning,
                  onConfirm: () => {
                    handleDeleteFile(item.id);
                  },
                });
              }}
            >
              <FaTrash color="#ffffff" />
            </button>
          </div>,
        ]);
      });
      setFileResults(arrayTransporterFiles);
      const arrayPages = [];
      for (let i = 0; i < transporterFiles.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setFilePages(arrayPages);
      setCurrentFilePage(page);
      hideLoading();
      addToast({
        title: 'Arquivos carregados com sucesso',
        type: 'success',
      });
    },
    [idNfeFile, idCteFile, showLoading, hideLoading, addToast],
  );

  const handleDeleteFile = useCallback(async (id_file: number) => {
    showLoading();
    const response = await deleteTransporterFiles(id_file);
    if (response === 200) {
      addToast({
        title: 'Arquivo Deletado',
        type: 'success',
      });
      getDataFiles(1);
    } else {
      addToast({
        title: 'Erro na exclusão',
        type: 'error',
      });
    }
    hideLoading();
  }, []);

  const handleChangeAddDocs = useCallback(
    async (e: any): Promise<any> => {
      if (e.target.files.length > 0) {
        showLoading();
        const promises = [];
        const fileNames = Array.from(e.target.files).map(
          ({ name }: any) => name,
        );
        for (let i = 0; i < fileNames.length; i++) {
          promises.push(
            addNewFile(
              id_nota,
              id_cte,
              docType,
              e.target.files[i],
              fileNames[i],
              docType,
            ),
          );
        }
        await Promise.all(promises);

        hideLoading();
        addToast({
          title: 'Upload feito com sucesso',
          type: 'success',
        });
      }
    },
    [id_nota, id_cte, docType, hideLoading, showLoading],
  );

  const handleClickCheckbox = useCallback(
    (event: React.FormEvent<HTMLButtonElement>) => {
      const btn = event.currentTarget;
      const id = Number(btn.getAttribute('data-id'));
      const isFinished = btn.getAttribute('data-status');
      const checked = btn.getAttribute('data-checked');
      if (checked === 'true') {
        btn.setAttribute('data-checked', 'false');
        setSavedInvoices(state => state.filter(item => item.id !== id));
      } else {
        btn.setAttribute('data-checked', 'true');
        setSavedInvoices(state => state.filter(item => item.id !== id));
        setSavedInvoices(state => [
          ...state,
          { id, isFinished: isFinished === 'true' ? true : false },
        ]);
      }
    },
    [],
  );

  const handlerChangeBrazilianPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMask('(99) 9999-9999?');
      } else {
        setPhoneNumberMask('(99) 99999-9999');
      }
    },
    [],
  );

  useEffect(() => {}, [phoneNumberMask]);

  useEffect(() => {
    console.log(savedInvoices);
  }, [savedInvoices]);
  useEffect(() => {}, [savedInvoices]);

  useEffect(() => {
    (async () => {
      const types = await getOcurrences({
        'ocurrences.name': valueSearchType,
      });
      const allTypes: any = [];
      types.data.forEach((type: any): any => {
        allTypes.push({ value: type.id, label: type.name });
      });
      setOptionsType(allTypes);
    })();
  }, [valueSearchType]);

  useEffect(() => {
    (async () => {
      const plates = await getVehicles({
        license_plate: valueSearchLicensePlate,
      });
      const allPlates: any = [];
      plates.data.forEach((vehicle: any): any => {
        allPlates.push({ value: vehicle.id, label: vehicle.license_plate });
      });
      setOptionsLicensePlate(allPlates);
    })();
  }, [valueSearchLicensePlate]);

  useEffect(() => {
    (async () => {
      const drivers = await getDrivers({
        'transporter_drivers.name': valueSearchDriver,
      });
      const allDrivers: any = [];
      drivers.data.forEach((driver: any): any => {
        allDrivers.push({ value: driver.id, label: driver.name });
      });
      setOptionsDriver(allDrivers);
    })();
  }, [valueSearchDriver]);

  const verifyCheckedInvoice = useCallback(
    (id: number): boolean => {
      return savedInvoices.filter(invoice => invoice.id === id).length > 0;
    },
    [savedInvoices],
  );

  const handleTypeInputChange = (newValue: string): void => {
    setSearchType(newValue);
  };

  const handleLicensePlateInputChange = (newValue: string): void => {
    setSearchLicensePlate(newValue);
  };

  const handleDriverInputChange = (newValue: string): void => {
    setSearchDriver(newValue);
  };

  const handleButtonStatusChange = (newValue: string): void => {
    setStatusButton(newValue);
  };

  const loadDriverVehicleInvoice = useCallback(
    async (invoiceId: number) => {
      setIsLoadingInvoiceDriverVehicle(true);
      showLoading();
      setInvoiceChosed(invoiceId);
      const invoiceInfo = await loadDriverVehicleInfo(invoiceId);
      (associateModalFormRef.current as any).reset();
      if (invoiceInfo) {
        if (invoiceInfo.data.vehicle) {
          (associateModalFormRef.current as any).setFieldValue(
            'license_plate',
            {
              value: invoiceInfo.data.vehicle.id,
              label: invoiceInfo.data.vehicle.license_plate,
            },
          );

          (associateModalFormRef.current as any).setFieldValue(
            'tipo-veiculo',
            invoiceInfo.data.vehicle.type,
          );

          (associateModalFormRef.current as any).setFieldValue(
            'status-veiculo',
            invoiceInfo.data.vehicle.status,
          );
        }

        if (invoiceInfo.data.driver) {
          (associateModalFormRef.current as any).setFieldValue('name', {
            value: invoiceInfo.data.driver.id,
            label: invoiceInfo.data.driver.name,
          });

          (associateModalFormRef.current as any).setFieldValue(
            'cpf',
            invoiceInfo.data.driver.document,
          );

          (associateModalFormRef.current as any).setFieldValue(
            'cnh',
            invoiceInfo.data.driver.license,
          );

          (associateModalFormRef.current as any).setFieldValue(
            'cnh-valid',
            new Date(invoiceInfo.data.driver.license_date).toLocaleDateString(
              'en-CA',
            ),
          );

          (associateModalFormRef.current as any).setFieldValue(
            'phone',
            invoiceInfo.data.driver.phone_number,
          );

          (associateModalFormRef.current as any).setFieldValue(
            'email',
            invoiceInfo.data.driver.email,
          );
        }
      }
      hideLoading();
      setIsLoadingInvoiceDriverVehicle(false);
    },
    [invoiceChosed],
  );

  const licensePlateChangeValue = useCallback(async () => {
    if (!isLoadingInvoiceDriverVehicle) {
      showLoading();
      const searchVehicle = await getVehicles(searchLicensePlate);
      if (searchVehicle.data.length > 0) {
        const vehicle = await getVehicle(searchVehicle.data[0].id);

        (associateModalFormRef.current as any).setFieldValue(
          'tipo-veiculo',
          vehicle.data.type,
        );

        (associateModalFormRef.current as any).setFieldValue(
          'status-veiculo',
          vehicle.data.status,
        );
      } else {
        addToast({
          title: 'Erro no carregamento',
          type: 'error',
        });
      }
      hideLoading();
    }
  }, [isLoadingInvoiceDriverVehicle]);

  const driverChangeValue = useCallback(async () => {
    if (!isLoadingInvoiceDriverVehicle) {
      showLoading();
      const searchDriverName = await getDrivers(searchDriver);
      if (searchDriverName.data.length > 0) {
        const Driver = await getDriver(searchDriverName.data[0].id);

        (associateModalFormRef.current as any).setFieldValue(
          'cpf',
          Driver.data.document,
        );

        (associateModalFormRef.current as any).setFieldValue(
          'cnh',
          Driver.data.license,
        );

        (associateModalFormRef.current as any).setFieldValue(
          'cnh-valid',
          new Date(Driver.data.license_date).toLocaleDateString('en-CA'),
        );

        (associateModalFormRef.current as any).setFieldValue(
          'phone',
          Driver.data.phone_number,
        );

        (associateModalFormRef.current as any).setFieldValue(
          'email',
          Driver.data.email,
        );
      } else {
        addToast({
          title: 'Erro no carregamento',
          type: 'error',
        });
      }
      hideLoading();
    }
  }, [isLoadingInvoiceDriverVehicle]);

  const openModal = useCallback(
    (idNota: number, idCte: number): void => {
      setShowModal(true);
      setIdNota(idNota);
      setIdCTe(idCte);
    },
    [setIdNota, setIdCTe],
  );

  const openListModal = useCallback((): void => {
    setShowListModal(true);
  }, []);

  const getFiles = useCallback(
    (idNfe: number, idCte: number): void => {
      setIdNfeFile(Number(idNfe));
      setIdCteFile(Number(idCte));
    },
    [setIdNfeFile, setIdCteFile, getDataFiles, openListModal],
  );

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      let params = Object();
      if (statusButton === 'Abertos') {
        params = {
          page,
          'transporter_invoices.is_finished': 0,
          ...optionsSearch,
        };
      } else if (statusButton === 'Finalizados') {
        params = {
          page,
          'transporter_invoices.is_finished': 1,
          ...optionsSearch,
        };
      } else {
        params = {
          page,
          ...optionsSearch,
        };
      }

      const eventsEntry = await getEventsEntries(params);
      const arrayEventsEntry: Array<Array<any>> = [];

      eventsEntry.data.data.forEach((item: any) => {
        const isChecked = verifyCheckedInvoice(item.id);
        arrayEventsEntry.push([
          <CheckButton
            type="button"
            data-id={item.id}
            data-status={item.is_finished}
            onClick={handleClickCheckbox}
            data-checked={isChecked}
            key={`invoice-button-${item.id}`}
          />,
          item.document.num_doc,
          item.num_nfe,
          new Date(item.date_issuance).toLocaleDateString(),
          `R$ ${formatValueToCurrencyInput(item.value_nfe)}`,
          `R$ ${formatValueToCurrencyInput(item.value_net_nfe)}`,
          item.driver && item.driver.name,
          item.vehicle && item.vehicle.license_plate,
          item.instructions,
          <TableListOptions>
            <TableListOptionsButton
              onClick={() => {
                openModal(item.id, item.document.id);
              }}
              type="button"
              title="Adicionar Arquivos"
            >
              <img
                src={AttachFileImg}
                style={{ filter: 'invert(100%)' }}
                alt=""
              />
            </TableListOptionsButton>

            <TableListOptionsButton
              onClick={() => {
                getFiles(item.id, item.document.id);
              }}
              type="button"
              title="Visualizar Arquivos"
            >
              <img
                src={ViewFileImg}
                style={{ filter: 'invert(100%)' }}
                alt=""
              />
            </TableListOptionsButton>

            <TableListOptionsButton
              onClick={() => {
                setCurrentInvoice(item.id);
                setShowListOcurrencesModal(true);
              }}
              title="Visualizar Ocorrências"
              type="button"
            >
              <HiClipboardList color="#ffffff" size={20} />
            </TableListOptionsButton>

            <TableListOptionsButton
              onClick={() => {
                openAssociateModal();
                loadDriverVehicleInvoice(item.id);
              }}
              title="Visualizar Veículo e Motorista"
              type="button"
            >
              <FaTruck color="#ffffff" size={20} />
            </TableListOptionsButton>
          </TableListOptions>,
        ]);
      });
      setResults(arrayEventsEntry);
      const arrayPages = [];
      for (let i = 0; i < eventsEntry.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      hideLoading();
      addToast({
        title: 'Ocorrências carregadas com sucesso',
        type: 'success',
      });
    },
    [
      optionsSearch,
      showLoading,
      hideLoading,
      addToast,
      savedInvoices,
      statusButton,
    ],
  );

  useEffect(() => {
    getData(1);
  }, [optionsSearch, statusButton]);

  const openAddModal = useCallback((): void => {
    setShowAddModal(true);
  }, [showAddModal]);

  const openAssociateModal = useCallback((): void => {
    setShowAssociateModal(true);
  }, []);

  useEffect(() => {
    if (idNfeFile > 0 && idCteFile > 0) {
      (async () => {
        showLoading();
        await getDataFiles(1);
        hideLoading();
        openListModal();
      })();
    }
  }, [idNfeFile, idCteFile]);

  const handleModalCancelButton = useCallback((): void => {
    (modalFormRef.current as any).reset();
    const select_ocurrence = modalFormRef!.current!.getFieldRef('ocurrence_id');
    select_ocurrence.select.clearValue();

    setShowAddModal(false);
  }, [setShowAddModal]);

  const submitFiles = useCallback((): void => {}, []);

  const handleRemoveSavedInvoices = useCallback(() => {
    const checks = document.querySelectorAll('button[data-checked="true"]');
    if (checks.length > 0) {
      for (let i = 0; i < checks.length; i++) {
        checks[i].setAttribute('data-checked', 'false');
      }
    }
    setSavedInvoices([]);
  }, [setSavedInvoices]);

  const submitModalEvent = useCallback(
    async (data: { ocurrence_id: number; comments: string }): Promise<void> => {
      try {
        (modalFormRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          ocurrence_id: Yup.string().required('Selecione a ocorrência'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        showLoading();
        const promises = [];
        for (let i = 0; i < savedInvoices.length; i++) {
          promises.push(
            postEvent({
              invoice_id: savedInvoices[i].id,
              ocurrence_id: data.ocurrence_id,
              comments: data.comments,
            }),
          );
        }
        await Promise.all(promises);
        addToast({
          title: 'Ocorrências lançadas com sucesso',
          type: 'success',
        });
        setShowAddModal(false);
        hideLoading();
        await getData(currentPage);
        handleRemoveSavedInvoices();
      } catch (err) {
        const errors = getValidationErrors(err);
        (modalFormRef.current as any).setErrors(errors);
      }
    },
    [
      savedInvoices,
      handleRemoveSavedInvoices,
      postEvent,
      showLoading,
      getData,
      addToast,
      setShowAddModal,
    ],
  );

  const submitAssociateModal = useCallback(
    async (data: { license_plate: number; name: number }): Promise<void> => {
      try {
        (associateModalFormRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          license_plate: Yup.string().required('Selecione a Placa'),
          name: Yup.string().required('Selecione o Motorista'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        showLoading();
        const promises = [];
        if (invoiceChosed) {
          promises.push(
            associateDriver(invoiceChosed, data.license_plate, data.name),
          );
        } else {
          for (let i = 0; i < savedInvoices.length; i++) {
            promises.push(
              associateDriver(
                savedInvoices[i].id,
                data.license_plate,
                data.name,
              ),
            );
          }
        }

        await Promise.all(promises);
        addToast({
          title: 'Associação feita com sucesso',
          type: 'success',
        });
        setShowAssociateModal(false);
        setInvoiceChosed(0);
        (associateModalFormRef.current as any).reset();
        hideLoading();
        getData(1);
      } catch (err) {
        const errors = getValidationErrors(err);
        (modalFormRef.current as any).setErrors(errors);
      }
    },
    [
      savedInvoices,
      associateDriver,
      showLoading,
      addToast,
      setShowAssociateModal,
      setInvoiceChosed,
      invoiceChosed,
    ],
  );
  useEffect(() => {}, [showAddModal]);

  const handleSubmitStack = useCallback(
    async (data: { ocurrence_id: number; comments: string }): Promise<void> => {
      try {
        (formStack.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          ocurrence_id: Yup.string().required('Selecione a ocorrência'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        showLoading();
        const response = await postStackEvent({ ...data, invoices: stackDocs });
        hideLoading();
        if (response.data.errors.length === 0) {
          addToast({
            title: 'Ocorrências lançadas com sucesso',
            type: 'success',
          });
          (formStack.current as any).reset();
          setShowModalStack(false);
          setStackDocs([]);
        } else {
          addToast({
            title: 'Não foi possível lançar algumas ocorrências',
            type: 'error',
          });
          setStackDocs(response.data.errors);
        }
        await getData(currentPage);
      } catch (err) {
        const errors = getValidationErrors(err);
        (formStack.current as any).setErrors(errors);
      }
    },
    [
      stackDocs,
      setStackDocs,
      postStackEvent,
      showLoading,
      getData,
      addToast,
      setShowModalStack,
    ],
  );

  const getOcurrencesInvoice = useCallback(
    async (page: number): Promise<void> => {
      showLoading();

      const response = await getEvents({
        page,
        'transporter_events.invoice_id': currentInvoice,
      });

      setOcurrencesInvoice([]);

      response.data.forEach(item => {
        setOcurrencesInvoice(state => [
          ...state,
          [
            new Date(item.created_at).toLocaleString(),
            item.ocurrence.name,
            item.comments,
          ],
        ]);
      });

      const arrayPages = [];
      for (let i = 0; i < response.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setOcurrencesInvoicePages(arrayPages);
      setCurrentOcurrencesInvoicePage(page);
      hideLoading();
    },
    [showLoading, hideLoading, currentInvoice],
  );

  useEffect(() => {
    (async () => {
      if (currentInvoice > 0 && showListOcurrencesModal) {
        await getOcurrencesInvoice(1);
      }
    })();
  }, [showListOcurrencesModal]);

  useEffect(() => {}, [currentInvoice]);
  useEffect(() => {}, [isLoadingInvoiceDriverVehicle]);

  interface Obj {
    [key: string]: string;
  }

  const handleFilterResults = useCallback(
    (data: Obj): void => {
      const searchData: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          if (
            key === 'transporter_invoices-value_net_nfe' ||
            key === 'transporter_invoices-value_nfe'
          ) {
            searchData[
              (key as any).replaceAll('-', '.')
            ] = formatValueFromCurrencyInput(value);
          } else {
            searchData[(key as any).replaceAll('-', '.')] = value;
          }
        }
      }
      setOptionsSearch(searchData);
    },
    [setOptionsSearch, formatValueFromCurrencyInput],
  );

  const handleClickDownloadCSV = useCallback(async () => {
    showLoading();
    try {
      const invoices: Array<number> = [];
      savedInvoices.forEach(item => {
        invoices.push(item.id);
      });
      const response = await downloadCSV({
        page: currentPage,
        ...optionsSearch,
        invoices,
      });
      const fileName = `invoices-${new Date().toLocaleDateString()}`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`); // or any other extension
      document.body.appendChild(link);
      link.click();
      /*   download(
      `data:text/csv; charset=utf-8,${response.data}`,
      fileName,
      'text/csv; charset=utf-8',
    ); */
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Documentos!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, savedInvoices, showLoading, hideLoading]);

  const handleClickDownloadPDF = useCallback(async () => {
    showLoading();
    try {
      const invoices: Array<number> = [];
      savedInvoices.forEach(item => {
        invoices.push(item.id);
      });

      const response = await downloadPDF({
        ...optionsSearch,
        invoices,
        page: currentPage,
        export_pdf: true,
      });
      const fileName = `invoices-${new Date().toLocaleDateString()}.pdf`;

      download(response.data, fileName, 'application/pdf');
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Documentos!',
      });
    }
    hideLoading();
  }, [currentPage, savedInvoices, optionsSearch, showLoading, hideLoading]);

  const handleCloseOcurrencelist = (): void => {
    setCurrentInvoice(0);
    setShowListOcurrencesModal(false);
  };

  const handleCloseAssociateModal = (): void => {
    setShowAssociateModal(false);
  };

  const handleCloseFilesList = (): void => {
    setShowListModal(false);
    setIdCteFile(0);
    setIdNfeFile(0);
  };

  const handleModalAddFileClose = (): void => {
    setShowModal(false);
    setIdCTe(0);
    setIdNota(0);
  };

  const handleOcorrunceModalClose = (): void => {
    setShowAddModal(false);
  };

  function handleClickDelete(code: string): void {
    const stateValues = stackDocs.filter(item => item !== code);
    setStackDocs(stateValues);
  }

  const handleKeyPressedCode = useCallback(
    event => {
      if (event.which === 13) {
        event.preventDefault();
        const codeValue: string = (formStack.current as any).getFieldValue(
          'code',
        );
        setStackDocs(state => [...state, codeValue.replace(/\D+/g, '')]);
        (formStack.current as any).clearField('code');
        (document.getElementById('code') as any).focus();
      }
    },
    [setStackDocs, stackDocs],
  );

  useEffect(() => {
    if (showModalStack === true) {
      (formStack.current as any).clearField('code');
      setTimeout(() => {
        const inputCode: HTMLInputElement = formStack.current?.getFieldRef(
          'code',
        );
        inputCode.focus();
      }, 500);
    }
  }, [showModalStack]);

  useEffect(() => {
    const data: Array<Array<any>> = [];
    stackDocs.forEach(item => {
      data.push([
        item,
        <div className="options-table-list">
          <button
            type="button"
            key={`item-${item}`}
            onClick={() => handleClickDelete(item)}
          >
            <FiTrash color="#ffffff" />
          </button>
        </div>,
      ]);
    });
    setStackDocsList(data);
  }, [stackDocs]);

  useEffect(() => {}, [stackDocsList]);
  useEffect(() => {}, [invoiceChosed]);

  return (
    <div>
      <PageContainer>
        <MainTitle>Lançar Ocorrências</MainTitle>
        <Row>
          <ContainerSize size="25%">
            <FormButton type="button" onClick={handleClickDownloadPDF}>
              <img
                src={DownloadImg}
                alt=""
                style={{ filter: 'invert(100%)' }}
              />
              <span>Relatório</span>
            </FormButton>
          </ContainerSize>

          <ContainerSize size="25%">
            <FormButton type="button" onClick={handleClickDownloadCSV}>
              <img src={ArchiveImg} style={{ filter: 'invert(100%)' }} alt="" />
              <span>Excel</span>
            </FormButton>
          </ContainerSize>

          <ContainerSize size="25%">
            <FormButton
              type="button"
              disabled={savedInvoices.length === 0}
              onClick={openAssociateModal}
            >
              <img src={AddImg} style={{ filter: 'invert(100%)' }} alt="" />
              <span>Associar</span>
            </FormButton>
          </ContainerSize>

          <ContainerSize size="25%">
            <FormButton
              type="button"
              disabled={
                savedInvoices.filter(state => state.isFinished === true)
                  .length !== 0 || savedInvoices.length === 0
              }
              onClick={openAddModal}
            >
              <IoIosAddCircle size={20} />
              <span>Ocorrência</span>
            </FormButton>
            {savedInvoices.filter(state => state.isFinished === true).length !==
            0 ? (
              <WarningText>Existe uma Nota já finalizada</WarningText>
            ) : (
              <></>
            )}
          </ContainerSize>
        </Row>
        <InfoContainer>
          {savedInvoices.length === 0 && (
            <span>Nenhuma fatura selecionada</span>
          )}
          {savedInvoices.length > 0 && (
            <>
              <span>
                {savedInvoices.length} fatura
                {savedInvoices.length > 1 ? 's selecionadas' : ' selecionada'}
              </span>
              <button type="button" onClick={handleRemoveSavedInvoices}>
                <FaTimesCircle /> remover
              </button>
            </>
          )}
        </InfoContainer>
        <RowCenter>
          <ChangeStatusButton
            type="button"
            name="Finalizados"
            disabled={statusButton === 'Finalizados'}
            onClick={() => handleButtonStatusChange('Finalizados')}
          >
            Realizados
          </ChangeStatusButton>
          <ContainerSize size="3%" />
          <ChangeStatusButton
            type="button"
            name="Abertos"
            disabled={statusButton === 'Abertos'}
            onClick={() => handleButtonStatusChange('Abertos')}
          >
            Abertos
          </ChangeStatusButton>
          <ContainerSize size="3%" />
          <ChangeStatusButton
            type="button"
            name="Todos"
            disabled={statusButton === 'Todos'}
            onClick={() => handleButtonStatusChange('Todos')}
          >
            Todos
          </ChangeStatusButton>
        </RowCenter>
        <Row>
          <ContainerSize size="100%">
            <TableList
              header={[
                '',
                'CTe',
                'Nota Fiscal',
                'Emissão NF',
                'Valor NF',
                'Valor Net NF',
                'Motorista',
                'Placa',
                'Última Ocorrência',
                'Opções',
              ]}
              data={results}
            />
          </ContainerSize>

          <Pagination
            pages={pages}
            currentPage={currentPage}
            onClickFunction={getData}
          />
        </Row>

        <Filter onSubmit={handleFilterResults}>
          <Input name="document-num_doc" type="text" placeholder="CTe" />
          <Input
            name="transporter_invoices-num_nfe"
            type="text"
            placeholder="Nota Fiscal"
          />
          <Input
            name="document-date_issuance>"
            type="date"
            placeholder="Data Inicial"
          />
          <Input
            name="document-date_issuance<"
            type="date"
            placeholder="Data Final"
          />
          <CurrencyInput
            name="transporter_invoices-value_nfe"
            type="text"
            placeholder="Valor NF"
          />
          <CurrencyInput
            name="transporter_invoices-value_net_nfe"
            type="text"
            placeholder="Valor Net NF"
          />
        </Filter>

        <Modal
          isOpen={showModalStack}
          title="Ocorrência em lote"
          backgroundColor="#F8F8FB"
          fontColor="#202020"
          id="modal-stack"
          onClose={() => setShowModalStack(false)}
        >
          <Form initialData={{}} ref={formStack} onSubmit={handleSubmitStack}>
            <Input
              name="code"
              onKeyPress={handleKeyPressedCode}
              placeholder="Cte"
            />
            {stackDocs.length > 0 && (
              <TableList header={['CTE', 'Remover']} data={stackDocsList} />
            )}
            <Autocomplete
              name="ocurrence_id"
              placeholder="Tipo de Evento"
              options={optionsType}
              changeValue={null}
              onInputChange={handleTypeInputChange}
            />

            <Textarea name="comments" placeholder="Observações" />
            <ButtonForm type="submit" disabled={stackDocs.length === 0}>
              Lançar Ocorrência
            </ButtonForm>
          </Form>
        </Modal>

        <Modal
          isOpen={showModal}
          id="modal-upload-files"
          title="Upload de Arquivos"
          backgroundColor="#FFFFFF"
          fontColor="#202020"
          onClose={handleModalAddFileClose}
        >
          <div>
            <Form initialData={{}} onSubmit={submitFiles}>
              {docType.length > 0 && (
                <UploadArea
                  multiple
                  name="anexos"
                  onChange={handleChangeAddDocs}
                />
              )}
              <ModalContainer>
                <Row>
                  <Radio
                    name="linkdocument"
                    options={[
                      { id: 'CTE', label: 'CTe' },
                      { id: 'NFE', label: 'Nota Fiscal' },
                    ]}
                    changeType={(event: any): void => {
                      const DocType = event.target.value;
                      setDocType(DocType);
                    }}
                    align="column"
                    specialmargin="20px 5px"
                  />
                </Row>
              </ModalContainer>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={showAddModal}
          id="modal-ocurrence"
          title="Adicionar Ocorrência"
          backgroundColor="#F8F8FB"
          fontColor="#202020"
          onClose={handleOcorrunceModalClose}
        >
          <Form ref={modalFormRef} initialData={{}} onSubmit={submitModalEvent}>
            <ContainerAddModal>
              <Autocomplete
                name="ocurrence_id"
                placeholder="Tipo de Evento"
                options={optionsType}
                changeValue={null}
                onInputChange={handleTypeInputChange}
              />

              <Textarea name="comments" placeholder="Observações" />

              <ModalButtonRow>
                <ContainerSize size="50%">
                  <ModalFormButton
                    type="button"
                    onClick={handleModalCancelButton}
                  >
                    Cancelar
                  </ModalFormButton>

                  <ModalFormButton type="submit">Criar</ModalFormButton>
                </ContainerSize>
              </ModalButtonRow>
            </ContainerAddModal>
          </Form>
        </Modal>

        <Modal
          isOpen={showListModal}
          title="Visualizar Arquivos"
          backgroundColor="#F8F8FB"
          fontColor="#202020"
          id="modal-files-list"
          onClose={handleCloseFilesList}
        >
          <TableList header={['', 'Excluir']} data={fileResults} />

          <Pagination
            pages={filePages}
            currentPage={currentFilePage}
            onClickFunction={getDataFiles}
          />
        </Modal>

        <Modal
          isOpen={showListOcurrencesModal}
          title="Visualizar Ocorrências"
          id="ocurrences-modal"
          backgroundColor="#F8F8FB"
          fontColor="#202020"
          onClose={handleCloseOcurrencelist}
        >
          <TableList
            header={['Data/Hora', 'Ocorrência', 'Observações']}
            data={ocurrencesInvoice}
          />

          <Pagination
            pages={ocurrencesInvoicePages}
            currentPage={currentOcurrencesInvoicePage}
            onClickFunction={getOcurrencesInvoice}
          />
        </Modal>

        <Modal
          isOpen={showAssociateModal}
          title="Associar Veículo/Motorista"
          id="associate-modal"
          backgroundColor="#F8F8FB"
          fontColor="#202020"
          onClose={handleCloseAssociateModal}
        >
          <Form ref={associateModalFormRef} onSubmit={submitAssociateModal}>
            <Row>
              <ContainerSize size="25%">
                <ModalContainerTitle> Veículo </ModalContainerTitle>
              </ContainerSize>
              <ContainerSize size="25%">
                <ModalButton className="disassociate">
                  Desassociar Veículo
                </ModalButton>
              </ContainerSize>
              <ContainerSize size="25%">
                <ModalButton>Associar Veículo</ModalButton>
              </ContainerSize>
              <ContainerSize size="25%">
                <ModalButton>Cadastrar Veículo</ModalButton>
              </ContainerSize>
            </Row>

            <Row>
              <ContainerSize size="25%">
                <Autocomplete
                  name="license_plate"
                  placeholder="Placa"
                  options={optionsLicensePlate}
                  changeValue={licensePlateChangeValue}
                  onInputChange={handleLicensePlateInputChange}
                />
              </ContainerSize>
              <ContainerSize size="40%">
                <Input
                  name="tipo-veiculo"
                  type="text"
                  placeholder="Tipo do Caminhão"
                  readOnly
                />
              </ContainerSize>
              <ContainerSize size="35%">
                <Input
                  name="status-veiculo"
                  type="text"
                  placeholder="Status"
                  readOnly
                />
              </ContainerSize>
            </Row>

            <Row style={{ marginTop: '20px' }}>
              <ContainerSize size="25%">
                <ModalContainerTitle> Motorista </ModalContainerTitle>
              </ContainerSize>
              <ContainerSize size="25%">
                <ModalButton className="disassociate">
                  Desassociar Motorista
                </ModalButton>
              </ContainerSize>
              <ContainerSize size="25%">
                <ModalButton>Associar Motorista</ModalButton>
              </ContainerSize>
              <ContainerSize size="25%">
                <ModalButton>Cadastrar Motorista</ModalButton>
              </ContainerSize>
            </Row>

            <Row>
              <ContainerSize size="30%">
                <InputMask
                  mask="999.999.999-99"
                  name="cpf"
                  type="text"
                  placeholder="CPF"
                  readOnly
                />
              </ContainerSize>

              <ContainerSize size="70%">
                <Autocomplete
                  name="name"
                  placeholder="Nome"
                  options={optionsDriver}
                  changeValue={driverChangeValue}
                  onInputChange={handleDriverInputChange}
                />
              </ContainerSize>
            </Row>

            <Row>
              <ContainerSize size="50%">
                <Input name="cnh" type="text" placeholder="CNH" readOnly />
              </ContainerSize>

              <ContainerSize size="50%">
                <Input
                  name="cnh-valid"
                  type="date"
                  placeholder="Validade CNH"
                  readOnly
                />
              </ContainerSize>
            </Row>

            <Row>
              <ContainerSize size="50%">
                <InputMask
                  name="phone"
                  type="text"
                  placeholder="Telefone"
                  mask={phoneNumberMask}
                  formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                  onKeyUp={handlerChangeBrazilianPhone}
                  maskChar=""
                />
              </ContainerSize>

              <ContainerSize size="50%">
                <Input name="email" type="email" placeholder="Email" readOnly />
              </ContainerSize>
            </Row>

            <Row>
              <ContainerSize size="100%">
                <AssociateModalFormButton type="submit">
                  Salvar
                </AssociateModalFormButton>
              </ContainerSize>
            </Row>
          </Form>
        </Modal>
      </PageContainer>
    </div>
  );
};

export default EventsEntry;
