import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  bpId: 0,
};

export const businessPersonSlice = createSlice({
  name: 'clientBusinessPerson',
  initialState,
  reducers: {
    setBusinessPersonId: (state, { payload: bpId }) => {
      state.bpId = !bpId ? initialState.bpId : bpId;
    },
  },
});
interface AsyncData {
  id?: number;
  data: any;
}

export const getClientBusinessPersonbyName = async (
  params: any,
): Promise<any> => {
  const businessPerson = await axios.get(`/local/client_business_person`, {
    params,
  });
  return businessPerson;
};

export const addClientBusinessPerson = createAsyncThunk<any, AsyncData>(
  'clientBusinessPerson/addClientBusinessPerson',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/local/client_business_person', {
        ...info.data,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const addClientBusinessPersonByClient = createAsyncThunk<any, AsyncData>(
  'clientBusinessPerson/addClientBusinessPerson',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/local/client_business_person/client',
        {
          ...info.data,
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export default businessPersonSlice.reducer;
