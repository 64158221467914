import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {};

export const ocurrencesIntegrationSlice = createSlice({
  name: 'ocurrencesIntegration',
  initialState,
  reducers: {},
});

interface OcurrenceIntegrationData {
  id: number;
  name: string;
}

export const getOcurrencesIntegration = async (
  params: any,
): Promise<OcurrenceIntegrationData[]> => {
  const {
    data: ocurrences_integration,
  } = await axios.get('/local/ocurrences_integration', { params });
  return ocurrences_integration.data;
};

export default ocurrencesIntegrationSlice.reducer;
