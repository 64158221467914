import styled, { css } from 'styled-components';

interface ComponentProps {
  size: string;
}

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: 600px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ContainerSizeAutoComplete = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;
