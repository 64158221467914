/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { useDebounce } from 'use-debounce';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import Input from '../../components/Input';
import { ContainerSize, AddRegisterButton } from './styles';
import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';
import { useDialog } from '../../hooks/dialog';
import Pagination from '../../components/Pagination';
import {
  getDrivers,
  DriverData,
  postDriver,
  updateDriver,
  setDriverId,
  getDriver,
  deleteDriver,
} from '../../features/transporterDrivers';
import { fetchTransporterByName } from '../../features/transporters';
import TableList from '../../components/TableList';
import Filter from '../../components/Filter';
import Autocomplete from '../../components/Autocomplete';
import Modal from '../../components/Modal';
import ButtonForm from '../../components/ButtonForm';
import InputMask from '../../components/InputMask';

interface optionsData {
  label: string;
  value: string;
}

const TransporterDrivers: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const [searchTransporters, setSearchTransporters] = useState('');
  const [optionsTransporters, setOptionsTransporters] = useState<optionsData[]>(
    [],
  );
  const [phoneNumberMask, setPhoneNumberMask] = useState('(99) 9999-9999?');
  const [valueSearchTransporter] = useDebounce(searchTransporters, 1000);
  const [isLoadingTransporters, setIsLoadingTransporters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState('Cadastrar');
  const { showLoading, hideLoading } = useLoading();
  const { addToast } = useToast();
  const { createDialog } = useDialog();
  const dispatch = useDispatch();

  const { driverId } = useSelector((state: RootStateOrAny) => ({
    driverId: state.features.transporterDrivers.driverId,
  }));

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const handleTransportersInputChange = (newValue: string): void => {
    if (newValue !== searchTransporters) {
      setIsLoadingTransporters(true);
    }
    setSearchTransporters(newValue);
  };

  useEffect(() => {
    (async () => {
      const transporters = await fetchTransporterByName({
        'transporter.name': valueSearchTransporter,
      });

      setOptionsTransporters([]);
      transporters.forEach((item: any): any => {
        setOptionsTransporters(state => [
          ...state,
          {
            value: item.id,
            label: `${item.id} - ${item.name}`,
          },
        ]);
      });
      setIsLoadingTransporters(false);
    })();
  }, [valueSearchTransporter]);

  const handleCreateDriver = useCallback((): void => {
    (formRef.current as any).reset();

    const select_transporter = formRef!.current!.getFieldRef('transporter_id');
    select_transporter.select.clearValue();

    const select_status = formRef!.current!.getFieldRef('status');
    select_status.select.clearValue();
    dispatch(setDriverId(0));
    openModal();
  }, [setSearchTransporters, dispatch, setDriverId]);

  const handleUpdateDriver = useCallback(
    async (id: number): Promise<void> => {
      (formRef.current as any).reset();

      const select_transporter = formRef!.current!.getFieldRef(
        'transporter_id',
      );
      select_transporter.select.clearValue();

      const select_status = formRef!.current!.getFieldRef('status');
      select_status.select.clearValue();

      showLoading();

      const response = await getDriver(id);

      if (response.status !== 200) {
        addToast({
          title: 'Erro',
          description: 'Ocorreu um erro ao buscar esse motorista',
          type: 'error',
        });
      } else {
        const driver = response.data;
        (formRef.current as any).setFieldValue('name', driver.name);
        (formRef.current as any).setFieldValue('email', driver.email);
        (formRef.current as any).setFieldValue('document', driver.document);
        const phoneNumber = driver.phone_number.replace(/[^0-9]/g, '');
        if (phoneNumber.length <= 10) {
          setPhoneNumberMask('(99) 9999-9999?');
        } else {
          setPhoneNumberMask('(99) 99999-9999');
        }
        (formRef.current as any).setFieldValue('phone_number', phoneNumber);
        (formRef.current as any).setFieldValue('license', driver.license);
        (formRef.current as any).setFieldValue(
          'license_date',
          new Date(driver.license_date).toLocaleDateString('en-CA'),
        );
        (formRef.current as any).setFieldValue('status', {
          label: driver.status,
          value: driver.status,
        });
        (formRef.current as any).setFieldValue('transporter_id', {
          label: `${driver.transporter.id} - ${driver.transporter.name}`,
          value: driver.transporter_id,
        });
        dispatch(setDriverId(driver.id));
        openModal();
      }

      hideLoading();
    },
    [
      setSearchTransporters,
      dispatch,
      setDriverId,
      openModal,
      showLoading,
      hideLoading,
    ],
  );

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback((data: Obj): void => {
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        searchData[key.replace('-', '.')] = value;
      }
    }
    setOptionsSearch(searchData);
  }, []);

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const drivers = await getDrivers({
        page,
        ...optionsSearch,
      });
      setResults([]);
      drivers.data.forEach((driver: DriverData) => {
        setResults(state => [
          ...state,
          [
            driver.id,
            driver.name,
            driver.email,
            driver.status,
            new Date(driver.created_at).toLocaleString('pt-BR', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }),
            <div className="options-table-list">
              <button
                type="button"
                onClick={() => {
                  handleUpdateDriver(driver.id);
                }}
              >
                <FaEdit size={20} color="#ffffff" />
              </button>
              <button
                type="button"
                onClick={() => {
                  createDialog({
                    text: 'Deseja realmente excluir esse motorista?',
                    textButtonCancel: 'Não',
                    textButtonConfirm: 'Sim',
                    onConfirm: () => {
                      (async () => {
                        showLoading();
                        await deleteDriver(driver.id);
                        hideLoading();
                        addToast({
                          title: 'Motorista excluído com sucesso',
                          type: 'success',
                        });
                        getData(1);
                      })();
                    },
                  });
                }}
              >
                <FaTrash size={20} color="#ffffff" />
              </button>
            </div>,
          ],
        ]);
      });
      const arrayPages = [];
      for (let i = 0; i < drivers.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);

      hideLoading();
    },
    [showLoading, optionsSearch, hideLoading],
  );

  const handleSubmitDriver = useCallback(
    async (data: any): Promise<void> => {
      try {
        (formRef.current as any).setErrors({});
        data.phone_number = data.phone_number.replace(/[^0-9]/g, '');
        const schemaValidation = Yup.object().shape({
          transporter_id: Yup.string().required('Selecione o transportador'),
          status: Yup.string().required('Selecione o status'),
          name: Yup.string().required('Informe o nome'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('Informe o e-mail'),
          document: Yup.string().required('Informe o RG'),
          phone_number: Yup.string().min(10, 'Mínimo de 10 dígitos'),
          license: Yup.string().required('Informe o número de habilitação'),
          license_date: Yup.date().required(
            'Informe a data de expedição da habilitação',
          ),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        showLoading();
        let response;
        if (driverId > 0) {
          response = await updateDriver(driverId, data);
        } else {
          response = await postDriver(data);
        }

        if (response.status === 200) {
          addToast({
            title: 'Sucesso',
            description: `Motorista ${
              driverId > 0 ? `atualizado` : `cadastrado`
            } com sucesso`,
            type: 'success',
          });
          dispatch(setDriverId(response.data.id));
        } else {
          addToast({
            title: 'Erro',
            description: `Não foi possível ${
              driverId > 0 ? `atualizar` : `cadastrar`
            } o motorista`,
            type: 'error',
          });
        }

        getData(1);

        hideLoading();
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (formRef.current as any).setErrors(errors);
        }
      }
    },
    [driverId, dispatch, showLoading, hideLoading, addToast, getData],
  );

  const handlerChangeBrazilianPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMask('(99) 9999-9999?');
      } else {
        setPhoneNumberMask('(99) 99999-9999');
      }
    },
    [],
  );

  useEffect(() => {}, [phoneNumberMask]);

  useEffect(() => {
    getData(1);
  }, [optionsSearch]);

  useEffect(() => {
    if (driverId > 0) {
      setButtonText('Alterar');
    } else {
      setButtonText('Cadastrar');
    }
  }, [driverId]);

  return (
    <PageContainer>
      <MainTitle>Motoristas</MainTitle>

      <TableList
        header={[
          'Código',
          'Nome',
          'Email',
          'Status',
          'Data de cadastro',
          'Editar',
        ]}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
      />
      <AddRegisterButton type="button" onClick={handleCreateDriver}>
        <IoMdAdd size={30} />
      </AddRegisterButton>
      <Filter onSubmit={handleSubmitForm}>
        <Input name="ocurrences-name" type="text" placeholder="Nome" />
        <Input name="ocurrences-status" type="text" placeholder="Status" />
        <Input
          name="ocurrences-created_at>"
          type="date"
          placeholder="Data Inicial"
        />
        <Input
          name="ocurrences-created_at<"
          type="date"
          placeholder="Data Final"
        />
      </Filter>
      <Modal
        isOpen={showModal}
        title={`${buttonText} Motorista`}
        backgroundColor="#F8F8FB"
        fontColor="#3a3a3a"
        id="modal-driver"
        onClose={() => setShowModal(false)}
      >
        <div>
          <Form
            ref={formRef}
            initialData={{}}
            onSubmit={handleSubmitDriver}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            noValidate
          >
            <ContainerSize size="100%" style={{ display: 'block' }}>
              <Autocomplete
                name="transporter_id"
                options={optionsTransporters}
                placeholder="Transportador"
                changeValue={null}
                onInputChange={handleTransportersInputChange}
                isLoading={isLoadingTransporters}
              />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input name="name" placeholder="Nome" />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input name="email" placeholder="email" />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input name="document" placeholder="RG" />
            </ContainerSize>
            <ContainerSize size="100%">
              <InputMask
                name="phone_number"
                type="text"
                placeholder="Telefone"
                mask={phoneNumberMask}
                formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                onKeyUp={handlerChangeBrazilianPhone}
                maskChar=""
              />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input name="license" placeholder="Habilitação" />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input
                type="date"
                name="license_date"
                placeholder="Data de expedição"
              />
            </ContainerSize>
            <ContainerSize size="100%" style={{ display: 'block' }}>
              <Autocomplete
                name="status"
                options={[
                  { label: 'Ativo', value: 'Ativo' },
                  { label: 'Inativo', value: 'Inativo' },
                ]}
                placeholder="Status"
                changeValue={null}
              />
            </ContainerSize>
            <ContainerSize size="40%" style={{ display: 'block' }}>
              <ButtonForm type="submit">{buttonText}</ButtonForm>
            </ContainerSize>
          </Form>
        </div>
      </Modal>
    </PageContainer>
  );
};

export default TransporterDrivers;
