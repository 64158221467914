import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch, fetchById, merge } from '../../entities/users';
import { storage } from '../../app/authConfig';
import axios from '../../app/axios';

const initialState = {
  userId: 0,
  currentListPage: 1,
};

interface AsyncDataPic {
  id: number;
  user: any;
  pic: Blob | Uint8Array | ArrayBuffer;
}

interface AsyncData {
  id: number;
  user: any;
}

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, { payload: userId }) => {
      state.userId = !userId ? initialState.userId : userId;
    },
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});

const { setUserId } = usersSlice.actions;

export const updateUserWithPhotoThunk = createAsyncThunk<any, AsyncDataPic>(
  'users/updateUserWithPhoto',
  async (info: AsyncDataPic, { dispatch, rejectWithValue }) => {
    const is_active = info.user.is_active === 1;
    const path =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    try {
      await storage.ref(`users/picture/${path}/profile.jpg`).put(info.pic);
      const downloadUrl = await storage
        .ref(`users/picture/${path}/profile.jpg`)
        .getDownloadURL();
      const response = await axios.put(`/local/users/${info.id}`, {
        ...info.user,
        photo_link: downloadUrl,
        is_active,
      });
      dispatch(merge(response.data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const addUserThunk = createAsyncThunk<any, AsyncData>(
  'users/addUserThunk',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const is_active = info.user.is_active === 1;
      const response = await axios.post('/local/users', {
        ...info.user,
        is_active,
      });
      dispatch(merge(response.data));
      dispatch(setUserId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateUserThunk = createAsyncThunk<any, AsyncData>(
  'users/addUserThunk',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const is_active = info.user.is_active === 1;
      const response = await axios.put(`/local/users/${info.id}`, {
        ...info.user,
        is_active,
      });
      dispatch(merge(response.data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const addNewUserWithPhotoThunk = createAsyncThunk(
  'users/addNewUser',
  async (info: AsyncDataPic, { dispatch, rejectWithValue }) => {
    const is_active = info.user.is_active === 1;
    const path =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    const response = await storage
      .ref(`users/picture/${path}/profile.jpg`)
      .put(info.pic)
      .then(() => {
        storage
          .ref(`users/picture/${path}/profile.jpg`)
          .getDownloadURL()
          .then(photo_link =>
            axios
              .post('/local/users', { ...info.user, is_active, photo_link })
              .then(({ data }) => {
                dispatch(merge(data));
                dispatch(setUserId(data.id));
              }),
          )
          .catch(err => {
            storage.ref(`users/picture/${path}/profile.jpg`).delete();
            rejectWithValue(err);
          });
      })
      .catch(error => {
        rejectWithValue(error);
      });
    return response;
  },
);

export const setUserCreatedId = (id: number) => (dispatch: any): any => {
  dispatch(setUserId(id));
};

export const fetchUsers = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchUsersById = (userId: number) => (dispatch: any): any => {
  dispatch(fetchById(userId));
  dispatch(setUserId(userId));
};

export const getUserById = async (userId: number): Promise<any> => {
  const { data: users } = await axios.get(`/local/users/${userId}`);
  return users;
};

export const fetchUsersWithParams = async (params: any): Promise<any> => {
  const { data: users } = await axios.get('/local/users', { params });
  return users;
};

export const fetchUsersByEmail = async (params: any): Promise<any> => {
  const { data: users } = await axios.get('/local/users', { params });
  return users.data;
};

export const patchUserLgpdAprove = async (id: number): Promise<any> => {
  const { data: user } = await axios.patch(`/local/users/lgpd/${id}`);
  return user;
};

export const addNewUser = (user: any): any => async (dispatch: any) => {
  const is_active = 1;
  await axios.post('/local/users', { ...user, is_active }).then(({ data }) => {
    dispatch(merge(data));
    dispatch(setUserId(data.id));
  });
};

export const addNewUserWithPhoto = (
  user: any,
  pic: Blob | Uint8Array | ArrayBuffer,
): any => async (dispatch: any) => {
  const is_active = user.is_active === 1;
  const path =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  storage
    .ref(`users/picture/${path}/profile.jpg`)
    .put(pic)
    .then(() => {
      storage
        .ref(`users/picture/${path}/profile.jpg`)
        .getDownloadURL()
        .then(photo_link =>
          axios
            .post('/local/users', { ...user, photo_link, is_active })
            .then(({ data }) => {
              dispatch(merge(data));
              dispatch(setUserId(data.id));
            }),
        )
        .catch(() => {
          storage.ref(`users/picture/${path}/profile.jpg`).delete();
        });
    })
    .catch(error => {
      console.log(error.message);
    });
};

export const updateUserWithPhoto = async (
  id: number,
  user: any,
  pic: Blob | Uint8Array | ArrayBuffer,
): Promise<any> => async (dispatch: any) => {
  const is_active = 1;
  const path =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  storage
    .ref(`users/picture/${path}/profile.jpg`)
    .put(pic)
    .then(() => {
      storage
        .ref(`users/picture/${path}/profile.jpg`)
        .getDownloadURL()
        .then(photo_link =>
          axios
            .put(`/local/users/${id}`, { ...user, photo_link, is_active })
            .then(({ data }) => {
              dispatch(merge(data));
            }),
        )
        .catch(() => {
          storage.ref(`users/picture/${path}/profile.jpg`).delete();
        });
    })
    .catch(error => {
      console.log(error.message);
    });
};

export const updateUser = (id: number, user: any): any => async (
  dispatch: any,
) => {
  console.log(user);
  const is_active = 1;
  axios.put(`/local/users/${id}`, { ...user, is_active }).then(({ data }) => {
    dispatch(merge(data));
  });
};

export default usersSlice.reducer;
