import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface HeaderProps {
  fontHeaderSize: string;
  fontHeaderSizeResponsive: string;
}

interface DataProps {
  fontDataSize: string;
  fontDataSizeResponsive: string;
  fontHeaderSizeResponsive: string;
}

export const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0;
  overflow-x: auto;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    padding: 12px 12px;
    background-color: #fff;
    border-radius: 12px;
  }
`;

export const Table = styled.div`
  width: 100%;
  max-width: 100%;
  display: table;
  margin: 0;
  padding-right: 2px;
  padding-left: 2px;
  border-collapse: separate;
  border-spacing: 0 0.5em;

  @media (max-width: 768px) {
    display: block;
  }
`;
export const Header = styled.div<HeaderProps>`
  display: table-row;
  background: transparent;
  color: #1e1c1c;
  padding: 0;
  height: 0;
  margin-bottom: 10px;
  > div {
    display: table-cell;
    ${props =>
      props.fontHeaderSize &&
      css`
        font-size: ${props.fontHeaderSize};
      `}
    color: #6f7683;
    font-weight: 600;
    line-height: 1.2;
    padding-top: 19px;
    padding-bottom: 19px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    text-align: center;
  }
  @media (max-width: 768px) {
    display: block;

    > div {
      display: none;
      &:before {
        ${props =>
          props.fontHeaderSizeResponsive &&
          css`
            font-size: ${props.fontHeaderSizeResponsive};
          `}
        color: gray;
        line-height: 1.2;
        text-transform: uppercase;
        font-weight: unset !important;
        margin-bottom: 13px;
        content: attr(data-title);
        min-width: 98px;
        display: block;
      }
    }
  }
`;
export const Row = styled.div<DataProps>`
  width: 100%;
  display: table-row;
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    border: none;
    display: block;
    padding-bottom: 18px;
    padding-top: 30px;
    padding-right: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 6px;
  }
`;

interface RowChildProps extends DataProps {
  breakWord: boolean;
}

export const RowChild = styled.div<RowChildProps>`
  display: table-cell;
  ${props =>
    props.fontDataSize &&
    css`
      font-size: ${props.fontDataSize};
    `}
  color: #6f7683;
  line-height: 1.2;
  font-weight: unset !important;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: middle;
  text-align: center;

  border: 3px solid #f8f8fb;
  border-bottom: unset;
  border-left: unset;
  border-right: unset;
  ${props =>
    props.breakWord &&
    css`
      word-break: break-word;
    `}

  &:last-child button,
  &:last-child a {
    border: none;
    border-radius: 50%;
    background-color: #003ca6;
    color: #ffffff;
    text-decoration: none;
    color: inherit;
    width: 40px;
    margin: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s linear;
    box-shadow: rgba(0, 60, 166, 0.2) 3px 3px 16px;

    &:hover {
      background-color: ${shade('0.1', '#003CA6')};
    }
  }

  @media (max-width: 768px) {
    display: block;
    border: none;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    ${props =>
      props.fontDataSizeResponsive &&
      css`
        font-size: ${props.fontDataSizeResponsive};
      `}
    color: #555;
    line-height: 1.2;
    font-weight: unset !important;
    width: 100%;
    &:before {
      ${props =>
        props.fontHeaderSizeResponsive &&
        css`
          font-size: ${props.fontHeaderSizeResponsive};
        `}
      color: #6f7683;
      line-height: 1.2;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 13px;
      content: attr(data-title);
      min-width: 98px;
      display: block;
    }
    &:first-child,
    &:last-child {
      border: none;
    }

    &:first-child {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    &:last-child {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
`;
