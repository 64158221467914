import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  transporterDocumentId: 0,
};

export const transporterDocumentSlice = createSlice({
  name: 'eventsEntry',
  initialState,
  reducers: {
    setTransporterDocumentId: (state, { payload: transporterDocumentId }) => {
      state.transporterDocumentId = !transporterDocumentId
        ? initialState.transporterDocumentId
        : transporterDocumentId;
    },
  },
});
export const { setTransporterDocumentId } = transporterDocumentSlice.actions;

interface TransporterDocumentResponseData {
  id: number;
  transporter_id: number | null;
  num_doc: number;
  doc_key: string;
  date_issuance: Date;
  value_doc: number;
  instructions: string;
  transporter: TransporterData | null;
}

interface TransporterData {
  id: number;
  user_id: number;
  num_doc: number;
  name: string;
}

interface ResponseDocumentsData {
  data: {
    data: TransporterDocumentResponseData[];
    per_page: number;
    total_pages: number;
    total: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getNotLinkedDocuments = async (
  params: any,
): Promise<ResponseDocumentsData> => {
  const documents = await axios.get(
    `/local/transporters_documents/not_linked`,
    {
      params,
    },
  );
  return documents;
};

export const getDocuments = async (
  params: any,
): Promise<ResponseDocumentsData> => {
  const documents = await axios.get(`/local/transporters_documents`, {
    params,
  });
  return documents;
};

export const updateDocument = async (
  id: number,
  transporter_id: number,
): Promise<any> => {
  const response = await axios.put(`/local/transporters_documents/${id}`, {
    transporter_id,
  });
  return response;
};

export const loadDriverVehicleInfo = async (
  invoice_id: number,
): Promise<any> => {
  const response = await axios.get(
    `/local/transporters_invoices/${invoice_id}`,
  );
  return response;
};

export const associateDriver = async (
  transporter_id: number,
  vehicle_id: number,
  driver_id: number,
): Promise<any> => {
  const response = await axios.patch(
    `/local/transporters_invoices/${transporter_id}/${vehicle_id}/${driver_id}`,
    {},
  );
  return response;
};

export default transporterDocumentSlice.reducer;
