/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IoIosAddCircle } from 'react-icons/io';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { FiTrash, FiInfo, FiExternalLink } from 'react-icons/fi';
import moment from 'moment';
import { FaFilePdf } from 'react-icons/fa';
import { SiMicrosoftexcel } from 'react-icons/si';
import download from 'downloadjs';
import {
  ViewContainer,
  LinkModal,
  FloatButton,
  ContainerFreightTable,
  DocInformation,
} from './styles';
import TotalQuotations from '../../components/TotalQuotations';
import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import TableList from '../../components/TableList';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';
import { formatValueToCurrencyInput } from '../../utils/formatValueCurrencyInput';

import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';
import { useDialog } from '../../hooks/dialog';

import {
  getQuotationGeneral,
  getQuotationInfo,
  quotationSlice,
  deleteQuotation,
  setStatusQuotation,
  downloadPDF,
  downloadCSV,
} from '../../features/quotations';
import Filter from '../../components/Filter';
import Autocomplete from '../../components/Autocomplete';
import Input from '../../components/Input';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../features/clients';
import { getFreightTableTariff } from '../../features/freightTableTariff';
import { getFreightTableItem } from '../../features/freightTableItens';
import { getClientBusinessPersonbyName } from '../../features/clientBusinessPerson';
import { getBusinessPersonbyName } from '../../features/businessPerson';
import { filterSlice } from '../../features/filter';

const ViewQuotation: React.FC = () => {
  const {
    userType,
    userTypeId,
    userId,
    currentListPage,
    formValue,
    locationFilter,
  } = useSelector((state: RootStateOrAny) => ({
    quotationId: state.features.quotation.quotationId,
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    currentListPage: state.features.quotation.currentListPage,
    formValue: state.features.filter.formValue,
    locationFilter: state.features.filter.location,
  }));
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? {} : formValue,
  );
  const [freightTableTariff, setFreightTableTariff] = useState<any>(null);
  const [freightTableTariffItem, setFreightTableTariffItem] = useState<any>(
    null,
  );
  const [showModalFreightTable, setShowModalFreightTable] = useState(false);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [optionsBusinessPersonRem, setOptionsBusinessPersonRem] = useState<any>(
    [],
  );
  const [searchBusinessPersonRem, setSearchBusinessPersonRem] = useState('');
  const [valueSearchBusinessPersonRem] = useDebounce(
    searchBusinessPersonRem,
    1000,
  );

  const [optionsBusinessPersonDest, setOptionsBusinessPersonDest] = useState<
    any
  >([]);
  const [searchBusinessPersonDest, setSearchBusinessPersonDest] = useState('');
  const [valueSearchBusinessPersonDest] = useDebounce(
    searchBusinessPersonDest,
    1000,
  );
  const [isLoadingBusinessPersonRem, setIsLoadingBusinessPersonRem] = useState(
    false,
  );
  const [
    isLoadingBusinessPersonDest,
    setIsLoadingBusinessPersonDest,
  ] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const { addToast } = useToast();
  const { createDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();

  const [TotalOpenQuotations, setTotalOpenQuotations] = useState(0);
  const [TotalToQuoteItems, setTotalToQuoteItems] = useState(0);
  const [TotalLastMonthQuotations, setTotalLastMonthQuotations] = useState(0);
  const [
    TotalAnsweredLastMonthQuotations,
    setTotalAnsweredLastMonthQuotations,
  ] = useState(0);
  const dispatch = useDispatch();
  const {
    setQuotationId,
    setCurrentListPage,
    setFilterOptionsSearch,
  } = quotationSlice.actions;
  const { setFormValue } = filterSlice.actions;

  interface Obj {
    [key: string]: string;
  }

  useEffect(() => {
    (async () => {
      const allBusinessPersonRem: any = [];
      if (userType === 'saleman' || userType === 'client') {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });
        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });
        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonRem(allBusinessPersonRem);
      setIsLoadingBusinessPersonRem(false);
    })();
  }, [valueSearchBusinessPersonRem]);

  useEffect(() => {
    (async () => {
      const allBusinessPersonDest: any = [];

      if (userType === 'saleman' || userType === 'client') {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonDest(allBusinessPersonDest);
      setIsLoadingBusinessPersonDest(false);
    })();
  }, [valueSearchBusinessPersonDest]);

  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      setCurrentPage(1);
      const searchData: Obj = {};
      const searchForm: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          searchData[key.replace('-', '.')] = value;
          searchForm[key] = value;
        }
      }

      dispatch(setFilterOptionsSearch(searchData));
      dispatch(setFormValue(searchData));
      setOptionsSearch(searchData);
    },
    [setOptionsSearch, setCurrentPage],
  );

  const handleDeleteQuotation = useCallback(
    async (id: number): Promise<void> => {
      showLoading();
      const response = await deleteQuotation(id);
      hideLoading();
      if (response.status === 200) {
        addToast({
          title: 'Cotação excluída com sucesso',
          type: 'success',
        });
        setUpdateData(true);
      } else {
        addToast({
          title: 'Erro ao excluir cotação',
          type: 'error',
        });
      }
    },
    [showLoading, hideLoading, setUpdateData],
  );

  const handleFreighTableInformation = useCallback(
    async (idTariff: number, idItem: number): Promise<void> => {
      showLoading();
      const freighTableInfo = await getFreightTableTariff(idTariff);
      const freighTableItemInfo = await getFreightTableItem(idItem);
      setFreightTableTariff(freighTableInfo.data);
      setFreightTableTariffItem(freighTableItemInfo.data);
      hideLoading();
      setShowModalFreightTable(true);
    },
    [],
  );

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();

      const quotationsInfo = await getQuotationInfo({ ...optionsSearch });
      const quotations = await getQuotationGeneral({ page, ...optionsSearch });
      setResults(quotations.data);
      setTotalOpenQuotations(quotationsInfo.data.opened);
      setTotalToQuoteItems(quotationsInfo.data.closed);
      setTotalLastMonthQuotations(quotationsInfo.data.total);
      setTotalAnsweredLastMonthQuotations(quotationsInfo.data.media);
      const arrayPages = [];
      for (let i = 0; i < quotations.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));
      hideLoading();
      addToast({ title: 'Cotações carregadas com sucesso', type: 'success' });
      const quotationResult: Array<object> = [];

      quotations.data.data.forEach((item: any) => {
        quotationResult.push([
          <>
            {item.id}
            <br />
            {moment.utc(item.created_at).add('day', 1).format('DD/MM/YYYY')}
          </>,
          item.client.short_name,
          `${item.origin.name}-${item.origin.uf} -> ${item.destination.name}-${item.destination.uf}`,
          <DocInformation>
            <span>Volume: {formatValueToCurrencyInput(item.volume)}</span>
            <span>Peso: {formatValueToCurrencyInput(item.weight)}</span>
            <span>
              Valor NFE: R$ {formatValueToCurrencyInput(item.nfe_value)}
            </span>
          </DocInformation>,
          `R$ ${formatValueToCurrencyInput(item.value)}`,
          item.status === 'A'
            ? 'Aprovada'
            : item.status === 'R'
            ? 'Reprovada'
            : 'Aguardando',
          item.status !== 'A' && item.status !== 'R' ? (
            <div className="options-table-list">
              <Link to={`/criar-cotacoes/${item.id}`}>
                <AiOutlineFileSearch size={20} color="#ffffff" />
              </Link>
              <button
                type="button"
                onClick={() => {
                  createDialog({
                    text: 'Excluir cotação?',
                    textButtonCancel: 'Não',
                    textButtonConfirm: 'Sim',
                    onConfirm: () => handleDeleteQuotation(item.id),
                  });
                }}
              >
                <FiTrash color="#ffffff" />
              </button>
              {item.freight_table_tariff_id && userType !== 'client' ? (
                <button
                  type="button"
                  onClick={() => {
                    handleFreighTableInformation(
                      item.freight_table_tariff_id,
                      item.freight_table_tariff_item_id,
                    );
                  }}
                >
                  <FiInfo size={20} color="#ffffff" />
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="options-table-list">
              <Link to={`/criar-cotacoes/${item.id}`}>
                <AiOutlineFileSearch size={20} color="#ffffff" />
              </Link>
            </div>
          ),
        ]);
      });
      setResults(quotationResult);
      setUpdateData(false);
      setIsLoadingPage(false);
    },
    [
      addToast,
      optionsSearch,
      hideLoading,
      showLoading,
      setUpdateData,
      currentPage,
    ],
  );

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchClientsByDocumentNumber({
                document_number: valueSearchClient,
                ...isSalesman,
              })
            : await fetchClientsByDocumentNumber({
                company_name: valueSearchClient,
                ...isSalesman,
              });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } = await fetchUserClientsByDocumentNumber({
          'client.document_number': valueSearchClient,
          user_id: userId,
        });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [userId, userType, userTypeId, valueSearchClient]);

  useEffect(() => {
    getData(currentPage);
  }, [optionsSearch]);

  useEffect(() => {
    if (updateData === true) {
      getData(currentPage);
    }
  }, [updateData]);

  useEffect(() => {
    dispatch(setQuotationId(0));
    dispatch(setStatusQuotation('I'));
  }, []);

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  const handleBusinessPersonRemInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonRem) {
      setIsLoadingBusinessPersonRem(true);
    }
    setSearchBusinessPersonRem(newValue);
  };

  const handleBusinessPersonDestInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonDest) {
      setIsLoadingBusinessPersonDest(true);
    }
    setSearchBusinessPersonDest(newValue);
  };

  const handleClickDownloadCSV = useCallback(async () => {
    showLoading();

    try {
      const response = await downloadCSV({
        ...optionsSearch,
      });
      const fileName = `cotacoes-${new Date().toLocaleDateString()}`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Faturas!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  const handleClickDownloadPDF = useCallback(async () => {
    showLoading();

    try {
      const response = await downloadPDF({
        ...optionsSearch,
      });
      const fileName = `cotacoes-${new Date().toLocaleDateString()}.pdf`;
      download(response.data, fileName, 'application/pdf');
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Faturas!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  return (
    <PageContainer>
      <MainTitle>Cotações</MainTitle>
      <TotalQuotations
        openQuotations={TotalOpenQuotations}
        ToQuoteItems={TotalToQuoteItems}
        LastMonthQuotations={TotalLastMonthQuotations}
        AnsweredLastMonthQuotations={TotalAnsweredLastMonthQuotations}
      />
      <ViewContainer>
        <Link to="/criar-cotacoes">
          <IoIosAddCircle size={20} />
          Cotação
        </Link>
      </ViewContainer>

      <hr className="hr" />

      <TableList
        header={[
          'ID - Data',
          'Cliente',
          'Origem e Destino',
          'Carga',
          'Valor Cotação',
          'Status',
          'Visualizar',
        ]}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
        isLoadingPage={isLoadingPage}
      />

      <Filter onSubmit={handleSubmitForm}>
        <Autocomplete
          name="client_id"
          options={optionsClients}
          placeholder="CPF/CNPJ"
          changeValue={null}
          onInputChange={handleClientInputChange}
          isLoading={isLoadingClients}
        />
        <Autocomplete
          name="sender_id"
          placeholder="Remetente"
          options={optionsBusinessPersonRem}
          changeValue={null}
          isLoading={isLoadingBusinessPersonRem}
          onInputChange={handleBusinessPersonRemInputChange}
        />
        <Autocomplete
          name="receiver_id"
          placeholder="Destinatario"
          options={optionsBusinessPersonDest}
          changeValue={null}
          isLoading={isLoadingBusinessPersonDest}
          onInputChange={handleBusinessPersonDestInputChange}
        />
        <Input type="date" name="quotation-created_at>" placeholder="De" />
        <Input type="date" name="quotation-created_at<" placeholder="Até" />
      </Filter>
      <FloatButton
        type="button"
        title="Exportar em Excel"
        onClick={() => handleClickDownloadCSV()}
      >
        <SiMicrosoftexcel size={25} />
      </FloatButton>
      <FloatButton
        type="button"
        title="Exportar em PDF"
        onClick={() => handleClickDownloadPDF()}
        style={{ bottom: '154px' }}
      >
        <FaFilePdf size={25} />
      </FloatButton>
      <Modal
        backgroundColor="linear-gradient(
          270deg,
          rgba(0, 92, 151, 0.95),
          rgba(54, 55, 149, 0.95)
        )"
        fontColor="#fff"
        isOpen={showModalFreightTable}
        title="Informações Tabela de Frete"
        id="modal-freight-table"
        onClose={() => setShowModalFreightTable(false)}
      >
        {freightTableTariff && freightTableTariffItem ? (
          <ContainerFreightTable>
            <h2>
              {freightTableTariff.freight_table.id} -{' '}
              {freightTableTariff.freight_table.description}
            </h2>
            {freightTableTariff.region_origin ? (
              <>
                <div>
                  <h3>Região Origem</h3>
                  <span>
                    {freightTableTariff.region_origin.id}-
                    {freightTableTariff.region_origin.name}
                  </span>
                </div>
                <div>
                  <h3>Região Destino</h3>
                  <span>
                    {freightTableTariff.region_destination.id}-
                    {freightTableTariff.region_destination.name}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div>
                  <h3>Região Origem</h3>
                  <span>
                    {freightTableTariff.origin.id}-
                    {freightTableTariff.origin.name}
                  </span>
                </div>
                <div>
                  <h3>Região Destino</h3>
                  <span>
                    {freightTableTariff.destination.id}-
                    {freightTableTariff.destination.name}
                  </span>
                </div>
              </>
            )}
            <div>
              <h3>Faixa Inicial</h3>
              <span> {freightTableTariffItem.starting.replace(',', '.')}</span>
            </div>
            <div>
              <h3>Faixa Final</h3>
              <span> {freightTableTariffItem.finishing.replace(',', '.')}</span>
            </div>
            <div>
              <h3>Tipo Pedágio</h3>
              <span>{freightTableTariff.tollType.name}</span>
            </div>
            <div>
              <h3>Tipo Faixa</h3>
              <span>{freightTableTariffItem.trackType.name}</span>
            </div>
            <LinkModal>
              <Link
                to={`/tabela-de-frete/${freightTableTariff.freight_table.id}/tarifas/${freightTableTariff.id}`}
              >
                <FiExternalLink size={20} />
                Tabela de Frete
              </Link>
            </LinkModal>
          </ContainerFreightTable>
        ) : (
          <></>
        )}
      </Modal>
    </PageContainer>
  );
};

export default ViewQuotation;
