/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { RiFileEditFill } from 'react-icons/ri';
import { FaTrash, FaTruck, FaEdit, FaQuestion } from 'react-icons/fa';
import { BiUserPlus } from 'react-icons/bi';
import { FiUsers, FiArrowLeft, FiCheck } from 'react-icons/fi';
import { VscFileSymlinkFile } from 'react-icons/vsc';
import { useDebounce } from 'use-debounce';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';
import { useDialog } from '../../hooks/dialog';

import getValidationErrors from '../../utils/getValidationErrors';

import {
  FreightTableData,
  getFreightTable,
  addNewFreightTable,
  updateFreightTable,
  freightTableSlice,
  getClientsFreightTable,
  addClientFreightTable,
  removeClientFreightTable,
} from '../../features/freightTable';

import {
  fetchFilesByFreightTable,
  addNewFile,
  removeFile,
} from '../../features/freightTableFiles';

import {
  getFreightTableTariffByFreightTable,
  setFreightTableTariffId,
  freightTableTariffSlice,
} from '../../features/freightTableTariff';
import { fetchSalesmanByName } from '../../features/salesman';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../features/clients';

import PageContainer from '../../components/PageContainer';

import MainTitle from '../../components/MainTitle';
import Input from '../../components/Input';
import Radio from '../../components/Radio';
import ButtonForm from '../../components/ButtonForm';
import Autocomplete from '../../components/Autocomplete';
import Modal from '../../components/Modal';
import UploadArea from '../../components/UploadArea';

import {
  Row,
  ContainerSize,
  ListFiles,
  ListClients,
  ButtonAddFile,
  ContainerRadio,
  ContainerTitle,
  ButtonAddClient,
  IdContainer,
  BackButton,
} from './styles';

import TableList from '../../components/TableList';
import Pagination from '../../components/Pagination';
import { formatValueToCurrencyInput } from '../../utils/formatValueCurrencyInput';
import Filter from '../../components/Filter';
import { aproveFreightTable } from '../../features/freightTable';

interface FreightTableParams {
  id: string | undefined;
}

interface Documents {
  id: number;
  file_name: string;
  client_id: number;
  file_link: string;
}

const FreightTable: React.FC = () => {
  const {
    freightTableId,
    userType,
    userId,
    userTypeId,
    currentListPage,
    formValue,
    locationFilter,
  } = useSelector((state: RootStateOrAny) => ({
    freightTableId: state.features.freightTable.freightTableId,
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    currentListPage: state.features.freightTableTariff.currentListPage,
    formValue: state.features.filter.formValue,
    locationFilter: state.features.filter.location,
  }));
  const formRef = useRef<FormHandles>(null);
  const modalFormRef = useRef<FormHandles>(null);
  const modalClientsFormRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? {} : formValue,
  );
  const [optionsSalesman, setOptionsSalesman] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [searchSalesname, setSearchSalesname] = useState('');
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const optionsIcms = [
    {
      value: 'PA',
      label: 'Padrão',
    },
    {
      value: 'IN',
      label: 'Incluso',
    },
    {
      value: 'SU',
      label: 'Substituição Tributária',
    },
    {
      value: 'IS',
      label: 'Isento',
    },
  ];
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [valueSearchSalesman] = useDebounce(searchSalesname, 1000);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalClients, setShowModalClients] = useState(false);
  const [insuranceClient, setInsuranceClient] = useState(false);
  const [buttonText, setButtonText] = useState('Cadastrar');
  const [tariffTable, setTariffTable] = useState<any>([]);
  const [pages, setPages] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [status, setStatus] = useState('I');
  const [filesFreightTable, setFilesFreightTable] = useState<Array<Documents>>(
    [],
  );
  const [clientsFreightTable, setClientsFreightTable] = useState<Array<any>>(
    [],
  );
  const { addToast } = useToast();
  const { createDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();
  const { setFreightTableId } = freightTableSlice.actions;
  const { setCurrentListPage } = freightTableTariffSlice.actions;

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const openModalClients = useCallback((): void => {
    setShowModalClients(true);
  }, []);

  const removeDocument = useCallback(
    async (id: number): Promise<void> => {
      showLoading();
      await removeFile(freightTableId, id);
      setFilesFreightTable(state => state.filter(file => file.id !== id));
      addToast({
        title: 'Sucesso',
        type: 'success',
        description: 'Arquivo excluído com sucesso',
      });
      hideLoading();
    },
    [showLoading, freightTableId, addToast, hideLoading],
  );

  const handleSubmit = useCallback(
    async (data: FreightTableData): Promise<void | false> => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          salesman_id:
            userType === 'admin'
              ? Yup.string().required('Selecione o Vendedor')
              : Yup.string().notRequired(),
          description: Yup.string().notRequired(),
          comments: Yup.string().notRequired(),
          icms_type: Yup.string().required('Informe o ICMS'),
          is_region: Yup.string().nullable().required('Selecione uma opção'),
          client_insurance: Yup.string()
            .nullable()
            .required('Selecione uma opção'),
          insurance_ddr: Yup.string().when('client_insurance', {
            is: '1',
            then: Yup.string().required('Informe DDR Seguro'),
          }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
        return false;
      }

      data.is_region = data.is_region === '1';
      showLoading();
      if (userType === 'salesman') {
        data.salesman_id = userTypeId;
      }
      if (freightTableId === 0) {
        const register = await addNewFreightTable(data);

        if (register.status === 200) {
          dispatch(setFreightTableId(register.data.id));
          addToast({
            title: 'Sucesso',
            description: 'Tabela de frete cadastrada com sucesso.',
            type: 'success',
          });
        } else {
          addToast({
            title: 'Erro',
            description: 'Erro ao cadastrar a tabela de frete.',
            type: 'error',
          });
        }
      } else {
        delete data.is_region;
        const update = await updateFreightTable(freightTableId, data);

        if (update.status === 200) {
          addToast({
            title: 'Sucesso',
            description: 'Tabela de frete alterada com sucesso.',
            type: 'success',
          });
        } else {
          addToast({
            title: 'Erro',
            description: 'Erro ao alterar a tabela de frete.',
            type: 'error',
          });
        }
      }

      hideLoading();
      return false;
    },
    [
      showLoading,
      userType,
      freightTableId,
      hideLoading,
      userTypeId,
      dispatch,
      setFreightTableId,
      addToast,
    ],
  );

  const handleSalesmanInputChange = (newValue: string): void => {
    if (newValue !== searchSalesname) {
      setIsLoadingSalesman(true);
    }
    setSearchSalesname(newValue);
  };

  useEffect(() => {
    (async () => {
      const allSalesman: any = [];
      if (userType === 'admin') {
        const salesmans = await fetchSalesmanByName({
          salesname: valueSearchSalesman,
        });
        if (salesmans) {
          salesmans.forEach((salesman: any): any => {
            allSalesman.push({
              value: salesman.id,
              label: salesman.salesname,
            });
          });
        }
      }
      setOptionsSalesman(allSalesman);
      setIsLoadingSalesman(false);
    })();
  }, [userType, valueSearchSalesman]);

  const handleChangeInsurance = useCallback((e: InputEvent): void => {
    setInsuranceClient((e as any).currentTarget.value === '1');
  }, []);

  useEffect(() => {
    if (freightTableId > 0) {
      setButtonText('Alterar');
    } else {
      setButtonText('Cadastrar');
    }
  }, [freightTableId]);

  const handleChangeAddDocs = useCallback(
    async (e: any): Promise<any> => {
      if (e.target.files.length > 0) {
        showLoading();
        const promises = [];
        const fileNames = Array.from(e.target.files).map(
          ({ name }: any) => name,
        );
        for (let i = 0; i < fileNames.length; i++) {
          promises.push(
            addNewFile(freightTableId, e.target.files[i], fileNames[i]),
          );
        }
        await Promise.all(promises);
        const freightTableFiles = await fetchFilesByFreightTable({
          freight_table_id: freightTableId,
        });
        setFilesFreightTable(freightTableFiles);
        hideLoading();
      }
    },
    [freightTableId, hideLoading, showLoading],
  );

  const { params } = useRouteMatch<FreightTableParams>();

  useEffect(() => {
    dispatch(setFreightTableTariffId(0));
    if (params.id || freightTableId) {
      (async (): Promise<void> => {
        showLoading();
        if (freightTableId === 0 && params.id) {
          dispatch(setFreightTableId(Number(params.id)));
        }
        const response = await getFreightTable(
          freightTableId || Number(params.id),
        );
        if (!response.data) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Tabela de frete não encontrada',
          });
          history.push('/');
        }
        const { data: freightTable } = response;
        setInsuranceClient((freightTable as any).client_insurance);
        if (freightTable.salesman_id !== null) {
          (formRef.current as any).setFieldValue('salesman_id', {
            value: (freightTable as any).salesman_id,
            label: (freightTable as any).salesman.salesname,
          });
        }
        (formRef.current as any).setFieldValue(
          'is_region',
          (freightTable as any).is_region === true ? 'Região' : 'Linha',
        );
        (formRef.current as any).setFieldValue('icms_type', {
          value: (freightTable as any).icms_type,
          label: optionsIcms.filter(
            (p: { value: any }) => p.value === (freightTable as any).icms_type,
          )[0].label,
        });
        (formRef.current as any).setFieldValue(
          'description',
          (freightTable as any).description,
        );
        (formRef.current as any).setFieldValue(
          'comments',
          (freightTable as any).comments,
        );
        (formRef.current as any).setFieldValue(
          'client_insurance',
          (freightTable as any).client_insurance === true ? '1' : '0',
        );
        (formRef.current as any).setFieldValue(
          'insurance_ddr',
          (freightTable as any).insurance_ddr,
        );
        const freightTableFiles = await fetchFilesByFreightTable({
          freight_table_id: freightTableId || Number(params.id),
        });
        setStatus((freightTable as any).status);
        setFilesFreightTable(freightTableFiles);
        const clientsFreight = await getClientsFreightTable(
          freightTableId || Number(params.id),
        );
        setClientsFreightTable(clientsFreight);
        hideLoading();
      })();
    } else {
      dispatch(setFreightTableId(0));
    }
  }, [freightTableId]);

  const getTariffs = useCallback(
    async (page = 1) => {
      showLoading();
      const getItens = await getFreightTableTariffByFreightTable({
        page,
        freight_table_id: freightTableId,
        ...optionsSearch,
      });
      const arrayPages = [];
      for (let i = 0; i < getItens.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));
      const tariffData: Array<object> = [];
      getItens.data.data.forEach((item: any) => {
        tariffData.push([
          item.freight_table.is_region === true
            ? item.region_origin?.name
            : item.origin?.name,
          item.freight_table.is_region === true
            ? item.region_destination?.name
            : item.destination?.name,
          formatValueToCurrencyInput(item.advalorem as number),
          formatValueToCurrencyInput(item.gris as number),
          formatValueToCurrencyInput(item.load_value as number),
          formatValueToCurrencyInput(item.discharge_value as number),
          formatValueToCurrencyInput(item.collect_value as number),
          formatValueToCurrencyInput(item.delivery_value as number),
          formatValueToCurrencyInput(item.dispatch_value as number),
          item.tollType?.name,
          formatValueToCurrencyInput(item.toll_value as number),
          formatValueToCurrencyInput(item.min_value as number),
          <div className="options-table-list">
            <Link to={`/tabela-de-frete/${freightTableId}/tarifas/${item.id}`}>
              <FaEdit size={20} color="#ffffff" />
            </Link>
          </div>,
        ]);
      });
      setTariffTable(tariffData);
      hideLoading();
    },
    [showLoading, freightTableId, hideLoading, optionsSearch],
  );

  useEffect(() => {}, [insuranceClient]);

  useEffect(() => {}, [optionsSalesman]);

  useEffect(() => {}, [filesFreightTable]);
  useEffect(() => {}, [clientsFreightTable]);
  useEffect(() => {
    if (freightTableId > 0) {
      getTariffs(1);
    }
  }, [freightTableId, getTariffs, optionsSearch]);

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchClientsByDocumentNumber({
                document_number: valueSearchClient,
                ...isSalesman,
              })
            : await fetchClientsByDocumentNumber({
                'clients.company_name': valueSearchClient,
                ...isSalesman,
              });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.company_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchUserClientsByDocumentNumber({
                'client.document_number': valueSearchClient,
                user_id: userId,
              })
            : await fetchClientsByDocumentNumber({
                'client.company_name': valueSearchClient,
                user_id: userId,
              });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [userId, userType, userTypeId, valueSearchClient]);

  const handleAddFreightTableCliente = useCallback(
    async (data: any) => {
      try {
        const schemaValidation = Yup.object().shape({
          client_id: Yup.string().required('Selecione o Cliente'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        const dataPost = {
          ft_id: freightTableId as number,
          client_id: data.client_id as number,
        };
        showLoading();
        const response = await addClientFreightTable(dataPost);

        if (response.status === 200) {
          addToast({
            title: 'Sucesso',
            description: 'Cliente adicionado com sucesso',
            type: 'success',
          });
        } else {
          addToast({
            title: 'Erro',
            description: 'Não foi possível adicionar o cliente',
            type: 'error',
          });
        }

        const clientsFreight = await getClientsFreightTable(freightTableId);
        setClientsFreightTable(clientsFreight);

        hideLoading();
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (modalFormRef.current as any).setErrors(errors);
        }
      }
    },
    [addToast, hideLoading, freightTableId, showLoading],
  );

  const handleDeleteUserClient = async (id: any): Promise<any> => {
    showLoading();
    const response = await removeClientFreightTable(id);
    if (response.status === 200) {
      addToast({
        title: 'Cliente removido com sucesso!',
        type: 'success',
      });
    } else {
      addToast({
        title: 'Erro ao remover o cliente',
        type: 'error',
      });
    }
    const clientsFreight = await getClientsFreightTable(freightTableId);
    setClientsFreightTable(clientsFreight);

    hideLoading();
  };

  interface Obj {
    [key: string]: string;
  }

  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      const searchData: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          searchData[key.replace('-', '.')] = value;
        }
      }

      setOptionsSearch(searchData);
    },
    [setOptionsSearch],
  );

  const handleAprove = useCallback(async (): Promise<void> => {
    const aproved = await aproveFreightTable(freightTableId);
    if (aproved.data) {
      setStatus(status === 'IG' ? 'AG' : 'UG');
    }
  }, [freightTableId]);

  return (
    <PageContainer>
      <MainTitle>
        <FaTruck size={25} /> Tabela de fretes
      </MainTitle>
      <Row>
        <ContainerSize size="20%">
          <IdContainer>Id Tabela: {freightTableId || ''}</IdContainer>
        </ContainerSize>
        <ContainerSize size="70%" />
        <ContainerSize size="10%">
          <BackButton onClick={() => history.goBack()}>
            {' '}
            <FiArrowLeft size={30} />
          </BackButton>
        </ContainerSize>
      </Row>
      <Form
        ref={formRef}
        initialData={{}}
        onSubmit={handleSubmit}
        noValidate
        autoComplete="new-password"
      >
        {params.id || freightTableId ? (
          <Row>
            <ContainerSize size="50%">
              <Input
                name="is_region"
                type="text"
                placeholder="Tipo de Tabela"
                readOnly
              />
            </ContainerSize>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          {params.id || freightTableId ? (
            <></>
          ) : (
            <ContainerSize size="100%">
              <ContainerRadio>
                <Radio
                  name="is_region"
                  options={[
                    { id: '1', label: 'Região' },
                    { id: '0', label: 'Linha' },
                  ]}
                />
              </ContainerRadio>
            </ContainerSize>
          )}
          {userType === 'admin' && (
            <ContainerSize size="50%">
              <Autocomplete
                name="salesman_id"
                options={optionsSalesman}
                placeholder="Vendedor"
                changeValue={null}
                onInputChange={handleSalesmanInputChange}
                isLoading={isLoadingSalesman}
              />
            </ContainerSize>
          )}
          <ContainerSize size="50%">
            <Autocomplete
              name="icms_type"
              options={optionsIcms}
              placeholder="Tipo ICMS"
              changeValue={null}
            />
          </ContainerSize>
        </Row>

        <Row>
          <ContainerSize size="50%">
            <Input name="description" type="text" placeholder="Descrição" />
          </ContainerSize>
          <ContainerSize size="50%">
            <Input name="comments" type="text" placeholder="Observação" />
          </ContainerSize>
        </Row>
        <Row>
          <ContainerSize size="50%">
            <ContainerRadio>
              <span>Seguro por conta do cliente?</span>
              <Radio
                name="client_insurance"
                options={[
                  { id: '1', label: 'Sim' },
                  { id: '0', label: 'Não' },
                ]}
                changeType={handleChangeInsurance}
              />
            </ContainerRadio>
          </ContainerSize>
          {insuranceClient === true && (
            <ContainerSize size="50%">
              <Input
                name="insurance_ddr"
                type="text"
                placeholder="DDR Seguro"
              />
            </ContainerSize>
          )}
        </Row>
        {freightTableId > 0 && (
          <>
            <Row>
              <ContainerSize size="30%" />
              <ContainerSize size="40%">
                <ButtonAddFile type="button" onClick={openModalClients}>
                  <span>Clientes</span>
                  <FiUsers size={20} />
                </ButtonAddFile>
              </ContainerSize>
            </Row>
            <Row>
              <ContainerSize size="30%" />
              <ContainerSize size="40%">
                <ButtonAddFile type="button" onClick={openModal}>
                  <span>Arquivos</span>
                  <VscFileSymlinkFile size={20} />
                </ButtonAddFile>
              </ContainerSize>
            </Row>
          </>
        )}
        <Row>
          <ContainerSize size="30%" />
          {status === 'I' || status === 'A' || status === 'U' ? (
            <ContainerSize size="40%">
              <ButtonForm type="submit">
                <RiFileEditFill size={20} />
                {buttonText}
              </ButtonForm>
            </ContainerSize>
          ) : (
            <></>
          )}
        </Row>
        {(status === 'IG' || status === 'IUG') && userType === 'admin' ? (
          <Row>
            <ContainerSize size="30%" />
            <ContainerSize size="40%">
              <ButtonForm onClick={handleAprove}>
                <FiCheck size={20} />
                Aprovar
              </ButtonForm>
            </ContainerSize>
          </Row>
        ) : (
          <></>
        )}
      </Form>
      <Modal
        isOpen={showModal}
        id="modal-files"
        onClose={() => setShowModal(false)}
        title="Arquivos"
      >
        <div>
          <Form initialData={{}} ref={modalFormRef} onSubmit={() => {}}>
            <UploadArea multiple name="anexos" onChange={handleChangeAddDocs} />
          </Form>
          <ListFiles>
            {filesFreightTable.map(file => (
              <div key={file.id}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  download={file.file_name}
                  href={file.file_link}
                >
                  {file.file_name}
                </a>
                <button
                  type="button"
                  onClick={() => {
                    removeDocument(file.id);
                  }}
                >
                  <FaTrash size={20} />
                </button>
              </div>
            ))}
          </ListFiles>
        </div>
      </Modal>
      {freightTableId > 0 && (
        <>
          <hr className="hr" />
          <ContainerTitle>
            <h3>Lista de tarifas:</h3>
            <Link to={`/tabela-de-frete/${freightTableId}/tarifas`}>
              Cadastrar
            </Link>
          </ContainerTitle>
          <ContainerSize size="100%">
            {!tariffTable ? (
              <h4 className="text-center">
                Nenhuma tarifa cadastrada nessa tabela de frete
              </h4>
            ) : (
              <>
                <TableList
                  header={[
                    'Origem',
                    'Destino',
                    '% Advalorem',
                    '% GRIS',
                    'Carga',
                    'Descarga',
                    'Coleta',
                    'Entrega',
                    'Despacho',
                    'Tipo de pedágio',
                    'Valor do pedágio',
                    'Frete Mínimo',
                    'Visualizar',
                  ]}
                  data={tariffTable}
                />

                <Pagination
                  pages={pages}
                  currentPage={currentPage}
                  onClickFunction={getTariffs}
                />
              </>
            )}
          </ContainerSize>

          <Modal
            isOpen={showModalClients}
            id="modal-clients"
            title="Vincular clientes"
            backgroundColor="#F8F8FB"
            fontColor="#3a3a3a"
            onClose={() => setShowModalClients(false)}
          >
            <div>
              <Form
                ref={modalClientsFormRef}
                initialData={{}}
                onSubmit={handleAddFreightTableCliente}
              >
                <Row style={{ alignItems: 'center' }}>
                  <ContainerSize size="calc(100% - 46px)">
                    <Autocomplete
                      name="client_id"
                      options={optionsClients}
                      placeholder="Cliente"
                      changeValue={null}
                      onInputChange={handleClientInputChange}
                      isLoading={isLoadingClients}
                    />
                  </ContainerSize>
                  <ButtonAddClient type="submit">
                    <BiUserPlus size={20} />
                  </ButtonAddClient>
                </Row>
              </Form>
              <ListClients>
                {clientsFreightTable ? (
                  clientsFreightTable.map((item: any) => (
                    <div key={item.id}>
                      <Link
                        to={`/cliente/${item.id}`}
                        title="Visualizar cliente"
                        target="_blank"
                      >
                        {item.client.company_name || item.client.name} -{' '}
                        {item.client.document_number}
                      </Link>
                      <button
                        type="button"
                        onClick={() => {
                          createDialog({
                            text: 'Deseja remover esse cliente?',
                            textButtonCancel: 'Não',
                            textButtonConfirm: 'Sim',
                            icon: FaQuestion,
                            onConfirm: () => handleDeleteUserClient(item.id),
                          });
                        }}
                      >
                        <FaTrash size={20} />
                      </button>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </ListClients>
            </div>
          </Modal>
        </>
      )}

      <Filter onSubmit={handleSubmitForm}>
        <Input type="text" name="origin-name" placeholder="Cidade Origem" />
        <Input
          type="text"
          name="destination-name"
          placeholder="Cidade Destino"
        />
        <Input
          type="text"
          name="region_origin-name"
          placeholder="Região Origem"
        />
        <Input
          type="text"
          name="region_destination-name"
          placeholder="Região Destino"
        />
      </Filter>
    </PageContainer>
  );
};

export default FreightTable;
