import { createSlice } from '@reduxjs/toolkit';
import { fetch } from '../../entities/clientFiles';
import { storage } from '../../app/authConfig';

import axios from '../../app/axios';

const initialState = {
  clientFilesId: 0,
};

export const gatheringOrderFilesSlice = createSlice({
  name: 'gatheringOrderFiles',
  initialState,
  reducers: {
    setClientFilesId: (state, { payload: clientFilesId }) => {
      state.clientFilesId = !clientFilesId
        ? initialState.clientFilesId
        : clientFilesId;
    },
  },
});

const { setClientFilesId } = gatheringOrderFilesSlice.actions;

export const setFileCreatedId = (id: number) => (dispatch: any): any => {
  dispatch(setClientFilesId(id));
};

export const fetchFiles = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchFilesByOrder = async (params: any): Promise<any> => {
  const { data: files } = await axios.get('/local/collection_order_file', {
    params,
  });
  return files.data;
};

export const addNewFile = async (
  client: number,
  doc: Blob | Uint8Array | ArrayBuffer,
  fileName: string,
): Promise<void> => {
  return new Promise(resolve => {
    const path =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    storage
      .ref(`collection_order/files/${path}/${fileName}`)
      .put(doc)
      .then(() => {
        storage
          .ref(`collection_order/files/${path}/${fileName}`)
          .getDownloadURL()
          .then(file_link =>
            axios
              .post(`/local/collection_order_file`, {
                collection_order_id: client,
                file_link,
                file_name: fileName,
              })
              .then(() => {
                resolve();
              }),
          )
          .catch(() => {
            storage.ref(`collection_order/files/${path}/${fileName}`).delete();
            resolve();
          });
      })
      .catch(() => {
        resolve();
      });
  });
};

export const removeFile = async (
  collection_order_id: number,
  file_id: number,
): Promise<any> => {
  const { data: file } = await axios.delete(
    `/local/collection_order_file/${file_id}`,
  );
  if (file.client_id === collection_order_id) {
    axios.delete(`/local/collection_order_file/${file_id}`).then(() => {
      storage.ref(file.path).delete();
      return true;
    });
  }
  return false;
};
export default gatheringOrderFilesSlice.reducer;
