import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ComponentProps {
  size: string;
}

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: 600px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const ButtonAddClient = styled.button`
  background-color: #003ca6;
  color: #ffffff;
  border-radius: 50%;
  margin-top: 25px;
  border: 0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;

  &:hover {
    background-color: ${shade('0.2', '#afd2ff')};
    color: ${shade('0.2', '#003CA6')};
  }
`;

export const ListClients = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    margin-top: 15px;
    a {
      flex: 1;
      background-color: #ffffff;
      border-radius: 25px;
      color: #003ca6;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    button {
      background: transparent;
      border-radius: 50%;
      margin-left: 5px;
      border: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #003ca6;
      transition: all 0.3s linear;

      &:hover {
        background-color: #afd2ff;
      }
    }
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ListFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    margin-top: 15px;
    a {
      flex: 1;
      background-color: #afd2ff;
      border-radius: 25px;
      color: #003ca6;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    button {
      background: transparent;
      border-radius: 50%;
      margin-left: 5px;
      border: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: all 0.3s linear;

      &:hover {
        background-color: #afd2ff;
        color: #003ca6;
      }
    }
  }
`;

export const ButtonAddFile = styled.button`
  margin-top: 35px;
  background: transparent;
  color: #003ca6;
  border: 1px solid #003ca6;
  border-radius: 29px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 40px;
  font-size: 18px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #003ca6;
    color: #ffffff;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: calc(100% - 20px);
  margin-top: 20px;

  div {
    flex: 1;
    padding: 0;
    display: flex;
    justify-content: flex-start;

    > div {
      color: #666360;
      font-weight: 400;
      font-size: 17px;
      margin-left: 10px;
    }

    @media (max-width: 768px) {
      flex: auto;
      width: 100%;

      > div {
        margin-top: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`;

export const ContainerTitle = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    background-color: #003ca6;
    color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    border-radius: 25px;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }
  }
`;

export const IdContainer = styled.div`
  background: #003ca6;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-top: 25px;
  border: 1px solid transparent;
  position: relative;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.07);
  padding: 12px;

  color: #fff;
  display: inline-block;

  font-weight: 700;

  @media (max-width: 768px) {
    width: 100%;
    height: 46px;
    padding: 12px;
  }
`;

export const BackButton = styled.button`
  border: 1px solid transparent;
  background-color: #003ca6;
  border-radius: 25px;
  width: 100%;
  height: 40px;
  padding: 4px 20px;
  margin: 0 2px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-self: flex-end;

  font-size: 24px;
  font-weight: 500;
  color: #fff;

  cursor: pointer;
  &:hover {
    background: ${shade(0.2, '#003CA6')};
    color: #fff;

    .save-icon {
      filter: invert(1);
    }
  }

  .save-icon {
    width: 24px;
    height: 24px;
    vertical-align: text-bottom;
    filter: invert(1);
    margin: 2px 5px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 46px;
    padding: 12px;
  }
`;
