import { createSlice } from '@reduxjs/toolkit';
import { fetch } from '../../entities/companyType';
import axios from '../../app/axios';

const initialState = {
  companyTypeId: 0,
};

export const companyTypeSlice = createSlice({
  name: 'companyType',
  initialState,
  reducers: {
    setcompanyTypeId: (state, { payload: companyTypeId }) => {
      state.companyTypeId = !companyTypeId
        ? initialState.companyTypeId
        : companyTypeId;
    },
  },
});

export const fetchCompanyType = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchCompanyTypeByName = async (params: any): Promise<any> => {
  const { data: companyTypes } = await axios.get('/local/company_type', {
    params,
  });
  return companyTypes.data;
};

export default companyTypeSlice.reducer;
