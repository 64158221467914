import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from '../../entities/quotationItens';
import axios from '../../app/axios';

export interface ItensData {
  id: string | number;
  nf_number: string;
  serial: string;
  product_name: string;
  comments: string;
  height: number;
  length: number;
  quantity: number;
  quotation_id: number;
  value: number;
  weight: number;
  weight_calculated: number;
  width: number;
}

interface InitialStateData {
  bpId: number;
  itensQuotation: ItensData[];
  itensQuantity: number;
}

const initialState: InitialStateData = {
  bpId: 0,
  itensQuotation: [],
  itensQuantity: 0,
};

export const quotationItemsSlice = createSlice({
  name: 'quotationItem',
  initialState,
  reducers: {
    setQuotationId: (state, { payload: bpId }) => {
      state.bpId = !bpId ? initialState.bpId : bpId;
    },
    setItens: (state, { payload: itensQuotation }) => {
      state.itensQuotation = !itensQuotation
        ? initialState.itensQuotation
        : itensQuotation;
    },
    setItensXml: (state, { payload: itensQuotation }) => {
      state.itensQuotation = !itensQuotation
        ? initialState.itensQuotation
        : itensQuotation;
    },
    setItensQuantity: (state, { payload: itensQuantity }) => {
      state.itensQuantity = !itensQuantity
        ? initialState.itensQuantity
        : itensQuantity;
    },
  },
});
export const {
  setQuotationId,
  setItens,
  setItensXml,
  setItensQuantity,
} = quotationItemsSlice.actions;

interface AsyncData {
  id?: number;
  data: any;
}
/* interface ResponseBusinessPeople {
  data: BusinessPersonResponseData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
} */

export const getQuotationItemById = async (params: any): Promise<any> => {
  const businessPerson = await axios.get(`/local/quotation_itens`, {
    params,
  });
  return businessPerson;
};

export const getQuotationItemByIdParam = async (id: number): Promise<any> => {
  const businessPerson = await axios.get(`/local/quotation_itens/${id}`);
  return businessPerson;
};

export const addQuotationItem = createAsyncThunk<any, AsyncData>(
  'quotationItems/addQuotationItem',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/local/quotation_itens', info.data);
      dispatch(merge(response.data));
      dispatch(setQuotationId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateQuotationItem = createAsyncThunk<any, AsyncData>(
  'quotationItem/updateQuotationItem',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/local/quotation_itens/${info.id}`,
        info.data,
      );
      dispatch(merge(response.data));
      dispatch(setQuotationId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const deleteQuotationItem = createAsyncThunk<any, AsyncData>(
  'quotationItem/deleteQuotationItem',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/local/quotation_itens/${info.id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export default quotationItemsSlice.reducer;
