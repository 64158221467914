import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 1073;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
  max-width: 100%;
  font-size: 18px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
