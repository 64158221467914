/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { FaQuestionCircle } from 'react-icons/fa';
import { Bar } from './styles';

import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';

import QuotationEntry from './Entry';
import { quotationSlice } from '../../features/quotations';
import {
  generateCollectOrder,
  gatheringOrderSlice,
} from '../../features/gatheringOrder';
import { useDialog } from '../../hooks/dialog';
import { AppDispatch } from '../../app/store';
import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';

interface UserParams {
  id: string | undefined;
}

const GatheringOrder: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { createDialog } = useDialog();
  const [updateQuotation, setUpdateQuotation] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  const { params } = useRouteMatch<UserParams>();
  const { addToast } = useToast();
  const { setQuotationId, setGeneratedCollect } = quotationSlice.actions;
  const { setStatusGatheringOrder } = gatheringOrderSlice.actions;

  const { quotationId, statusQuotation, generatedCollect } = useSelector(
    (state: RootStateOrAny) => ({
      quotationId: state.features.quotation.quotationId,
      statusQuotation: state.features.quotation.statusQuotation,
      generatedCollect: state.features.quotation.generatedCollect,
    }),
  );

  const handleChangeUpdateQuotation = (status: boolean): void => {
    setUpdateQuotation(status);
  };

  const confirm = useCallback(async () => {
    showLoading();
    const Response = await dispatch(
      generateCollectOrder({ id: Number(quotationId), data: null }),
    );
    if (generateCollectOrder.fulfilled.match(Response)) {
      addToast({
        title: 'Ordem de Coletada gerada com sucesso',
        type: 'success',
      });
      dispatch(setGeneratedCollect(true));
      createDialog({
        text: 'Deseja visualizar a coleta gerada?',
        textButtonCancel: 'Não',
        textButtonConfirm: 'Sim',
        icon: FaQuestionCircle,
        onConfirm: () => {
          dispatch(setStatusGatheringOrder('I'));
          history.push(`/ordem-de-coleta/${Response.payload.id}`);
        },
      });
    } else {
      addToast({ title: 'Erro ao gerar Coleta', type: 'error' });
    }
    hideLoading();
  }, [addToast, dispatch, setGeneratedCollect, quotationId]);

  const handleGenerateCollectOrder = (): void => {
    createDialog({
      text: 'Deseja gerar uma coleta a partir da cotação?',
      textButtonCancel: 'Não',
      textButtonConfirm: 'Sim',
      icon: FaQuestionCircle,
      onConfirm: confirm,
    });
  };

  useEffect(() => {
    if (params.id) {
      dispatch(setQuotationId(params.id));
    }
  }, []);

  useEffect(() => {}, [updateQuotation]);

  return (
    <PageContainer>
      <MainTitle>Cotação</MainTitle>
      <Bar>
        <button type="button" onClick={() => history.goBack()}>
          <FiArrowLeft size={30} />
        </button>
        {statusQuotation === 'A' && generatedCollect === false && quotationId && (
          <button type="button" onClick={handleGenerateCollectOrder}>
            Gerar Coleta
          </button>
        )}
      </Bar>
      <QuotationEntry
        updateQuotationFlag={updateQuotation}
        handleUpdateQuotation={handleChangeUpdateQuotation}
        quotationId={quotationId}
        statusQuotation={statusQuotation}
      />
    </PageContainer>
  );
};

export default GatheringOrder;
