import styled from 'styled-components';

export const Fileuploadarea = styled.div`
  width: 100%;
  height: 100px;
  border: 1px dashed #fff;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s linear;

  &:hover {
    border-style: solid;
  }

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 5;
  }
`;
