/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import {
  Container,
  ContainerInput,
  ContainerInputFile,
  ContainerError,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  onBlurParent?: any;
}

const InputTracking: React.FC<InputProps> = ({
  name,
  onBlurParent,
  placeholder,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const [filesLenght, setFilesLenght] = useState(0);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    if (onBlurParent) {
      onBlurParent();
    }
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
    if (type === 'date') {
      setIsFocused(true);
    }
  }, [type]);

  const handleInputChange = useCallback(() => {
    setIsFilled(!!inputRef.current?.value);
    if (type === 'date') {
      setIsFocused(true);
    }
  }, [type]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref: any, value: any) => {
        setIsFilled(!!value);
        ref.value = value;
      },
    });

    if (type === 'file') {
      setIsFile(true);
      (inputRef.current as any).addEventListener('change', () => {
        if (typeof inputRef.current?.files?.length !== 'undefined') {
          setFilesLenght(inputRef.current.files.length);
        } else {
          setFilesLenght(0);
        }
      });
    }

    if (type === 'date') {
      setIsFocused(true);
    }
  }, [fieldName, registerField, type]);

  useEffect(() => {
    setIsFilled(!!inputRef.current?.value);
  }, [inputRef.current?.value]);

  useEffect(() => {}, [isFilled]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Container
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isFile={isFile}
      >
        <ContainerInput>
          <input
            id={fieldName}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            placeholder={placeholder}
            defaultValue={defaultValue}
            ref={inputRef}
            type={type}
            autoComplete="off"
            {...rest}
          />
          {isFile && filesLenght > 0 && (
            <ContainerInputFile>
              {filesLenght} arquivo
              {filesLenght > 1 ? `s selecionados` : ` selecionado`}
            </ContainerInputFile>
          )}
        </ContainerInput>
      </Container>
      {!!error === true && isFilled === false && (
        <ContainerError>{error}</ContainerError>
      )}
    </div>
  );
};

export default InputTracking;
