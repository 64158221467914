import React from 'react';

import { FiArrowLeft } from 'react-icons/fi';
import styled from 'styled-components';
import { shade } from 'polished';
import { useHistory } from 'react-router-dom';
import BusinessPersonForm from '../../components/BusinessPersonForm';
import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';

const CreateBusinessPerson: React.FC = () => {
  const history = useHistory();
  const BackButton = styled.button`
    border: none;
    background-color: #003CA6;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 50%;

    color: #ffffff;
    transition: background-color 0.2s ease;

    cursor: pointer;

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }
  `;
  return (
    <PageContainer>
      <MainTitle>Remetente / Destinatário</MainTitle>
      <BackButton type="button" onClick={() => history.goBack()}>
        <FiArrowLeft size={30} />
      </BackButton>
      <BusinessPersonForm />
    </PageContainer>
  );
};

export default CreateBusinessPerson;
