/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { uuid } from 'uuidv4';
import { FiTrash2 } from 'react-icons/fi';
import { FaEdit } from 'react-icons/fa';
import { Row, ContainerSize } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import Autocomplete from '../../../components/Autocomplete';
import Input from '../../../components/Input';
import { AppDispatch } from '../../../app/store';
import {
  clientsSlice,
  updateClientEmailThunk,
  addClientEmailThunk,
  getClientEmail,
  deleteClientEmail,
  getClientEmailById,
} from '../../../features/clients';
import { useToast } from '../../../hooks/toast';
import { useLoading } from '../../../hooks/loading';
import TableList from '../../../components/TableList';
import Pagination from '../../../components/Pagination';
import ButtonForm from '../../../components/ButtonForm';

interface EmailProp {
  clientId: number;
}

const RegisterClientEmail: React.FC<EmailProp> = ({ clientId }) => {
  const formRef = useRef<FormHandles>(null);
  const [idEmailRedux, setIdEmailRedux] = useState(0);
  const [idEmailClient, setIdEmailClient] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch: AppDispatch = useDispatch();
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { setEmails } = clientsSlice.actions;
  const { emails } = useSelector((state: RootStateOrAny) => ({
    emails: state.features.client.emails,
  }));
  const optionsType = [
    {
      label: 'Boletos',
      value: 'Boletos',
    },
    {
      label: 'XML',
      value: 'XML',
    },
    {
      label: 'Cotação e Coleta',
      value: 'Cotação e Coleta',
    },
  ];

  const handleSubmit = useCallback(
    async (data: any): Promise<void | false> => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .required('O E-mail é obrigatório')
            .email('E-mail fora do padrão'),
          type: Yup.string().required('Tipo de e-mail obrigatório'),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        const clientEmailData = {
          client_id: clientId,
          email: data.email,
          type: data.type,
        };
        if (clientId) {
          showLoading();
          if (idEmailClient) {
            const Response = await dispatch(
              updateClientEmailThunk({
                id: Number(idEmailClient),
                data: clientEmailData,
              }),
            );
            if (updateClientEmailThunk.fulfilled.match(Response)) {
              addToast({
                title: 'E-mail atualizado com sucesso',
                type: 'success',
              });
              (formRef.current as any).reset();
              setIdEmailRedux(0);
              setIsUpdating(true);
            } else {
              addToast({ title: 'Erro na criação', type: 'error' });
            }
          } else {
            const Response = await dispatch(
              addClientEmailThunk({ data: clientEmailData }),
            );
            if (addClientEmailThunk.fulfilled.match(Response)) {
              addToast({ title: 'E-mail criado com sucesso', type: 'success' });
              (formRef.current as any).reset();
              setIdEmailRedux(0);
            } else {
              addToast({ title: 'Erro na criação', type: 'error' });
            }
          }
          (formRef.current as any).reset();
          setIsUpdating(true);
          hideLoading();
        } else {
          if (idEmailRedux === 0) {
            dispatch(
              setEmails([...emails, { ...clientEmailData, id: uuid() }]),
            );
            addToast({ title: 'E-mail criado com sucesso', type: 'success' });
            (formRef.current as any).reset();
          } else {
            dispatch(
              setEmails([
                ...emails.filter((x: any): any => x.id !== idEmailRedux),
                { ...clientEmailData, id: idEmailRedux },
              ]),
            );
            addToast({
              title: 'E-mail atualizado com sucesso',
              type: 'success',
            });
            (formRef.current as any).reset();
          }
        }
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
      }
    },
    [idEmailRedux, clientId, idEmailClient, setIdEmailClient, emails],
  );

  const handleDeleteEmail = useCallback(
    async (idItem: number | string): Promise<void | false> => {
      if (clientId > 0) {
        showLoading();
        const Response = await dispatch(
          deleteClientEmail({ id: Number(idItem), data: null }),
        );
        if (deleteClientEmail.fulfilled.match(Response)) {
          addToast({
            title: 'E-mail removido com sucesso',
            type: 'success',
          });
          (formRef.current as any).reset();
          setIdEmailRedux(0);
        } else {
          addToast({
            title: 'Erro ao remover item',
            type: 'error',
          });
        }
        setIsUpdating(true);
        hideLoading();
      } else {
        dispatch(
          setEmails(emails.filter((item: any): any => item.id !== idItem)),
        );
        setIsUpdating(true);
      }
    },
    [clientId, isUpdating, emails],
  );

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      if (clientId > 0) {
        const clientEmails = await getClientEmail({
          page,
          client_id: clientId,
        });
        const arrayUsers: Array<Array<any>> = [];
        clientEmails.data.data.forEach((item: any) => {
          arrayUsers.push([
            item.type,
            item.email,
            <div className="options-table-list">
              <button type="button" onClick={() => setIdEmailClient(item.id)}>
                <FaEdit size={20} color="#ffffff" />
              </button>
              <button type="button" onClick={() => handleDeleteEmail(item.id)}>
                <FiTrash2 size={20} color="#ffffff" />
              </button>
            </div>,
          ]);
        });
        setResults(arrayUsers);
        const arrayPages = [];
        for (let i = 0; i < clientEmails.data.total_pages; i++) {
          arrayPages.push(i + 1);
        }
        setPages(arrayPages);
        setCurrentPage(page);
      } else {
        const arrayUsers: Array<Array<any>> = [];
        emails.forEach((item: any) => {
          arrayUsers.push([
            item.type,
            item.email,
            <div className="options-table-list">
              <button type="button" onClick={() => setIdEmailRedux(item.id)}>
                <FaEdit size={20} color="#ffffff" />
              </button>
              <button type="button" onClick={() => handleDeleteEmail(item.id)}>
                <FiTrash2 size={20} color="#ffffff" />
              </button>
            </div>,
          ]);
        });
        setResults(arrayUsers);
      }
      hideLoading();
    },
    [showLoading, clientId, hideLoading, handleDeleteEmail, emails],
  );

  useEffect(() => {
    console.log(emails);
    getData(currentPage);
  }, [emails, clientId]);

  useEffect(() => {
    if (isUpdating) {
      getData(currentPage);
      setIsUpdating(false);
    }
  }, [isUpdating]);

  useEffect(() => {
    console.log(idEmailClient);
    if (idEmailClient) {
      showLoading();
      (async () => {
        const { data: clientEmailSel } = await getClientEmailById(
          Number(idEmailClient),
        );
        (formRef.current as any).setFieldValue('email', clientEmailSel?.email);
        (formRef.current as any).setFieldValue('type', {
          value: clientEmailSel?.type,
          label: clientEmailSel?.type,
        });
        hideLoading();
      })();
    }
  }, [idEmailClient]);

  useEffect(() => {
    if (idEmailRedux) {
      (async () => {
        const clientEmailRedux = emails.find(
          (x: any): any => x.id === idEmailRedux,
        );
        (formRef.current as any).setFieldValue(
          'email',
          clientEmailRedux?.email,
        );
        (formRef.current as any).setFieldValue('type', {
          value: clientEmailRedux?.type,
          label: clientEmailRedux?.type,
        });
      })();
    }
  }, [idEmailRedux]);

  return (
    <>
      <Form
        ref={formRef}
        initialData={{}}
        onSubmit={handleSubmit}
        noValidate
        autoComplete="new-password"
      >
        <Row>
          <ContainerSize size="25%">
            <Autocomplete
              name="type"
              options={optionsType}
              placeholder="Tipo Email *"
              changeValue={null}
            />
          </ContainerSize>
          <ContainerSize size="75%">
            <Input name="email" type="text" placeholder="E-mail *" />
          </ContainerSize>
        </Row>
        <Row>
          <ContainerSize size="30%" />
          <ContainerSize size="40%">
            <ButtonForm type="submit">Salvar</ButtonForm>
          </ContainerSize>
        </Row>
      </Form>
      <hr className="hr" />
      <Row style={{ marginLeft: '0', marginRight: '0', marginTop: '10px' }}>
        <ContainerSize size="100%">
          <TableList header={['Tipo', 'E-mail', '']} data={results} />
        </ContainerSize>
      </Row>
      <Row style={{ marginLeft: '0', marginRight: '0', marginTop: '10px' }}>
        <ContainerSize size="100%">
          <Pagination
            pages={pages}
            currentPage={currentPage}
            onClickFunction={getData}
          />
        </ContainerSize>
      </Row>
    </>
  );
};

export default RegisterClientEmail;
