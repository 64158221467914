import styled from 'styled-components';
import { shade } from 'polished';

export const BackButton = styled.button`
  border: none;
  background-color: #003ca6;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50%;

  color: #ffffff;
  transition: background-color 0.2s ease;

  cursor: pointer;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;
