import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  driverId: 0,
  totalPages: 0,
};

export const transporterDriversSlice = createSlice({
  name: 'transporterDrivers',
  initialState,
  reducers: {
    setDriverId: (state, { payload: driverId }) => {
      state.driverId = !driverId ? initialState.driverId : driverId;
    },
    setTotalPages: (state, { payload: totalPages }) => {
      state.totalPages = !totalPages ? initialState.totalPages : totalPages;
    },
  },
});

export const { setDriverId, setTotalPages } = transporterDriversSlice.actions;

interface TransporterData {
  id: number;
  user_id: number;
  name: string;
}
export interface DriverData {
  id: number;
  transporter_id: number;
  name: string;
  license: string;
  email: string;
  document: string;
  status: 'Ativo' | 'Inativo';
  phone_number: string;
  license_date: Date;
  created_at: Date;
  transporter: TransporterData;
}

interface ResponseDriver {
  data: DriverData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDrivers {
  data: DriverData[];
  per_page: number;
  total_pages: number;
}

export const getDriver = async (id: number): Promise<ResponseDriver> => {
  const driver = await axios.get(`/local/transporters_drivers/${id}`);
  return driver;
};

export const postDriver = async (
  data: Omit<DriverData, 'id' | 'transporter' | 'created_at'>,
): Promise<Omit<ResponseDriver, 'transporter'>> => {
  const response = await axios.post('/local/transporters_drivers', data);
  return response;
};

export const updateDriver = async (
  id: number,
  data: Omit<DriverData, 'id' | 'transporter' | 'created_at'>,
): Promise<Omit<ResponseDriver, 'transporter'>> => {
  const response = await axios.put(`/local/transporters_drivers/${id}`, data);
  return response;
};

export const getDrivers = async (params: any): Promise<ResponseDrivers> => {
  const { data: events } = await axios.get('/local/transporters_drivers', {
    params,
  });
  return events;
};

export const deleteDriver = async (id: number): Promise<any> => {
  const response = await axios.delete(`/local/transporters_drivers/${id}`);
  return response;
};

export default transporterDriversSlice.reducer;
