import styled, { css } from 'styled-components';
import { shade } from 'polished';
import checkIcon from '../../assets/check_icon_btn.svg';

interface ComponentProps {
  size: string;
}

export const CheckButton = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 9px;
  border: none;
  background-color: #003CA6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &[data-checked='true']::after {
    width: 18px;
    height: 22px;
    opacity: 1;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    margin-bottom: 2px;
    background-image: url(${checkIcon});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 5;
    transition: all 0.2s ease;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: 600px) {
    margin-right: 0px;
    margin-left: 0px;
  }

  .disassociate {
    color: #dc143c;
    border: solid 2px #dc143c;

    &:hover {
      background: ${shade(0.2, '#DC143C')};
      border: solid 2px ${shade(0.2, '#DC143C')};
      color: #fff;
    }
  }
`;

export const WarningText = styled.span`
  display: flex;
  justify-content: center;
  color: #c53030;
  font-size: 16px;
`;

export const RowCenter = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const FormButton = styled.button`
  width: 100%;
  padding: 8px 50px;
  background-color: #003CA6;
  color: #ffffff;
  border-radius: 29px;
  border: none;
  margin: 27px 15px 0 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;

  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    margin-right: 5px;
  }

  &[disabled] {
    opacity: 0.7;
    cursor: unset;
  }

  &:not([disabled]):hover {
    background: ${shade(0.2, '#003CA6')};
    color: #fff;
    img {
      filter: invert(1);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

export const ModalContainer = styled.div`
  padding: 0 15px;

  display: flex;

  position: relative;
  left: 23%;
  right: 23%;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    left: 19%;
    right: 23%;
  }

  @media (max-width: 670px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    left: 9%;
    right: 23%;
  }
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  position: absolute;
  left: 90%;
  bottom: 43px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    left: 22%;
  }

  @media (max-width: 670px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    left: 30%;
    bottom: 36px;
  }
`;

export const ModalButton = styled.button`
  height: 44px;
  width: 100%;
  padding: 8px 20px;
  background-color: transparent;
  color: #3066ad;
  border-radius: 10px;
  border: solid 2px #3066ad;
  font-size: 12px;
  margin: 10px 5px;

  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#3066AD')};
    border: solid 2px ${shade(0.2, '#3066AD')};
    color: #fff;
    background-color: ${shade(0.2, '#003CA6')};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin: 20px 0;
  }

  @media (max-width: 670px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin: 20px 0;
  }
`;

export const TableListOptions = styled.div`
  display: flex;
  justify-content: center;
`;

export const TableListOptionsButton = styled.button`
  background-color: #b6d2fb;
  img {
    width: 30px;
    height: 30px;
    padding: 5px;
  }

  &:hover {
    background: ${shade(0.2, '#003CA6')};
    border-radius: 30px;
  }
`;

export const ContainerAddModal = styled.div``;

export const ModalButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;

  position: relative;
  left: 52%;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;

    position: relative;
    left: 0%;
  }
`;

export const ChangeStatusButton = styled.button`
  height: 34px;
  width: 150px;
  background-color: transparent;
  color: #3066ad;
  border-radius: 29px;
  border: 1px solid #3066ad;
  margin: 15px 3px;

  position: relative;

  font-size: 16px;

  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#3066AD')};
    color: #fff;
  }

  &:disabled {
    background: ${shade(0.2, '#3066AD')};
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

export const ModalFormButton = styled.button`
  height: 34px;
  width: 150px;
  padding: 8px 20px;
  background-color: #90dcec;
  border-radius: 29px;
  border: none;
  margin: 15px 3px;

  position: relative;

  font-size: 16px;

  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#3066AD')};
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

export const AssociateModalFormButton = styled.button`
  height: 34px;
  width: 100%;
  padding: 8px 20px;
  background-color: #3066ad;
  color: #fff;
  border-radius: 29px;
  border: none;
  margin: 30px 3px 10px 3px;

  position: relative;

  font-size: 16px;

  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#3066AD')};
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

export const ModalListItem = styled.a`
  width: 100% !important;
  height: 40px !important;
  background-color: transparent !important;
  border-radius: 0 !important;

  color: #232323;

  &:hover {
    color: ${shade(0.2, '#232323')};
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;

  button {
    margin-left: 15px;
    border: none;
    padding: 10px 15px;
    border-radius: 29px;
    background-color: #003CA6;
    color: #ffffff;
    display: flex;
    align-items: center;
    width: auto;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }

    svg {
      margin-right: 5px;
    }
  }
`;

export const ModalContainerTitle = styled.h3`
  font-weight: 700;
  text-align: center;

  margin-top: 20px;
`;
