import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTruckLoading, FaTruck } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiDollar } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { IoMdDocument } from 'react-icons/io';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { logout } from '../../features/auth';
import AvatarImg from '../../assets/avatar.png';
import LogoImg from '../../assets/transreal_logo.svg';
import DashboardIcon from '../../assets/dashboard_icon.svg';
import UsersIcon from '../../assets/login_icon.svg';
import LogoutIcon from '../../assets/logout_icone.png';
import ModalInfo from '../ModalInformation';
import ModalLgpd from '../ModalLgpd';

import { MenuContainer, Items, Tooltip } from './styles';

const Menu: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    photoURL,
    userType,
    menuType,
    clientsUser,
    isLoading,
    lgpd,
    userId,
  } = useSelector((state: RootStateOrAny) => ({
    photoURL: state.features.auth.photoUrl,
    userType: state.features.auth.type,
    menuType: state.features.auth.menuType,
    clientsUser: state.features.auth.clientsUser,
    isLoading: state.features.auth.isLoading,
    lgpd: state.features.auth.lgpd,
    userId: state.features.auth.userId,
  }));

  const handleMobileMenu = useCallback(() => {
    setIsOpened(true);
  }, []);

  const handleClickOutside = (): void => {
    setIsOpened(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (userType === 'client' && clientsUser.length === 0 && !isLoading) {
      setShowInfo(true);
    }
  }, [photoURL, userType, clientsUser, isLoading]);

  function handleLogout(): any {
    dispatch(logout());
    history.push('login');
  }

  return (
    <>
      <ModalLgpd
        isOpen={!isLoading && lgpd === false}
        id="lgpd-info"
        userId={userId}
        onClose={() => {}}
      />
      <ModalInfo isOpen={showInfo && lgpd} id="user-info" onClose={() => {}} />
      <MenuContainer>
        <button type="button" onClick={handleMobileMenu}>
          <GiHamburgerMenu size={30} color="#fff" />
        </button>
        <img src={LogoImg} alt="Logo" />
        <hr />
        <Link to="/" className="profile-menu">
          <img src={photoURL || AvatarImg} alt="Vendedor" />
          {userType !== 'transporter' && <span>{menuType}</span>}
          {userType === 'transporter' && (
            <span style={{ fontSize: '13px' }}>{menuType}</span>
          )}
        </Link>
        <Items isOpened={isOpened} className="nav">
          {userType !== 'transporter' && (
            <Link to="/">
              <img src={DashboardIcon} width="30px" alt="" />
              <Tooltip>Dashboard</Tooltip>
            </Link>
          )}
          {userType === 'salesman' && (
            <>
              <Link to="/clientes">
                <img src={UsersIcon} width="30px" alt="" />
                <Tooltip>Consultar clientes</Tooltip>
              </Link>
              <Link to="/tabelas-de-frete">
                <FaTruck size={40} />
                <Tooltip>Consultar Tabelas de Frete</Tooltip>
              </Link>
            </>
          )}
          {userType === 'client' && (
            <>
              <Link to="/criar-cotacoes">
                <BiDollar size={40} />
                <Tooltip>Cotação</Tooltip>
              </Link>
              <Link to="/ordem-de-coleta">
                <FaTruckLoading size={40} />
                <Tooltip>Coleta</Tooltip>
              </Link>
            </>
          )}
          {userType === 'admin' && (
            <Link to="/usuarios">
              <img src={UsersIcon} width="30px" alt="" />
              <Tooltip>Consultar usuários</Tooltip>
            </Link>
          )}
          {userType === 'transporter' && (
            <>
              <Link to="/lancar-ocorrencias">
                <IoMdDocument size={40} />
                <Tooltip>Visualizar Documentos</Tooltip>
              </Link>
              <Link to="/motoristas">
                <AiOutlineUsergroupAdd size={40} />
                <Tooltip>Motoristas</Tooltip>
              </Link>
              <Link to="/veiculos">
                <FaTruck size={40} />
                <Tooltip>Veículos</Tooltip>
              </Link>
            </>
          )}
          <button
            type="button"
            onClick={handleLogout}
            className="logout-mobile"
          >
            <img src={LogoutIcon} width="30px" alt="" />
            <Tooltip>Sair</Tooltip>
          </button>
        </Items>

        <button type="button" onClick={handleLogout} className="logout-desktop">
          <img src={LogoutIcon} width="30px" alt="" />
        </button>
      </MenuContainer>
    </>
  );
};

export default Menu;
