/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { login, Authslice } from '../../features/auth/index';
import LogoTransreal from '../../assets/transreal_logo.svg';
import LogoTransrealAzul from '../../assets/transreal_logo_azul.svg';
import { Background, Container, WellcomeContainer, Main } from './styles';
import InputIcon from '../../components/InputIcon';
import Button from '../../components/Button';

interface FormInterface {
  [key: string]: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setIsLoadingAuth } = Authslice.actions;
  const { loginError, isLoadingAuth } = useSelector(
    (state: RootStateOrAny) => ({
      loginError: state.features.auth.loginError,
      isLoadingAuth: state.features.auth.isLoading,
    }),
  );

  const handleSubmit = useCallback(
    async (data: FormInterface) => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          password: Yup.string().min(4, 'Mínimo de 4 dígitos'),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        dispatch(setIsLoadingAuth(true));
        setIsLoading(true);
        dispatch(login(data.email, data.password));
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
      }
    },
    [dispatch],
  );

  useEffect(() => {}, [loginError]);

  useEffect(() => {
    setIsLoading(isLoadingAuth);
  }, [isLoadingAuth]);

  return (
    <Background>
      <Main>
        <WellcomeContainer>
          <div>
            <h1>Bem-vindo ao web portal Trans Real!</h1>
            <p>Entre com suas credenciais para prosseguir</p>
          </div>
          <img src={LogoTransreal} width="120px" alt="Transreal" />
        </WellcomeContainer>
        <Container>
          <img src={LogoTransrealAzul} width="180px" alt="Transreal" />
          <Form
            ref={formRef}
            initialData={{}}
            onSubmit={handleSubmit}
            noValidate
          >
            <InputIcon
              icon={FiMail}
              name="email"
              type="email"
              placeholder="E-mail"
              disabled={isLoading}
            />
            <InputIcon
              icon={FiLock}
              name="password"
              type="password"
              placeholder="Senha"
              disabled={isLoading}
            />
            {loginError === true && <span>Email/Senha incorreto</span>}
            <Button type="submit" isLoading={isLoading}>
              login
            </Button>
          </Form>
          <Link to="/esqueceu-senha">Esqueceu a senha?</Link>
        </Container>
      </Main>
    </Background>
  );
};

export default Login;
