import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from '../../entities/gatheringOrder';
import axios from '../../app/axios';

const initialState = {
  gatheringOrderId: 0,
  statusGatheringOrder: 'I',
  weightValue: 0,
  volumeValue: 0,
  quantityValue: 0,
  nfeValue: 0,
  valueCollection: 0,
  metersCubic: 0,
  currentListPage: 1,
};

export const gatheringOrderSlice = createSlice({
  name: 'gatheringOrder',
  initialState,
  reducers: {
    setGatheringOrderId: (state, { payload: bpId }) => {
      state.gatheringOrderId = !bpId ? initialState.gatheringOrderId : bpId;
    },
    setStatusGatheringOrder: (state, { payload: statusGatheringOrder }) => {
      state.statusGatheringOrder = !statusGatheringOrder
        ? initialState.statusGatheringOrder
        : statusGatheringOrder;
    },
    setWeightValue: (state, { payload: weightValue }) => {
      state.weightValue = !weightValue ? initialState.weightValue : weightValue;
    },
    setVolumeValue: (state, { payload: volumeValue }) => {
      state.volumeValue = !volumeValue ? initialState.volumeValue : volumeValue;
    },
    setQuantityValue: (state, { payload: quantityValue }) => {
      state.quantityValue = !quantityValue
        ? initialState.quantityValue
        : quantityValue;
    },
    setValueCollection: (state, { payload: valueCollection }) => {
      state.valueCollection = !valueCollection
        ? initialState.valueCollection
        : valueCollection;
    },
    setNfeValue: (state, { payload: nfeValue }) => {
      state.nfeValue = !nfeValue ? initialState.nfeValue : nfeValue;
    },
    setMetersCubic: (state, { payload: metersCubic }) => {
      state.metersCubic = !metersCubic ? initialState.metersCubic : metersCubic;
    },
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});
export const {
  setGatheringOrderId,
  setStatusGatheringOrder,
  setWeightValue,
  setVolumeValue,
  setQuantityValue,
  setValueCollection,
  setNfeValue,
  setMetersCubic,
  setCurrentListPage,
} = gatheringOrderSlice.actions;

interface AsyncData {
  id?: number;
  data: any;
}
/* interface ResponseBusinessPeople {
  data: BusinessPersonResponseData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
} */

export const getGatheringOrder = async (id: number): Promise<any> => {
  const gatheringOrder = await axios.get(`/local/collection_order/${id}`);
  return gatheringOrder;
};

export const generateCollectOrder = createAsyncThunk<any, AsyncData>(
  'quotation/generateCollectOrder',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/local/collection_order/generate/${info.id}`,
      );
      dispatch(merge(response.data));

      await axios.post(
        `/local/collection_order_itens/generate/${info.id}/${response.data.id}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const addGatheringOrder = createAsyncThunk<any, AsyncData>(
  'gatheringOrder/addGatheringOrder',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/local/collection_order', info.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateGatheringOrder = createAsyncThunk<any, AsyncData>(
  'gatheringOrder/updateGatheringOrder',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/local/collection_order/${info.id}`,
        info.data,
      );
      dispatch(merge(response.data));
      dispatch(setGatheringOrderId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const downloadCSV = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/collection_order/excel`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export const downloadPDF = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/collection_order/pdf`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export default gatheringOrderSlice.reducer;
