import users from '../../entities/users';
import cities from '../../entities/cities';
import regions from '../../entities/regions';
import clientFiles from '../../entities/clientFiles';
import clients from '../../entities/clients';
import salesman from '../../entities/salesman';
import freightTable from '../../entities/freightTable';
import freightTableFiles from '../../entities/freightTableFiles';
import businessPerson from '../../entities/businessPerson';
import quotation from '../../entities/quotation';
import freightTableTariff from '../../entities/freightTableTariff';
import freightTableItens from '../../entities/freightTableItens';
import tollType from '../../entities/tollType';
import trackType from '../../entities/trackType';
import gatheringOrder from '../../entities/gatheringOrder';
import gatheringOrderItems from '../../entities/gatheringOrderItems';
import merchandiseTracking from '../../entities/merchandiseTracking';
import collectionOrders from '../../entities/collectionOrders';
import clientDocuments from '../../entities/clientDocuments';
import clientInvoices from '../../entities/clientInvoices';
import eventsEntry from '../../entities/eventsEntry';
import transporters from '../../entities/transporters';
import transporterDocuments from '../../entities/transporterDocuments';
import transporterEvents from '../../entities/transporterEvents';
import transporterDrivers from '../../entities/transporterDrivers';
import transporterVehicles from '../../entities/transporterVehicles';
import quotationItens from '../../entities/quotationItens';
import clientBusinessPerson from '../../entities/clientBusinessPerson';
import ocurrences from '../../entities/ocurrences';
import ocurrencesIntegration from '../../entities/ocurrencesIntegration';

export default {
  users,
  cities,
  regions,
  clientFiles,
  clients,
  salesman,
  freightTable,
  freightTableFiles,
  businessPerson,
  quotation,
  freightTableTariff,
  freightTableItens,
  tollType,
  trackType,
  gatheringOrder,
  gatheringOrderItems,
  merchandiseTracking,
  collectionOrders,
  clientDocuments,
  clientInvoices,
  eventsEntry,
  transporters,
  transporterDocuments,
  transporterEvents,
  quotationItens,
  clientBusinessPerson,
  ocurrences,
  ocurrencesIntegration,
  transporterDrivers,
  transporterVehicles,
};
