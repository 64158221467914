import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filter: '',
  location: '',
  formValue: {},
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, { payload: filter }) => {
      state.filter = !filter ? initialState.filter : filter;
    },
    setLocation: (state, { payload: location }) => {
      state.location = !location ? initialState.location : location;
    },
    setFormValue: (state, { payload: formValue }) => {
      state.formValue = !formValue ? initialState.formValue : formValue;
    },
  },
});

export const { setFilter, setLocation, setFormValue } = filterSlice.actions;

export default filterSlice.reducer;
