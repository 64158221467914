/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiSave } from 'react-icons/fi';
import * as Yup from 'yup';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce/lib';
import {
  addBusinessPerson,
  updateBusinessPerson,
  businessPersonSlice,
  getBusinessPerson,
} from '../../features/businessPerson';
import {
  addClientBusinessPerson,
  addClientBusinessPersonByClient,
} from '../../features/clientBusinessPerson';
import Input from '../Input';
import ButtonForm from '../ButtonForm';
import { cep } from '../../utils/cep';
import Autocomplete from '../Autocomplete';
import { Row, ContainerSize, ContainerSizeAutoComplete } from './styles';
import InputMask from '../InputMask';
import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';
import { fetchCityByNameAndUF } from '../../features/city';
import getValidationErrors from '../../utils/getValidationErrors';
import { AppDispatch } from '../../app/store';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../features/clients';

const optionsCompany = [
  { value: 'M', label: 'Micro Empresa' },
  { value: 'J', label: 'Jurídica' },
  { value: 'F', label: 'Física' },
];

const optionsFiscalSituation = [
  { value: '2', label: 'Comércio' },
  { value: '3', label: 'Armazenagem' },
  { value: '4', label: 'Indústria' },
  { value: '5', label: 'Produto Rural' },
  { value: '6', label: 'Prestador Não Contribuinte' },
  { value: '7', label: 'Serviço de Comunicação' },
  { value: '8', label: 'Gerador/Prestador de Energia' },
  { value: '9', label: 'Prestador Contribuinte' },
];

const optionsStates = [
  {
    value: 'AC',
    label: 'Acre',
  },
  {
    value: 'AL',
    label: 'Alagoas',
  },
  {
    value: 'AM',
    label: 'Amazonas',
  },
  {
    value: 'AP',
    label: 'Amapá',
  },
  {
    value: 'BA',
    label: 'Bahia',
  },
  {
    value: 'CE',
    label: 'Ceará',
  },
  {
    value: 'DF',
    label: 'Distrito Federal',
  },
  {
    value: 'ES',
    label: 'Espírito Santo',
  },
  {
    value: 'GO',
    label: 'Goiás',
  },
  {
    value: 'MA',
    label: 'Maranhão',
  },
  {
    value: 'MG',
    label: 'Minas Gerais',
  },
  {
    value: 'MS',
    label: 'Mato Grosso do Sul',
  },
  {
    value: 'MT',
    label: 'Mato Grosso',
  },
  {
    value: 'PA',
    label: 'Pará',
  },
  {
    value: 'PB',
    label: 'Paraíba',
  },
  {
    value: 'PE',
    label: 'Pernambuco',
  },
  {
    value: 'PI',
    label: 'Piauí',
  },
  {
    value: 'PR',
    label: 'Paraná',
  },
  {
    value: 'RJ',
    label: 'Rio de Janeiro',
  },
  {
    value: 'RN',
    label: 'Rio Grande do Norte',
  },
  {
    value: 'RO',
    label: 'Rondônia',
  },
  {
    value: 'RR',
    label: 'Roraima',
  },
  {
    value: 'RS',
    label: 'Rio Grande do Sul',
  },
  {
    value: 'SC',
    label: 'Santa Catarina',
  },
  {
    value: 'SE',
    label: 'Sergipe',
  },
  {
    value: 'SP',
    label: 'São Paulo',
  },
  {
    value: 'TO',
    label: 'Tocantins',
  },
];

interface FormFields {
  unique_code: string;
  type: string;
  fiscal_situation: string;
  company_type: string;
  ie?: string;
  state_registration?: string;
  cep: string;
  address: string;
  complement?: string;
  number: string;
  phone_number: string;
  district: string;
  city_id: number;
  status: boolean;
  cpf: string;
  cnpj: string;
  pagador?: string;
  reference: string;
}

interface BusinessPersonParams {
  id: string | undefined;
}

const BusinessPersonForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch: AppDispatch = useDispatch();
  const [companyType, setCompanyType] = useState('M');
  const [fiscalSituation, setFiscalSituation] = useState('2');
  const [optionsClient, setOptionsClient] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [optionsCity, setOptionsCity] = useState<any>([]);
  const [cepValid, setCepValid] = useState(false);
  const [phoneNumberMask, setPhoneNumberMask] = useState('(99) 9999-9999?');
  const history = useHistory();
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { params } = useRouteMatch<BusinessPersonParams>();
  const { setBusinessPersonId } = businessPersonSlice.actions;
  const { bpId, userId, userType, userTypeId } = useSelector(
    (state: RootStateOrAny) => ({
      bpId: state.features.businessPerson.bpId,
      userId: state.features.auth.userId,
      userType: state.features.auth.type,
      userTypeId: state.features.auth.userTypeId,
    }),
  );

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClient(true);
    }
    setSearchClients(newValue);
  };

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchClientsByDocumentNumber({
                document_number: valueSearchClient,
                ...isSalesman,
              })
            : await fetchClientsByDocumentNumber({
                company_name: valueSearchClient,
                ...isSalesman,
              });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchUserClientsByDocumentNumber({
                'client.document_number': valueSearchClient,
                user_id: userId,
              })
            : await fetchUserClientsByDocumentNumber({
                'client.company_name': valueSearchClient,
                user_id: userId,
              });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClient(allClients);
      setIsLoadingClient(false);
    })();
  }, [userId, userType, userTypeId, valueSearchClient]);

  const handleInputCep = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const cepSearch = e.currentTarget.value.replace('_', '');
      if (cepSearch.length === 10) {
        e.currentTarget.blur();
        showLoading();
        const searchAddress = await cep(cepSearch);
        if (typeof searchAddress.erro === 'undefined') {
          (formRef.current as any).setFieldValue(
            'address',
            searchAddress.logradouro,
          );
          (formRef.current as any).setFieldValue(
            'district',
            searchAddress.bairro,
          );
          let selectedState = {};
          optionsStates.forEach(state => {
            if (state.value === searchAddress.uf) {
              selectedState = {
                value: searchAddress.uf,
                label: state.label,
              };
            }
          });
          (formRef.current as any).setFieldValue('state', selectedState);
          const cities = await fetchCityByNameAndUF({
            name: searchAddress.localidade,
            uf: searchAddress.uf,
          });
          const allCities: any = [];
          cities.forEach((city: any): any => {
            allCities.push({ value: city.id, label: city.name });
          });
          setOptionsCity(allCities);
          (formRef.current as any).setFieldValue('city_id', allCities[0]);
          hideLoading();
          (document.querySelector('#number') as any).focus();
          addToast({
            type: 'success',
            title: 'CEP encontrado',
          });
          setCepValid(true);
          (formRef.current as any).setFieldError(`cep`, '');
        } else {
          hideLoading();
          (formRef.current as any).setFieldError('cep', 'CEP não encontrado');
          (document.querySelector('#cep') as any).focus();
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'CEP não encontrado',
          });
          setCepValid(false);
        }
      } else {
        setCepValid(false);
      }
    },
    [addToast, hideLoading, showLoading],
  );

  const handlerChangeBrazilianPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMask('(99) 9999-9999?');
      } else {
        setPhoneNumberMask('(99) 99999-9999');
      }
    },
    [],
  );

  const handleCompanyTypeChange = useCallback((value: any): void => {
    setCompanyType(value.value);
  }, []);

  const handleFiscalSituationChange = useCallback((value: any): void => {
    setFiscalSituation(value.value);
  }, []);

  useEffect(() => {}, [fiscalSituation]);

  useEffect(() => {
    if (params.id && history.location.pathname.includes('parceiro-comercial')) {
      (async () => {
        showLoading();
        dispatch(setBusinessPersonId(Number(params.id)));
        const { data: client } = await getBusinessPerson(Number(params.id));
        /*
        (formRef.current as any).setFieldValue('user_id', {
          value: (client as any).user_id,
          label: (client as any).user.email,
        }); */
        setCepValid(true);
        setCompanyType((client as any).type);
        const phoneNumber = client.phone_number.replace(/[^0-9]/g, '');
        if (phoneNumber.length <= 10) {
          setPhoneNumberMask('(99) 9999-9999?');
        } else {
          setPhoneNumberMask('(99) 99999-9999');
        }
        (formRef.current as any).setFieldValue('cep', client.cep);
        (formRef.current as any).setFieldValue('address', client.address);
        (formRef.current as any).setFieldValue('complement', client.complement);
        (formRef.current as any).setFieldValue('number', client.number);
        (formRef.current as any).setFieldValue(
          'phone_number',
          client.phone_number,
        );
        (formRef.current as any).setFieldValue('district', client.district);
        (formRef.current as any).setFieldValue('city_id', {
          value: (client as any).city_id,
          label: (client as any).city.name,
        });
        (formRef.current as any).setFieldValue('state', {
          value: (client as any).city.uf,
          label: (client as any).city.uf,
        });
        if ((client as any).branch_id) {
          (formRef.current as any).setFieldValue('branch_id', {
            value: (client as any).branch_id,
            label: (client as any).branch.name,
          });
        }
        (formRef.current as any).setFieldValue('type', {
          value: (client as any).type,
          label: optionsCompany.filter(p => p.value === (client as any).type)[0]
            .label,
        });
        (formRef.current as any).setFieldValue('fiscal_situation', {
          value: (client as any).fiscal_situation,
          label: optionsFiscalSituation.filter(
            p => p.value === (client as any).fiscal_situation,
          )[0].label,
        });
        (formRef.current as any).setFieldValue(
          'unique_code',
          client.unique_code,
        );
        (formRef.current as any).setFieldValue('name', client.name);
        (formRef.current as any).setFieldValue('reference', client.reference);
        (formRef.current as any).setFieldValue(
          'state_registration',
          client.state_registration,
        );

        (document.querySelector('#cep') as any).focus();
        (document.querySelector('#phone_number') as any).focus();
        (document.querySelector('#phone_number') as any).blur();
        hideLoading();
      })();
    }
  }, [dispatch, hideLoading, params.id, setBusinessPersonId, showLoading]);

  const handleSubmit = useCallback(
    async (data: FormFields): Promise<void | false> => {
      try {
        (formRef.current as any).setErrors({});
        const uniqueCodeMask = data.unique_code;
        const phoneNumberValueMask = data.phone_number;
        const cepMask = data.cep;
        data.cep = data.cep.replace(/[^0-9]/g, '');
        data.phone_number = data.phone_number.replace(/[^0-9]/g, '');
        const schemaValidation = Yup.object().shape({
          cep: Yup.string().min(7, 'Mínimo de 7 dígitos'),
          phone_number: Yup.string().min(10, 'Mínimo de 10 dígitos'),
          fiscal_situation: Yup.string().required(
            'Selecione a situação fiscal',
          ),
          pagador:
            userType !== 'client'
              ? Yup.string().required('Informe o Cliente')
              : Yup.string(),
          address: Yup.string().required('Informe o endereço'),
          number: Yup.string().required('Informe o número'),
          district: Yup.string().required('Informe o bairro'),
          city_id: Yup.string().required('Informe a cidade'),
          state_registration: Yup.string().required(
            'Informe a inscrição estadual',
          ),
          type: Yup.string().required('Informe o tipo da empresa'),
          reference: Yup.string(),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        if (data.type !== '' && data.fiscal_situation !== '') {
          try {
            let schemaValidation2;

            if (data.type === 'F') {
              data.unique_code = data.unique_code.replace(/[^0-9]/g, '');
              schemaValidation2 = Yup.object().shape({
                unique_code: Yup.string().min(11, 'Mínimo de 11 dígitos'),
                name: Yup.string().required('Informe o nome'),
                state_registration: Yup.string().required('Informe o RG'),
              });
            } else {
              data.unique_code = data.unique_code.replace(/[^0-9]/g, '');
              schemaValidation2 = Yup.object().shape({
                unique_code: Yup.string().min(14, 'Mínimo de 14 dígitos'),
                name: Yup.string().required('Informe a razão social'),
                state_registration: Yup.string().required(
                  'Informe a inscrição estadual',
                ),
              });
            }
            await schemaValidation2.validate(data, {
              abortEarly: false,
            });

            data.unique_code = uniqueCodeMask;
            data.cep = cepMask;
            data.phone_number = phoneNumberValueMask;

            if (cepValid === true) {
              showLoading();
              try {
                if (!bpId) {
                  const response = await dispatch(addBusinessPerson({ data }));
                  if (addBusinessPerson.fulfilled.match(response)) {
                    userType !== 'client'
                      ? await dispatch(
                          addClientBusinessPersonByClient({
                            data: {
                              bp_id: response.payload.id,
                              client_id: data.pagador,
                            },
                          }),
                        )
                      : await dispatch(
                          addClientBusinessPerson({
                            data: {
                              bp_id: response.payload.id,
                              user_id: userId,
                            },
                          }),
                        );
                    dispatch(setBusinessPersonId(response.payload.id));
                    addToast({
                      type: 'success',
                      title: 'Sucesso',
                      description: 'Parceiro Comercial cadastrado com sucesso',
                    });
                    hideLoading();
                  } else {
                    addToast({
                      type: 'error',
                      title: 'Sucesso',
                      description: 'Erro ao cadastrar Parceiro Comercial',
                    });
                  }
                } else {
                  const response = await dispatch(
                    updateBusinessPerson({ data, id: bpId }),
                  );
                  if (updateBusinessPerson.fulfilled.match(response)) {
                    addToast({
                      type: 'success',
                      title: 'Sucesso',
                      description: 'Parceiro Comercial atualizado com sucesso',
                    });
                  } else {
                    addToast({
                      type: 'error',
                      title: 'Sucesso',
                      description: 'Erro ao atualizar Parceiro Comercial',
                    });
                  }
                }
                hideLoading();
              } catch (error) {
                hideLoading();
                addToast({
                  type: 'error',
                  title: 'Erro',
                  description: 'Erro ao cadastrar o Parceiro Comercial',
                });
              }
            } else {
              (formRef.current as any).setFieldError(
                'cep',
                'Digite um CEP válido',
              );
              (formRef.current as any).setFieldValue('city_id', {});
            }
          } catch (err2) {
            const errors = getValidationErrors(err2);
            (formRef.current as any).setErrors(errors);
          }
        }
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
      }
    },
    [
      addToast,
      cepValid,
      bpId,
      dispatch,
      hideLoading,
      showLoading,
      userId,
      userType,
      setBusinessPersonId,
    ],
  );

  return (
    <Form
      ref={formRef}
      initialData={{}}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="new-password"
    >
      {userType !== 'client' ? (
        <Row>
          {' '}
          <ContainerSizeAutoComplete size="50%">
            <Autocomplete
              name="pagador"
              placeholder="Pagador *"
              options={optionsClient}
              changeValue={null}
              isLoading={isLoadingClient}
              onInputChange={handleClientInputChange}
            />
          </ContainerSizeAutoComplete>
        </Row>
      ) : (
        <></>
      )}

      <Row>
        <ContainerSize size="30%">
          <Autocomplete
            name="type"
            options={optionsCompany}
            placeholder="Tipo Empresa *"
            changeValue={handleCompanyTypeChange}
          />
        </ContainerSize>
        <ContainerSize size="30%">
          {companyType !== 'F' ? (
            <InputMask
              mask="99.999.999/9999-99"
              name="unique_code"
              type="text"
              placeholder="CNPJ *"
              autoFocus
            />
          ) : (
            <InputMask
              mask="999.999.999-99"
              name="unique_code"
              type="text"
              placeholder="CPF *"
              autoFocus
            />
          )}
        </ContainerSize>
        <ContainerSize size="40%">
          <Autocomplete
            name="fiscal_situation"
            options={optionsFiscalSituation}
            placeholder="Situação Fiscal *"
            changeValue={handleFiscalSituationChange}
          />
        </ContainerSize>
      </Row>
      <Row>
        <ContainerSize size="50%">
          <Input
            name="name"
            type="text"
            placeholder={companyType !== 'F' ? 'Razão Social *' : 'Nome *'}
          />
        </ContainerSize>
        <ContainerSize size="25%">
          <Input
            name="state_registration"
            type="text"
            placeholder={companyType === 'F' ? 'RG *' : 'I.E *'}
          />
        </ContainerSize>
        <ContainerSize size="25%">
          <InputMask
            name="phone_number"
            type="text"
            placeholder="Telefone *"
            mask={phoneNumberMask}
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            onKeyUp={handlerChangeBrazilianPhone}
            maskChar=""
          />
        </ContainerSize>
      </Row>
      <Row>
        <ContainerSize size="25%">
          <InputMask
            name="cep"
            type="text"
            placeholder="CEP *"
            onKeyUp={handleInputCep}
            mask="99.999-999"
          />
        </ContainerSize>
        <ContainerSize size="50%">
          <Input name="address" type="text" placeholder="Endereço *" />
        </ContainerSize>
        <ContainerSize size="25%">
          <Input name="number" type="text" placeholder="Número *" />
        </ContainerSize>
        <ContainerSize size="50%">
          <Input name="complement" type="text" placeholder="Complemento" />
        </ContainerSize>
        <ContainerSize size="50%">
          <Input name="reference" type="text" placeholder="Referência" />
        </ContainerSize>
        <ContainerSize size="30%">
          <Input name="district" type="text" placeholder="Bairro *" />
        </ContainerSize>
        <ContainerSize size="45%">
          <Autocomplete
            name="city_id"
            options={optionsCity}
            placeholder="Município *"
            changeValue={null}
            isDisabled
          />
        </ContainerSize>
        <ContainerSize size="25%">
          <Autocomplete
            name="state"
            options={optionsStates}
            placeholder="Estado *"
            changeValue={null}
            isDisabled
          />
        </ContainerSize>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <ContainerSize size="265px">
          <ButtonForm type="submit">
            <FiSave size={20} />
            Salvar
          </ButtonForm>
        </ContainerSize>
      </Row>
    </Form>
  );
};

export default BusinessPersonForm;
