/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import download from 'downloadjs';

import { FaClipboardList, FaFilePdf } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce/lib';
import { FiList } from 'react-icons/fi';
import moment from 'moment';
import {
  getClientInvoices,
  sendMailInvoice,
  downloadPDF,
  downloadCSV,
  ClientInvoiceSlice,
  getInvoiceDetailsInfo,
} from '../../../features/clientInvoices';

import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';

import PageContainer from '../../../components/PageContainer';
import TableList from '../../../components/TableList';
import Filter from '../../../components/Filter';
import Input from '../../../components/Input';
import Pagination from '../../../components/Pagination';

import MainTitle from '../../../components/MainTitle';
import { formatValueToCurrencyInput } from '../../../utils/formatValueCurrencyInput';
import { Badge, DocInformation } from './styles';
import { FloatButton } from '../../LinkTransporterDocuments/styles';
import Autocomplete from '../../../components/Autocomplete';
import { filterSlice } from '../../../features/filter';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../../features/clients';
import Modal from '../../../components/Modal';

const ListClientDocuments: React.FC = () => {
  const {
    currentListPage,
    formValue,
    locationFilter,
    userType,
    userTypeId,
    userId,
  } = useSelector((state: RootStateOrAny) => ({
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    currentListPage: state.features.clientInvoices.currentListPage,
    formValue: state.features.filter.formValue,
    locationFilter: state.features.filter.location,
  }));
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const [invoiceDetails, setInvoiceDetails] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? [] : formValue,
  );
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const { showLoading, hideLoading } = useLoading();
  const { setCurrentListPage } = ClientInvoiceSlice.actions;
  const { setFormValue } = filterSlice.actions;

  const handleClickSendMailInvoice = useCallback(
    async (id: number): Promise<void> => {
      showLoading();
      const response = await sendMailInvoice(id);
      if (response.status === 200) {
        addToast({
          title: 'Fatura enviada com sucesso',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Erro ao enviar a fatura',
          type: 'error',
        });
      }
      hideLoading();
    },
    [showLoading, hideLoading, addToast],
  );

  const optionsStatus = [
    {
      label: 'Pendente',
      value: '0',
    },
    {
      label: 'Paga',
      value: '1',
    },
  ];

  const getInvoiceDetails = useCallback(async (id: number): Promise<void> => {
    showLoading();
    const clientInvoices = await getInvoiceDetailsInfo(id);
    if (clientInvoices.status === 200) {
      setInvoiceDetails(clientInvoices);
      setShowDetails(true);
    } else {
      addToast({
        title: 'Nenhum detalhe encontrado',
        type: 'info',
      });
    }
    hideLoading();
  }, []);

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const clientInvoices = await getClientInvoices({
        page,
        ...optionsSearch,
      });
      const arrayPages = [];
      for (let i = 0; i < clientInvoices.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));

      const clientInvoicesResult: Array<object> = [];

      clientInvoices.data.data.forEach(item => {
        clientInvoicesResult.push([
          item.client.company_name,
          item.client.document_number,
          item.contract,
          `R$ ${formatValueToCurrencyInput(Number(item.value))}`,
          <Badge type={item.is_settled ? 'success' : 'danger'}>
            {item.is_settled ? 'pago' : 'pendente'}
          </Badge>,
          <DocInformation>
            <span>
              Emitido em:{' '}
              {new Date(item.invoice_date).toLocaleDateString('pt-BR', {
                timeZone: 'UTC',
              })}
            </span>
            <span>
              Vence em: {moment.utc(item.due_date).format('DD/MM/YYYY')}
            </span>
            {item.pay_date ? (
              <span>
                Pago em:{' '}
                {new Date(item.pay_date).toLocaleDateString('pt-BR', {
                  timeZone: 'UTC',
                })}
              </span>
            ) : (
              ''
            )}
          </DocInformation>,
          '',
          /*  <div className="options-table-list">
            <button
              type="button"
              onClick={() => handleClickSendMailInvoice(item.id)}
              title="Enviar fatura para e-mail"
            >
              <MdMail size={20} color="#ffffff" />
            </button>
            <button
              type="button"
              onClick={() => getInvoiceDetails(item.id)}
              title="Ver detalhes da fatura"
            >
              <FiList size={20} color="#ffffff" />
            </button>
          </div> */
        ]);
      });
      setResults(clientInvoicesResult);
      setIsLoadingPage(false);
      hideLoading();
      addToast({
        title: 'Faturas carregadas com sucesso',
        type: 'success',
      });
    },
    [addToast, hideLoading, showLoading, optionsSearch],
  );

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback((data: Obj): void => {
    setCurrentPage(1);
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        searchData[key.replace('-', '.')] = value;
      }
    }
    dispatch(setFormValue(searchData));
    setOptionsSearch(searchData);
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [optionsSearch]);

  useEffect(() => {}, [pages]);

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } = await fetchClientsByDocumentNumber({
          document_number: valueSearchClient,
          ...isSalesman,
        });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } = await fetchUserClientsByDocumentNumber({
          'client.document_number': valueSearchClient,
          user_id: userId,
        });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [userId, userType, userTypeId, valueSearchClient]);

  const handleClickDownloadCSV = useCallback(async () => {
    showLoading();

    try {
      const response = await downloadCSV({
        ...optionsSearch,
      });
      const fileName = `documentos-${new Date().toLocaleString('pt-BR', {
        timeZone: 'UTC',
      })}`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Faturas!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  const handleClickDownloadPDF = useCallback(async () => {
    showLoading();

    try {
      const response = await downloadPDF({
        ...optionsSearch,
      });
      const fileName = `documentos-${new Date().toLocaleDateString()}.pdf`;
      download(response.data, fileName, 'application/pdf');
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao Baixar o Arquivo',
        description: 'Verifique a quantidade de Faturas!',
      });
    }
    hideLoading();
  }, [currentPage, optionsSearch, showLoading, hideLoading]);

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  return (
    <PageContainer>
      <MainTitle>
        <FaClipboardList />
        Consultar Faturas
      </MainTitle>
      <TableList
        header={[
          'Razão Social',
          'CNPJ/CPF',
          'N°. Duplicata',
          'Valor',
          'Status',
          'Datas',
          '',
        ]}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
        isLoadingPage={isLoadingPage}
      />
      <Filter onSubmit={handleSubmitForm}>
        <Autocomplete
          name="client_invoices-client_id"
          options={optionsClients}
          placeholder="Cliente"
          changeValue={null}
          onInputChange={handleClientInputChange}
          isLoading={isLoadingClients}
        />
        <Autocomplete
          name="client_invoices-is_settled"
          placeholder="Status"
          options={optionsStatus}
          changeValue={null}
        />
        <Input
          type="text"
          name="client_invoices-contract"
          placeholder="N°. Duplicata"
        />
        <Input
          type="date"
          name="client_invoices-invoice_date>"
          placeholder="Data Emissão De"
        />
        <Input
          type="date"
          name="client_invoices-invoice_date<"
          placeholder="Data Emissão Até"
        />
        <Input
          type="date"
          name="client_invoices-due_date>"
          placeholder="Data Vencimento De"
        />
        <Input
          type="date"
          name="client_invoices-due_date<"
          placeholder="Data Vencimento Até"
        />
        <Input
          type="date"
          name="client_invoices-pay_date>"
          placeholder="Data Pagamento De"
        />
        <Input
          type="date"
          name="client_invoices-pay_date<"
          placeholder="Data Pagamento Até"
        />
      </Filter>
      <FloatButton
        type="button"
        title="Exportar em Excel"
        onClick={() => handleClickDownloadCSV()}
      >
        <SiMicrosoftexcel size={25} />
      </FloatButton>
      <FloatButton
        type="button"
        title="Exportar em PDF"
        onClick={() => handleClickDownloadPDF()}
        style={{ bottom: '154px' }}
      >
        <FaFilePdf size={25} />
      </FloatButton>
      <Modal
        backgroundColor="#F8F8FB"
        fontColor="#202020"
        isOpen={showDetails}
        title="Detalhes Fatura"
        id="modal-business-person"
        onClose={() => setShowDetails(false)}
      >
        {invoiceDetails && invoiceDetails.data ? (
          <div
            style={{ width: '90%', marginRight: 'auto', marginLeft: 'auto' }}
          >
            <strong style={{ textAlign: 'center' }}>Fatura Transreal</strong>
            <br />
            <br />
            <strong style={{ color: '#003CA6' }}>
              {invoiceDetails.data.info.client_name},
            </strong>
            <p>
              A sua fatura nº {invoiceDetails.data.info.contract} já está
              disponível para pagamento! Consulte abaixo um resumo dela.
            </p>

            <p
              style={{
                backgroundColor: '#ffffff',
                borderRadius: '5px',
                marginTop: '15px',
                padding: '15px',
              }}
            >
              Vencimento: <strong>{invoiceDetails.data.info.due_date}</strong>
              <br />
              Valor: <strong>{invoiceDetails.data.info.value}</strong>
              <br />
              Banco: <strong>{invoiceDetails.data.info.bank_name}</strong>
              <br />
              Código do boleto:{' '}
              <strong style={{ wordWrap: 'break-word' }}>
                {invoiceDetails.data.info.code_invoice}
              </strong>
              <br />
              Link 2ª Via:{' '}
              <a
                href={invoiceDetails.data.info.bank_link}
                style={{ wordWrap: 'break-word' }}
              >
                {invoiceDetails.data.info.bank_link}
              </a>
            </p>
            {invoiceDetails.data.documents.map((item: any) => (
              <div style={{ fontSize: '90%' }}>
                <p style={{ textAlign: 'center' }}>
                  <strong>Documentos</strong>
                </p>
                <p
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '5px',
                    marginTop: '15px',
                    padding: '15px',
                  }}
                >
                  Documento: {item.code}-{item.series}
                  <br />
                  Valor: {item.value}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </PageContainer>
  );
};

export default ListClientDocuments;
