/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useDebounce } from 'use-debounce/lib';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { FaQuestionCircle } from 'react-icons/fa';
import { RiDraftLine } from 'react-icons/ri';
import { FiSend } from 'react-icons/fi';
import ReproveImg from '../../../assets/X Material Icon.svg';
import { AppDispatch } from '../../../app/store';
import {
  Row,
  ContainerSize,
  SpecialRow,
  AddNewButton,
  OCSContainer,
  ContainerSizeAutoComplete,
  FormButton,
} from './styles';
import Input from '../../../components/Input';
import Autocomplete from '../../../components/Autocomplete';
import InputMask from '../../../components/InputMask';
import Modal from '../../../components/Modal';
import BusinessPersonForm from '../../../components/BusinessPersonForm';
import GatheringOrderItens from '../items';

import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
  setCubageFactor,
  getClient,
} from '../../../features/clients';
import {
  getBusinessPersonbyName,
  getBusinessPerson,
  businessPersonSlice,
} from '../../../features/businessPerson';
import { getClientBusinessPersonbyName } from '../../../features/clientBusinessPerson';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';
import { fetchCityByNameAndUF } from '../../../features/city';
import { cep } from '../../../utils/cep';
import OkImg from '../../../assets/checkmark-2x.png';
import AddImg from '../../../assets/AddUser.png';
import {
  addGatheringOrder,
  updateGatheringOrder,
  gatheringOrderSlice,
  getGatheringOrder,
  setWeightValue,
  setVolumeValue,
  setQuantityValue,
  setValueCollection,
  setNfeValue,
} from '../../../features/gatheringOrder';
import {
  ItensData,
  gatheringOrderItemsSlice,
  addGatheringOrderItem,
  setItensQuantity,
} from '../../../features/gatheringOrderItems';
import { formatValueToCurrencyInput } from '../../../utils/formatValueCurrencyInput';
import { useDialog } from '../../../hooks/dialog';
import Textarea from '../../../components/Textarea';
import { ErrorMessage } from '../../../components/Radio/styles';

interface FormInterface {
  [key: string]: string;
}

interface EntryOrderParams {
  id: string | undefined;
}

interface EntryProps {
  gatheringOrderId: number;
  statusGatheringOrder: string;
  cubageFactor: number;
}

const GatheringOrderEntry: React.FC<EntryProps> = ({
  gatheringOrderId,
  statusGatheringOrder,
  cubageFactor,
}) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch: AppDispatch = useDispatch();
  const { addToast } = useToast();
  const { createDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();
  const [optionsCity, setOptionsCity] = useState<any>([]);
  const [phoneNumberMask, setPhoneNumberMask] = useState('(99) 9999-9999?');
  const [phoneNumberMaskReceiver, setPhoneNumberMaskReceiver] = useState(
    '(99) 9999-9999?',
  );
  const [optionsClient, setOptionsClient] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [quotationIdCollect, setQuotationIdCollect] = useState(0);
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const {
    setGatheringOrderId,
    setStatusGatheringOrder,
    setMetersCubic,
  } = gatheringOrderSlice.actions;
  const { setItens } = gatheringOrderItemsSlice.actions;

  const [optionsBusinessPersonRem, setOptionsBusinessPersonRem] = useState<any>(
    [],
  );
  const [searchBusinessPersonRem, setSearchBusinessPersonRem] = useState('');
  const [valueSearchBusinessPersonRem] = useDebounce(
    searchBusinessPersonRem,
    1000,
  );

  const [optionsBusinessPersonDest, setOptionsBusinessPersonDest] = useState<
    any
  >([]);
  const [searchBusinessPersonDest, setSearchBusinessPersonDest] = useState('');
  const [valueSearchBusinessPersonDest] = useDebounce(
    searchBusinessPersonDest,
    1000,
  );

  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isLoadingBusinessPersonRem, setIsLoadingBusinessPersonRem] = useState(
    false,
  );
  const [
    isLoadingBusinessPersonDest,
    setIsLoadingBusinessPersonDest,
  ] = useState(false);
  const modalFormRef = useRef<FormHandles>(null);

  const [isDraft, setIsDraft] = useState(false);
  const [isReproved, setIsReproved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [collectionDraft, setCollectionDraft] = useState(false);
  const { params } = useRouteMatch<EntryOrderParams>();

  const [titleModal, setTitleModal] = useState('');
  const [showModalBp, setShowModalBp] = useState(false);

  const [disableRem, setDisableRem] = useState(false);
  const [disableDest, setDisableDest] = useState(false);

  const {
    userType,
    userTypeId,
    userId,
    weightValue,
    volumeValue,
    quantityValue,
    nfeValue,
    metersCubic,
    bpId,
    itensCollect,
    itensQuantity,
  } = useSelector((state: RootStateOrAny) => ({
    quotationId: state.features.quotation.quotationId,
    userType: state.features.auth.type,
    userTypeId: state.features.auth.userTypeId,
    userId: state.features.auth.userId,
    weightValue: state.features.gatheringOrder.weightValue,
    volumeValue: state.features.gatheringOrder.volumeValue,
    quantityValue: state.features.gatheringOrder.quantityValue,
    nfeValue: state.features.gatheringOrder.nfeValue,
    metersCubic: state.features.gatheringOrder.metersCubic,
    bpId: state.features.businessPerson.bpId,
    itensCollect: state.features.gatheringOrderItems.itensCollect,
    itensQuantity: state.features.gatheringOrderItems.itensQuantity,
  }));

  let oldBusinessPersonId = 0;

  const canceled = useCallback(() => console.log('canceled'), []);

  const confirm = useCallback(async () => {
    const data = (formRef.current as any).getData();
    const dataReprove = (modalFormRef.current as any).getData();

    const gatheringOrderData = {
      branch_id: 1,
      client_id: data.pagador,
      origin_id: data.origem,
      destination_id: data.destino,
      payer_id: data.pagador,
      sender_id: data.remetente,
      receiver_id: data.destinatario,
      cep_origin: data['cep-origem'],
      cep_destination: data['cep-destino'],
      comments: data.obs,
      issuance_date: data['data-emissao'],
      sender_address: data.sender_address,
      sender_number: data.sender_number,
      sender_complement: data.sender_complement,
      sender_district: data.sender_district,
      receiver_address: data.receiver_address,
      receiver_number: data.receiver_number,
      receiver_complement: data.receiver_complement,
      receiver_district: data.receiver_district,
      receiver_contact: data.receiver_contact,
      receiver_reference: data.receiver_reference,
      receiver_phonenumber: data.receiver_phonenumber,
      sender_contact: data.sender_contact,
      sender_reference: data.sender_reference,
      sender_phonenumber: data.sender_phonenumber,
      limit_date: data.limit_date,
      reprove_reason: dataReprove.email,
    };
    showLoading();
    if (params.id) {
      let Response;
      if (isDraft === true) {
        Response = await dispatch(
          updateGatheringOrder({
            id: Number(params.id),
            data: {
              ...gatheringOrderData,
              status: 'D',
            },
          }),
        );
        setCollectionDraft(true);
      } else {
        Response = await dispatch(
          updateGatheringOrder({
            id: Number(params.id),
            data: {
              ...gatheringOrderData,
              status: isReproved === true ? 'R' : 'A',
            },
          }),
        );
        dispatch(setStatusGatheringOrder('A'));
        setCollectionDraft(false);
      }
      if (updateGatheringOrder.fulfilled.match(Response)) {
        addToast({
          title: 'Lançamento atualizado com sucesso',
          type: 'success',
        });
      } else {
        addToast({ title: 'Erro na criação', type: 'error' });
      }
    } else {
      let Response;
      if (isDraft === true) {
        if (gatheringOrderId > 0) {
          Response = await dispatch(
            updateGatheringOrder({
              id: gatheringOrderId,
              data: { ...gatheringOrderData, status: 'D' },
            }),
          );
          setCollectionDraft(true);
        } else {
          Response = await dispatch(
            addGatheringOrder({
              data: { ...gatheringOrderData, status: 'D' },
            }),
          );
          setCollectionDraft(true);
        }
      } else {
        if (gatheringOrderId > 0) {
          Response = await dispatch(
            updateGatheringOrder({
              id: gatheringOrderId,
              data: { ...gatheringOrderData, status: 'A' },
            }),
          );
          dispatch(setStatusGatheringOrder('A'));
          setCollectionDraft(false);
        } else {
          Response = await dispatch(
            addGatheringOrder({
              data: { ...gatheringOrderData, status: 'A' },
            }),
          );
          dispatch(setStatusGatheringOrder('A'));
          setCollectionDraft(false);
        }
      }
      if (Response.payload) {
        if (gatheringOrderId === 0) {
          if (itensCollect.length > 0) {
            const itensRedux: ItensData[] = itensCollect;
            for (const itemRedux of itensRedux) {
              const {
                quantity,
                comments,
                height,
                length,
                nf_number,
                product_name,
                serial,
                value,
                weight,
                weight_calculated,
                width,
              } = itemRedux;

              const newItem = {
                quantity,
                comments,
                height,
                length,
                nf_number,
                product_name,
                serial,
                value,
                weight,
                weight_calculated,
                width,
                collection_order_id: Response.payload.id,
              };

              // eslint-disable-next-line no-await-in-loop
              await dispatch(addGatheringOrderItem({ data: newItem }));
            }
            dispatch(setItens([]));
          }
        }
        dispatch(setGatheringOrderId(Response.payload.id));
        addToast({
          title: 'Lançamento criado com sucesso',
          type: 'success',
        });
      } else {
        addToast({ title: 'Erro na criação', type: 'error' });
      }
    }
    hideLoading();
  }, [
    addToast,
    dispatch,
    hideLoading,
    params.id,
    showLoading,
    isDraft,
    setGatheringOrderId,
    setItens,
    addGatheringOrderItem,
    gatheringOrderId,
    itensCollect,
  ]);

  useEffect(() => {
    if (bpId > 0 && bpId !== oldBusinessPersonId) {
      oldBusinessPersonId = bpId;
      (async () => {
        showLoading();
        const businessPerson = await getBusinessPerson(bpId);
        (formRef.current as any).setFieldValue(titleModal, {
          value: bpId,
          label: businessPerson.data.name,
        });
        hideLoading();
        setShowModalBp(false);
      })();
    }
  }, [bpId]);

  const handleLoadCepInfo = useCallback(
    async (cepValue: string, fieldName: string) => {
      if (cepValue) {
        const cepSearch = cepValue.replace('_', '');
        if (cepSearch.length === 10 || cepSearch.length === 8) {
          // showLoading();
          const searchAddress = await cep(cepSearch);
          if (typeof searchAddress.erro === 'undefined') {
            const addressInputs = fieldName.includes('origem')
              ? 'sender'
              : 'receiver';
            const cities = await fetchCityByNameAndUF({
              name: searchAddress.localidade,
              uf: searchAddress.uf,
            });
            const allCities: any = [];
            cities.forEach((city: any): any => {
              allCities.push({
                value: city.id,
                label: `${city.name}-${searchAddress.uf}`,
              });
            });
            setOptionsCity(allCities);
            (formRef.current as any).setFieldValue(fieldName, allCities[0]);
            (formRef.current as any).setFieldValue(
              `${addressInputs}_address`,
              formRef.current?.getFieldValue(`${addressInputs}_address`)
                ? formRef.current?.getFieldValue(`${addressInputs}_address`)
                : searchAddress.logradouro,
            );
            (formRef.current as any).setFieldValue(
              `${addressInputs}_district`,
              formRef.current?.getFieldValue(`${addressInputs}_district`)
                ? formRef.current?.getFieldValue(`${addressInputs}_district`)
                : searchAddress.bairro,
            );
            (formRef.current as any).setFieldError(`cep-${fieldName}`, '');

            // hideLoading();
          } else {
            // hideLoading();
            (formRef.current as any).setFieldError(
              `cep-${fieldName}`,
              'CEP não encontrado',
            );
            (document.querySelector(`#cep-${fieldName}`) as any).focus();
          }
        }
        // hideLoading();
      }
    },
    [addToast, hideLoading, showLoading],
  );

  useEffect(() => {
    dispatch(setWeightValue(0));
    dispatch(setQuantityValue(0));
    dispatch(setVolumeValue(0));
    dispatch(setValueCollection(0));
    dispatch(setNfeValue(0));
    dispatch(setMetersCubic(0));
    dispatch(setItens([]));
    dispatch(setItensQuantity(0));
    if (params.id) {
      dispatch(setGatheringOrderId(params.id));
    }

    if (!quotationIdCollect) {
      setQuotationIdCollect(0);
    }
  }, []);

  useEffect(() => {
    if (gatheringOrderId > 0 || params.id) {
      (async () => {
        const idEsc = params.id ? params.id : gatheringOrderId;
        dispatch(setItens([]));
        showLoading();
        const { data: quotation } = await getGatheringOrder(Number(idEsc));

        if (quotation.quotation_id) {
          setQuotationIdCollect(quotation.quotation_id);
          await handleLoadCepInfo(quotation.cep_destination, 'destino');
          await handleLoadCepInfo(quotation.cep_origin, 'origem');

          if (quotation.sender) {
            setDisableRem(true);
          }

          if (quotation.receiver) {
            setDisableDest(true);
          }
        }
        dispatch(setStatusGatheringOrder(quotation.status));
        if (quotation.sender_phonenumber) {
          const phoneNumber = quotation.sender_phonenumber.replace(
            /[^0-9]/g,
            '',
          );
          if (phoneNumber.length <= 10) {
            setPhoneNumberMask('(99) 9999-9999?');
          } else {
            setPhoneNumberMask('(99) 99999-9999');
          }

          (formRef.current as any).setFieldValue(
            'sender_phonenumber',
            quotation.sender_phonenumber,
          );
        }

        if (quotation.receiver_phonenumber) {
          const phoneNumberReceiver = quotation.receiver_phonenumber.replace(
            /[^0-9]/g,
            '',
          );
          if (phoneNumberReceiver.length <= 10) {
            setPhoneNumberMaskReceiver('(99) 9999-9999?');
          } else {
            setPhoneNumberMaskReceiver('(99) 99999-9999');
          }
          (formRef.current as any).setFieldValue(
            'receiver_phonenumber',
            quotation.receiver_phonenumber,
          );
        }

        (formRef.current as any).setFieldValue('pagador', {
          value: (quotation as any).client.id,
          label: `${
            (quotation as any).client.name
              ? (quotation as any).client.name
              : (quotation as any).client.company_name
          }-${quotation.client.document_number}`,
        });

        if ((quotation as any).sender) {
          (formRef.current as any).setFieldValue('remetente', {
            value: (quotation as any).sender.id,
            label: `${(quotation as any).sender.name}-${
              (quotation as any).sender.unique_code
            }`,
          });
        }

        if ((quotation as any).receiver) {
          (formRef.current as any).setFieldValue('destinatario', {
            value: (quotation as any).receiver.id,
            label: `${(quotation as any).receiver.name}-${
              (quotation as any).receiver.unique_code
            }`,
          });
        }

        (formRef.current as any).setFieldValue('obs', quotation.comments);
        (formRef.current as any).setFieldValue(
          'data-emissao',
          moment.utc(quotation.issuance_date).format('YYYY-MM-DD'),
        );

        if (quotation.cep_origin) {
          (formRef.current as any).setFieldValue(
            'cep-origem',
            quotation.cep_origin,
          );
        }

        if (quotation.cep_destination) {
          (formRef.current as any).setFieldValue(
            'cep-destino',
            quotation.cep_destination,
          );
        }

        (formRef.current as any).setFieldValue(
          'sender_address',
          quotation.sender_address,
        );
        (formRef.current as any).setFieldValue(
          'sender_number',
          quotation.sender_number,
        );
        (formRef.current as any).setFieldValue(
          'sender_complement',
          quotation.sender_complement,
        );
        (formRef.current as any).setFieldValue(
          'sender_district',
          quotation.sender_district,
        );
        (formRef.current as any).setFieldValue(
          'receiver_address',
          quotation.receiver_address,
        );
        (formRef.current as any).setFieldValue(
          'receiver_number',
          quotation.receiver_number,
        );
        (formRef.current as any).setFieldValue(
          'receiver_complement',
          quotation.receiver_complement,
        );
        (formRef.current as any).setFieldValue(
          'receiver_contact',
          quotation.receiver_contact,
        );
        (formRef.current as any).setFieldValue(
          'receiver_reference',
          quotation.receiver_reference,
        );
        (formRef.current as any).setFieldValue(
          'sender_contact',
          quotation.sender_contact,
        );
        (formRef.current as any).setFieldValue(
          'sender_reference',
          quotation.sender_reference,
        );

        (formRef.current as any).setFieldValue(
          'limit_date',
          moment.utc(quotation.limit_date).format('YYYY-MM-DD'),
        );

        (formRef.current as any).setFieldValue(
          'receiver_district',
          quotation.receiver_district,
        );

        dispatch(setStatusGatheringOrder((quotation as any).status));
        setIsDraft(
          (quotation as any).status !== 'A' &&
            (quotation as any).status !== 'R',
        );
        setCollectionDraft(
          (quotation as any).status !== 'A' &&
            (quotation as any).status !== 'R',
        );
        dispatch(setWeightValue(quotation.weight));
        dispatch(setQuantityValue(quotation.quantity));
        dispatch(setVolumeValue(quotation.volume));
        dispatch(setValueCollection(quotation.value));
        dispatch(setNfeValue(quotation.nfe_value));
        let totalCubicMeters = 0;
        (quotation.itens as any).forEach((item: any): void => {
          const calcCubicMeters = item.length * item.width * item.height;
          totalCubicMeters += calcCubicMeters;
        });
        dispatch(setMetersCubic(totalCubicMeters));

        hideLoading();
      })();
    }
  }, [gatheringOrderId]);

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchClientsByDocumentNumber({
                document_number: valueSearchClient,
                ...isSalesman,
              })
            : await fetchClientsByDocumentNumber({
                company_name: valueSearchClient,
                ...isSalesman,
              });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.name ? client.name : client.company_name} - ${
              client.document_number
            }`,
          });
        });
      } else {
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchUserClientsByDocumentNumber({
                'client.document_number': valueSearchClient,
                user_id: userId,
              })
            : await fetchUserClientsByDocumentNumber({
                'client.company_name': valueSearchClient,
                user_id: userId,
              });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${
              item.client.name ? item.client.name : item.client.company_name
            } - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClient(allClients);
      setIsLoadingClient(false);
    })();
  }, [valueSearchClient]);

  useEffect(() => {
    (async () => {
      const allBusinessPersonRem: any = [];
      if (userType === 'saleman' || userType === 'client') {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });
        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonRem =
          valueSearchBusinessPersonRem.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonRem,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonRem,
              });
        businessPersonRem.data.data.forEach((business_person: any): any => {
          allBusinessPersonRem.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonRem(allBusinessPersonRem);
      setIsLoadingBusinessPersonRem(false);
    })();
  }, [valueSearchBusinessPersonRem]);

  useEffect(() => {
    (async () => {
      const allBusinessPersonDest: any = [];

      if (userType === 'saleman' || userType === 'client') {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getClientBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getClientBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      } else {
        const businessPersonDest =
          valueSearchBusinessPersonDest.replace(/[^0-9]/g, '').length > 1
            ? await getBusinessPersonbyName({
                'business_person.unique_code': valueSearchBusinessPersonDest,
              })
            : await getBusinessPersonbyName({
                'business_person.name': valueSearchBusinessPersonDest,
              });
        businessPersonDest.data.data.forEach((business_person: any): any => {
          allBusinessPersonDest.push({
            value: business_person.id,
            label: `${business_person.name}-${business_person.unique_code}`,
          });
        });
      }

      setOptionsBusinessPersonDest(allBusinessPersonDest);
      setIsLoadingBusinessPersonDest(false);
    })();
  }, [valueSearchBusinessPersonDest]);

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClient(true);
    }
    setSearchClients(newValue);
  };

  const handleBusinessPersonRemInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonRem) {
      setIsLoadingBusinessPersonRem(true);
    }
    setSearchBusinessPersonRem(newValue);
  };

  const handleClientChangeCubageFactor = useCallback(
    async (value: any): Promise<void> => {
      if (value) {
        const cubage = await getClient(value.value);
        dispatch(setCubageFactor(cubage.data.cubage_factor));
      }
    },
    [dispatch, setCubageFactor],
  );

  const handleBusinessPersonDestInputChange = (newValue: string): void => {
    if (newValue !== searchBusinessPersonDest) {
      setIsLoadingBusinessPersonDest(true);
    }
    setSearchBusinessPersonDest(newValue);
  };

  const handleInputCep = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const cepSearch = e.currentTarget.value.replace('_', '');
      if (cepSearch.length === 10) {
        const fieldName = e.currentTarget.id.includes('origem')
          ? 'origem'
          : 'destino';
        const addressInputs = e.currentTarget.id.includes('origem')
          ? 'sender'
          : 'receiver';
        e.currentTarget.blur();
        showLoading();
        const searchAddress = await cep(cepSearch);
        if (typeof searchAddress.erro === 'undefined') {
          const cities = await fetchCityByNameAndUF({
            name: searchAddress.localidade,
            uf: searchAddress.uf,
          });
          const allCities: any = [];
          cities.forEach((city: any): any => {
            allCities.push({
              value: city.id,
              label: `${city.name}-${searchAddress.uf}`,
            });
          });
          setOptionsCity(allCities);
          (formRef.current as any).setFieldValue(
            fieldName,
            allCities[allCities.length - 1],
          );
          hideLoading();
          addToast({
            type: 'success',
            title: 'CEP encontrado',
          });
          (formRef.current as any).setFieldValue(
            `${addressInputs}_address`,
            searchAddress.logradouro,
          );
          (formRef.current as any).setFieldValue(
            `${addressInputs}_district`,
            searchAddress.bairro,
          );
        } else {
          hideLoading();
          (formRef.current as any).setFieldError(
            `cep-${fieldName}`,
            'CEP não encontrado',
          );
          (document.querySelector(`#cep-${fieldName}`) as any).focus();
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'CEP não encontrado',
          });
        }
      }
    },
    [addToast, hideLoading, showLoading],
  );

  const handleBusinessPersonRemChange = useCallback(
    async (value: any): Promise<void> => {
      if (value) {
        showLoading();
        const cepResponse = await getBusinessPerson(value.value);
        if ((formRef.current as any) && cepResponse.data.cep) {
          if (quotationIdCollect === 0) {
            (formRef.current as any).setFieldValue(
              'cep-origem',
              cepResponse.data.cep,
            );
            (formRef.current as any).setFieldError('cep-origem', '');
          }

          (formRef.current as any).setFieldValue(
            'sender_number',
            cepResponse.data.number,
          );

          (formRef.current as any).setFieldValue(
            'sender_complement',
            cepResponse.data.complement,
          );

          (formRef.current as any).setFieldValue(
            'sender_address',
            cepResponse.data.address,
          );

          (formRef.current as any).setFieldValue(
            'sender_district',
            cepResponse.data.district,
          );

          (formRef.current as any).setFieldValue(
            'sender_reference',
            cepResponse.data.reference,
          );

          if (cepResponse.data.phone_number) {
            const phoneNumberReceiver = cepResponse.data.phone_number.replace(
              /[^0-9]/g,
              '',
            );
            if (phoneNumberReceiver.length <= 10) {
              setPhoneNumberMask('(99) 9999-9999?');
            } else {
              setPhoneNumberMask('(99) 99999-9999');
            }
            (formRef.current as any).setFieldValue(
              'sender_phonenumber',
              cepResponse.data.phone_number,
            );
          }

          if (quotationIdCollect === 0) {
            await handleLoadCepInfo(cepResponse.data.cep, 'origem');
            hideLoading();
          } else {
            hideLoading();
          }
        }
      }
    },
    [handleLoadCepInfo, quotationIdCollect],
  );

  const handleBusinessPersonDestChange = useCallback(
    async (value: any): Promise<void> => {
      if (value) {
        showLoading();
        const cepResponse = await getBusinessPerson(value.value);
        if ((formRef.current as any) && cepResponse.data.cep) {
          if (quotationIdCollect === 0) {
            (formRef.current as any).setFieldValue(
              'cep-destino',
              cepResponse.data.cep,
            );
            (formRef.current as any).setFieldError('cep-destino', '');
          }

          (formRef.current as any).setFieldValue(
            'receiver_number',
            cepResponse.data.number,
          );
          (formRef.current as any).setFieldValue(
            'receiver_complement',
            cepResponse.data.complement,
          );

          (formRef.current as any).setFieldValue(
            'receiver_address',
            cepResponse.data.address,
          );

          (formRef.current as any).setFieldValue(
            'receiver_district',
            cepResponse.data.district,
          );

          (formRef.current as any).setFieldValue(
            'receiver_reference',
            cepResponse.data.reference,
          );

          if (cepResponse.data.phone_number) {
            const phoneNumberReceiver = cepResponse.data.phone_number.replace(
              /[^0-9]/g,
              '',
            );
            if (phoneNumberReceiver.length <= 10) {
              setPhoneNumberMaskReceiver('(99) 9999-9999?');
            } else {
              setPhoneNumberMaskReceiver('(99) 99999-9999');
            }
            (formRef.current as any).setFieldValue(
              'receiver_phonenumber',
              cepResponse.data.phone_number,
            );
          }
          if (quotationIdCollect === 0) {
            await handleLoadCepInfo(cepResponse.data.cep, 'destino');
            hideLoading();
          } else {
            hideLoading();
          }
        }
      }
    },
    [handleLoadCepInfo, quotationIdCollect],
  );

  const handleSubmit = useCallback(
    async (data: FormInterface) => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          'data-emissao': Yup.string().required('Data obrigatória'),
          pagador: Yup.string().required('Pagador obrigatório'),
          remetente: Yup.string().required('Remetente obrigatório'),
          'cep-origem': Yup.string().required('Cep Obrigatório'),
          origem: Yup.string().required('Origem obrigatório'),
          destinatario: Yup.string().required('Destinatário obrigatório'),
          'cep-destino': Yup.string().required('Cep Obrigatório'),
          destino: Yup.string().required('Destino obrigatório'),
          obs: Yup.string(),
          sender_address: Yup.string().required('Informe o endereço'),
          receiver_address: Yup.string().required('Informe o endereço'),
          sender_number: Yup.string().required('Informe o número'),
          receiver_number: Yup.string().required('Informe o número'),
          sender_complement: Yup.string().notRequired(),
          receiver_complement: Yup.string().notRequired(),
          sender_district: Yup.string().required('Informe o bairro'),
          receiver_district: Yup.string().required('Informe o bairro'),
          receiver_contact: Yup.string(),
          sender_contact: Yup.string(),
          receiver_reference: Yup.string(),
          sender_reference: Yup.string(),
          receiver_phonenumber: Yup.string(),
          sender_phonenumber: Yup.string(),
          limit_date: Yup.string().required('Informe a Data Limite'),
        });

        if (itensCollect.length === 0 && itensQuantity === 0) {
          setIsError(true);

          await schemaValidation.validate(data, {
            abortEarly: false,
          });
        } else {
          await schemaValidation.validate(data, {
            abortEarly: false,
          });
          let textDialog = '';
          if (params.id) {
            if (isDraft === true) {
              textDialog = 'Deseja atualizar a Coleta?';
            } else {
              textDialog = `Deseja aprovar a Coleta? Após ${
                isReproved ? 'reprovada' : 'aprovada'
              }, a Coleta não poderá ser atualizada.`;
            }
          } else {
            if (isDraft === true) {
              if (gatheringOrderId > 0) {
                textDialog = 'Deseja atualizar a Coleta como Rascunho?';
              } else {
                textDialog = 'Deseja salvar a Coleta como Rascunho?';
              }
            } else {
              if (gatheringOrderId > 0) {
                textDialog = `Deseja aprovar a Coleta? Após ${
                  isReproved ? 'reprovada' : 'aprovada'
                }, a Coleta não poderá ser atualizada.`;
              } else {
                textDialog = `Deseja aprovar a Coleta? Após ${
                  isReproved ? 'reprovada' : 'aprovada'
                }, a Coleta não poderá ser atualizada.`;
              }
            }
          }

          createDialog({
            text: textDialog,
            textButtonCancel: 'Não',
            textButtonConfirm: 'Sim',
            icon: FaQuestionCircle,
            onConfirm: confirm,
            onCancel: canceled,
          });
        }
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          const elementRef = (formRef.current as any).getFieldRef(
            err.inner[0].path as string,
          );
          const position = elementRef.offsetTop + 35;
          window.scroll({ top: position, behavior: 'smooth' });
          elementRef.focus();
          (formRef.current as any).setErrors(errors);
        }
      }
    },
    [
      addToast,
      dispatch,
      hideLoading,
      params.id,
      showLoading,
      isDraft,
      gatheringOrderId,
      itensCollect,
      itensQuantity,
    ],
  );

  useEffect(() => {
    if (gatheringOrderId === 0) {
      let totalCubicMeters = 0;
      let totalQuantity = 0;
      let totalVolume = 0;
      let totalWeight = 0;
      let totalValue = 0;
      itensCollect.forEach((item: any): any => {
        const calcCubicMeters = item.length * item.width * item.height;
        totalCubicMeters += calcCubicMeters;
        totalQuantity += item.quantity;
        totalVolume += item.weight_calculated;
        totalWeight += item.weight;
        totalValue += item.value;
      });

      dispatch(setWeightValue(totalWeight));
      dispatch(setQuantityValue(totalQuantity));
      dispatch(setVolumeValue(totalVolume));
      dispatch(setNfeValue(totalValue));
      dispatch(setMetersCubic(totalCubicMeters));
    }
  }, [itensCollect]);

  const handlerChangeBrazilianPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMask('(99) 9999-9999?');
      } else {
        setPhoneNumberMask('(99) 99999-9999');
      }
    },
    [],
  );

  const handlerChangeBrazilianPhoneReceiver = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMaskReceiver('(99) 9999-9999?');
      } else {
        setPhoneNumberMaskReceiver('(99) 99999-9999');
      }
    },
    [],
  );

  useEffect(() => {}, [isDraft, collectionDraft]);

  const { setBusinessPersonId } = businessPersonSlice.actions;

  const handleClickAddBp = useCallback(
    (type: string): void => {
      dispatch(setBusinessPersonId(0));
      setTitleModal(type);
      setShowModalBp(true);
    },
    [dispatch, setBusinessPersonId, setTitleModal, setShowModalBp],
  );

  const handleSubmitEmail = useCallback(
    async (data: FormInterface) => {
      try {
        (modalFormRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          email: Yup.string().required('Motivo de Reprovação obrigatório'),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        setShowModal(false);
        await handleSubmit((formRef.current as any).getData());
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (modalFormRef.current as any).setErrors(errors);
        }
      }
      hideLoading();
    },
    [addToast, dispatch, showModal, setShowModal],
  );

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  return (
    <>
      <Modal
        backgroundColor="#ffffff"
        fontColor="#666666"
        isOpen={showModal}
        title="Motivo Reprovação"
        id="contact-modal"
        onClose={() => setShowModal(false)}
      >
        <div>
          <Form
            ref={modalFormRef}
            initialData={{}}
            onSubmit={handleSubmitEmail}
          >
            <Row>
              <ContainerSize size="100%">
                <Textarea
                  name="email"
                  placeholder="Digite o motivo da reprovação"
                />
              </ContainerSize>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <FormButton type="submit">
                <span>
                  <FiSend />
                  Salvar
                </span>
              </FormButton>
            </Row>
          </Form>
        </div>
      </Modal>
      <Form ref={formRef} initialData={{}} onSubmit={handleSubmit} noValidate>
        <Row>
          <ContainerSize size="20%">
            <OCSContainer>Coleta: {gatheringOrderId || ''}</OCSContainer>
          </ContainerSize>
          {gatheringOrderId ? (
            <ContainerSize size="30%">
              <OCSContainer>
                Status Coleta:{' '}
                {statusGatheringOrder === 'A'
                  ? 'Aprovada'
                  : statusGatheringOrder === 'R'
                  ? 'Reprovada'
                  : 'Aguardando Aprovação'}
              </OCSContainer>
            </ContainerSize>
          ) : (
            <></>
          )}
        </Row>
        <Row style={{ marginBottom: '35px' }}>
          <ContainerSizeAutoComplete size="50%">
            <Autocomplete
              name="pagador"
              placeholder="Pagador *"
              options={optionsClient}
              changeValue={handleClientChangeCubageFactor}
              isLoading={isLoadingClient}
              onInputChange={handleClientInputChange}
              isDisabled={statusGatheringOrder === 'A'}
            />
          </ContainerSizeAutoComplete>
          <ContainerSize size="20%">
            <Input
              name="data-emissao"
              type="date"
              placeholder="Data de Emissão *"
              defaultValue={
                gatheringOrderId === 0 ? moment().format('YYYY-MM-DD') : ''
              }
              disabled={statusGatheringOrder === 'A'}
            />
          </ContainerSize>
          <ContainerSize size="20%">
            <Input
              name="limit_date"
              type="date"
              placeholder="Data Limite *"
              defaultValue={
                gatheringOrderId === 0 ? moment().format('YYYY-MM-DD') : ''
              }
              disabled={statusGatheringOrder === 'A'}
            />
          </ContainerSize>
        </Row>

        <SpecialRow style={{ marginBottom: '35px' }}>
          <Row>
            <div className="special-title">
              <h4>Local de Coleta</h4>
              {statusGatheringOrder !== 'A' &&
              statusGatheringOrder !== 'R' &&
              !disableRem ? (
                <AddNewButton onClick={() => handleClickAddBp('remetente')}>
                  <img src={AddImg} alt="Novo Remetente" />
                  Remetente
                </AddNewButton>
              ) : (
                <></>
              )}
            </div>
            <ContainerSizeAutoComplete size="50%">
              <Autocomplete
                name="remetente"
                placeholder="Remetente *"
                options={optionsBusinessPersonRem}
                changeValue={handleBusinessPersonRemChange}
                isLoading={isLoadingBusinessPersonRem}
                onInputChange={handleBusinessPersonRemInputChange}
                isDisabled={statusGatheringOrder === 'A' || disableRem}
              />
            </ContainerSizeAutoComplete>

            <ContainerSize size="20%">
              <InputMask
                name="cep-origem"
                type="text"
                placeholder="CEP Origem *"
                mask="99.999-999"
                onKeyUp={handleInputCep}
                disabled={
                  statusGatheringOrder === 'A' ||
                  quotationIdCollect !== 0 ||
                  statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>

            <ContainerSizeAutoComplete size="30%">
              <Autocomplete
                name="origem"
                placeholder="Origem *"
                options={optionsCity}
                changeValue={null}
                isDisabled={
                  statusGatheringOrder === 'A' ||
                  quotationIdCollect !== 0 ||
                  statusGatheringOrder === 'R'
                }
              />
            </ContainerSizeAutoComplete>
          </Row>
          <Row>
            <ContainerSize size="25%">
              <Input
                name="sender_address"
                type="text"
                placeholder="Endereço *"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="sender_number"
                type="text"
                placeholder="Número *"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="sender_complement"
                type="text"
                placeholder="Complemento"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="sender_district"
                type="text"
                placeholder="Bairro *"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
          </Row>
          <Row>
            <ContainerSize size="25%">
              <Input
                name="sender_contact"
                type="text"
                placeholder="Contato"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <InputMask
                name="sender_phonenumber"
                type="text"
                placeholder="Telefone"
                mask={phoneNumberMask}
                formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                onKeyUp={handlerChangeBrazilianPhone}
                maskChar=""
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="50%">
              <Input
                name="sender_reference"
                type="text"
                placeholder="Referência"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
          </Row>
        </SpecialRow>

        <SpecialRow>
          <Row>
            <div className="special-title">
              <h4>Local de Entrega</h4>
              {statusGatheringOrder !== 'A' &&
              statusGatheringOrder !== 'R' &&
              !disableDest ? (
                <AddNewButton onClick={() => handleClickAddBp('destinatario')}>
                  <img src={AddImg} alt="Novo Destinatário" />
                  Destinatário
                </AddNewButton>
              ) : (
                <></>
              )}
            </div>

            <ContainerSizeAutoComplete size="50%">
              <Autocomplete
                name="destinatario"
                placeholder="Destinatario *"
                options={optionsBusinessPersonDest}
                changeValue={handleBusinessPersonDestChange}
                isLoading={isLoadingBusinessPersonDest}
                onInputChange={handleBusinessPersonDestInputChange}
                isDisabled={
                  statusGatheringOrder === 'A' ||
                  statusGatheringOrder === 'R' ||
                  disableDest
                }
              />
            </ContainerSizeAutoComplete>

            <ContainerSize size="20%">
              <InputMask
                name="cep-destino"
                type="text"
                placeholder="CEP Destino *"
                mask="99.999-999"
                onKeyUp={handleInputCep}
                disabled={
                  statusGatheringOrder === 'A' ||
                  quotationIdCollect !== 0 ||
                  statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>

            <ContainerSizeAutoComplete size="30%">
              <Autocomplete
                name="destino"
                placeholder="Destino *"
                options={optionsCity}
                changeValue={null}
                isDisabled={
                  statusGatheringOrder === 'A' ||
                  quotationIdCollect !== 0 ||
                  statusGatheringOrder === 'R'
                }
              />
            </ContainerSizeAutoComplete>
          </Row>

          <Row>
            <ContainerSize size="25%">
              <Input
                name="receiver_address"
                type="text"
                placeholder="Endereço *"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="receiver_number"
                type="text"
                placeholder="Número *"
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="receiver_complement"
                type="text"
                placeholder="Complemento"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <Input
                name="receiver_district"
                type="text"
                placeholder="Bairro *"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
          </Row>
          <Row>
            <ContainerSize size="25%">
              <Input
                name="receiver_contact"
                type="text"
                placeholder="Contato"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="25%">
              <InputMask
                name="receiver_phonenumber"
                type="text"
                placeholder="Telefone"
                mask={phoneNumberMaskReceiver}
                formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                onKeyUp={handlerChangeBrazilianPhoneReceiver}
                maskChar=""
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
            <ContainerSize size="50%">
              <Input
                name="receiver_reference"
                type="text"
                placeholder="Referência"
                disabled={
                  statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
                }
              />
            </ContainerSize>
          </Row>
        </SpecialRow>
        {cubageFactor ? (
          <Row style={{ marginTop: '35px', marginBottom: '35px' }}>
            <GatheringOrderItens
              gatheringOrderId={gatheringOrderId}
              cubageFactor={cubageFactor}
              statusGatheringOrder={statusGatheringOrder}
              quotationIdCollect={quotationIdCollect}
            />
          </Row>
        ) : (
          <></>
        )}
        <ErrorMessage>
          {isError && itensQuantity === 0 && (
            <span className="error">
              Necessário informar pelo menos um item para o cálculo da Cotação
            </span>
          )}
        </ErrorMessage>
        <Row>
          <ContainerSize size="20%">
            <Input
              name="nfe-value"
              type="text"
              placeholder="R$ Total Mercadoria"
              readOnly
              value={formatValueToCurrencyInput(nfeValue)}
              disabled={statusGatheringOrder === 'A'}
            />
          </ContainerSize>
          <ContainerSize size="20%">
            <Input
              name="quantidade"
              type="text"
              placeholder="Quantidade Total"
              readOnly
              value={formatValueToCurrencyInput(quantityValue)}
            />
          </ContainerSize>
          <ContainerSize size="20%">
            <Input
              name="cubagem"
              type="text"
              placeholder="Cubagem Total"
              readOnly
              value={formatValueToCurrencyInput(volumeValue)}
            />
          </ContainerSize>
          <ContainerSize size="20%">
            <Input
              name="peso"
              type="text"
              placeholder="Peso Total"
              readOnly
              value={formatValueToCurrencyInput(weightValue)}
            />
          </ContainerSize>
          <ContainerSize size="20%">
            <Input
              name="cubic_meters"
              type="text"
              placeholder="Metros Cúbicos"
              readOnly
              value={formatValueToCurrencyInput(metersCubic)}
            />
          </ContainerSize>
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <ContainerSize size="100%">
            <Input
              name="obs"
              type="textarea"
              placeholder="Observações"
              disabled={
                statusGatheringOrder === 'A' || statusGatheringOrder === 'R'
              }
            />
          </ContainerSize>
          <div className="value-row">
            {statusGatheringOrder !== 'A' && statusGatheringOrder !== 'R' ? (
              <>
                {!params.id && !gatheringOrderId && (
                  <FormButton
                    type="submit"
                    onClick={() => {
                      setIsDraft(true);
                    }}
                  >
                    Salvar Coleta
                    <RiDraftLine />
                  </FormButton>
                )}
                {collectionDraft === true && gatheringOrderId > 0 ? (
                  <>
                    <FormButton
                      type="submit"
                      onClick={() => {
                        setIsDraft(true);
                      }}
                    >
                      Atualizar Coleta
                      <RiDraftLine />
                    </FormButton>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {statusGatheringOrder !== 'A' &&
            statusGatheringOrder !== 'R' &&
            gatheringOrderId > 0 ? (
              <>
                <FormButton
                  type="button"
                  onClick={() => {
                    setIsDraft(false);
                    setIsReproved(true);
                    openModal();
                  }}
                >
                  <span>
                    <img src={ReproveImg} alt="Reprovar" />
                    Cancelar Coleta
                  </span>
                </FormButton>
                <FormButton
                  type="submit"
                  onClick={() => {
                    setIsDraft(false);
                    setIsReproved(false);
                  }}
                >
                  <img
                    src={OkImg}
                    style={{ marginLeft: '5px', filter: 'invert(100%)' }}
                    alt="Salvar"
                    className="save-icon"
                  />
                  Aprovar Coleta
                </FormButton>
              </>
            ) : (
              <></>
            )}
          </div>
        </Row>
      </Form>

      <Modal
        backgroundColor="#F8F8FB"
        fontColor="#202020"
        isOpen={showModalBp}
        title={`Cadastrar ${titleModal}`}
        id="modal-business-person"
        onClose={() => setShowModalBp(false)}
      >
        {showModalBp && <BusinessPersonForm />}
      </Modal>
    </>
  );
};

export default GatheringOrderEntry;
