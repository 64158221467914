import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ComponentProps {
  size: string;
}

export const ContainerOptions = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  flex-wrap: wrap;

  @media (max-width: 350px) {
    padding: 0;
  }

  span {
    font-weight: bold;
  }

  button {
    background-color: #003ca6;
    color: #ffffff;
    padding: 3px 15px;
    border: none;
    border-radius: 25px;
    margin: 3px;

    display: flex;
    align-items: center;

    transition: background-color 0.3s ease;

    svg {
      margin-right: 5px;
    }

    &::first-child {
      margin-left: 5px;
    }

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }
  }
`;

export const OCSContainer = styled.div`
  background: #003ca6;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-top: 25px;
  border: 1px solid transparent;
  position: relative;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.07);
  padding: 12px;

  color: #fff;
  display: inline-block;

  font-weight: 700;

  @media (max-width: 768px) {
    width: 100%;
    height: 46px;
    padding: 12px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .attachment-button {
    width: 100%;
    height: fit-content;
    display: flex;
    position: absolute;
    left: 68.4%;
  }

  .title {
    margin: auto;
    position: relative;
    top: 12px;
    right: 10px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const AddXmlButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #003ca6;
  color: #fff;
  border-radius: 25px;
  width: 192px;
  height: 49px;
  padding: 10px 19px;
  margin-top: 0px;
  margin-left: 0px;
  border: none;

  cursor: pointer;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }

  input {
    display: none;
  }

  img {
    width: 18px;
    height: 18px;
    margin: 2px 5px;
    filter: invert(100%);
  }

  span {
    font-weight: 700;
    font-size: 16px;
    align-self: center;
  }
`;

export const NewButton = styled.button`
  border: none;
  background-color: #003ca6;
  margin-top: 25px;
  border-radius: 25px;
  width: fit-content;
  height: 40px;
  padding: 4px 40px 4px 30px;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 500;
  color: #fff;

  cursor: pointer;
  &:hover {
    background: ${shade(0.2, '#003CA6')};
  }

  img {
    width: 18px;
    height: 18px;
    align-self: center;
    filter: invert(100%);
    margin: 2px 5px;
  }

  svg {
    margin-right: 5px;
  }

  svg path {
    stroke: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 46px;
    padding: 12px;
  }
`;
