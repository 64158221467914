import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { RadioGroup, ErrorMessage } from './styles';

interface Option {
  id: string | undefined;
  label: string;
  icon?: any;
}
interface Props {
  name: string;
  options: Option[];
  changeType?: any;
  align?: any;
  specialmargin?: any;
}
const Radio: React.FC<Props> = ({
  name,
  options,
  changeType,
  align,
  specialmargin,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  if (!align) {
    align = 'row';
  }

  if (!specialmargin) {
    specialmargin = '5px';
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref: { checked: any }) => ref.checked);
        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find(
          (ref: { value: unknown }) => ref.value === value,
        );
        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);
  return (
    <>
      <RadioGroup style={{ flexDirection: align }}>
        {options.map((option, index) => (
          <div key={option.id} style={{ margin: specialmargin }}>
            <input
              ref={elRef => {
                (inputRefs.current as any)[index] = elRef;
              }}
              type="radio"
              id={`${fieldName}-${option.id}`}
              name={fieldName}
              value={option.id}
              defaultChecked={defaultValue === option.id}
              onChange={typeof changeType !== 'undefined' && changeType}
            />
            {option.icon && <img src={option.icon} alt={option.label} />}
            <label htmlFor={option.id}> {option.label}</label>
          </div>
        ))}
      </RadioGroup>

      <ErrorMessage>
        {error && <span className="error">{error}</span>}
      </ErrorMessage>
    </>
  );
};
export default Radio;
