import styled from 'styled-components';
import { shade } from 'polished';

export const FloatButton = styled.button`
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #003CA6;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;
