import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from '../../entities/quotation';
import axios from '../../app/axios';

const initialState = {
  quotationId: 0,
  statusQuotation: 'I',
  generatedCollect: false,
  currentListPage: 1,
  optionsSearch: {},
};
export const quotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    setQuotationId: (state, { payload: quotationId }) => {
      state.quotationId = !quotationId ? initialState.quotationId : quotationId;
    },
    setStatusQuotation: (state, { payload: statusQuotation }) => {
      state.statusQuotation = !statusQuotation
        ? initialState.statusQuotation
        : statusQuotation;
    },
    setGeneratedCollect: (state, { payload: generatedCollect }) => {
      state.generatedCollect = !generatedCollect
        ? initialState.generatedCollect
        : generatedCollect;
    },
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
    setFilterOptionsSearch: (state, { payload: optionsSearch }) => {
      state.optionsSearch = !optionsSearch
        ? initialState.optionsSearch
        : optionsSearch;
    },
  },
});
export const {
  setQuotationId,
  setStatusQuotation,
  setGeneratedCollect,
  setFilterOptionsSearch,
} = quotationSlice.actions;
interface AsyncData {
  id?: number | undefined;
  data: any;
}

interface Item {
  id: number;
  serial: string;
  nf_number: string;
  product_name: string;
  value: number;
  quantity: number;
  weight: number;
  weight_calculated: number;
  length: number;
  width: number;
  height: number;
  comments: string;
}

export interface quotationData {
  client_id: number;
  origin_id: number;
  destination_id: number;
  payer_id: number;
  sender_id: number;
  receiver_id: number;
  quantity: number;
  weight: number;
  volume: number;
  value: number;
  nf_key: string;
  status: string;
  cep_origin: string;
  cep_destination: string;
  contact_phone: string;
  contact_name: string;
  sender_address: string;
  sender_number: string;
  sender_complement: string;
  sender_district: string;
  receiver_address: string;
  receiver_number: string;
  receiver_complement: string;
  receiver_district: string;
  nfe_value: number;
  client: any;
  itens?: Item[];
  generated_collect: boolean;
  comments: string;
  expected_date: string;
}
interface ResponseQuotation {
  data: quotationData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getQuotation = async (id: number): Promise<ResponseQuotation> => {
  const quotation = await axios.get(`/local/quotation/${id}`);
  return quotation;
};

export const updateStatusQuotation = async (
  id: number,
): Promise<ResponseQuotation> => {
  const quotation = await axios.patch(`local/quotation/aprove/${id}`);
  return quotation;
};

export const getQuotationGeneral = async (params: any): Promise<any> => {
  const quotation = await axios.get(`/local/quotation/`, { params });
  return quotation;
};

export const getQuotationInfo = async (params: any): Promise<any> => {
  const quotation = await axios.get(`/local/quotation/infos`, {
    params,
  });
  return quotation;
};

export const deleteQuotation = async (id: number): Promise<any> => {
  const quotation = await axios.delete(`/local/quotation/${id}`);
  return quotation;
};

export const getCalculatedQuotation = async (data: any): Promise<any> => {
  const quotation = await axios.post(`/local/calculate_quotation`, data);
  return quotation;
};

export const addQuotation = createAsyncThunk<any, AsyncData>(
  'quotation/addQuotation',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/local/quotation', info.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const sendEmailQuotation = createAsyncThunk<any, AsyncData>(
  'quotation/sendEmail',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/local/mail`, info.data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateQuotation = createAsyncThunk<any, AsyncData>(
  'quotation/updateQuotation',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/local/quotation/${info.id}`,
        info.data,
      );
      dispatch(merge(response.data));
      dispatch(setQuotationId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const downloadCSV = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/quotation/excel`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export const downloadPDF = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/quotation/pdf`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export default quotationSlice.reducer;
