import { createSlice, Slice } from '@reduxjs/toolkit';
import axios from '../app/axios';

export const createEntitySlice = (
  sliceName: string,
  baseEndpoint: string,
): Slice => {
  const initialState = {
    byId: {},
    allIds: null,
  };
  const reducers = {
    setData: (state: any, { payload }: any) => {
      const currentAllIds = state.allIds ? [...state.allIds] : [];
      const set = (item: { id: number }): void => {
        state.byId[item.id] = item;
        if (currentAllIds.indexOf(item.id) < 0) {
          currentAllIds.push(item.id);
        }
      };

      if (!Array.isArray(payload)) {
        set(payload);
      } else {
        payload.forEach(item => {
          set(item);
        });
      }
      state.allIds = currentAllIds;
    },
    dropId: (state: any, { payload }: any) => {
      const currentAllIds = state.allIds ? [...state.allIds] : [];
      const drop = (id: number): void => {
        const idIndex = currentAllIds.indexOf(id);
        if (idIndex >= 0) {
          currentAllIds.splice(idIndex, 1);
          delete state.byId[id];
        }
      };

      if (!Array.isArray(payload)) {
        drop(payload);
      } else {
        payload.forEach(item => {
          drop(item);
        });
      }
      state.allIds = currentAllIds;
    },
    dropAll: (state: any) => {
      const currentAllIds = state.allIds ? [...state.allIds] : [];
      currentAllIds.forEach(id => {
        delete state.byId[id];
      });
      state.allIds = [];
    },
  };

  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers,
  });

  const { setData } = slice.actions;

  const newActions = {
    merge: (data: any) => (dispatch: any): Promise<any> => {
      let promiseResult;
      if (Array.isArray(data)) {
        const incomingIds: Array<number> = [];
        data.forEach(item => {
          incomingIds.push(item.id);
        });
        promiseResult = incomingIds;
      } else {
        promiseResult = data.id;
      }

      dispatch(setData(data));
      return Promise.resolve(promiseResult);
    },

    fetch: (params = null) => async (dispatch: any): Promise<any> =>
      axios
        .get(baseEndpoint, { params })
        .then(({ data }) => dispatch(newActions.merge(data))),

    fetchById: (id: number | Array<number>) => async (dispatch: any) => {
      const hasManyIds = Array.isArray(id);
      const url = !hasManyIds ? `${baseEndpoint}/${id}` : baseEndpoint;
      const body = !hasManyIds ? undefined : { params: { id } };
      return axios
        .get(url, body)
        .then(({ data }) => dispatch(newActions.merge(data)));
    },

    addId: (id: number | Array<number>) => (dispatch: any, getState: any) => {
      let idToFetch;

      const { byId } = getState().entities[sliceName];
      if (!Array.isArray(id)) {
        if (!byId[id]) idToFetch = id;
      } else {
        const missingIds: Array<number> = [];
        id.forEach((idItem: number) => {
          if (!byId[idItem]) missingIds.push(idItem);
        });
        if (missingIds.length > 1) {
          idToFetch = missingIds;
        } else if (missingIds.length > 0) {
          [idToFetch] = missingIds;
        }
      }

      return !idToFetch
        ? Promise.resolve([])
        : dispatch(newActions.fetchById(idToFetch));
    },
  };

  // slice.actions = newActions;

  return slice;
};
