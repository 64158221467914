import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationContainer } from './styles';

interface PaginationData {
  pages: Array<number>;
  currentPage: number;
  isLoadingPage?: boolean;
  onClickFunction(page: number): Promise<void>;
}

const Pagination: React.FC<PaginationData> = ({
  pages,
  onClickFunction,
  // eslint-disable-next-line no-unused-vars
  currentPage,
  isLoadingPage,
}) => {
  const totalPages = pages.length;

  return totalPages > 0 ? (
    <PaginationContainer>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        breakClassName="break-me"
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        initialPage={currentPage - 1}
        onPageChange={({ selected }) => {
          if (!isLoadingPage) {
            console.log('aoba');
            onClickFunction(selected + 1);
          }
        }}
        containerClassName="pagination"
        activeClassName="active"
      />
    </PaginationContainer>
  ) : (
    <></>
  );
};

export default Pagination;
