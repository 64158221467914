import auth from '../../features/auth';
import client from '../../features/clients';
import city from '../../features/city';
import regions from '../../features/regions';
import companyType from '../../features/companyTypes';
import clientFile from '../../features/clientFiles';
import user from '../../features/users';
import salesman from '../../features/salesman';
import freightTable from '../../features/freightTable';
import freightTableFiles from '../../features/freightTableFiles';
import businessPerson from '../../features/businessPerson';
import quotation from '../../features/quotations';
import quotationItem from '../../features/quotationItens';
import freightTableTariff from '../../features/freightTableTariff';
import freightTableItens from '../../features/freightTableItens';
import tollType from '../../features/tollType';
import trackType from '../../features/trackType';
import gatheringOrder from '../../features/gatheringOrder';
import gatheringOrderItems from '../../features/gatheringOrderItems';
import merchandiseTracking from '../../features/merchandiseTracking';
import collectionOrder from '../../features/collectionOrder';
import clientInvoices from '../../features/clientInvoices';
import eventsEntry from '../../features/eventsEntry';
import transporters from '../../features/transporters';
import transporterDocuments from '../../features/transporterDocuments';
import transporterEvents from '../../features/transporterEvents';
import transporterDrivers from '../../features/transporterDrivers';
import transporterVehicles from '../../features/transporterVehicles';
import clientBusinessPerson from '../../features/clientBusinessPerson';
import ocurrences from '../../features/ocurrences';
import ocurrencesIntegration from '../../features/ocurrencesIntegration';
import filter from '../../features/filter';

export default {
  auth,
  client,
  city,
  regions,
  companyType,
  clientFile,
  user,
  salesman,
  freightTable,
  freightTableFiles,
  businessPerson,
  quotation,
  quotationItem,
  freightTableTariff,
  freightTableItens,
  tollType,
  trackType,
  gatheringOrder,
  gatheringOrderItems,
  merchandiseTracking,
  collectionOrder,
  clientInvoices,
  eventsEntry,
  transporters,
  transporterDocuments,
  transporterEvents,
  clientBusinessPerson,
  ocurrences,
  ocurrencesIntegration,
  transporterDrivers,
  transporterVehicles,
  filter,
};
