/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

import {
  Modal,
  ModalDialog,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalBackdrop,
} from './styles';

interface Props {
  title: string;
  isOpen: boolean;
  backgroundColor?: string;
  fontColor?: string;
  width?: string;
  id?: string;
  onClose?: any;
}

const CustomModal: React.FC<Props> = ({
  children,
  title,
  isOpen,
  backgroundColor,
  fontColor,
  width,
  id = 'modal',
  onClose,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal(): void {
    setIsOpen(false);
    document.body.classList.remove('modal-open');
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (isOpen === true) {
      setIsOpen(true);
      document.body.classList.add('modal-open');
    } else {
      closeModal();
    }
  }, [isOpen]);

  const handleOutsideClick = (e: any): void => {
    if (e.target.id === id) closeModal();
  };

  return (
    <>
      <Modal id={id} isOpen={modalIsOpen} onClick={handleOutsideClick}>
        <ModalDialog>
          <ModalContent
            className="modal-content"
            backgroundColor={backgroundColor}
            width={width}
            fontColor={fontColor}
          >
            <ModalHeader className="modal-header">
              <h2>{title}</h2>
              <button type="button" onClick={closeModal}>
                <FaTimes size={18} />
              </button>
            </ModalHeader>
            <ModalBody className="modal-body">{children}</ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      <ModalBackdrop isOpen={modalIsOpen} onClick={closeModal} />
    </>
  );
};

export default CustomModal;
