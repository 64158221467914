import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from '../../entities/eventsEntry';
import { storage } from '../../app/authConfig';
import axios from '../../app/axios';

const initialState = {
  gatheringOrderId: 0,
};

export const eventsEntrySlice = createSlice({
  name: 'eventsEntry',
  initialState,
  reducers: {
    setEventsEntryId: (state, { payload: bpId }) => {
      state.gatheringOrderId = !bpId ? initialState.gatheringOrderId : bpId;
    },
  },
});
const { setEventsEntryId } = eventsEntrySlice.actions;

interface AsyncData {
  id?: number;
  data: any;
}
/* interface ResponseBusinessPeople {
  data: BusinessPersonResponseData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
} */

export const addNewFile = async (
  invoice_id: number,
  document_id: number,
  type: string,
  doc: Blob | Uint8Array | ArrayBuffer,
  fileName: string,
  docType: string,
): Promise<void> => {
  return new Promise(resolve => {
    const path =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    storage
      .ref(`transporter/files/${path}/${fileName}`)
      .put(doc)
      .then(() => {
        storage
          .ref(`transporter/files/${path}/${fileName}`)
          .getDownloadURL()
          .then(file_link =>
            axios
              .post(`/local/transporters_files`, {
                invoice_id,
                document_id: docType === 'CTE' ? document_id : null,
                type,
                file_name: fileName,
                path: `transporter/files/${path}/${fileName}`,
                file_link,
              })
              .then(() => {
                resolve();
              }),
          )
          .catch(() => {
            storage.ref(`transporter/files/${path}/${fileName}`).delete();
            resolve();
          });
      })
      .catch(() => {
        resolve();
      });
  });
};

export const getTransporterFiles = async (params: any): Promise<any> => {
  const transporterFiles = await axios.get(`/local/transporters_files/`, {
    params,
  });
  return transporterFiles;
};

export const deleteTransporterFiles = async (id: number): Promise<any> => {
  const transporterFiles = await axios.delete(
    `/local/transporters_files/${id}`,
  );
  return transporterFiles.status;
};

export const getEventsEntries = async (params: any): Promise<any> => {
  const eventsEntry = await axios.get(`/local/transporters_invoices/`, {
    params,
  });
  return eventsEntry;
};

export const downloadCSV = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/transporters_invoices/excel`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export const downloadPDF = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/transporters_invoices`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export const getEventsEntry = async (id: number): Promise<any> => {
  const eventsEntry = await axios.get(`/local/transporters_invoices/${id}`);
  return eventsEntry;
};

export const addEventsEntry = createAsyncThunk<any, AsyncData>(
  'eventsEntry/addEventsEntry',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/local/transporters_invoices',
        info.data,
      );
      dispatch(merge(response.data));
      dispatch(setEventsEntryId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateEventsEntry = createAsyncThunk<any, AsyncData>(
  'eventsEntry/updateEventsEntry',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/local/transporters_invoices/${info.id}`,
        info.data,
      );
      dispatch(merge(response.data));
      dispatch(setEventsEntryId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export default eventsEntrySlice.reducer;
