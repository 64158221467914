import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isFile: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: stretch;
  margin-top: 25px;
  border: 1px solid transparent;
  position: relative;
  box-sizing: border-box;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.07);

  ${props =>
    props.isErrored &&
    css`
      background-color: rgba(197, 48, 48, 0.18);
    `}

  ${props =>
    (props.isFocused || props.isFilled) &&
    css`
      background-color: #ffffff;
    `}


  label {
    display: inline-block;
    position: absolute;
    left: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 150ms ease-in;
    color: #666360;
    cursor: text;

    ${props =>
      (props.isFocused || props.isFilled) &&
      css`
        transform: translateY(-23px);
        font-size: 0.95em;
        color: #3a3a3a;
      `}
  }

  input {
    color: #333333;
    ${props =>
      props.isFile &&
      css`
        opacity: 0;
      `}

    background: transparent;
    border: none;
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    &::-webkit-calendar-picker-indicator {
      display: inline-block;
      padding: 0;
      margin: 0;
      width: 15px;
      height: 15px;
    }

    &[type='date']::-webkit-inner-spin-button,
    &[type='date']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: transparent;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #333333 !important;
    }
  }
`;

export const ContainerInput = styled.div`
  background: transparent;
  flex: 1;
  padding: 12px;
`;

export const ContainerInputFile = styled.span`
  background: transparent;
  flex: 1;
  padding: 12px;
  position: absolute;
  left: 0;
  bottom: 4px;
`;

export const ContainerError = styled.div`
  margin-top: 5px;
  width: 100%;
  text-align: left;
  color: #c53030;
`;
