import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from '../../entities/gatheringOrder';
import axios from '../../app/axios';

export interface ItensData {
  id: string | number;
  nf_number: string;
  serial: string;
  product_name: string;
  comments: string;
  height: number;
  length: number;
  quantity: number;
  quotation_id: number;
  value: number;
  weight: number;
  weight_calculated: number;
  width: number;
}

interface InitialStateData {
  bpId: number;
  itensCollect: ItensData[];
  itensQuantity: number;
}

const initialState: InitialStateData = {
  bpId: 0,
  itensCollect: [],
  itensQuantity: 0,
};

export const gatheringOrderItemsSlice = createSlice({
  name: 'gatheringOrderItems',
  initialState,
  reducers: {
    setGatheringOrderId: (state, { payload: bpId }) => {
      state.bpId = !bpId ? initialState.bpId : bpId;
    },
    setItens: (state, { payload: itensCollect }) => {
      state.itensCollect = !itensCollect
        ? initialState.itensCollect
        : itensCollect;
    },
    setItensQuantity: (state, { payload: itensQuantity }) => {
      state.itensQuantity = !itensQuantity
        ? initialState.itensQuantity
        : itensQuantity;
    },
  },
});

export const {
  setGatheringOrderId,
  setItens,
  setItensQuantity,
} = gatheringOrderItemsSlice.actions;

interface AsyncData {
  id?: number;
  data: any;
}
/* interface ResponseBusinessPeople {
  data: BusinessPersonResponseData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
} */

export const getCollectOrderItemById = async (params: any): Promise<any> => {
  const businessPerson = await axios.get(`/local/collection_order_itens/`, {
    params,
  });
  return businessPerson;
};

export const getCollectOrderItemByIdParam = async (
  id: number,
): Promise<any> => {
  const businessPerson = await axios.get(`/local/collection_order_itens/${id}`);
  return businessPerson;
};

export const getBusinessPersonbyName = async (params: any): Promise<any> => {
  const businessPerson = await axios.get(`/local/business_person`, {
    params,
  });
  return businessPerson;
};

export const addGatheringOrderItem = createAsyncThunk<any, AsyncData>(
  'gatheringOrderItems/addGatheringOrderItem',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/local/collection_order_itens',
        info.data,
      );
      dispatch(merge(response.data));
      dispatch(setGatheringOrderId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateGatheringOrderItem = createAsyncThunk<any, AsyncData>(
  'gatheringOrder/updateGatheringOrderItem',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/local/collection_order_itens/${info.id}`,
        info.data,
      );
      dispatch(merge(response.data));
      dispatch(setGatheringOrderId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const deleteGatheringOrderItem = createAsyncThunk<any, AsyncData>(
  'gatheringOrder/deleteGatheringOrderItem',
  async (info: AsyncData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/local/collection_order_itens/${info.id}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export default gatheringOrderItemsSlice.reducer;
