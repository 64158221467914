import styled from 'styled-components';
import { shade } from 'polished';
import bg from '../../assets/bg-login.svg';

export const Background = styled.div`
  min-height: 100vh;
  width: 100%;
  /* background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 0;
  background-position-y: 0; */
  background-color: #F8F8FB;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.main`
  min-height: 400px;
  display: flex;
  align-items: strech;
  justify-content: center;
  margin-top: -100px;

  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 25px 75px rgb(16, 30, 54, 0.25);

  @media (max-width: 600px) {
    max-width: 90%;
    width: 90%;
  }
`;

export const Container = styled.div`
  width: 500px;

  background-color: #fff;
  padding: 30px;
  border-radius: 0 12px 12px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  img {
    display: none;
  }
  
  @media (max-width: 600px) {
    padding: 15px;
    border-radius: 12px;
    img {
      margin-bottom: 30px;
      display: block;
    }
  }


  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: red;
      margin-top: 20px;
    }
  }

  a {
    margin-top: 30px;
    color: #5e5959;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: ${shade(0.8, '#5e5959')};
    }
  }
`;

export const WellcomeContainer = styled.div`
  width: 400px;
  height: auto;
  padding: 80px 80px;
  background: rgb(0,60,166);
  background: linear-gradient(90deg, rgba(0,60,166,1) 0%, rgba(111,145,205,1) 100%);

  border-radius: 12px 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: none;
  }

  div{
    width: 80%;
    padding-bottom: 80px;
  }

  h1{
    color: #fff;
    font-weight: 400;
  }

  p{
    color: #fff;
    margin-top: 15px;
    font-size: 18px;
  }
`;