import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ComponentProps {
  size: string;
}

export const ViewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  margin-top: 15px;

  a {
    background-color: #003ca6;
    border: none;
    color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 30px;
    border-radius: 12px;
    transition: background-color 0.2s linear;
    width: 200px;
    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }

    svg {
      margin-right: 3px;
    }
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;

    flex-direction: column;
  }
`;

export const LinkModal = styled.div`
  display: flex;
  justify-content: center;
  a {
    background-color: rgba(255, 255, 255, 1);
    border: none;
    color: #005c97;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 30px;
    border-radius: 12px;
    transition: background-color 0.2s linear;
    width: 200px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }

    svg {
      margin-right: 3px;
    }
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}
`;

export const FloatButton = styled.button`
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #003ca6;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;

export const ContainerFreightTable = styled.div`
  color: #fff;
  padding: 20px;
  border-radius: 0.3rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.05);

  h2 {
    width: 100%;
    text-align: center;
  }

  div {
    margin-top: 10px;
    width: 50%;

    &:last-child {
      width: 100%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    span {
      font-weight: 500;
      font-size: 17px;
    }
  }
`;

export const DocInformation = styled.div`
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    padding: 5px 6px;
    background-color: #f8f8fb;
    border-radius: 8px;
    margin-top: 10px;
  }
`;
