import { createSlice } from '@reduxjs/toolkit';
import { fetch } from '../../entities/clientFiles';
import { storage } from '../../app/authConfig';

import axios from '../../app/axios';

const initialState = {
  freightTableFilesId: 0,
};

export const clientFilesSlice = createSlice({
  name: 'freightTableFiles',
  initialState,
  reducers: {
    setFreightTableFilesId: (state, { payload: freightTableFilesId }) => {
      state.freightTableFilesId = !freightTableFilesId
        ? initialState.freightTableFilesId
        : freightTableFilesId;
    },
  },
});

export const setFileCreatedId = (id: number) => (dispatch: any): any => {
  dispatch(setFreightTableFilesId(id));
};

export const fetchFiles = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchFilesByFreightTable = async (params: any): Promise<any> => {
  const { data: files } = await axios.get('/local/freight_table_files', {
    params,
  });
  return files.data;
};

export const addNewFile = async (
  freight_table_id: number,
  doc: Blob | Uint8Array | ArrayBuffer,
  fileName: string,
): Promise<void> => {
  return new Promise(resolve => {
    const path =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    storage
      .ref(`freight_table/files/${path}/${fileName}`)
      .put(doc)
      .then(() => {
        storage
          .ref(`freight_table/files/${path}/${fileName}`)
          .getDownloadURL()
          .then(file_link =>
            axios
              .post(`/local/freight_table_files`, {
                freight_table_id,
                file_name: fileName,
                path: `freight_table/files/${path}/${fileName}`,
                file_link,
              })
              .then(() => {
                resolve();
              }),
          )
          .catch(() => {
            storage.ref(`freight_table/files/${path}/${fileName}`).delete();
            resolve();
          });
      })
      .catch(() => {
        resolve();
      });
  });
};

export const removeFile = async (
  freight_table_id: number,
  file_id: number,
): Promise<any> => {
  const { data: file } = await axios.get(`/local/client_files/${file_id}`);
  if (file.freight_table_id === freight_table_id) {
    axios.delete(`/local/freight_table_files/${file_id}`).then(() => {
      storage.ref(file.path).delete();
      return true;
    });
  }
  return false;
};

const { setFreightTableFilesId } = clientFilesSlice.actions;

export default clientFilesSlice.reducer;
