import React from 'react';

import { Container, Separator, Title } from './styles';

const MainTitle: React.FC = ({ children }) => {
  return (
    <Container>
      <Title>{children}</Title>
      <Separator />
    </Container>
  );
};

export default MainTitle;
