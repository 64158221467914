/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clientInfo from '../../assets/ClientErrorInfo.svg';
import { useLoading } from '../../hooks/loading';
import { logout } from '../../features/auth';

import {
  Modal,
  ModalDialog,
  TextTitle,
  TextSubtitle,
  ModalBackdrop,
  ModalContent,
  OkButton,
} from './styles';

interface Props {
  isOpen: boolean;
  width?: string;
  id?: string;
  onClose?: any;
}

const CustomModal: React.FC<Props> = ({
  isOpen,
  id = 'modalinfo',
  onClose,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { hideLoading } = useLoading();
  const dispatch = useDispatch();
  const history = useHistory();
  function closeModal(): void {
    setIsOpen(false);
    document.body.classList.remove('modal-open');
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (isOpen === true) {
      hideLoading();
      setIsOpen(true);
      document.body.classList.add('modal-open');
    } else {
      closeModal();
    }
  }, [isOpen]);

  function handleLogout(): any {
    dispatch(logout());
    history.push('login');
  }

  return (
    <>
      <Modal id={id} isOpen={modalIsOpen}>
        <ModalDialog>
          <ModalContent width="200">
            <img src={clientInfo} alt="info" width="75" />
            <TextTitle>Nenhuma empresa vinculada!</TextTitle>
            <TextSubtitle>
              Entre em contato com a TransReal para maiores informações!
            </TextSubtitle>
            <OkButton type="button" onClick={() => handleLogout()}>
              OK
            </OkButton>
          </ModalContent>
        </ModalDialog>
      </Modal>
      <ModalBackdrop isOpen={modalIsOpen} />
    </>
  );
};

export default CustomModal;
