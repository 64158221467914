/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';

import { FiEdit2 } from 'react-icons/fi';

import { Link, useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { IoMdBusiness } from 'react-icons/io';
import { getClientBusinessPersonbyName } from '../../../features/clientBusinessPerson';
import { businessPersonSlice } from '../../../features/businessPerson';

import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';

import PageContainer from '../../../components/PageContainer';
import TableList from '../../../components/TableList';
import Filter from '../../../components/Filter';
import Input from '../../../components/Input';
import Pagination from '../../../components/Pagination';

import MainTitle from '../../../components/MainTitle';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../../features/clients';
import Autocomplete from '../../../components/Autocomplete';
import { AddBpButton } from './styles';
import InputMask from '../../../components/InputMask';

const ListBusinessPerson: React.FC = () => {
  const { userType, userTypeId, userId, currentListPage } = useSelector(
    (state: RootStateOrAny) => ({
      userType: state.features.auth.type,
      userTypeId: state.features.auth.userTypeId,
      userId: state.features.auth.userId,
      currentListPage: state.features.businessPerson.currentListPage,
    }),
  );
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [documentMask, setDocumentMask] = useState('999.999.999-99?');

  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { setCurrentListPage } = businessPersonSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const trackings = await getClientBusinessPersonbyName({
        page,
        ...optionsSearch,
      });
      const arrayPages = [];
      for (let i = 0; i < trackings.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));

      const trackingsResult: Array<object> = [];

      if (userType === 'admin') {
        trackings.data.data.forEach((item: any) => {
          trackingsResult.push([
            item.businessPerson.unique_code,
            item.businessPerson.name,
            item.businessPerson.phone_number,
            item.businessPerson.city.name,
            item.businessPerson.address,
            <div className="options-table-list">
              <Link
                to={`/parceiro-comercial/${item.businessPerson.id}`}
                title="Editar parceiro comercial"
              >
                <FiEdit2 size={20} color="#fff" />
              </Link>
            </div>,
          ]);
        });
      } else {
        trackings.data.data.forEach((item: any) => {
          trackingsResult.push([
            item.unique_code,
            item.name,
            item.phone_number,
            item.city.name,
            item.address,
            <div className="options-table-list">
              <Link
                to={`/parceiro-comercial/${item.id}`}
                title="Editar parceiro comercial"
              >
                <FiEdit2 size={20} color="#fff" />
              </Link>
            </div>,
          ]);
        });
      }

      setResults(trackingsResult);
      setIsLoadingPage(false);
      hideLoading();
      addToast({
        title: 'Rastreios carregados com sucesso',
        type: 'success',
      });
    },
    [addToast, hideLoading, showLoading, optionsSearch],
  );

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchClientsByDocumentNumber({
                document_number: valueSearchClient,
                ...isSalesman,
              })
            : await fetchClientsByDocumentNumber({
                company_name: valueSearchClient,
                ...isSalesman,
              });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } =
          valueSearchClient.replace(/[^0-9]/g, '').length > 1
            ? await fetchUserClientsByDocumentNumber({
                'client.document_number': valueSearchClient,
                user_id: userId,
              })
            : await fetchUserClientsByDocumentNumber({
                'client.company_name': valueSearchClient,
                user_id: userId,
              });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [valueSearchClient]);

  const handleDocumentMaskType = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length > 11) {
        setDocumentMask('99.999.999/9999-99');
      } else {
        setDocumentMask('999.999.999-99?');
      }
    },
    [],
  );

  const handleAddUser = (): void => {
    history.push('parceiro-comercial');
  };

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      const searchData: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          searchData[key.replace('-', '.')] = value;
        }
      }
      setOptionsSearch(searchData);
    },
    [setOptionsSearch],
  );

  useEffect(() => {}, [optionsClients]);

  useEffect(() => {
    if (Object.keys(optionsSearch).length === 0) {
      getData(currentPage);
    } else {
      getData(1);
    }
  }, [optionsSearch]);

  return (
    <PageContainer>
      <MainTitle>Consultar Remetente / Destinatário</MainTitle>
      <TableList
        header={['CPF/CNPJ', 'Nome', 'Telefone', 'Cidade', 'Endereço', '']}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
        isLoadingPage={isLoadingPage}
      />
      <>
        <AddBpButton type="button" onClick={handleAddUser}>
          <IoMdBusiness size={30} />
        </AddBpButton>
      </>
      <Filter onSubmit={handleSubmitForm}>
        {userType === 'admin' ? (
          <Autocomplete
            name="client_id"
            options={optionsClients}
            placeholder="Cliente"
            changeValue={null}
            onInputChange={handleClientInputChange}
            isLoading={isLoadingClients}
          />
        ) : (
          <></>
        )}

        <InputMask
          name="unique_code"
          type="text"
          mask={documentMask}
          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
          onKeyUp={handleDocumentMaskType}
          placeholder="CPF/CNPJ"
          maskChar=""
        />
        <Input type="text" name="business_person-name" placeholder="Nome" />
        <Input
          type="date"
          name="business_person-created_at>"
          placeholder="De"
        />
        <Input
          type="date"
          name="business_person-created_at<"
          placeholder="Até"
        />
      </Filter>
    </PageContainer>
  );
};

export default ListBusinessPerson;
