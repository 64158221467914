/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';

import { BsFillInfoCircleFill } from 'react-icons/bs';

import { DialogMessage, useDialog } from '../../hooks/dialog';

import {
  DialogContainer,
  BodyContainer,
  TextContainer,
  ButtonsContainer,
  DialogBackdrop,
} from './styles';

const Dialog: React.FC<DialogMessage> = ({
  id,
  text,
  icon: Icon,
  textButtonConfirm,
  textButtonCancel,
  onCancel,
  onConfirm,
}) => {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const { removeDialog } = useDialog();

  const onClose = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    setDialogVisible(false);
    setTimeout(() => {
      removeDialog();
    }, 300);
  }, [setDialogVisible, onCancel]);

  const onAcceppt = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    setDialogVisible(false);
    setTimeout(() => {
      removeDialog();
    }, 300);
  }, [setDialogVisible, onConfirm]);

  useEffect(() => {
    if (text) {
      setDialogVisible(true);
    }
  }, [text]);

  return text ? (
    <>
      <DialogContainer isVisible={dialogVisible} key={id}>
        <BodyContainer>
          {Icon ? <Icon size={50} /> : <BsFillInfoCircleFill size={50} />}
          <TextContainer>{text}</TextContainer>
          <ButtonsContainer>
            <button onClick={onClose} type="button">
              {textButtonCancel}
            </button>
            <button onClick={onAcceppt} type="button">
              {textButtonConfirm}
            </button>
          </ButtonsContainer>
        </BodyContainer>
      </DialogContainer>
      <DialogBackdrop onClick={onClose} isVisible={dialogVisible} />
    </>
  ) : null;
};

export default Dialog;
