import styled, { css } from 'styled-components';

interface ModalProps {
  isOpen: boolean;
}

interface ModalContentProps {
  backgroundColor?: string;
  fontColor?: string;
  width?: string;
}

export const Modal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1072;
  display: block;
  padding-right: 15px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  @media (max-width: 768px) {
    padding-right: 0;
  }

  ${props =>
    props.isOpen === false &&
    css`
      display: none;
    `}
`;

export const ModalDialog = styled.div`
  margin: 0.5rem;
  position: relative;
  width: auto;
  pointer-events: none;
  min-height: calc(100% - 1rem);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (min-width: 576px) {
    min-height: calc(100% - 3.5rem);
    max-width: 800px;
    margin: 1.75rem auto;
  }
`;
export const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;

  ${props =>
    props.backgroundColor
      ? css`
          background: ${props.backgroundColor};
        `
      : css`
          background-color: #003ca6;
        `}
  ${props =>
    props.fontColor
      ? css`
          color: ${props.fontColor};
        `
      : css`
          color: #fff;
        `}
  ${props =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 100%;
        `}
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
`;
export const ModalHeader = styled.div`
  position: relative;
  text-align: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  color: inherit;

  h2 {
    margin-left: 20px;
    margin-right: 20px;
    color: inherit;
  }

  button {
    position: absolute;
    background: transparent;
    border: 0;
    padding: 10px;
    right: 16px;
    top: 11px;
    color: inherit;
  }
`;

export const ModalBody = styled.div`
  padding: 1rem;
`;

export const ModalBackdrop = styled.div<ModalProps>`
  ${props =>
    props.isOpen === false &&
    css`
      display: none;
    `}
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
`;
