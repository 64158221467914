/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { useDebounce } from 'use-debounce';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import Input from '../../components/Input';
import { ContainerSize, AddRegisterButton } from './styles';
import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';
import { useDialog } from '../../hooks/dialog';
import Pagination from '../../components/Pagination';
import {
  getVehicles,
  VehicleData,
  postVehicle,
  updateVehicle,
  setVehicleId,
  getVehicle,
  deleteVehicle,
} from '../../features/transporterVehicles';
import { fetchTransporterByName } from '../../features/transporters';
import TableList from '../../components/TableList';
import Filter from '../../components/Filter';
import Autocomplete from '../../components/Autocomplete';
import Modal from '../../components/Modal';
import ButtonForm from '../../components/ButtonForm';

interface optionsData {
  label: string;
  value: string;
}

const TransporterVehicles: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const [searchTransporters, setSearchTransporters] = useState('');
  const [optionsTransporters, setOptionsTransporters] = useState<optionsData[]>(
    [],
  );
  const [valueSearchTransporter] = useDebounce(searchTransporters, 1000);
  const [isLoadingTransporters, setIsLoadingTransporters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState('Cadastrar');
  const { showLoading, hideLoading } = useLoading();
  const { addToast } = useToast();
  const { createDialog } = useDialog();
  const dispatch = useDispatch();

  const { vehicleId } = useSelector((state: RootStateOrAny) => ({
    vehicleId: state.features.transporterVehicles.vehicleId,
  }));

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const handleTransportersInputChange = (newValue: string): void => {
    if (newValue !== searchTransporters) {
      setIsLoadingTransporters(true);
    }
    setSearchTransporters(newValue);
  };

  useEffect(() => {
    (async () => {
      const transporters = await fetchTransporterByName({
        'transporter.name': valueSearchTransporter,
      });

      setOptionsTransporters([]);
      transporters.forEach((item: any): any => {
        setOptionsTransporters(state => [
          ...state,
          {
            value: item.id,
            label: `${item.id} - ${item.name}`,
          },
        ]);
      });
      setIsLoadingTransporters(false);
    })();
  }, [valueSearchTransporter]);

  const handleCreateVehicle = useCallback((): void => {
    (formRef.current as any).reset();

    const select_transporter = formRef!.current!.getFieldRef('transporter_id');
    select_transporter.select.clearValue();

    const select_status = formRef!.current!.getFieldRef('status');
    select_status.select.clearValue();
    dispatch(setVehicleId(0));
    openModal();
  }, [setSearchTransporters, dispatch, setVehicleId]);

  const handleUpdateupdateVehicle = useCallback(
    async (id: number): Promise<void> => {
      (formRef.current as any).reset();

      const select_transporter = formRef!.current!.getFieldRef(
        'transporter_id',
      );
      select_transporter.select.clearValue();

      const select_status = formRef?.current?.getFieldRef('status');
      select_status.select.clearValue();

      showLoading();

      const response = await getVehicle(id);

      if (response.status !== 200) {
        addToast({
          title: 'Erro',
          description: 'Ocorreu um erro ao buscar esse veículo',
          type: 'error',
        });
      } else {
        const vehicle = response.data;
        (formRef.current as any).setFieldValue(
          'license_plate',
          vehicle.license_plate,
        );
        (formRef.current as any).setFieldValue('type', vehicle.type);
        (formRef.current as any).setFieldValue('status', {
          label: vehicle.status,
          value: vehicle.status,
        });
        (formRef.current as any).setFieldValue('transporter_id', {
          label: `${vehicle.transporter.id} - ${vehicle.transporter.name}`,
          value: vehicle.transporter_id,
        });
        dispatch(setVehicleId(vehicle.id));
        openModal();
      }

      hideLoading();
    },
    [
      setSearchTransporters,
      dispatch,
      setVehicleId,
      openModal,
      showLoading,
      hideLoading,
    ],
  );

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback((data: Obj): void => {
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        searchData[key.replace('-', '.')] = value;
      }
    }
    setOptionsSearch(searchData);
  }, []);

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const vehicles = await getVehicles({
        page,
        ...optionsSearch,
      });
      setResults([]);
      vehicles.data.forEach((vehicle: VehicleData) => {
        setResults(state => [
          ...state,
          [
            vehicle.id,
            vehicle.license_plate,
            vehicle.type,
            vehicle.status,
            new Date(vehicle.created_at).toLocaleString('pt-BR', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }),
            <div className="options-table-list">
              <button
                type="button"
                onClick={() => {
                  handleUpdateupdateVehicle(vehicle.id);
                }}
              >
                <FaEdit size={20} color="#ffffff" />
              </button>
              <button
                type="button"
                onClick={() => {
                  createDialog({
                    text: 'Deseja realmente excluir esse veículo?',
                    textButtonCancel: 'Não',
                    textButtonConfirm: 'Sim',
                    onConfirm: () => {
                      (async () => {
                        showLoading();
                        await deleteVehicle(vehicle.id);
                        hideLoading();
                        addToast({
                          title: 'Veículo excluído com sucesso',
                          type: 'success',
                        });
                        getData(1);
                      })();
                    },
                  });
                }}
              >
                <FaTrash size={20} color="#ffffff" />
              </button>
            </div>,
          ],
        ]);
      });
      const arrayPages = [];
      for (let i = 0; i < vehicles.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);

      hideLoading();
    },
    [showLoading, optionsSearch, hideLoading],
  );

  const handleSubmitVehicle = useCallback(
    async (data: any): Promise<void> => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          transporter_id: Yup.string().required('Selecione o transportador'),
          status: Yup.string().required('Selecione o status'),
          license_plate: Yup.string().required('Informe a placa'),
          type: Yup.string().required('Informe o tipo'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        showLoading();
        let response;
        if (vehicleId > 0) {
          response = await updateVehicle(vehicleId, data);
        } else {
          response = await postVehicle(data);
        }

        if (response.status === 200) {
          addToast({
            title: 'Sucesso',
            description: `Veículo ${
              vehicleId > 0 ? `atualizado` : `cadastrado`
            } com sucesso`,
            type: 'success',
          });
          dispatch(setVehicleId(response.data.id));
        } else {
          addToast({
            title: 'Erro',
            description: `Não foi possível ${
              vehicleId > 0 ? `atualizar` : `cadastrar`
            } o veículo`,
            type: 'error',
          });
        }

        getData(1);

        hideLoading();
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (formRef.current as any).setErrors(errors);
        }
      }
    },
    [vehicleId, dispatch, showLoading, hideLoading, addToast, getData],
  );

  useEffect(() => {
    getData(1);
  }, [optionsSearch]);

  useEffect(() => {
    if (vehicleId > 0) {
      setButtonText('Alterar');
    } else {
      setButtonText('Cadastrar');
    }
  }, [vehicleId]);

  return (
    <PageContainer>
      <MainTitle>Veículos</MainTitle>

      <TableList
        header={[
          'Código',
          'Placa',
          'Tipo',
          'Status',
          'Data de cadastro',
          'Opções',
        ]}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
      />
      <AddRegisterButton type="button" onClick={handleCreateVehicle}>
        <IoMdAdd size={30} />
      </AddRegisterButton>
      <Filter onSubmit={handleSubmitForm}>
        <Input name="ocurrences-name" type="text" placeholder="Nome" />
        <Input name="ocurrences-status" type="text" placeholder="Status" />
        <Input
          name="ocurrences-created_at>"
          type="date"
          placeholder="Data Inicial"
        />
        <Input
          name="ocurrences-created_at<"
          type="date"
          placeholder="Data Final"
        />
      </Filter>
      <Modal
        isOpen={showModal}
        title={`${buttonText} Veículo`}
        backgroundColor="#F8F8FB"
        fontColor="#3a3a3a"
        id="modal-vehicle"
        onClose={() => setShowModal(false)}
      >
        <div>
          <Form
            ref={formRef}
            initialData={{}}
            onSubmit={handleSubmitVehicle}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            noValidate
          >
            <ContainerSize size="100%" style={{ display: 'block' }}>
              <Autocomplete
                name="transporter_id"
                options={optionsTransporters}
                placeholder="Transportador"
                changeValue={null}
                onInputChange={handleTransportersInputChange}
                isLoading={isLoadingTransporters}
              />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input
                name="license_plate"
                style={{ textTransform: 'uppercase' }}
                placeholder="Placa do veículo"
              />
            </ContainerSize>
            <ContainerSize size="100%">
              <Input name="type" placeholder="Tipo do veículo" />
            </ContainerSize>
            <ContainerSize size="100%" style={{ display: 'block' }}>
              <Autocomplete
                name="status"
                options={[
                  { label: 'Ativo', value: 'Ativo' },
                  { label: 'Inativo', value: 'Inativo' },
                ]}
                placeholder="Status"
                changeValue={null}
              />
            </ContainerSize>
            <ContainerSize size="40%" style={{ display: 'block' }}>
              <ButtonForm type="submit">{buttonText}</ButtonForm>
            </ContainerSize>
          </Form>
        </div>
      </Modal>
    </PageContainer>
  );
};

export default TransporterVehicles;
