import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  trackTypeId: 0,
};

export const trackTypeSlice = createSlice({
  name: 'trackType',
  initialState,
  reducers: {
    setTrackTypeId: (state, { payload: trackTypeId }) => {
      state.trackTypeId = !trackTypeId ? initialState.trackTypeId : trackTypeId;
    },
  },
});

export interface trackTypeData {
  id?: number;
  name: string;
}

interface ResponseData {
  data: trackTypeData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGet {
  data: trackTypeData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getTrackType = async (
  trackTypeId: number,
): Promise<ResponseDataGet> => {
  const response = await axios.get(`/local/track_types/${trackTypeId}`);
  return response;
};

export const fetchTrackTypeByName = async (name: string): Promise<any> => {
  const { data: trackTypes } = await axios.get('/local/track_types', {
    params: { name },
  });
  return trackTypes.data;
};

export const addNewTrackType = async (
  trackType: trackTypeData,
): Promise<ResponseData> => {
  const response = await axios.post('/local/track_types', trackType);
  return response;
};

export const updateTrackType = async (
  id: number,
  trackType: trackTypeData,
): Promise<ResponseData> => {
  const response = await axios.put(`/local/track_types/${id}`, trackType);
  return response;
};

export const { setTrackTypeId } = trackTypeSlice.actions;

export default trackTypeSlice.reducer;
