import { createSlice } from '@reduxjs/toolkit';
import { User } from 'firebase';
import { auth } from '../../app/authConfig';
import { getUserClient } from '../clients';
import axios from '../../app/axios';

export const LoadingSteps = {
  INITIAL: 0,
  AUTH_LOADING: 1,
  AUTH_READY: 2,
};

const initialState = {
  loadingStep: LoadingSteps.INITIAL,
  user: null,
  userId: null,
  userTypeId: null,
  type: null,
  loginError: false,
  resetError: false,
  resetSuccess: false,
  menuType: '',
  photoUrl: '',
  isLoading: false,
  clientsUser: [],
  lgpd: false,
};
export const Authslice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: { user: any }, { payload: user }: any) => {
      state.user = !user ? initialState.user : user;
    },
    setLoadingStep: (
      state: { loadingStep: any },
      { payload: loadingStep }: any,
    ) => {
      state.loadingStep = loadingStep;
    },
    setUserType: (state: { type: any }, { payload: type }: any) => {
      state.type = !type ? initialState.type : type;
    },
    setUserTypeId: (
      state: { userTypeId: any },
      { payload: userTypeId }: any,
    ) => {
      state.userTypeId = !userTypeId ? initialState.userTypeId : userTypeId;
    },
    setMenuType: (state: { menuType: any }, { payload: menuType }: any) => {
      state.menuType = !menuType ? initialState.menuType : menuType;
    },
    setPhotoUrl: (state: { photoUrl: any }, { payload: photoURL }: any) => {
      state.photoUrl = !photoURL ? initialState.photoUrl : photoURL;
    },
    setLoginError: (state, { payload: loginError }) => {
      state.loginError = !loginError ? initialState.loginError : loginError;
    },
    setResetError: (state, { payload: resetError }) => {
      state.resetError = !resetError ? initialState.resetError : resetError;
    },
    setResetSuccess: (state, { payload: resetSuccess }) => {
      state.resetSuccess = !resetSuccess
        ? initialState.resetSuccess
        : resetSuccess;
    },
    setUserId: (state: { userId: any }, { payload: userId }: any) => {
      state.userId = !userId ? initialState.userId : userId;
    },
    setIsLoadingAuth: (state, { payload: isLoading }) => {
      state.isLoading = !isLoading ? initialState.isLoading : isLoading;
    },
    setIsLoadingReset: (state, { payload: isLoading }) => {
      state.isLoading = !isLoading ? initialState.isLoading : isLoading;
    },
    setClientsUser: (state, { payload: clientUser }) => {
      state.clientsUser = !clientUser ? initialState.clientsUser : clientUser;
    },
    setLgpdsUser: (state, { payload: lgpd }) => {
      state.lgpd = !lgpd ? initialState.lgpd : lgpd;
    },
  },
});

const {
  setUser,
  setLoadingStep,
  setUserType,
  setMenuType,
  setPhotoUrl,
  setLoginError,
  setResetError,
  setResetSuccess,
  setUserId,
  setUserTypeId,
  setIsLoadingAuth,
  setIsLoadingReset,
  setClientsUser,
  setLgpdsUser,
} = Authslice.actions;

function filterAuthUser(
  userResponse: User | null,
): { id: string; displayName: string | null } | null {
  if (userResponse !== null) {
    const {
      // https://firebase.google.com/docs/reference/js/firebase.User
      // email,
      // phoneNumber,
      // photoURL,
      uid: id,
      displayName,
    } = userResponse;
    return { id, displayName };
  }
  return null;
}

export const changeMenu = (type: string) => (dispatch: any): void => {
  dispatch(setMenuType(type));
};

export const changeAuthLoginStep = (value: number) => (dispatch: any): void => {
  dispatch(setLoadingStep(value));
};

export const init = () => async (dispatch: any): Promise<void> => {
  dispatch(setLoadingStep(LoadingSteps.AUTH_LOADING));
  auth.onAuthStateChanged(
    async (userResponse: User | null): Promise<void> => {
      if (userResponse) {
        await dispatch(setUser(filterAuthUser(userResponse)));
        const response = await userResponse.getIdTokenResult();
        dispatch(setUserType(response.claims.auth));
        if (response.claims.auth === 'client') {
          const userClient = await getUserClient(response.claims.user_id);
          dispatch(setClientsUser(userClient.data));
          dispatch(setMenuType('Cliente'));
        } else if (response.claims.auth === 'salesman') {
          dispatch(setMenuType('Vendedor'));
        } else if (response.claims.auth === 'admin') {
          dispatch(setMenuType('Admin'));
        } else {
          dispatch(setMenuType('Transportador'));
        }

        dispatch(setUserId(response.claims.user_id));
        if (response.claims.auth === 'salesman') {
          const salesman = await axios.get('local/salesman', {
            params: { user_id: response.claims.user_id },
          });
          dispatch(setUserTypeId(salesman.data.data[0].id));
        }
        /* userResponse.getIdTokenResult().then((response: any) => {
          dispatch(setUserType(response.claims.auth));
          dispatch(setMenuType(response.claims.auth));
          dispatch(setUserId(response.claims.user_id));
        }); */
        const photo = await axios.get('local/users', {
          params: { email: userResponse.email },
        });
        if (photo.data.data.length > 0) {
          dispatch(setPhotoUrl(photo.data.data[0].photo_link));
          dispatch(setLgpdsUser(photo.data.data[0].lgpd_aprove));
        }
      } else {
        await dispatch(setUser(null));
      }
      dispatch(setLoadingStep(LoadingSteps.AUTH_READY));
    },
  );
};

export const createPassword = (email: string) => (
  dispatch: any,
): Promise<void> =>
  auth
    .sendPasswordResetEmail(email)
    .then(() => {
      dispatch(setResetError(false));
      dispatch(setResetSuccess(true));
      dispatch(setIsLoadingReset(false));
      return Promise.resolve();
    })
    .catch(() => {
      dispatch(setResetError(true));
      dispatch(setResetSuccess(false));
      dispatch(setIsLoadingReset(false));
    });

interface UserLogin {
  user: User | undefined | null;
}

export const login = (email: string, password: string) => (
  dispatch: any,
): Promise<void> =>
  auth
    .signInWithEmailAndPassword(email, password)
    .then(async (response: UserLogin) => {
      if (response) {
        const userclaim = await response.user?.getIdTokenResult();
        dispatch(setUserType(userclaim?.claims.auth));
        if (userclaim?.claims.auth === 'client') {
          const userClient = await getUserClient(userclaim?.claims.user_id);
          dispatch(setClientsUser(userClient.data));
          dispatch(setMenuType('Cliente'));
        } else if (userclaim?.claims.auth === 'salesman') {
          dispatch(setMenuType('Vendedor'));
        } else if (userclaim?.claims.auth === 'admin') {
          dispatch(setMenuType('Admin'));
        } else {
          dispatch(setMenuType('Transportador'));
        }

        dispatch(setUserId(userclaim?.claims.user_id));
        const { user }: UserLogin = response;
        dispatch(setUser(filterAuthUser(user as any)));
        const photo = await axios.get('local/users', { params: { email } });
        if (photo.data.data.length > 0) {
          dispatch(setPhotoUrl(photo.data.data[0].photo_link));
          dispatch(setLgpdsUser(photo.data.data[0].lgpd_aprove));
        }
      }
      dispatch(setLoginError(false));
      dispatch(setIsLoadingAuth(false));
      return Promise.resolve();
    })
    .catch(() => {
      dispatch(setLoginError(true));
      dispatch(setIsLoadingAuth(false));
    });

export const logout = () => async (dispatch: any): Promise<void> => {
  dispatch(setUser(null));
  dispatch(setMenuType(null));
  dispatch(setLgpdsUser(false));
  dispatch(setClientsUser([]));
  await auth.signOut();
};

export default Authslice.reducer;
