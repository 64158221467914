/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  FaBoxOpen,
  FaCheck,
  FaTruckLoading,
  FaWarehouse,
  FaCalendarAlt,
} from 'react-icons/fa';
import { BsFileCheck, BsFillInfoCircleFill } from 'react-icons/bs';
import { MdAssignmentReturn } from 'react-icons/md';
import {
  RiTruckLine,
  RiTreasureMapLine,
  RiMapPinTimeLine,
} from 'react-icons/ri';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment';
import { FiArrowLeft } from 'react-icons/fi';

import {
  MerchandiseTrackingData,
  getTrackingInfoByCode,
} from '../../../features/merchandiseTracking';

import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';
import { BackButton } from '../../client/RegisterClient/styles';

interface tackingParams {
  code: string;
  setCode: any;
}

const MerchandiseTracking: React.FC<tackingParams> = ({ code, setCode }) => {
  const history = useHistory();
  const [tracking, setTracking] = useState<MerchandiseTrackingData[]>([]);
  const [finished, setFinished] = useState<boolean>(false);
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();

  const getInformationRender = (
    status: string,
    type: string,
    trackInfo: any,
  ): any => {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus.includes('coleta solicitada')) {
      if (type === 'icon') {
        return <FaBoxOpen />;
      }
      return (
        <>
          <h3 className="vertical-timeline-element-title">
            {trackInfo.status}
          </h3>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.occurrence}</p>
          <h4 className="vertical-timeline-element-subtitle">
            Origem: {trackInfo.place}
          </h4>
          <h4 className="vertical-timeline-element-subtitle">
            Destino: {trackInfo.place_destination}
          </h4>
          {trackInfo.expected_date ? (
            <h4 className="vertical-timeline-element-subtitle">
              Previsão de Entrega:{' '}
              {moment
                .utc(trackInfo.expected_date)
                .format('DD/MM/YYYY HH:mm:ss')}
            </h4>
          ) : (
            <></>
          )}
        </>
      );
    }
    if (lowerCaseStatus.includes('remessa criada')) {
      if (type === 'icon') {
        return <BsFileCheck />;
      }
      return (
        <>
          <h3 className="vertical-timeline-element-title">
            {trackInfo.status}
          </h3>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.occurrence}</p>

          <h4 className="vertical-timeline-element-subtitle">
            Origem {trackInfo.place}
          </h4>
          <h4 className="vertical-timeline-element-subtitle">
            Destino {trackInfo.place_destination}
          </h4>
          {trackInfo.expected_date ? (
            <h4 className="vertical-timeline-element-subtitle">
              Previsão de Entrega:{' '}
              {moment
                .utc(trackInfo.expected_date)
                .format('DD/MM/YYYY HH:mm:ss')}
            </h4>
          ) : (
            <></>
          )}
        </>
      );
    }
    if (
      lowerCaseStatus.includes('em transferência') ||
      lowerCaseStatus.includes('em transferencia') ||
      lowerCaseStatus.includes('em transito')
    ) {
      if (type === 'icon') {
        return <RiTruckLine />;
      }

      return (
        <>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.status}</p>
          <h4 className="vertical-timeline-element-title">Em transferência</h4>
          <h4 className="vertical-timeline-element-title">
            Origem: {trackInfo.place}
          </h4>
          <h4 className="vertical-timeline-element-title">
            Destino: {trackInfo.place_destination}
          </h4>
        </>
      );
    }
    if (lowerCaseStatus.includes('em rota de entrega')) {
      if (type === 'icon') {
        return <RiTreasureMapLine />;
      }

      return (
        <>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.status}</p>
          <h4 className="vertical-timeline-element-title">
            Saída para Entrega
          </h4>
        </>
      );
    }
    if (
      lowerCaseStatus.includes('entregue') ||
      lowerCaseStatus.includes('realizada com sucesso')
    ) {
      if (type === 'icon') {
        return <FaTruckLoading />;
      }

      return (
        <>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.status}</p>
          <h4 className="vertical-timeline-element-title">
            {trackInfo.occurrence}
          </h4>
          <h4 className="vertical-timeline-element-title">
            {trackInfo.receiver_name}
          </h4>
          <h4 className="vertical-timeline-element-title">
            {trackInfo.receiver_doc}
          </h4>
        </>
      );
    }
    if (lowerCaseStatus.includes('outros')) {
      if (type === 'icon') {
        return <BsFillInfoCircleFill />;
      }
      return (
        <>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.status}</p>
        </>
      );
    }
    if (
      lowerCaseStatus.includes('filial') ||
      lowerCaseStatus.includes('matriz')
    ) {
      if (type === 'icon') {
        return <FaWarehouse />;
      }

      return (
        <>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.occurrence}</p>
          <h3 className="vertical-timeline-element-title">
            {trackInfo.status}
          </h3>
        </>
      );
    }
    if (lowerCaseStatus.includes('agendamento')) {
      if (type === 'icon') {
        return <FaCalendarAlt />;
      }

      return (
        <>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.occurrence}</p>
          <h3 className="vertical-timeline-element-title">
            {trackInfo.status}
          </h3>
        </>
      );
    }
    if (lowerCaseStatus.includes('devolvida')) {
      if (type === 'icon') {
        return <MdAssignmentReturn />;
      }

      return (
        <>
          <p style={{ wordWrap: 'break-word' }}>{trackInfo.occurrence}</p>
          <h3 className="vertical-timeline-element-title">
            {trackInfo.status}
          </h3>
        </>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (code) {
      (async () => {
        showLoading();
        const { data: trackingResult } = await getTrackingInfoByCode(code);
        if (trackingResult.length > 0) {
          setTracking(trackingResult);
          trackingResult.forEach(track => {
            if (track.status.toLowerCase().includes('entregue')) {
              setFinished(true);
            }
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description:
              'Não foi possível realizar o rastreio. Sem informações no sistema',
          });
          history.goBack();
        }
        hideLoading();
      })();
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <BackButton
        type="button"
        style={{ width: '64px', alignSelf: 'flex-start', alignItems: 'center' }}
        onClick={() => setCode('')}
      >
        <FiArrowLeft size={30} />
      </BackButton>
      <VerticalTimeline>
        {tracking.map(track => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: '#fff',
              color: '#3a3a3a',
              borderTop: '3px solid #003CA6',
              boxShadow: '0 3px 8px rgb(199 199 199 / 38%)',
            }}
            contentArrowStyle={{
              borderRight: '7px solid  #fff',
            }}
            date={moment.utc(track.date_hour).format('DD/MM/YYYY HH:mm:ss')}
            iconStyle={{ background: '#003CA6', color: '#fff' }}
            icon={getInformationRender(track.status, 'icon', track)}
          >
            {getInformationRender(track.status, 'info', track)}
          </VerticalTimelineElement>
        ))}
        <VerticalTimelineElement
          iconStyle={{
            background: `${finished === true ? '#15DB15' : '#003CA6'}`,
            color: '#fff',
          }}
          icon={
            finished === true ? (
              <FaCheck color="#ffffff" />
            ) : (
              <RiMapPinTimeLine color="#ffffff" />
            )
          }
        />
      </VerticalTimeline>
    </div>
  );
};

export default MerchandiseTracking;
