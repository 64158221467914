/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { useField } from '@unform/core';
import { Container, ContainerInput, ContainerError } from './styles';

interface Props extends InputProps {
  name: string;
}

const InputMaskTracking: React.FC<Props> = ({
  name,
  placeholder,
  type,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!(inputRef.current as any).value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
        setIsFilled(!!value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
        setIsFilled(false);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setIsFilled(!!(inputRef.current as any).value);
  }, [inputRef.current]);

  useEffect(() => {}, [isFilled]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
        <ContainerInput>
          <ReactInputMask
            id={fieldName}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            type={type}
            autoComplete="off"
            placeholder={placeholder}
            {...rest}
          />
        </ContainerInput>
      </Container>
      {error && <ContainerError>{error}</ContainerError>}
    </div>
  );
};

export default InputMaskTracking;
