import { createSlice } from '@reduxjs/toolkit';
import { fetch } from '../../entities/regions';
import axios from '../../app/axios';

const initialState = {
  regionId: 0,
};

export const regionSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setregionId: (state, { payload: companyId }) => {
      state.regionId = !companyId ? initialState.regionId : companyId;
    },
  },
});

export const fetchRegions = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchRegionByName = async (name: string): Promise<any> => {
  const { data: regions } = await axios.get('/local/regions', {
    params: { 'regions.name': name },
  });
  return regions.data;
};

export default regionSlice.reducer;
