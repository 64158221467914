import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ComponentProps {
  size: string;
}

export const ViewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: fit-content;

  margin-top: 15px;

  a {
    background-color: #003ca6;
    border: none;
    color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 30px;
    border-radius: 8px;
    transition: background-color 0.2s linear;
    width: 200px;
    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }

    svg {
      margin-right: 3px;
    }
  }

  .add-quotation {
    width: 100%;
    height: fit-content;
    border-bottom: 5px solid #003ca6;

    font-size: 24px;
    font-weight: 400;
    //vertical-align: sub;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      vertical-align: top;

      cursor: pointer;

      &:hover {
        background: ${shade(0.2, '#003CA6')};
        border-radius: 300%;
      }
    }
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;

    flex-direction: column;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}
`;

interface BadgeProps {
  type: 'approved' | 'canceled' | 'waiting';
}

export const BadgeStatues = styled.div<BadgeProps>`
  padding: 10px 20px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 14px;

  ${props =>
    props.type === 'approved' &&
    css`
      background-color: #eafce8;
      color: #62ad59;
    `}

  ${props =>
    props.type === 'waiting' &&
    css`
      background-color: #e7ecf8;
      color: #809ed3;
    `}

  ${props =>
    props.type === 'canceled' &&
    css`
      background-color: #fae1e3;
      color: #dc3545;
    `}
`;

export const TotalContainers = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  > div {
    width: calc(25% - 20px);
    height: 154px;
    margin: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background-color: #003ca6;
    box-shadow: rgba(0, 60, 166, 0.2) 6px 6px 16px;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 400;

    span {
      color: #f8f8fb;
      &:first-child {
        font-size: 60px;
        font-weight: 600;
      }
    }

    @media (max-width: 768px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 460px) {
      width: calc(100% - 20px);
    }
  }
`;

export const FloatButton = styled.button`
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #003ca6;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;

export const DocInformation = styled.div`
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    padding: 5px 6px;
    background-color: #f8f8fb;
    border-radius: 8px;
    margin-top: 10px;
  }
`;
