import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const TrackingMainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const FormDiv = styled.div`
  background-color: #003ca6;
  box-shadow: rgba(0, 60, 166, 0.2) 6px 6px 16px;
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 30px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AlertSpan = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #fff;

  padding: 30px;
  padding-top: 0;
  padding-bottom: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;

export const TitleTrackingSpan = styled.span`
  font-size: 25px;
  font-weight: 600;
  margin-top: 50px;
  width: 80%;
  color: rgba(0, 60, 166, 1);
`;

export const CardListContainer = styled.div`
  width: 90%;
  display: inline-flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 0;
  text-align: left;

  ul li {
    list-style: none;
    display: inline-block;
    padding: 0;
    border: 1px solid #ccc;
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WarningDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
`;

export const WarningSpan = styled.span`
  font-size: 26px;
  font-weight: 500;
  color: #003ca6;
  margin-top: 15px;
  text-align: center;
`;
