/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDebounce } from 'use-debounce';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FaTimesCircle } from 'react-icons/fa';
import { AiOutlineBarcode } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';
import PageContainer from '../../components/PageContainer';
import MainTitle from '../../components/MainTitle';
import TableList from '../../components/TableList';
import Pagination from '../../components/Pagination';
import Filter from '../../components/Filter';
import Input from '../../components/Input';

import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ContainerSize,
  Row,
  CheckButton,
  InfoContainer,
  FloatButton,
  BindOptionButton,
} from './styles';

import { fetchTransporterByName } from '../../features/transporters';
import {
  getNotLinkedDocuments,
  updateDocument,
  stackDocumentsTransporter,
  stackUnlinkDocumentsTransporter,
  getLinkedDocuments,
  unlinkDocument,
} from '../../features/transporterDocuments';
import {
  formatValueToCurrencyInput,
  formatValueFromCurrencyInput,
} from '../../utils/formatValueCurrencyInput';
import Autocomplete from '../../components/Autocomplete';
import ButtonForm from '../../components/ButtonForm';
import CurrencyInput from '../../components/CurrencyInput';
import Modal from '../../components/Modal';

interface documentItem {
  id: number;
}

interface FormData {
  transporter_id: number;
}

const LinkTransporterDocuments: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formStack = useRef<FormHandles>(null);
  const formStackUnlink = useRef<FormHandles>(null);
  const formBind = useRef<FormHandles>(null);
  const [results, setResults] = useState<Array<any>>([]);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const [optionsTransporters, setOptionsTransporters] = useState<any>([]);
  const [optionsTransportersModal, setOptionsTransportersModal] = useState<any>(
    [],
  );
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { showLoading, hideLoading } = useLoading();
  const { addToast } = useToast();
  const [searchTransporters, setSearchTransporters] = useState('');
  const [valueSearchTransporters] = useDebounce(searchTransporters, 1000);
  const [isLoadingTransporters, setIsLoadingTransporters] = useState(false);
  const [searchTransportersModal, setSearchTransportersModal] = useState('');
  const [valueSearchTransportersModal] = useDebounce(
    searchTransportersModal,
    1000,
  );
  const [isLoadingTransportersModal, setIsLoadingTransportersModal] = useState(
    false,
  );
  const [savedDocuments, setSavedDocuments] = useState<documentItem[]>([]);

  const [stackDocs, setStackDocs] = useState<Array<string>>([]);
  const [stackDocsList, setStackDocsList] = useState<Array<Array<string>>>([]);
  const [showModalStack, setShowModalStack] = useState(false);

  const [bindState, setBindState] = useState<string>('Vincular');

  const handleClickCheckbox = useCallback(
    (event: React.FormEvent<HTMLButtonElement>) => {
      const btn = event.currentTarget;
      const id = Number(btn.getAttribute('data-id'));
      const checked = btn.getAttribute('data-checked');
      if (checked === 'true') {
        btn.setAttribute('data-checked', 'false');
        setSavedDocuments(state => state.filter(item => item.id !== id));
      } else {
        btn.setAttribute('data-checked', 'true');
        setSavedDocuments(state => state.filter(item => item.id !== id));
        setSavedDocuments(state => [...state, { id }]);
      }
    },
    [],
  );

  useEffect(() => {
    console.log(savedDocuments, 'savedDocuments');
  }, [savedDocuments]);

  const verifyCheckedInvoice = useCallback(
    (id: number): boolean => {
      return savedDocuments.filter(invoice => invoice.id === id).length > 0;
    },
    [savedDocuments],
  );

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();

      const eventsEntry =
        bindState === 'Vincular'
          ? await getNotLinkedDocuments({
              page,
              ...optionsSearch,
            })
          : await getLinkedDocuments({
              page,
              ...optionsSearch,
            });
      const arrayEventsEntry: Array<Array<any>> = [];

      eventsEntry.data.data.forEach((item: any) => {
        const isChecked = verifyCheckedInvoice(item.id);
        arrayEventsEntry.push([
          <CheckButton
            type="button"
            data-id={item.id}
            onClick={handleClickCheckbox}
            data-checked={isChecked}
            key={`invoice-button-${item.id}`}
          />,
          item.num_doc,
          item.doc_key,
          new Date(item.date_issuance).toLocaleString(),
          formatValueToCurrencyInput(item.value_doc),
          item.instructions,
        ]);
      });
      setResults(arrayEventsEntry);
      const arrayPages = [];
      for (let i = 0; i < eventsEntry.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      hideLoading();
      addToast({
        title: 'Documentos carregados com sucesso',
        type: 'success',
      });
    },
    [
      showLoading,
      hideLoading,
      addToast,
      setResults,
      setPages,
      setCurrentPage,
      savedDocuments,
      optionsSearch,
      bindState,
    ],
  );

  const handleTransportersInputChange = (newValue: string): void => {
    if (newValue !== searchTransporters) {
      setIsLoadingTransporters(true);
    }
    setSearchTransporters(newValue);
  };

  useEffect(() => {
    (async () => {
      const transporters = await fetchTransporterByName({
        'transporter.name': valueSearchTransporters,
      });
      const allTransporters: any = [];
      transporters.forEach((transporter: any): any => {
        allTransporters.push({
          value: transporter.id,
          label: transporter.name,
        });
      });
      setOptionsTransporters(allTransporters);
      setIsLoadingTransporters(false);
    })();
  }, [valueSearchTransporters]);

  const handleTransportersModalInputChange = (newValue: string): void => {
    if (newValue !== searchTransportersModal) {
      setIsLoadingTransportersModal(true);
    }
    setSearchTransportersModal(newValue);
  };

  useEffect(() => {
    (async () => {
      const transporters = await fetchTransporterByName({
        'transporter.name': valueSearchTransportersModal,
      });
      const allTransporters: any = [];
      transporters.forEach((transporter: any): any => {
        allTransporters.push({
          value: transporter.id,
          label: transporter.name,
        });
      });
      setOptionsTransportersModal(allTransporters);
      setIsLoadingTransportersModal(false);
    })();
  }, [valueSearchTransportersModal]);

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback((data: Obj): void => {
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        if (key === 'transporter_documents-value_doc') {
          searchData[key.replace('-', '.')] = formatValueFromCurrencyInput(
            value,
          );
        } else {
          searchData[key.replace('-', '.')] = value;
        }
      }
    }
    setOptionsSearch(searchData);
  }, []);

  const handleRemoveSavedDocuments = useCallback(() => {
    const checks = document.querySelectorAll('button[data-checked="true"]');
    if (checks.length > 0) {
      for (let i = 0; i < checks.length; i++) {
        checks[i].setAttribute('data-checked', 'false');
      }
    }
    setSavedDocuments([]);
  }, [setSavedDocuments]);

  useEffect(() => {
    getData(1);
  }, [optionsSearch, bindState]);

  const handleSubmit = useCallback(
    async (data: FormData): Promise<void> => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          transporter_id: Yup.string().required('Selecione o transportador'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        showLoading();
        const promises = [];
        for (let i = 0; i < savedDocuments.length; i++) {
          promises.push(
            updateDocument(savedDocuments[i].id, data.transporter_id),
          );
        }
        await Promise.all(promises);
        addToast({
          title: 'Documentos vinculados com sucesso',
          type: 'success',
        });
        setSavedDocuments([]);
        hideLoading();
        getData(1);
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
      }
    },
    [savedDocuments, updateDocument, setSavedDocuments],
  );

  const handleSubmitUnlink = useCallback(
    async (data: FormData): Promise<void> => {
      try {
        (formBind.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({});

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        showLoading();
        const promises = [];
        for (let i = 0; i < savedDocuments.length; i++) {
          promises.push(
            unlinkDocument(savedDocuments[i].id, data.transporter_id),
          );
        }
        await Promise.all(promises);
        addToast({
          title: 'Documentos desvinculados com sucesso',
          type: 'success',
        });
        setSavedDocuments([]);
        hideLoading();
        getData(1);
      } catch (err) {
        const errors = getValidationErrors(err);
        (formBind.current as any).setErrors(errors);
      }
    },
    [savedDocuments, updateDocument, setSavedDocuments],
  );

  const handleSubmitStack = useCallback(
    async (data: { transporter_id: number }): Promise<void> => {
      try {
        (formStack.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          transporter_id: Yup.string().required('Selecione o transportador'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        showLoading();
        const response = await stackDocumentsTransporter({
          documents: stackDocs,
          transporter_id: data.transporter_id,
        });
        hideLoading();
        if (response.data.errors.length === 0) {
          addToast({
            title: 'Documentos vinculados com sucesso',
            type: 'success',
          });
          (formStack.current as any).reset();
          const select = formStack!.current!.getFieldRef('transporter_id');
          select.select.clearValue();
          setStackDocs([]);
          setShowModalStack(false);
        } else {
          addToast({
            title: 'Não foi possível vincular alguns documentos',
            type: 'error',
          });
          setStackDocs(response.data.errors);
        }
        await getData(currentPage);
      } catch (err) {
        const errors = getValidationErrors(err);
        (formStack.current as any).setErrors(errors);
      }
    },
    [
      stackDocs,
      setStackDocs,
      stackDocumentsTransporter,
      showLoading,
      getData,
      addToast,
      setShowModalStack,
    ],
  );

  const handleSubmitStackUnlink = useCallback(
    async (data: { transporter_id: number }): Promise<void> => {
      try {
        (formStackUnlink.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({});

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        showLoading();
        const response = await stackUnlinkDocumentsTransporter({
          documents: stackDocs,
        });
        hideLoading();
        if (response.data.errors.length === 0) {
          addToast({
            title: 'Documentos desvinculados com sucesso',
            type: 'success',
          });
          (formStackUnlink.current as any).reset();

          setStackDocs([]);
          setShowModalStack(false);
        } else {
          addToast({
            title: 'Não foi possível desvincular alguns documentos',
            type: 'error',
          });
          setStackDocs(response.data.errors);
        }
        await getData(currentPage);
      } catch (err) {
        const errors = getValidationErrors(err);
        (formStackUnlink.current as any).setErrors(errors);
      }
    },
    [
      stackDocs,
      setStackDocs,
      stackUnlinkDocumentsTransporter,
      showLoading,
      getData,
      addToast,
      setShowModalStack,
    ],
  );

  const handleClickStack = useCallback(() => {
    setShowModalStack(true);
  }, []);

  function handleClickDelete(code: string): void {
    const stateValues = stackDocs.filter(item => item !== code);
    setStackDocs(stateValues);
  }

  const handleKeyPressedCode = useCallback(
    event => {
      if (bindState === 'Vincular') {
        if (event.which === 13) {
          event.preventDefault();
          const codeValue: string = (formStack.current as any).getFieldValue(
            'code',
          );
          if (codeValue.replace(/\D+/g, '').length > 0) {
            setStackDocs(state => [...state, codeValue.replace(/\D+/g, '')]);
          }

          (formStack.current as any).clearField('code');
          (document.getElementById('code') as any).focus();
        }
      } else if (event.which === 13) {
        event.preventDefault();
        const codeValue: string = (formStackUnlink.current as any).getFieldValue(
          'code',
        );
        if (codeValue.replace(/\D+/g, '').length > 0) {
          setStackDocs(state => [...state, codeValue.replace(/\D+/g, '')]);
        }

        (formStackUnlink.current as any).clearField('code');
        (document.getElementById('code') as any).focus();
      }
    },
    [setStackDocs, stackDocs, bindState],
  );

  const BindSelected = useCallback(() => {
    setBindState('Vincular');
    console.log('Vincular');
  }, [bindState, setBindState]);

  const UnbindSelected = useCallback(() => {
    setBindState('Desvincular');
    console.log('Desvincular');
  }, [bindState, setBindState]);

  useEffect(() => {}, [bindState]);

  useEffect(() => {
    if (bindState === 'Vincular') {
      if (showModalStack === true) {
        (formStack.current as any).clearField('code');
        setTimeout(() => {
          const inputCode: HTMLInputElement = (formStack.current as any).getFieldRef(
            'code',
          );
          inputCode.focus();
        }, 500);
      }
    } else if (showModalStack === true) {
      (formStackUnlink.current as any).clearField('code');
      setTimeout(() => {
        const inputCode: HTMLInputElement = (formStackUnlink.current as any).getFieldRef(
          'code',
        );
        inputCode.focus();
      }, 500);
    }
  }, [showModalStack, bindState]);

  useEffect(() => {
    const data: Array<Array<any>> = [];
    stackDocs.forEach(item => {
      data.push([
        item,
        <div className="options-table-list">
          <button
            type="button"
            key={`item-${item}`}
            onClick={() => handleClickDelete(item)}
          >
            <FiTrash color="#ffffff" />
          </button>
        </div>,
      ]);
    });
    setStackDocsList(data);
  }, [stackDocs]);

  useEffect(() => {}, [stackDocsList]);

  return (
    <div>
      <PageContainer>
        <MainTitle>Vincular Documentos</MainTitle>

        <Row>
          <ContainerSize size="20%" />

          <ContainerSize size="30%">
            <BindOptionButton
              onClick={BindSelected}
              style={
                bindState === 'Vincular'
                  ? { backgroundColor: '#003CA6', color: '#fff' }
                  : {}
              }
              disabled={bindState === 'Vincular'}
            >
              Vincular
            </BindOptionButton>
          </ContainerSize>

          <ContainerSize size="30%">
            <BindOptionButton
              onClick={UnbindSelected}
              style={
                bindState === 'Vincular'
                  ? {}
                  : { backgroundColor: '#003CA6', color: '#fff' }
              }
              disabled={bindState !== 'Vincular'}
            >
              Desvincular
            </BindOptionButton>
          </ContainerSize>

          <ContainerSize size="20%" />
        </Row>

        {bindState === 'Vincular' ? (
          <>
            <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
              <h3>Selecione o transportador</h3>
              <Row>
                <ContainerSize size="70%">
                  <Autocomplete
                    name="transporter_id"
                    options={optionsTransporters}
                    placeholder="Transportador"
                    changeValue={null}
                    onInputChange={handleTransportersInputChange}
                    isLoading={isLoadingTransporters}
                  />
                </ContainerSize>
                <ContainerSize size="30%">
                  <ButtonForm type="submit">Vincular</ButtonForm>
                </ContainerSize>
              </Row>
            </Form>
            <InfoContainer>
              {savedDocuments.length === 0 && (
                <span>Nenhum documento selecionado</span>
              )}
              {savedDocuments.length > 0 && (
                <>
                  <span>
                    {savedDocuments.length} documento
                    {savedDocuments.length > 1
                      ? 's selecionados'
                      : ' selecionado'}
                  </span>
                  <button type="button" onClick={handleRemoveSavedDocuments}>
                    <FaTimesCircle /> remover
                  </button>
                </>
              )}
            </InfoContainer>
          </>
        ) : (
          <Form ref={formBind} onSubmit={handleSubmitUnlink}>
            <Row>
              <ContainerSize size="70%">
                <InfoContainer>
                  {savedDocuments.length === 0 && (
                    <span>Nenhum documento selecionado</span>
                  )}
                  {savedDocuments.length > 0 && (
                    <>
                      <span>
                        {savedDocuments.length} documento
                        {savedDocuments.length > 1
                          ? 's selecionados'
                          : ' selecionado'}
                      </span>
                      <button
                        type="button"
                        onClick={handleRemoveSavedDocuments}
                      >
                        <FaTimesCircle /> remover
                      </button>
                    </>
                  )}
                </InfoContainer>
              </ContainerSize>

              <ContainerSize size="30%">
                <ButtonForm
                  type="submit"
                  style={
                    savedDocuments.length === 0
                      ? { marginTop: '0', position: 'relative', top: '9.5px' }
                      : { marginTop: '0', position: 'relative', top: '19.5px' }
                  }
                >
                  Desvincular
                </ButtonForm>
              </ContainerSize>
            </Row>
          </Form>
        )}

        <Row>
          <ContainerSize size="100%">
            <TableList
              header={[
                '',
                'Nº Doc.',
                'Chave',
                'Emissão',
                'Valor',
                'Instruções',
              ]}
              data={results}
            />
            <Pagination
              pages={pages}
              currentPage={currentPage}
              onClickFunction={getData}
            />
          </ContainerSize>
        </Row>

        <Modal
          isOpen={showModalStack}
          title="Vincular/Desvincular documentos em lote"
          backgroundColor="#F8F8FB"
          fontColor="#202020"
          id="modal-stack"
          onClose={() => setShowModalStack(false)}
        >
          {bindState === 'Vincular' ? (
            <Form initialData={{}} ref={formStack} onSubmit={handleSubmitStack}>
              <Input
                name="code"
                onKeyPress={handleKeyPressedCode}
                placeholder="Chave"
              />
              {stackDocs.length > 0 && (
                <TableList header={['Chave', 'Remover']} data={stackDocsList} />
              )}
              <Autocomplete
                name="transporter_id"
                options={optionsTransportersModal}
                placeholder="Transportador"
                changeValue={null}
                onInputChange={handleTransportersModalInputChange}
                isLoading={isLoadingTransportersModal}
              />
              <ButtonForm type="submit" disabled={stackDocs.length === 0}>
                Vincular
              </ButtonForm>
            </Form>
          ) : (
            <Form
              initialData={{}}
              ref={formStackUnlink}
              onSubmit={handleSubmitStackUnlink}
            >
              <Input
                name="code"
                onKeyPress={handleKeyPressedCode}
                placeholder="Chave"
              />
              {stackDocs.length > 0 && (
                <TableList header={['Chave', 'Remover']} data={stackDocsList} />
              )}

              <ButtonForm type="submit" disabled={stackDocs.length === 0}>
                Desvincular
              </ButtonForm>
            </Form>
          )}
        </Modal>

        <FloatButton type="button" onClick={() => handleClickStack()}>
          <AiOutlineBarcode size={25} />
        </FloatButton>
        <Filter onSubmit={handleSubmitForm}>
          <Input
            name="transporter_documents-date_issuance>"
            type="date"
            placeholder="Data Emissão Inicial"
          />
          <Input
            name="transporter_documents-date_issuance<"
            type="date"
            placeholder="Data Emissão Final"
          />
          <Input
            name="transporter_documents-num_doc"
            type="text"
            placeholder="Nº Doc."
          />
          <Input
            name="transporter_documents-doc_key"
            type="text"
            placeholder="Chave"
          />
          <CurrencyInput
            name="transporter_documents-value_doc"
            placeholder="Valor Doc"
          />
        </Filter>
      </PageContainer>
    </div>
  );
};

export default LinkTransporterDocuments;
