/* eslint-disable react/jsx-curly-newline */
import React, { useRef, useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';
import { FiSearch, FiAlertCircle } from 'react-icons/fi';
import { AiFillInfoCircle } from 'react-icons/ai';
import InputMaskTracking from '../../components/InputMaskTracking';
import InputTracking from '../../components/InputTracking';
import ButtonForm from '../../components/ButtonTracking';
import {
  Row,
  TrackingMainContainer,
  FormDiv,
  AlertSpan,
  TitleTrackingSpan,
  CardListContainer,
  WarningDiv,
  WarningSpan,
} from './styles';
import { getTrackingsByDocumentNumber } from '../../features/merchandiseTracking';
import MainTitle from '../../components/MainTitle';
import TrackingCard from './TrackingInfoCard';
import MerchandiseTracking from './TrackingByStatus';
import getValidationErrors from '../../utils/getValidationErrors';

const TrackingPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [documentMask, setDocumentMask] = useState('999.999.999-99');
  const [searchedTrackings, setSearchedTrackings] = useState<any[]>([]);
  const [codeTrack, setCodeTrack] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [afterSearch, setAfterSearch] = useState(false);

  const handleSubmit = useCallback(
    async (data: any): Promise<void | false> => {
      let isErrored = false;

      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          nf_number: Yup.string().required('Número da nota é necessário'),
          document_number: Yup.string()
            .required('Documento necessário para realizar a busca')
            .test(
              'is-cnpj',
              documentMask === '999.999.999-99' ||
                documentMask === '999.999.999-99?'
                ? 'CPF inválido'
                : 'CNPJ inválido',
              value =>
                documentMask === '999.999.999-99' ||
                documentMask === '999.999.999-99?'
                  ? validateCPF(value as string)
                  : validateCNPJ(value as string),
            ),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
        isErrored = true;
      }

      if (isErrored === false) {
        setAfterSearch(true);
        setIsLoading(true);
        try {
          const trackings = await getTrackingsByDocumentNumber(
            data.document_number.replace('/', ''),
            data.nf_number,
          );
          setCodeTrack('');
          setSearchedTrackings(trackings.data);
        } catch (err) {
          setSearchedTrackings([]);
        }
        setIsLoading(false);
      }
    },
    [setSearchedTrackings, setIsLoading, documentMask],
  );

  const handlerChangeDocument = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const documentNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (documentNumber.length <= 11) {
        setDocumentMask('999.999.999-99?');
      } else {
        setDocumentMask('99.999.999/9999-99');
      }
    },
    [setDocumentMask],
  );

  return (
    <TrackingMainContainer>
      <MainTitle>Consultar Mercadorias</MainTitle>
      <FormDiv>
        <AlertSpan>
          <FiAlertCircle style={{ marginRight: '10px' }} />
          Digite o número do seu CPF ou CNPJ para verificar os status da sua
          entrega
        </AlertSpan>
        <Form
          ref={formRef}
          initialData={{}}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="new-password"
        >
          <Row>
            <InputMaskTracking
              mask={documentMask}
              name="document_number"
              type="text"
              placeholder="CPF/CNPJ Destinatário"
              maskChar=""
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              onKeyUp={handlerChangeDocument}
              disabled={isLoading}
              onPaste={event =>
                event.clipboardData.items[0].getAsString(text => {
                  const documentNumber = text.replace(/[^0-9]/g, '');
                  if (documentNumber.length === 11) {
                    setDocumentMask('999.999.999-99?');
                    (formRef.current as any).setFieldValue(
                      'document_number',
                      documentNumber.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                        '$1.$2.$3-$4',
                      ),
                    );
                  } else if (documentNumber.length === 14) {
                    setDocumentMask('99.999.999/9999-99');
                    (formRef.current as any).setFieldValue(
                      'document_number',
                      documentNumber.replace(
                        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        '$1.$2.$3/$4-$5',
                      ),
                    );
                  }
                })
              }
            />
            <div style={{ marginRight: 10 }} />
            <InputTracking
              name="nf_number"
              type="text"
              placeholder="Número da Nota"
            />
            <ButtonForm type="submit" isLoading={isLoading}>
              <FiSearch size={20} />
              Pesquisar
            </ButtonForm>
          </Row>
        </Form>
      </FormDiv>
      {afterSearch && isLoading === false ? (
        <>
          {codeTrack !== '' ? (
            <>
              <CardListContainer>
                <TitleTrackingSpan>
                  Detalhes da entrega: {codeTrack}
                </TitleTrackingSpan>
              </CardListContainer>
              <CardListContainer>
                <MerchandiseTracking code={codeTrack} setCode={setCodeTrack} />
              </CardListContainer>
            </>
          ) : searchedTrackings.length > 0 ? (
            <>
              <CardListContainer>
                <TitleTrackingSpan>Entregas:</TitleTrackingSpan>
              </CardListContainer>
              <CardListContainer>
                {searchedTrackings.map((item: any) => (
                  <TrackingCard trackInfo={item} setCodeTrack={setCodeTrack} />
                ))}
              </CardListContainer>
            </>
          ) : (
            <WarningDiv>
              <AiFillInfoCircle
                size={50}
                style={{ fill: '#003ca6', alignSelf: 'center' }}
              />
              <WarningSpan>Nenhuma Entrega encontrada</WarningSpan>
            </WarningDiv>
          )}
        </>
      ) : (
        <></>
      )}
    </TrackingMainContainer>
  );
};

export default TrackingPage;
