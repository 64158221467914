/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { RiFileEditFill } from 'react-icons/ri';
import { FaTruck, FaEdit } from 'react-icons/fa';
import { IoMdArrowBack, IoIosAddCircle } from 'react-icons/io';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';

import {
  formatValueFromCurrencyInput,
  formatValueToCurrencyInput,
} from '../../utils/formatValueCurrencyInput';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  getFreightTable,
  freightTableSlice,
} from '../../features/freightTable';

import {
  freightTableTariffSlice,
  getFreightTableTariff,
  addNewFreightTableTariff,
  updateFreightTableTariff,
  aproveFreightTableTariff,
} from '../../features/freightTableTariff';

import {
  getFreightTableItem,
  getFreightTableItemByTariff,
  addNewFreightTableItem,
  updateFreightTableItem,
  setFreightTableItemId,
  aproveFreightTable,
} from '../../features/freightTableItens';

import { fetchRegionByName } from '../../features/regions';

import { fetchCitiesByName } from '../../features/city';

import { fetchTollTypeByName, tollTypeData } from '../../features/tollType';

import { fetchTrackTypeByName, trackTypeData } from '../../features/trackType';

import PageContainer from '../../components/PageContainer';

import MainTitle from '../../components/MainTitle';
import CurrencyInput from '../../components/CurrencyInput';
import ButtonForm from '../../components/ButtonForm';
import Autocomplete from '../../components/Autocomplete';
import Modal from '../../components/Modal';

import { Row, ContainerSize, ContainerTitle } from './styles';
import Input from '../../components/Input';
import TableList from '../../components/TableList';
import Pagination from '../../components/Pagination';

interface FreightTableParams {
  id: string | undefined;
  tariff?: string | undefined;
}

const FreightTableTariff: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formItemRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isRegion, setIsRegion] = useState<boolean>();
  const [searchRegionsOrigin, setSearchRegionsOrigin] = useState('');
  const [valueSearchRegionsOrigin] = useDebounce(searchRegionsOrigin, 1000);
  const [searchRegionsDestination, setSearchRegionsDestination] = useState('');
  const [valueSearchRegionsDestination] = useDebounce(
    searchRegionsDestination,
    1000,
  );
  const [searchCityOrigin, setSearchCityOrigin] = useState('');
  const [valueSearchCityOrigin] = useDebounce(searchCityOrigin, 1000);
  const [searchCityDestination, setSearchCityDestination] = useState('');
  const [valueSearchCityDestination] = useDebounce(searchCityDestination, 1000);
  const [searchTollType, setSearchTollType] = useState('');
  const [valueSearchTollType] = useDebounce(searchTollType, 1000);
  const [searchTrackType, setSearchTrackType] = useState('');
  const [valueSearchTrackType] = useDebounce(searchTrackType, 1000);
  const [optionsRegionsOrigin, setOptionsRegionsOrigin] = useState([]);
  const [optionsRegionsDestination, setOptionsRegionsDestination] = useState(
    [],
  );
  const [optionsCityOrigin, setOptionsCityOrigin] = useState([]);
  const [optionsCityDestination, setOptionsCityDestination] = useState([]);
  const [optionsTollType, setOptionsTollType] = useState([]);
  const [optionsTrackType, setOptionsTrackType] = useState([]);
  const [isLoadingRegionsOrigin, setIsLoadingRegionsOrigin] = useState(false);
  const [
    isLoadingRegionsDestination,
    setIsLoadingRegionsDestination,
  ] = useState(false);
  const [isLoadingCityOrigin, setIsLoadingCityOrigin] = useState(false);
  const [isLoadingCityDestination, setIsLoadingCityDestination] = useState(
    false,
  );
  const [isLoadingTollType, setIsLoadingTollType] = useState(false);
  const [isLoadingTrackType, setIsLoadingTrackType] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState('Cadastrar');
  const [buttonTextItem, setButtonTextItem] = useState('Cadastrar');
  const [status, setStatus] = useState('A');
  const [statusItem, setStatusItem] = useState('A');
  const [itensTariff, setItensTariff] = useState<any>([]);
  const [pages, setPages] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { setFreightTableId } = freightTableSlice.actions;
  const { setFreightTableTariffId } = freightTableTariffSlice.actions;

  const { freightTableId, userType } = useSelector((state: RootStateOrAny) => ({
    freightTableId: state.features.freightTable.freightTableId,
    userType: state.features.auth.type,
  }));

  const { freightTableTariffId } = useSelector((state: RootStateOrAny) => ({
    freightTableTariffId:
      state.features.freightTableTariff.freightTableTariffId,
  }));

  const { freightTableItemId } = useSelector((state: RootStateOrAny) => ({
    freightTableItemId: state.features.freightTableItens.freightTableItemId,
  }));

  const openModal = useCallback((): void => {
    (formItemRef.current as any).reset();
    dispatch(setFreightTableItemId(null));
    setShowModal(true);
  }, []);

  const handleClickUpdateItem = useCallback(async (id: number): Promise<
    void
  > => {
    showLoading();
    openModal();
    const { data: responseItem } = await getFreightTableItem(id);
    (formItemRef.current as any).setFieldValue(
      'starting',
      responseItem.starting,
    );
    (formItemRef.current as any).setFieldValue(
      'finishing',
      responseItem.finishing,
    );
    (formItemRef.current as any).setFieldValue(
      'value',
      formatValueToCurrencyInput(responseItem.value as number),
    );
    (formItemRef.current as any).setFieldValue('track_type_id', {
      value: responseItem.trackType?.id,
      label: responseItem.trackType?.name,
    });
    setStatusItem(responseItem.status);
    dispatch(setFreightTableItemId(responseItem.id));
    hideLoading();
  }, []);

  const getItensTariff = useCallback(
    async (page = 1) => {
      showLoading();
      const getItens = await getFreightTableItemByTariff(
        page,
        freightTableTariffId,
      );
      const arrayPages = [];
      for (let i = 0; i < getItens.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      const itensTariffData: Array<object> = [];
      getItens.data.data.forEach(item => {
        itensTariffData.push([
          item.starting,
          item.finishing,
          formatValueToCurrencyInput(item.value),
          item.trackType?.name,
          <div className="options-table-list">
            <button
              type="button"
              onClick={() => handleClickUpdateItem(Number(item.id))}
            >
              <FaEdit size={20} color="#ffffff" />
            </button>
          </div>,
        ]);
      });
      setItensTariff(itensTariffData);
      hideLoading();
    },
    [freightTableTariffId],
  );

  const handleRegionsOriginInputChange = useCallback(
    (newValue: string): void => {
      if (newValue !== searchRegionsOrigin) {
        setIsLoadingRegionsOrigin(true);
      }
      setSearchRegionsOrigin(newValue);
    },
    [searchRegionsOrigin],
  );

  useEffect(() => {
    (async () => {
      const regions = await fetchRegionByName(valueSearchRegionsOrigin);
      const allregions: any = [];
      regions.forEach((region: any): any => {
        allregions.push({ value: region.id, label: region.name });
      });
      setOptionsRegionsOrigin(allregions);
      setIsLoadingRegionsOrigin(false);
    })();
  }, [valueSearchRegionsOrigin]);

  const handleRegionsDestinationInputChange = useCallback(
    (newValue: string): void => {
      if (newValue !== searchRegionsDestination) {
        setIsLoadingRegionsDestination(true);
      }
      setSearchRegionsDestination(newValue);
    },
    [searchRegionsDestination],
  );

  useEffect(() => {
    (async () => {
      const regions = await fetchRegionByName(valueSearchRegionsDestination);
      const allregions: any = [];
      regions.forEach((region: any): any => {
        allregions.push({ value: region.id, label: region.name });
      });
      setOptionsRegionsDestination(allregions);
      setIsLoadingRegionsDestination(false);
    })();
  }, [valueSearchRegionsDestination]);

  const handleCityOriginInputChange = useCallback(
    (newValue: string): void => {
      if (newValue !== searchCityOrigin) {
        setIsLoadingCityOrigin(true);
      }
      setSearchCityOrigin(newValue);
    },
    [searchCityOrigin],
  );

  useEffect(() => {
    (async () => {
      const cities = await fetchCitiesByName({
        name: valueSearchCityDestination,
      });
      const allcities: any = [];
      cities.forEach((city: any): any => {
        allcities.push({ value: city.id, label: `${city.name} - ${city.uf}` });
      });
      setOptionsCityDestination(allcities);
      setIsLoadingCityDestination(false);
    })();
  }, [valueSearchCityDestination]);

  const handleCityDestinationInputChange = useCallback(
    (newValue: string): void => {
      if (newValue !== searchCityDestination) {
        setIsLoadingCityDestination(true);
      }
      setSearchCityDestination(newValue);
    },
    [searchCityDestination],
  );

  useEffect(() => {
    (async () => {
      const cities = await fetchCitiesByName({
        name: valueSearchCityOrigin,
      });
      const allcities: any = [];
      cities.forEach((city: any): any => {
        allcities.push({ value: city.id, label: `${city.name} - ${city.uf}` });
      });
      setOptionsCityOrigin(allcities);
      setIsLoadingCityOrigin(false);
    })();
  }, [valueSearchCityOrigin]);

  const handleTollTypeInputChange = useCallback(
    (newValue: string): void => {
      if (newValue !== searchTollType) {
        setIsLoadingTollType(true);
      }
      setSearchTollType(newValue);
    },
    [searchTollType],
  );

  useEffect(() => {
    (async () => {
      const tollTypes = await fetchTollTypeByName(valueSearchTollType);
      const allTollTypes: any = [];
      tollTypes.forEach((tollType: tollTypeData): void => {
        allTollTypes.push({
          value: tollType.id,
          label: tollType.name,
        });
      });
      setOptionsTollType(allTollTypes);
      setIsLoadingTollType(false);
    })();
  }, [valueSearchTollType]);

  const handleTrackTypeInputChange = useCallback(
    (newValue: string): void => {
      if (newValue !== searchTrackType) {
        setIsLoadingTrackType(true);
      }
      setSearchTrackType(newValue);
    },
    [searchTrackType],
  );

  useEffect(() => {
    (async () => {
      const trackTypes = await fetchTrackTypeByName(valueSearchTrackType);
      const allTrackTypes: any = [];
      trackTypes.forEach((trackType: trackTypeData): void => {
        allTrackTypes.push({
          value: trackType.id,
          label: trackType.name,
        });
      });
      setOptionsTrackType(allTrackTypes);
      setIsLoadingTrackType(false);
    })();
  }, [valueSearchTrackType]);

  const handleSubmit = useCallback(
    async (data: any): Promise<any> => {
      data.advalorem = formatValueFromCurrencyInput(data.advalorem);
      data.gris = formatValueFromCurrencyInput(data.gris);
      data.load_value = formatValueFromCurrencyInput(data.load_value);
      data.discharge_value = formatValueFromCurrencyInput(data.discharge_value);
      data.collect_value = formatValueFromCurrencyInput(data.collect_value);
      data.delivery_value = formatValueFromCurrencyInput(data.delivery_value);
      data.dispatch_value = formatValueFromCurrencyInput(data.dispatch_value);
      data.toll_value = formatValueFromCurrencyInput(data.toll_value);
      data.min_value = formatValueFromCurrencyInput(data.min_value);
      data.gris_min = formatValueFromCurrencyInput(data.gris_min);
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          region_origin_id:
            isRegion === true
              ? Yup.string().required('Informe a região de origem')
              : Yup.string().notRequired(),
          region_destination_id:
            isRegion === true
              ? Yup.string().required('Informe a região de destino')
              : Yup.string().notRequired(),
          origin_id:
            isRegion === false
              ? Yup.string().required('Informe a cidade de origem')
              : Yup.string().notRequired(),
          destination_id:
            isRegion === false
              ? Yup.string().required('Informe a cidade de destino')
              : Yup.string().notRequired(),
          advalorem: Yup.number().required('Informe um valor'),
          gris: Yup.number().required('Informe um valor'),
          load_value: Yup.number().required('Informe um valor'),
          discharge_value: Yup.number().required('Informe um valor'),
          collect_value: Yup.number().required('Informe um valor'),
          delivery_value: Yup.number().required('Informe um valor'),
          dispatch_value: Yup.number().required('Informe um valor'),
          toll_type_id: Yup.number().required('Selecione o tipo de pedágio'),
          toll_value: Yup.number().required('Informe o pedágio'),
          min_value: Yup.number().required('Informe um valor'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
        return false;
      }
      showLoading();
      if (freightTableTariffId === 0) {
        const register = await addNewFreightTableTariff({
          freight_table_id: freightTableId,
          ...data,
        });

        if (register.status === 200) {
          dispatch(setFreightTableTariffId(register.data.id));
          addToast({
            title: 'Sucesso',
            description: 'Tarifa da tabela de frete cadastrada com sucesso.',
            type: 'success',
          });
          setStatus(register.data.status);
        } else {
          addToast({
            title: 'Erro',
            description: 'Erro ao cadastrar a tarifa da tabela de frete.',
            type: 'error',
          });
        }
      } else {
        const update = await updateFreightTableTariff(
          freightTableTariffId as number,
          data,
        );

        if (update.status === 200) {
          addToast({
            title: 'Sucesso',
            description: 'Tarifa da tabela de frete alterada com sucesso.',
            type: 'success',
          });
          setStatus(update.data.status);
        } else {
          addToast({
            title: 'Erro',
            description: 'Erro ao alterar a tarifa da tabela de frete.',
            type: 'error',
          });
        }
      }
      hideLoading();
      return true;
    },
    [
      addToast,
      freightTableId,
      freightTableTariffId,
      dispatch,
      hideLoading,
      showLoading,
    ],
  );

  const handleSubmitItem = useCallback(
    async (data: any): Promise<any> => {
      data.value = formatValueFromCurrencyInput(data.value);
      try {
        (formItemRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          starting: Yup.string().nullable().required('Informe a faixa inicial'),
          finishing: Yup.string().nullable().required('Informe a faixa final'),
          track_type_id: Yup.string()
            .nullable()
            .required('Selecione o tipo de faixa'),
          value: Yup.number().required('Informe um valor'),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        const errors = getValidationErrors(err);
        (formItemRef.current as any).setErrors(errors);
        return false;
      }
      showLoading();
      if (!freightTableItemId) {
        const registerItem = await addNewFreightTableItem({
          freight_table_tariff_id: freightTableTariffId,
          ...data,
        });
        if (registerItem.status === 200) {
          dispatch(setFreightTableItemId(registerItem.data.id));
          addToast({
            title: 'Sucesso',
            description: 'Item da Tarifa cadastrado com sucesso.',
            type: 'success',
          });
        } else {
          addToast({
            title: 'Erro',
            description: 'Erro ao cadastrar o item da tarifa.',
            type: 'error',
          });
        }
      } else {
        const updateItem = await updateFreightTableItem(freightTableItemId, {
          freight_table_tariff_id: freightTableTariffId,
          ...data,
        });
        if (updateItem.status === 200) {
          addToast({
            title: 'Sucesso',
            description: 'Item da Tarifa atualizado com sucesso.',
            type: 'success',
          });
        } else {
          addToast({
            title: 'Erro',
            description: 'Erro ao atualizar o item da tarifa.',
            type: 'error',
          });
        }
      }
      hideLoading();
      await getItensTariff(currentPage);
      return true;
    },
    [
      addToast,
      freightTableItemId,
      freightTableTariffId,
      getItensTariff,
      currentPage,
      dispatch,
      hideLoading,
      showLoading,
    ],
  );

  useEffect(() => {
    if (freightTableTariffId) {
      setButtonText('Alterar');
    }
  }, [freightTableTariffId]);

  useEffect(() => {
    if (freightTableItemId) {
      setButtonTextItem('Alterar');
    } else {
      setButtonTextItem('Cadastrar');
    }
  }, [freightTableItemId]);

  const { params } = useRouteMatch<FreightTableParams>();

  useEffect(() => {
    if (params.id) {
      (async (): Promise<void> => {
        showLoading();
        dispatch(setFreightTableId(Number(params.id)));
        const getFreight = await getFreightTable(Number(params.id));
        if (!getFreight.data) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Tabela de frete não encontrada',
          });
          history.push('/');
        }
        setIsRegion(getFreight.data.is_region as boolean);
        if (params.tariff) {
          const getFreightTariff = await getFreightTableTariff(
            Number(params.tariff),
          );
          if (!getFreightTariff.data) {
            addToast({
              type: 'error',
              title: 'Erro',
              description: 'Tarifa não encontrada',
            });
            history.push('/');
          }
          const { data: tariff } = getFreightTariff;
          if (tariff.freight_table_id !== getFreight.data.id) {
            addToast({
              type: 'error',
              title: 'Erro',
              description: 'Tarifa não encontrada',
            });
            history.push('/');
          }
          dispatch(setFreightTableTariffId(tariff.id));
          if (getFreight.data.is_region === true) {
            (formRef.current as any).setFieldValue('region_origin_id', {
              value: (tariff.region_origin as any).id,
              label: (tariff.region_origin as any).name,
            });
            (formRef.current as any).setFieldValue('region_destination_id', {
              value: (tariff.region_destination as any).id,
              label: (tariff.region_destination as any).name,
            });
          } else {
            (formRef.current as any).setFieldValue('origin_id', {
              value: (tariff.origin as any).id,
              label: (tariff.origin as any).name,
            });
            (formRef.current as any).setFieldValue('destination_id', {
              value: (tariff.destination as any).id,
              label: (tariff.destination as any).name,
            });
          }
          (formRef.current as any).setFieldValue('toll_type_id', {
            value: (tariff.tollType as any).id,
            label: (tariff.tollType as any).name,
          });
          (formRef.current as any).setFieldValue(
            'advalorem',
            formatValueToCurrencyInput(tariff.advalorem as number),
          );
          (formRef.current as any).setFieldValue(
            'gris',
            formatValueToCurrencyInput(tariff.gris as number),
          );
          (formRef.current as any).setFieldValue(
            'gris_min',
            formatValueToCurrencyInput(tariff.gris_min as number),
          );
          (formRef.current as any).setFieldValue(
            'load_value',
            formatValueToCurrencyInput(tariff.load_value as number),
          );
          (formRef.current as any).setFieldValue(
            'discharge_value',
            formatValueToCurrencyInput(tariff.discharge_value as number),
          );
          (formRef.current as any).setFieldValue(
            'collect_value',
            formatValueToCurrencyInput(tariff.collect_value as number),
          );
          (formRef.current as any).setFieldValue(
            'delivery_value',
            formatValueToCurrencyInput(tariff.delivery_value as number),
          );
          (formRef.current as any).setFieldValue(
            'dispatch_value',
            formatValueToCurrencyInput(tariff.dispatch_value as number),
          );
          (formRef.current as any).setFieldValue(
            'toll_value',
            formatValueToCurrencyInput(tariff.toll_value as number),
          );
          (formRef.current as any).setFieldValue(
            'min_value',
            formatValueToCurrencyInput(tariff.min_value as number),
          );
          setStatus(tariff.status);
        }
        hideLoading();
      })();
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Tabela de frete não encontrada',
      });
      history.push('/');
    }
  }, [
    dispatch,
    hideLoading,
    params.id,
    setFreightTableId,
    setFreightTableTariffId,
    showLoading,
  ]);

  const headerList = [
    'Faixa Inicial',
    'Faixa Final',
    'Valor',
    'Tipo de faixa',
    'Opções',
  ];

  useEffect(() => {
    if (freightTableTariffId > 0) {
      getItensTariff(1);
    }
  }, [freightTableTariffId, getItensTariff]);

  const handleAprove = useCallback(async (): Promise<void> => {
    const aproved = await aproveFreightTableTariff(freightTableTariffId);
    if (aproved.data) {
      setStatus(status === 'IG' ? 'AG' : 'UG');
    }
  }, [setStatus, status]);

  const handleAproveItem = useCallback(async (): Promise<void> => {
    const aproved = await aproveFreightTable(freightTableTariffId);
    if (aproved.data) {
      setStatusItem(status === 'IG' ? 'AG' : 'UG');
    }
  }, [setStatusItem, statusItem]);

  return (
    <PageContainer>
      <MainTitle>
        <FaTruck size={25} /> Tabela de fretes
      </MainTitle>
      <h3>Tarifa:</h3>
      <Form
        ref={formRef}
        initialData={{}}
        onSubmit={handleSubmit}
        noValidate
        autoComplete="new-password"
      >
        <Row>
          <ContainerSize size="50%">
            {isRegion === true && (
              <>
                <Autocomplete
                  name="region_origin_id"
                  options={optionsRegionsOrigin}
                  placeholder="Região de origem"
                  changeValue={null}
                  onInputChange={handleRegionsOriginInputChange}
                  isLoading={isLoadingRegionsOrigin}
                />
                <Autocomplete
                  name="region_destination_id"
                  options={optionsRegionsDestination}
                  placeholder="Região de destino"
                  changeValue={null}
                  onInputChange={handleRegionsDestinationInputChange}
                  isLoading={isLoadingRegionsDestination}
                />
              </>
            )}
            {isRegion === false && (
              <>
                <Autocomplete
                  name="origin_id"
                  options={optionsCityOrigin}
                  placeholder="Cidade de origem"
                  changeValue={null}
                  onInputChange={handleCityOriginInputChange}
                  isLoading={isLoadingCityOrigin}
                />
                <Autocomplete
                  name="destination_id"
                  options={optionsCityDestination}
                  placeholder="Cidade de destino"
                  changeValue={null}
                  onInputChange={handleCityDestinationInputChange}
                  isLoading={isLoadingCityDestination}
                />
              </>
            )}
            <Row>
              <ContainerSize size="33.3333%">
                <CurrencyInput name="advalorem" placeholder="% Advalorem" />
              </ContainerSize>
              <ContainerSize size="33.333%">
                <CurrencyInput name="gris" placeholder="% GRIS" />
              </ContainerSize>
              <ContainerSize size="33.333%">
                <CurrencyInput
                  name="gris_min"
                  placeholder="Valor Gris Mínimo"
                />
              </ContainerSize>
              <ContainerSize size="50%">
                <CurrencyInput name="load_value" placeholder="Carga" />
              </ContainerSize>
              <ContainerSize size="50%">
                <CurrencyInput name="discharge_value" placeholder="Descarga" />
              </ContainerSize>
            </Row>
          </ContainerSize>
          <ContainerSize size="50%">
            <Row>
              <ContainerSize size="50%">
                <CurrencyInput name="collect_value" placeholder="Coleta" />
              </ContainerSize>
              <ContainerSize size="50%">
                <CurrencyInput name="delivery_value" placeholder="Entrega" />
              </ContainerSize>
            </Row>
            <CurrencyInput name="dispatch_value" placeholder="Despacho" />
            <Autocomplete
              name="toll_type_id"
              options={optionsTollType}
              placeholder="Tipo de Pedágio"
              changeValue={null}
              onInputChange={handleTollTypeInputChange}
              isLoading={isLoadingTollType}
            />
            <CurrencyInput name="toll_value" placeholder="Valor do Pedágio" />
            <CurrencyInput name="min_value" placeholder="Frete Mínimo" />
          </ContainerSize>
        </Row>
        <Row>
          <ContainerSize size="30%" />
          <ContainerSize size="40%">
            <ButtonForm type="button" onClick={() => history.goBack()}>
              <IoMdArrowBack size={20} />
              Voltar
            </ButtonForm>
          </ContainerSize>
        </Row>
        {status === 'I' || status === 'A' || status === 'U' ? (
          <Row>
            <ContainerSize size="30%" />
            <ContainerSize size="40%">
              <ButtonForm type="submit">
                <RiFileEditFill size={20} />
                {buttonText}
              </ButtonForm>
            </ContainerSize>
          </Row>
        ) : (
          <></>
        )}
        {(status === 'IG' || status === 'IUG') && userType === 'admin' ? (
          <Row>
            <ContainerSize size="30%" />
            <ContainerSize size="40%">
              <ButtonForm onClick={handleAprove}>
                <RiFileEditFill size={20} />
                Aprovar
              </ButtonForm>
            </ContainerSize>
          </Row>
        ) : (
          <></>
        )}
      </Form>
      {freightTableTariffId > 0 && (
        <>
          <hr className="hr" />
          <ContainerTitle>
            <h3>Lista de itens da tarifa:</h3>
            <button type="button" onClick={openModal}>
              <IoIosAddCircle size={20} color="#ffffff" />
              Adicionar item
            </button>
          </ContainerTitle>
          {!itensTariff ? (
            <h4 className="text-center">
              Nenhum item cadastrado nessa tarifa da tabela de frete
            </h4>
          ) : (
            <>
              <TableList header={headerList} data={itensTariff} />

              <Pagination
                pages={pages}
                currentPage={currentPage}
                onClickFunction={getItensTariff}
              />
            </>
          )}
          <Modal
            backgroundColor="#ffffff"
            fontColor="#666666"
            isOpen={showModal}
            title="Item da tarifa"
            id="modal-tariff"
            onClose={() => setShowModal(false)}
          >
            <Form
              ref={formItemRef}
              initialData={{}}
              onSubmit={handleSubmitItem}
            >
              <Row>
                <ContainerSize size="50%">
                  <Input name="starting" placeholder="Faixa Inicial" />
                </ContainerSize>
                <ContainerSize size="50%">
                  <Input name="finishing" placeholder="Faixa Final" />
                </ContainerSize>
                <ContainerSize size="50%">
                  <CurrencyInput name="value" placeholder="Valor" />
                </ContainerSize>
                <ContainerSize size="50%">
                  <Autocomplete
                    name="track_type_id"
                    options={optionsTrackType}
                    placeholder="Tipo de Faixa"
                    changeValue={null}
                    onInputChange={handleTrackTypeInputChange}
                    isLoading={isLoadingTrackType}
                  />
                </ContainerSize>
              </Row>
              {statusItem === 'I' ||
              statusItem === 'A' ||
              statusItem === 'U' ? (
                <Row>
                  <ContainerSize size="30%" />
                  <ContainerSize size="40%">
                    <ButtonForm type="submit">
                      <RiFileEditFill size={20} />
                      {buttonTextItem}
                    </ButtonForm>
                  </ContainerSize>
                </Row>
              ) : (
                <></>
              )}
              {(statusItem === 'IG' || statusItem === 'IUG') &&
              userType === 'admin' ? (
                <Row>
                  <ContainerSize size="30%" />
                  <ContainerSize size="40%">
                    <ButtonForm onClick={handleAproveItem}>
                      <RiFileEditFill size={20} />
                      Aprovar
                    </ButtonForm>
                  </ContainerSize>
                </Row>
              ) : (
                <></>
              )}
            </Form>
          </Modal>
        </>
      )}
    </PageContainer>
  );
};

export default FreightTableTariff;
