import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: block;
  position: relative;

  label {
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    left: 17px;
    top: 40px;
    margin-bottom: auto;
    transition: all 150ms ease-in;
    color: #666360;
    z-index: 3;

    ${props =>
      (props.isFocused || props.isFilled) &&
      css`
        transform: translateY(-23px);
        font-size: 0.95em;
        color: #3a3a3a;
      `}
  }
`;

export const ContainerError = styled.div`
  margin-top: 5px;
  width: 100%;
  text-align: left;
  color: #c53030;
`;
