/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { BiShowAlt, BiHide } from 'react-icons/bi';
import { GrDocumentUpdate } from 'react-icons/gr';
import { FiTrash2 } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FaEdit, FaQuestionCircle, FaPlusCircle } from 'react-icons/fa';
import * as xml from 'xml2js';
import { uuid } from 'uuidv4';
import { AppDispatch } from '../../../app/store';
import Pagination from '../../../components/Pagination';
import Modal from '../../../components/Modal';

import {
  ContainerOptions,
  ContainerSize,
  Row,
  NewButton,
  AddXmlButton,
} from './styles';
import Input from '../../../components/Input';
import TableList from '../../../components/TableList';

import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  setWeightValue,
  setVolumeValue,
  setQuantityValue,
  setValueCollection,
  setNfeValue,
  setMetersCubic,
} from '../../../features/gatheringOrder';
import {
  addGatheringOrderItem,
  updateGatheringOrderItem,
  getCollectOrderItemById,
  getCollectOrderItemByIdParam,
  deleteGatheringOrderItem,
  gatheringOrderItemsSlice,
  setItensQuantity,
} from '../../../features/gatheringOrderItems';

import AddImg from '../../../assets/AddUser.png';
import XmlImg from '../../../assets/xml-icon.png';
import { useDialog } from '../../../hooks/dialog';
import ReadOnlyInput from '../../../components/ReadOnlyInput';
import {
  formatValueToCurrencyInput,
  formatValueFromCurrencyInput,
} from '../../../utils/formatValueCurrencyInput';
import CurrencyInput from '../../../components/CurrencyInput';

interface FormInterface {
  [key: string]: string;
}
interface ItensProp {
  gatheringOrderId: number;
  cubageFactor: number;
  statusGatheringOrder: string;
  quotationIdCollect: number;
}

const GatheringOrderItems: React.FC<ItensProp> = ({
  gatheringOrderId,
  cubageFactor,
  statusGatheringOrder,
  quotationIdCollect,
}) => {
  const formRef = useRef<FormHandles>(null);
  const listRef = useRef<HTMLButtonElement>(null);
  const hiddenFileInput = useRef(null);
  const dispatch: AppDispatch = useDispatch();
  const { addToast } = useToast();
  const { createDialog } = useDialog();
  const { setItens } = gatheringOrderItemsSlice.actions;

  const [xmlFile, setXmlFile] = useState<any>();
  const [results, setResults] = useState<Array<any>>([]);
  const [gatheringOrderItemId, setGatheringOrderItemId] = useState(0);
  const [weigthCalculated, setWeightCalculated] = useState<number>(0);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [showItens, setShowItens] = useState(false);
  const [totalItens, setTotalItens] = useState(0);
  const [idItemRedux, setIdItemRedux] = useState('');

  const { itensCollect, itensQuantity } = useSelector(
    (state: RootStateOrAny) => ({
      itensCollect: state.features.gatheringOrderItems.itensCollect,
      itensQuantity: state.features.gatheringOrderItems.itensQuantity,
    }),
  );

  const props = useSpring({
    opacity: showItens ? 1 : 0,
    display: showItens ? 'block' : 'none',
    config: { duration: 500 },
  });

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const handleDeleteOrderItem = async (idItem: number): Promise<void> => {
    if (gatheringOrderId > 0) {
      showLoading();
      const Response = await dispatch(
        deleteGatheringOrderItem({ id: idItem, data: null }),
      );
      if (deleteGatheringOrderItem.fulfilled.match(Response)) {
        addToast({
          title: 'Item removido com sucesso',
          type: 'success',
        });

        const gathering = Response.payload;
        dispatch(setWeightValue(gathering.weight));
        dispatch(setQuantityValue(gathering.quantity));
        dispatch(setVolumeValue(gathering.volume));
        dispatch(setValueCollection(gathering.value));
        dispatch(setNfeValue(gathering.nfe_value));
        let totalCubicMeters = 0;
        (gathering.itens as any).forEach((item: any): void => {
          const calcCubicMeters = item.length * item.width * item.height;
          totalCubicMeters += calcCubicMeters;
        });
        dispatch(setMetersCubic(totalCubicMeters));

        (formRef.current as any).reset();
        setGatheringOrderItemId(0);
        dispatch(setItensQuantity(itensQuantity - 1));
      } else {
        addToast({
          title: 'Erro ao remover item',
          type: 'error',
        });
      }
      hideLoading();
      setUpdatePage(true);
    } else {
      dispatch(
        setItens(itensCollect.filter((item: any): any => item.id !== idItem)),
      );
    }
  };

  const handleDialogDeleteItem = (idItem: number): void => {
    createDialog({
      text: `Excluir item?`,
      textButtonCancel: 'Não',
      textButtonConfirm: 'Sim',
      onConfirm: () => handleDeleteOrderItem(idItem),
    });
  };

  const getData = useCallback(
    async (page: number): Promise<void> => {
      console.log(quotationIdCollect);
      if (gatheringOrderId > 0) {
        showLoading();
        const gatheringOrderitens = await getCollectOrderItemById({
          page,
          collection_order_id: gatheringOrderId,
        });
        const arrayUsers: Array<Array<any>> = [];
        gatheringOrderitens.data.data.forEach((item: any) => {
          arrayUsers.push([
            item.serial,
            item.nf_number,
            item.product_name,
            formatValueToCurrencyInput(item.value),
            formatValueToCurrencyInput(item.quantity),
            formatValueToCurrencyInput(item.weight),
            formatValueToCurrencyInput(item.weight_calculated),
            item.comments,
            statusGatheringOrder !== 'A' &&
            statusGatheringOrder !== 'R' &&
            quotationIdCollect === 0 ? (
              <div className="options-table-list">
                <button
                  type="button"
                  onClick={() => setGatheringOrderItemId(item.id)}
                >
                  <FaEdit color="#fff" size={20} />
                </button>
                <button
                  type="button"
                  onClick={() => handleDialogDeleteItem(item.id)}
                >
                  <FiTrash2 color="#fff" size={20} />
                </button>
              </div>
            ) : (
              <></>
            ),
          ]);
        });
        setResults(arrayUsers);
        const arrayPages = [];
        for (let i = 0; i < gatheringOrderitens.data.total_pages; i++) {
          arrayPages.push(i + 1);
        }
        setTotalItens(gatheringOrderitens.data.total);
        dispatch(setItensQuantity(gatheringOrderitens.data.total));
        setPages(arrayPages);
        setCurrentPage(page);
        hideLoading();
        setUpdatePage(false);
      } else {
        const arrayUsers: Array<Array<any>> = [];
        itensCollect.forEach((item: any) => {
          arrayUsers.push([
            item.serial,
            item.nf_number,
            item.product_name,
            formatValueToCurrencyInput(item.value),
            formatValueToCurrencyInput(item.quantity),
            formatValueToCurrencyInput(item.weight),
            formatValueToCurrencyInput(item.weight_calculated),
            item.comments,
            statusGatheringOrder !== 'A' && quotationIdCollect === 0 ? (
              <div className="options-table-list">
                <button type="button" onClick={() => setIdItemRedux(item.id)}>
                  <FaEdit size={20} color="#ffffff" />
                </button>
                <button
                  type="button"
                  onClick={() => handleDialogDeleteItem(item.id)}
                >
                  <FiTrash2 size={20} color="#ffffff" />
                </button>
              </div>
            ) : (
              <></>
            ),
          ]);
        });
        setTotalItens(arrayUsers.length);
        setResults(arrayUsers);
      }
    },
    [
      showLoading,
      gatheringOrderId,
      quotationIdCollect,
      statusGatheringOrder,
      hideLoading,
      itensCollect,
      setTotalItens,
      setResults,
      handleDialogDeleteItem,
    ],
  );

  const handleSubmit = useCallback(
    async (data: FormInterface) => {
      try {
        (formRef.current as any).setErrors({});
        data.peso = formatValueFromCurrencyInput(data.peso);
        data.quantidade = formatValueFromCurrencyInput(data.quantidade);
        data['valor-mercadoria'] = formatValueFromCurrencyInput(
          data['valor-mercadoria'],
        );
        const schemaValidation = Yup.object().shape({
          serie: Yup.string(),
          'numero-nf': Yup.string(),
          produto: Yup.string().required('Produto obrigatório'),
          'valor-mercadoria': Yup.number()
            .min(0.01, 'Valor deve ser maior que 0')
            .required('Valor Obrigatório'),
          quantidade: Yup.number()
            .min(0.01, 'Quantidade deve ser maior que 0')
            .required('Quantidade obrigatória'),
          peso: Yup.number()
            .min(0.01, 'Peso deve ser maior que 0')
            .required('Peso obrigatório'),
          altura: Yup.string(),
          comprimento: Yup.string(),
          largura: Yup.string(),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        const gatheringOrderItemData = {
          collection_order_id: gatheringOrderId,
          serial: data.serie,
          nf_number: data['numero-nf'],
          product_name: data.produto,
          receiver: data.destinatario,
          value: data['valor-mercadoria'],
          quantity: data.quantidade,
          weight: data.peso,
          weight_calculated: weigthCalculated,
          comments: data['refcliente-ordemcompra'],
          width:
            data.largura.length > 0
              ? formatValueFromCurrencyInput(data.largura)
              : 0,
          length:
            data.comprimento.length > 0
              ? formatValueFromCurrencyInput(data.comprimento)
              : 0,
          height:
            data.altura.length > 0
              ? formatValueFromCurrencyInput(data.altura)
              : 0,
        };

        if (gatheringOrderId === 0) {
          if (idItemRedux === '') {
            dispatch(
              setItens([
                ...itensCollect,
                { ...gatheringOrderItemData, id: uuid() },
              ]),
            );
            addToast({ title: 'Item criado com sucesso', type: 'success' });
            dispatch(setItensQuantity(itensQuantity + 1));
          } else {
            dispatch(
              setItens([
                ...itensCollect.filter((x: any): any => x.id !== idItemRedux),
                { ...gatheringOrderItemData, id: idItemRedux },
              ]),
            );
            addToast({ title: 'Item atualizado com sucesso', type: 'success' });
          }
          setIdItemRedux('');
          setWeightCalculated(0);
          (formRef.current as any).reset();
        } else {
          showLoading();
          if (gatheringOrderItemId > 0) {
            const Response = await dispatch(
              updateGatheringOrderItem({
                id: Number(gatheringOrderItemId),
                data: gatheringOrderItemData,
              }),
            );
            if (updateGatheringOrderItem.fulfilled.match(Response)) {
              addToast({
                title: 'Item atualizado com sucesso',
                type: 'success',
              });
              (formRef.current as any).reset();
              setGatheringOrderItemId(0);
              const gathering = Response.payload;
              dispatch(setWeightValue(gathering.weight));
              dispatch(setQuantityValue(gathering.quantity));
              dispatch(setVolumeValue(gathering.volume));
              dispatch(setValueCollection(gathering.value));
              dispatch(setNfeValue(gathering.nfe_value));

              let totalCubicMeters = 0;
              (gathering.itens as any).forEach((item: any): void => {
                const calcCubicMeters = item.length * item.width * item.height;
                totalCubicMeters += calcCubicMeters;
              });
              dispatch(setMetersCubic(totalCubicMeters));
              setWeightCalculated(0);
            } else {
              addToast({ title: 'Erro na criação', type: 'error' });
            }
          } else {
            const Response = await dispatch(
              addGatheringOrderItem({ data: gatheringOrderItemData }),
            );
            if (addGatheringOrderItem.fulfilled.match(Response)) {
              addToast({ title: 'Item criado com sucesso', type: 'success' });
              dispatch(setItensQuantity(itensQuantity + 1));
              (formRef.current as any).reset();
              setGatheringOrderItemId(0);
              const gathering = Response.payload;
              dispatch(setWeightValue(gathering.weight));
              dispatch(setQuantityValue(gathering.quantity));
              dispatch(setVolumeValue(gathering.volume));
              dispatch(setValueCollection(gathering.value));
              dispatch(setNfeValue(gathering.nfe_value));
              let totalCubicMeters = 0;
              (gathering.itens as any).forEach((item: any): void => {
                const calcCubicMeters = item.length * item.width * item.height;
                totalCubicMeters += calcCubicMeters;
              });
              dispatch(setMetersCubic(totalCubicMeters));
              setWeightCalculated(0);
            } else {
              addToast({ title: 'Erro na criação', type: 'error' });
            }
          }
          hideLoading();
          getData(1);
        }
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (formRef.current as any).setErrors(errors);
        }
      }
    },
    [
      addToast,
      dispatch,
      setItens,
      itensCollect,
      idItemRedux,
      setMetersCubic,
      gatheringOrderId,
      hideLoading,
      showLoading,
      gatheringOrderItemId,
      weigthCalculated,
    ],
  );

  useEffect(() => {
    if (gatheringOrderItemId > 0) {
      (async () => {
        showLoading();
        const {
          data: gatheringOrderItemSel,
        } = await getCollectOrderItemByIdParam(Number(gatheringOrderItemId));
        (formRef.current as any).setFieldValue(
          'serie',
          gatheringOrderItemSel?.serial,
        );
        (formRef.current as any).setFieldValue(
          'numero-nf',
          gatheringOrderItemSel?.nf_number,
        );
        (formRef.current as any).setFieldValue(
          'produto',
          gatheringOrderItemSel?.product_name,
        );
        (formRef.current as any).setFieldValue(
          'valor-mercadoria',
          formatValueToCurrencyInput(gatheringOrderItemSel?.value),
        );
        (formRef.current as any).setFieldValue(
          'quantidade',
          formatValueToCurrencyInput(gatheringOrderItemSel?.quantity),
        );
        (formRef.current as any).setFieldValue(
          'peso',
          formatValueToCurrencyInput(gatheringOrderItemSel?.weight),
        );
        (formRef.current as any).setFieldValue(
          'altura',
          formatValueToCurrencyInput(gatheringOrderItemSel?.height),
        );
        (formRef.current as any).setFieldValue(
          'largura',
          formatValueToCurrencyInput(gatheringOrderItemSel?.width),
        );
        (formRef.current as any).setFieldValue(
          'comprimento',
          formatValueToCurrencyInput(gatheringOrderItemSel?.length),
        );
        setWeightCalculated(gatheringOrderItemSel?.weight_calculated);
        (formRef.current as any).setFieldValue(
          'refcliente-ordemcompra',
          gatheringOrderItemSel?.comments,
        );
        openModal();
        hideLoading();
      })();
    }
  }, [gatheringOrderItemId, hideLoading, showLoading]);

  useEffect(() => {
    if (idItemRedux !== '') {
      const gatheringOrderItemSel = itensCollect.find(
        (x: any): any => x.id === idItemRedux,
      );
      (formRef.current as any).setFieldValue(
        'serie',
        gatheringOrderItemSel?.serial,
      );
      (formRef.current as any).setFieldValue(
        'numero-nf',
        gatheringOrderItemSel?.nf_number,
      );
      (formRef.current as any).setFieldValue(
        'produto',
        gatheringOrderItemSel?.product_name,
      );
      (formRef.current as any).setFieldValue(
        'valor-mercadoria',
        formatValueToCurrencyInput(gatheringOrderItemSel?.value),
      );
      (formRef.current as any).setFieldValue(
        'quantidade',
        formatValueToCurrencyInput(gatheringOrderItemSel?.quantity),
      );
      (formRef.current as any).setFieldValue(
        'peso',
        formatValueToCurrencyInput(gatheringOrderItemSel?.weight),
      );
      (formRef.current as any).setFieldValue(
        'altura',
        formatValueToCurrencyInput(gatheringOrderItemSel?.height),
      );
      (formRef.current as any).setFieldValue(
        'largura',
        formatValueToCurrencyInput(gatheringOrderItemSel?.width),
      );
      (formRef.current as any).setFieldValue(
        'comprimento',
        formatValueToCurrencyInput(gatheringOrderItemSel?.length),
      );
      setWeightCalculated(gatheringOrderItemSel?.weight_calculated);
      (formRef.current as any).setFieldValue(
        'refcliente-ordemcompra',
        gatheringOrderItemSel?.comments,
      );
      openModal();
    }
  }, [idItemRedux]);

  useEffect(() => {
    if (gatheringOrderId > 0) {
      getData(1);
    }
  }, [gatheringOrderId, statusGatheringOrder, quotationIdCollect]);

  useEffect(() => {
    if (gatheringOrderId === 0) {
      getData(1);
    }
  }, [itensCollect]);

  useEffect(() => {
    if (updatePage) {
      getData(1);
    }
  }, [updatePage]);

  const handleXmlClick = (): void => {
    (hiddenFileInput as any).current.click();
  };

  const handleAddXmlInfo = async (): Promise<void> => {
    const fileReader = new FileReader();
    fileReader.readAsText(xmlFile);
    fileReader.onloadend = () => {
      const parser = new xml.Parser();
      parser.parseString(
        fileReader.result as string,
        (err: any, result: any) => {
          try {
            const products = result.nfeProc?.NFe[0]?.infNFe[0]?.det;
            if (products) {
              showLoading();
              const itensCollectXML: {
                id: string;
                collection_order_id: number;
                serial: any;
                nf_number: any;
                product_name: any;
                value: any;
                quantity: any;
                weight: number;
                weight_calculated: number;
                width: number;
                length: number;
                height: number;
                comments: string;
              }[] = [];
              products.forEach(async (item: any) => {
                const gatheringOrderItemData = {
                  collection_order_id: gatheringOrderId,
                  serial: result.nfeProc?.NFe[0]?.infNFe[0]?.ide[0]?.serie[0],
                  nf_number: result.nfeProc?.NFe[0]?.infNFe[0]?.ide[0]?.nNF[0],
                  product_name: item.prod[0]?.xProd[0],
                  value: Number(item.prod[0]?.vProd[0]),
                  quantity: Number(item.prod[0]?.qCom[0]),
                  weight: 0,
                  weight_calculated: weigthCalculated,
                  width: 0,
                  length: 0,
                  height: 0,
                  comments: 'Importação Nota',
                };
                if (gatheringOrderId > 0) {
                  const Response = await dispatch(
                    addGatheringOrderItem({ data: gatheringOrderItemData }),
                  );
                  if (addGatheringOrderItem.fulfilled.match(Response)) {
                    addToast({
                      title: 'Item importado com sucesso',
                      type: 'success',
                    });
                    (formRef.current as any).reset();
                    setGatheringOrderItemId(0);
                    setUpdatePage(true);
                  } else {
                    addToast({
                      title: 'Erro na importação do item',
                      type: 'error',
                    });
                  }
                } else {
                  itensCollectXML.push({
                    ...gatheringOrderItemData,
                    id: uuid(),
                  });
                  addToast({
                    title: 'Item importado com sucesso',
                    type: 'success',
                  });
                }
              });
              dispatch(setItens([...itensCollect, ...itensCollectXML]));
              hideLoading();
            }
          } catch {
            addToast({
              title: 'Erro ao importar item',
              type: 'success',
            });
          }
        },
      );
    };
    setIsConfirmed(false);
    setXmlFile(null);
  };

  useEffect(() => {
    if (xmlFile && isConfirmed) {
      handleAddXmlInfo();
    }
  }, [xmlFile, isConfirmed]);

  const confirm = async (): Promise<void> => {
    setIsConfirmed(true);
  };

  const canceled = useCallback(() => {}, []);

  const handlerCalculateWeight = (): void => {
    if (
      (formRef.current as any).getFieldValue('altura') &&
      (formRef.current as any).getFieldValue('comprimento') &&
      (formRef.current as any).getFieldValue('largura')
    ) {
      setWeightCalculated(
        Number(
          formatValueFromCurrencyInput(
            (formRef.current as any).getFieldValue('altura'),
          ),
        ) *
          Number(
            formatValueFromCurrencyInput(
              (formRef.current as any).getFieldValue('comprimento'),
            ),
          ) *
          Number(
            formatValueFromCurrencyInput(
              (formRef.current as any).getFieldValue('largura'),
            ),
          ) *
          Number(
            formatValueFromCurrencyInput(
              (formRef.current as any).getFieldValue('quantidade'),
            ),
          ) *
          cubageFactor,
      );
    }
  };

  const handleChange = (event: any): void => {
    if (event.target.files.length > 0) {
      setXmlFile(event.target.files[0]);
      createDialog({
        text: 'Deseja importar as informações do XML para a Coleta?',
        textButtonCancel: 'Não',
        textButtonConfirm: 'Sim',
        icon: FaQuestionCircle,
        onConfirm: confirm,
        onCancel: canceled,
      });
    }
  };

  const handleClickAddItem = useCallback(() => {
    setGatheringOrderItemId(0);
    (formRef.current as any).reset();
    openModal();
  }, [setGatheringOrderItemId, openModal]);

  const handleClickShowItens = useCallback(() => {
    if (showItens === true) {
      setShowItens(false);
    } else {
      setShowItens(true);
      setTimeout(() => {
        const position = (listRef.current as any).offsetTop + 35;
        window.scroll({ top: position, behavior: 'smooth' });
      }, 500);
    }
  }, [showItens, setShowItens, listRef]);

  return (
    <div style={{ width: '100%' }}>
      <ContainerOptions>
        <span>Itens ({totalItens})</span>
        {statusGatheringOrder !== 'A' &&
        statusGatheringOrder !== 'R' &&
        quotationIdCollect === 0 ? (
          <button type="button" onClick={() => handleClickAddItem()}>
            <FaPlusCircle color="#ffffff" /> item
          </button>
        ) : (
          <></>
        )}
        {totalItens > 0 && (
          <button
            type="button"
            ref={listRef}
            onClick={() => handleClickShowItens()}
          >
            {showItens === false ? (
              <BiShowAlt color="#ffffff" />
            ) : (
              <BiHide color="#ffffff" />
            )}
            {showItens === false ? 'ver' : 'esconder'} itens
          </button>
        )}
      </ContainerOptions>
      <Modal
        isOpen={showModal}
        title={`${gatheringOrderItemId ? `Atualizar` : `Cadastrar`} Item`}
        backgroundColor="#F8F8FB"
        fontColor="#3a3a3a"
        id="modal-item"
        onClose={() => {
          setShowModal(false);
          setIdItemRedux('');
          setGatheringOrderItemId(0);
        }}
      >
        <Form ref={formRef} initialData={{}} onSubmit={handleSubmit} noValidate>
          {statusGatheringOrder !== 'A' && statusGatheringOrder !== 'R' ? (
            <Row>
              <ContainerSize
                style={{ justifyContent: 'flex-start' }}
                size="100%"
              >
                <AddXmlButton type="button" onClick={handleXmlClick}>
                  <img src={XmlImg} alt="Importar" />
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    accept="text/xml"
                  />
                  <span> Importar NFe </span>
                </AddXmlButton>
              </ContainerSize>
            </Row>
          ) : (
            <></>
          )}

          <Row>
            <ContainerSize size="20%">
              <Input name="serie" type="text" placeholder="Série" />
            </ContainerSize>

            <ContainerSize size="35%">
              <Input name="numero-nf" type="text" placeholder="Número NF" />
            </ContainerSize>

            <ContainerSize size="100%">
              <Input name="produto" type="text" placeholder="Produto *" />
            </ContainerSize>
          </Row>

          <Row>
            <ContainerSize size="100%">
              <Input
                name="refcliente-ordemcompra"
                type="text"
                placeholder="Ref . Cliente/Ordem Compra"
              />
            </ContainerSize>

            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="valor-mercadoria"
                type="text"
                placeholder="Valor Mercadoria *"
                onBlur={handlerCalculateWeight}
              />
            </ContainerSize>

            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="quantidade"
                type="text"
                placeholder="Quantidade *"
              />
            </ContainerSize>

            <ContainerSize size="33.333333333%">
              <CurrencyInput name="peso" type="text" placeholder="Peso KG *" />
            </ContainerSize>
          </Row>

          <Row>
            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="altura"
                type="text"
                placeholder="Altura (m)"
                onBlur={handlerCalculateWeight}
              />
            </ContainerSize>
            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="largura"
                type="text"
                placeholder="Largura (m)"
                onBlur={handlerCalculateWeight}
              />
            </ContainerSize>
            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="comprimento"
                type="text"
                placeholder="Comprimento (m)"
                onBlur={handlerCalculateWeight}
              />
            </ContainerSize>
            <ContainerSize
              size="100%"
              style={{
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <h4
                  className="title"
                  style={{
                    width: 'auto',
                    marginRight: '5px',
                    marginLeft: '15px',
                  }}
                >
                  Peso Calculado
                </h4>
                <ContainerSize
                  size="33.333333333%"
                  style={{ paddingRight: '0' }}
                >
                  <ReadOnlyInput
                    valor={formatValueToCurrencyInput(weigthCalculated)}
                  />
                </ContainerSize>
              </div>
              {statusGatheringOrder !== 'A' ? (
                <NewButton
                  type="button"
                  onClick={() =>
                    handleSubmit((formRef.current as any).getData())
                  }
                >
                  {gatheringOrderItemId ? (
                    <>
                      <GrDocumentUpdate size={20} color="#ffffff" />
                      Atualizar
                    </>
                  ) : (
                    <>
                      <img src={AddImg} alt="Novo Item" />
                      Salvar
                    </>
                  )}
                </NewButton>
              ) : (
                <></>
              )}
            </ContainerSize>
          </Row>
        </Form>
      </Modal>
      <animated.div style={props}>
        <Row style={{ marginLeft: '0', marginRight: '0' }}>
          <ContainerSize size="100%">
            <TableList
              header={[
                'Série',
                'Nº NF',
                'Produto',
                'Valor Mercadoria',
                'Quantidade',
                'Peso KG',
                'Cubagem',
                'Ref. Cliente/Ordem Compra',
                '',
              ]}
              data={results}
            />
          </ContainerSize>
        </Row>
        <Row style={{ marginLeft: '0', marginRight: '0' }}>
          <ContainerSize size="100%">
            <Pagination
              pages={pages}
              currentPage={currentPage}
              onClickFunction={getData}
            />
          </ContainerSize>
        </Row>
      </animated.div>
    </div>
  );
};
export default GatheringOrderItems;
