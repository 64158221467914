/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  TextareaHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { Container, ContainerError, ContainerInput } from './styles';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  placeholdercolor?: string;
}

const Textarea: React.FC<InputProps> = ({
  name,
  placeholder,
  placeholdercolor,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!textareaRef.current?.value);
  }, []);

  const handleInputChange = useCallback(() => {
    setIsFilled(!!textareaRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
      setValue: (ref: any, value: any) => {
        setIsFilled(!!value);
        ref.value = value;
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setIsFilled(!!textareaRef.current?.value);
  }, [textareaRef.current?.value]);

  useEffect(() => {}, [isFilled]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Container
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isFile={false}
      >
        <ContainerInput>
          <label htmlFor={fieldName}>
            {isFilled ? placeholder?.replace('*', '') : placeholder}
          </label>
          <textarea
            className="txtarea"
            id={fieldName}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            defaultValue={defaultValue}
            ref={textareaRef}
            autoComplete="off"
            {...rest}
            style={{}}
          />
        </ContainerInput>
      </Container>
      {!!error === true && isFilled === false && (
        <ContainerError>{error}</ContainerError>
      )}
    </div>
  );
};

export default Textarea;
