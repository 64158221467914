import styled, { css } from 'styled-components';

interface BadgeData {
  type: 'danger' | 'success';
}

export const Badge = styled.span<BadgeData>`
  border-radius: 4px;
  padding: 5px 7px;
  font-size: 80%;
  letter-spacing: 0.3px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  border-radius: 50px;
  color: #ffffff;

  ${props =>
    props.type === 'danger' &&
    css`
      background-color: #f83f37;
    `}

  ${props =>
    props.type === 'success' &&
    css`
      background-color: #22af47;
    `}
`;

export const DocInformation = styled.div`
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    padding: 5px 6px;
    background-color: #f8f8fb;
    border-radius: 8px;
    margin-top: 10px;
  }
`;
