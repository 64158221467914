import React, { createContext, useContext, useCallback, useState } from 'react';

import LoadingFull from '../components/LoadingFull';

interface LoadingContextData {
  showLoading(): void;
  hideLoading(): void;
}

const Loading = createContext<LoadingContextData>({} as LoadingContextData);

const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const hideLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <Loading.Provider value={{ showLoading, hideLoading }}>
      {children}
      {isLoading === true && <LoadingFull />}
    </Loading.Provider>
  );
};

function useLoading(): LoadingContextData {
  const context = useContext(Loading);

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
}

export { LoadingProvider, useLoading };
