import styled from 'styled-components';

export const TotalContainers = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  > div {
    width: calc(25% - 20px);
    margin: 10px;
    height: 154px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    
    background-color: #003CA6;
    box-shadow: rgba(0, 60, 166, 0.2) 6px 6px 16px;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 400;



    span{
      color: #F8F8FB;
      &:first-child{
        font-size: 60px;
        font-weight: 600;
      }
    }


    @media (max-width: 768px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 460px) {
      width: calc(100% - 20px);
    }
  }
`;
