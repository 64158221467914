/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, RootStateOrAny } from 'react-redux';

import { RiRoadMapLine } from 'react-icons/ri';

import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getTrackings } from '../../features/merchandiseTracking';

import { useLoading } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';

import PageContainer from '../../components/PageContainer';
import TableList from '../../components/TableList';
import Filter from '../../components/Filter';
import Input from '../../components/Input';
import Pagination from '../../components/Pagination';

import MainTitle from '../../components/MainTitle';
import {
  fetchClientsByDocumentNumber,
  fetchUserClientsByDocumentNumber,
} from '../../features/clients';
import Autocomplete from '../../components/Autocomplete';

const ListTrackings: React.FC = () => {
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [optionsSearch, setOptionsSearch] = useState<object>({});
  const [optionsClients, setOptionsClients] = useState<any>([]);
  const [searchClients, setSearchClients] = useState('');
  const [valueSearchClient] = useDebounce(searchClients, 1000);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();

  const { userType, userTypeId, userId } = useSelector(
    (state: RootStateOrAny) => ({
      quotationId: state.features.quotation.quotationId,
      userType: state.features.auth.type,
      userTypeId: state.features.auth.userTypeId,
      userId: state.features.auth.userId,
    }),
  );

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const trackings = await getTrackings({
        page,
        ...optionsSearch,
      });
      const arrayPages = [];
      for (let i = 0; i < trackings.data.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);

      const trackingsResult: Array<object> = [];

      trackings.data.data.forEach((item: any) => {
        trackingsResult.push([
          item.document_number,
          new Date(item.date_hour).toLocaleString(),
          item.place,
          item.status,
          item.code,
          <div className="options-table-list">
            <Link
              to={`/consultar-mercadoria/${item.code}/code`}
              title="Visualizar rastreio"
            >
              <RiRoadMapLine size={20} color="#ffffff" />
            </Link>
          </div>,
        ]);
      });
      setResults(trackingsResult);
      hideLoading();
      addToast({
        title: 'Rastreios carregados com sucesso',
        type: 'success',
      });
    },
    [addToast, hideLoading, showLoading, optionsSearch],
  );

  const handleClientInputChange = (newValue: string): void => {
    if (newValue !== searchClients) {
      setIsLoadingClients(true);
    }
    setSearchClients(newValue);
  };

  useEffect(() => {
    (async () => {
      const allClients: any = [];
      if (userType === 'salesman' || userType === 'admin') {
        const isSalesman: { [key: string]: any } = {};
        if (userType === 'salesman') {
          isSalesman.salesman_id = userTypeId;
        }
        const { data: clients } = await fetchClientsByDocumentNumber({
          document_number: valueSearchClient,
          ...isSalesman,
        });
        clients.forEach((client: any): any => {
          allClients.push({
            value: client.id,
            label: `${client.short_name} - ${client.document_number}`,
          });
        });
      } else {
        const { data: clients } = await fetchUserClientsByDocumentNumber({
          'client.document_number': valueSearchClient,
          user_id: userId,
        });
        clients.forEach((item: any): any => {
          allClients.push({
            value: item.client.id,
            label: `${item.client.short_name} - ${item.client.document_number}`,
          });
        });
      }
      setOptionsClients(allClients);
      setIsLoadingClients(false);
    })();
  }, [valueSearchClient]);

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      const searchData: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          searchData[key.replace('-', '.')] = value;
        }
      }
      setOptionsSearch(searchData);
    },
    [setOptionsSearch],
  );

  useEffect(() => {}, [optionsClients]);

  useEffect(() => {
    getData(1);
  }, [optionsSearch]);

  return (
    <PageContainer>
      <MainTitle>Consultar Rastreios</MainTitle>
      <TableList
        header={['N°. Documento', 'Data/Hora', 'Local', 'Status', 'Código', '']}
        data={results}
      />
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onClickFunction={getData}
      />
      <Filter onSubmit={handleSubmitForm}>
        <Autocomplete
          name="client_id"
          options={optionsClients}
          placeholder="CPF/CNPJ"
          changeValue={null}
          onInputChange={handleClientInputChange}
          isLoading={isLoadingClients}
        />
        <Input
          type="text"
          name="merchandise_tracking-document_number"
          placeholder="N°. Documento"
        />
        <Input
          type="text"
          name="merchandise_tracking-code"
          placeholder="Código"
        />
        <Input
          type="text"
          name="merchandise_tracking-place"
          placeholder="Local"
        />
        <Input
          type="text"
          name="merchandise_tracking-status"
          placeholder="Status"
        />
        <Input
          type="date"
          name="merchandise_tracking-date_hour>"
          placeholder="De"
        />
        <Input
          type="date"
          name="merchandise_tracking-date_hour<"
          placeholder="Até"
        />
      </Filter>
    </PageContainer>
  );
};

export default ListTrackings;
