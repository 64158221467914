import React from 'react';
import { TotalContainers } from './styles';

interface Props {
  openQuotations: number;
  ToQuoteItems: number;
  LastMonthQuotations: number;
  AnsweredLastMonthQuotations: number;
}

const TotalQuotations: React.FC<Props> = ({
  openQuotations,
  ToQuoteItems,
  LastMonthQuotations,
  AnsweredLastMonthQuotations,
}) => {
  return (
    <TotalContainers>
      <div className="span-total">
        <span>{LastMonthQuotations}</span>
        <span>TOTAL DE COTAÇÕES</span>
      </div>
      <div className="span-total">
        <span>{openQuotations}</span>
        <span>COTAÇÕES ABERTAS</span>
      </div>

      <div className="span-total">
        <span className="number-items">{ToQuoteItems}</span>
        <span>COTAÇÕES FECHADAS</span>
      </div>

      <div className="span-total">
        <span>{AnsweredLastMonthQuotations}</span>
        <span>MÉDIA DE COTAÇÕES</span>
      </div>
    </TotalContainers>
  );
};

export default TotalQuotations;
