import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body{
    background: #fff;
    color: #3a3a3a;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button{
    font-family: 'Rajdhani', sans-serif;

    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
  }

  button{
    cursor: pointer;
    &:focus{
      outline: none;
    }
  }

  a{
    text-decoration: none;
  }

  .text-center{
    text-align: center;
  }

  .modal-open{
    overflow: hidden;
    padding-right: 15px;
  }

  .hr {
    border-color: #003CA6;
    border-width: 2px;
    border-style: solid;
    border-radius: 31px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .options-table-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .vertical-timeline::before {
    background: #003CA6;
  }

  @media(max-width: 768px){
    .modal-open{
      padding-right: 0;
    }
  }
`;
