import styled, { css } from 'styled-components';
import { shade } from 'polished';

import checkIcon from '../../assets/check_icon_btn.svg';

interface ComponentProps {
  size: string;
}

export const CheckButton = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 9px;
  border: none;
  background-color: #003ca6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &[data-checked='true']::after {
    width: 18px;
    height: 22px;
    opacity: 1;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    margin-bottom: 2px;
    background-image: url(${checkIcon});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 5;
    transition: all 0.2s ease;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: 600px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;

  button {
    margin-left: 15px;
    border: none;
    padding: 10px 15px;
    border-radius: 29px;
    background-color: #003ca6;
    color: #ffffff;
    display: flex;
    align-items: center;
    width: auto;

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }

    svg {
      margin-right: 5px;
    }
  }
`;

export const FormButton = styled.button`
  height: 44px;
  width: 100%;
  padding: 8px 50px;
  background-color: #90dcec;
  border-radius: 29px;
  border: none;
  margin: 27px 15px 0 0px;

  position: relative;

  font-size: 20px;

  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    margin-right: 5px;
  }

  &:hover {
    background: ${shade(0.2, '#003CA6')};
    color: #fff;
    img {
      filter: invert(1);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

export const ModalContainer = styled.div`
  padding: 0 15px;

  display: flex;

  position: relative;
  left: 23%;
  right: 23%;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    left: 19%;
    right: 23%;
  }

  @media (max-width: 670px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    left: 9%;
    right: 23%;
  }
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  position: absolute;
  left: 90%;
  bottom: 43px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    left: 22%;
  }

  @media (max-width: 670px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    left: 30%;
    bottom: 36px;
  }
`;

export const ModalButton = styled.button`
  height: 44px;
  width: 100%;
  padding: 8px 20px;
  background-color: #90dcec;
  border-radius: 29px;
  border: none;
  font-size: 20px;
  margin: 10px 5px;

  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#003CA6')};
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 30%;
    padding-right: 0;
    padding-left: 0;
  }

  @media (max-width: 670px) {
    width: 20%;
    padding-right: 0;
    padding-left: 0;
    margin-left: 20px;
  }
`;

export const FloatButton = styled.button`
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #003ca6;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;

export const BindOptionButton = styled.button`
  width: 100%;
  height: 42px;
  margin-bottom: 24px;

  background-color: transparent;
  border: solid 1px #003ca6;
  border-radius: 10px;
  color: #000;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
    color: #fff;
  }
`;
