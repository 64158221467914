import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import AsyncSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { Container, ContainerError } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  changeValue: any;
  backgroundColor?: string;
  fontColor?: string;
}

const Autocomplete: React.FC<Props> = ({
  name,
  placeholder,
  options,
  changeValue,
  isLoading,
  backgroundColor,
  fontColor,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const backColor = backgroundColor || '#ffffff';
  const fontColorInside = fontColor || 'black';
  const handleSelectFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleSelectChange = useCallback(e => {
    if (e === null) {
      setIsFilled(false);
    } else {
      setIsFilled(!!e.value);
    }
  }, []);

  useEffect(() => {
    // Force state isLoading to false on initial render
    (selectRef.current as any).state.isLoading = false;
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref: any, value: any) => {
        ref.select.setValue(value || null);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  useEffect(() => {
    const newValue = JSON.stringify(rest.value);
    if (newValue === '{}' || typeof newValue === 'undefined') {
      setIsFilled(false);
    } else {
      setIsFilled(true);
    }
  }, [rest.value]);

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background:
        !!error === true && isFilled === false
          ? 'rgba(197, 48, 48, 0.18)'
          : backColor,
      borderRadius: 10,
      marginTop: 25,
      padding: 6,
      borderColor: state.isFocused ? 'transparent' : 'transparent',
      boxShadow: '2px 2px 9px 0px rgba(0, 0, 0, 0.07);',
      '&:hover': {
        borderColor: state.isFocused ? 'transparent' : 'transparent',
      },
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: 10,
      marginTop: 0,
      zIndex: 4,
      color: fontColorInside,
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      color: fontColorInside,
    }),
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Container isFocused={isFocused} isFilled={isFilled} isErrored={!!error}>
        <label htmlFor={name}>
          {isFilled ? placeholder?.toString().replace('*', '') : placeholder}
        </label>
        <AsyncSelect
          inputId={name}
          defaultValue={defaultValue}
          styles={customStyles}
          classNamePrefix="react-select"
          placeholder=""
          openMenuOnFocus
          options={options}
          onFocus={handleSelectFocus}
          onBlur={handleSelectBlur}
          isClearable
          onChange={e => {
            handleSelectChange(e);
            if (changeValue !== null) {
              changeValue(e);
            }
          }}
          ref={selectRef}
          width="100%"
          isLoading={isLoading}
          {...rest}
        />
      </Container>
      {!!error === true && isFilled === false && (
        <ContainerError>{error}</ContainerError>
      )}
    </div>
  );
};

export default Autocomplete;
