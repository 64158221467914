import axios from 'axios';

import {
  defaultConfig,
  defaultAuthFirebaseRequestHandler,
  defaultResponseHandlers,
} from '../utils/axios';

import { auth } from './authConfig';
import envConfig from './envConfig';

const axiosInstance = axios.create({
  ...defaultConfig,
  baseURL: envConfig.api.url,
});

axiosInstance.interceptors.request.use(config =>
  defaultAuthFirebaseRequestHandler(config, auth),
);
axiosInstance.interceptors.response.use(
  defaultResponseHandlers.response,
  defaultResponseHandlers.error,
);

export function addGlobalRequestInterceptor(
  onFulfilled: any,
  onRejected: any,
): number {
  const id = axiosInstance.interceptors.request.use(onFulfilled, onRejected);
  return id;
}

export function removeGlobalRequestInterceptor(id: number): void {
  axiosInstance.interceptors.request.eject(id);
}

export function addGlobalResponseInterceptor(
  onFulfilled: any,
  onRejected: any,
): number {
  const id = axiosInstance.interceptors.response.use(onFulfilled, onRejected);
  return id;
}

export function removeGlobalResponseInterceptor(id: number): void {
  axiosInstance.interceptors.response.eject(id);
}

export default axiosInstance;
