import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  ocurrenceId: 0,
  totalPages: 0,
};

export const ocurrenceSlice = createSlice({
  name: 'ocurrences',
  initialState,
  reducers: {
    setOcurrenceId: (state, { payload: ocurrenceId }) => {
      state.ocurrenceId = !ocurrenceId ? initialState.ocurrenceId : ocurrenceId;
    },
    setTotalPages: (state, { payload: totalPages }) => {
      state.totalPages = !totalPages ? initialState.totalPages : totalPages;
    },
  },
});

export const { setOcurrenceId, setTotalPages } = ocurrenceSlice.actions;

interface IntegrationOcurrenceData {
  id: number;
  name: string;
}

export interface OcurrenceData {
  id: number;
  code_integration: number;
  name: string;
  status: string;
  created_at: Date;
  ocurrence_integration: IntegrationOcurrenceData;
}

interface ResponseOcurrence {
  data: OcurrenceData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseOcurrences {
  data: OcurrenceData[];
  per_page: number;
  total_pages: number;
}

export const getOcurrence = async (id: number): Promise<ResponseOcurrence> => {
  const client = await axios.get(`/local/ocurrences/${id}`);
  return client;
};

export const postOcurrence = async (
  data: Omit<OcurrenceData, 'id' | 'ocurrence_integration'>,
): Promise<Omit<ResponseOcurrence, 'ocurrence_integration'>> => {
  const response = await axios.post('/local/ocurrences', data);
  return response;
};

export const updateOcurrence = async (
  id: number,
  data: Omit<OcurrenceData, 'id' | 'ocurrence_integration'>,
): Promise<Omit<ResponseOcurrence, 'ocurrence_integration'>> => {
  const response = await axios.put(`/local/ocurrences/${id}`, data);
  return response;
};

export const getOcurrences = async (
  params: any,
): Promise<ResponseOcurrences> => {
  const { data: ocurrences } = await axios.get('/local/ocurrences', { params });
  return ocurrences;
};

export default ocurrenceSlice.reducer;
