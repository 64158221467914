import { AxiosResponse } from 'axios';

export const defaultConfig = {
  // withCredentials: true,
  timeout: 60000,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
    // To fix CORS problem ?
    // 'Access-Control-Allow-Origin': '*',
  },
};

interface ObjectInterface {
  [key: string]: any;
}

export const defaultAuthFirebaseRequestHandler = async (
  config: ObjectInterface,
  firebaseAuth: ObjectInterface,
): Promise<ObjectInterface> => {
  const isAuthenticated = !!firebaseAuth.currentUser;
  // config.withCredentials = isAuthenticated;
  if (isAuthenticated) {
    const currentUser = await firebaseAuth.currentUser.getIdTokenResult();
    config.headers.Authorization = `Bearer ${currentUser.token}`;
    config.headers.user_id = currentUser.claims.user_id;
    config.headers.UserType = currentUser.claims.auth
      ? currentUser.claims.auth
      : 'error';
  } else {
    delete config.headers.Authorization;
  }

  return config;
};

export const defaultResponseHandlers = {
  response: (response: AxiosResponse): Promise<AxiosResponse> => {
    return Promise.resolve(response);
  },
  error: (error: ObjectInterface): Promise<ObjectInterface> => {
    const isNetworkError = !!error.isAxiosError && !error.response;
    const errorInfos = {
      status: !isNetworkError ? error.response.status : null,
      data: !isNetworkError ? error.response.data : null,
      message: error.message,
    };
    return Promise.reject(errorInfos);
  },
};

// export const defaultAuthLocalStorageRequestHandler = (config, authLocalStorageItemName) => {
//   const apiAuthToken = localStorage.getItem(authLocalStorageItemName);
//   if (apiAuthToken) {
//     config.headers.Authorization = `Bearer ${apiAuthToken}`;
//     config.withCredentials = true;
//   }

//   return config;
// };

// export const defaultAuthLocalStorageSetter = (axiosInstance, authLocalStorageItemName, token) => {
//   axiosInstance.defaults.withCredentials = !!token;
//   if (!token) {
//     localStorage.removeItem(authLocalStorageItemName);
//     delete axiosInstance.defaults.headers.common.Authorization;
//   } else {
//     localStorage.setItem(authLocalStorageItemName, token);
//     axiosInstance.defaults.headers.common.Authorization = token;
//   }
// };

// export const defaultAuthRefreshLocalStorageSetter = (
//   axiosInstance, refreshAuthLocalStorageItemName, token,
// ) => {
//   axiosInstance.defaults.withCredentials = !!token;
//   if (!token) {
//     localStorage.removeItem(refreshAuthLocalStorageItemName);
//     delete axiosInstance.defaults.headers.common.Authorization;
//   } else {
//     localStorage.setItem(refreshAuthLocalStorageItemName, token);
//     axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
//   }
// };
