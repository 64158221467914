import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiEdit2, FiClipboard, FiArrowLeft } from 'react-icons/fi';
import { BiUserPlus } from 'react-icons/bi';
import { useDebounce } from 'use-debounce';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { FaTrash } from 'react-icons/fa';
import {
  addUserThunk,
  addNewUserWithPhotoThunk,
  updateUserThunk,
  updateUserWithPhotoThunk,
  getUserById,
} from '../../features/users';
import {
  fetchSalesmanByName,
  getSalesmanByUserId,
} from '../../features/salesman';
import { fetchClientsByName } from '../../features/client';
import {
  getUserClient,
  postUserClient,
  deleteUserClient,
} from '../../features/clients';
import {
  Background,
  Avatar,
  Container,
  ContainerRadio,
  Row,
  InputPic,
  ButtonAddClient,
  ContainerSizeAutoComplete,
  ContainerFull,
  BackButton,
} from './styles';
import MainTitle from '../../components/MainTitle';
import getValidationErrors from '../../utils/getValidationErrors';

import PageContainer from '../../components/PageContainer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Radio from '../../components/Radio';
import AvatarImg from '../../assets/avatar.png';

import optionSales from '../../assets/shopping_bag-24px.svg';
import optionAdmin from '../../assets/security-24px.svg';
import optionTransport from '../../assets/local_shipping-24px.svg';
import optionClient from '../../assets/perm_identity-24px.svg';
import Autocomplete from '../../components/Autocomplete';
import InputMask from '../../components/InputMask';
import { useToast } from '../../hooks/toast';
import { useLoading } from '../../hooks/loading';
import Modal from '../../components/Modal/index';
import { ContainerSize } from '../client/RegisterClient/styles';
import { AppDispatch } from '../../app/store';
import { ListFiles } from './styles';

interface FormInterface {
  [key: string]: string;
}

interface UserParams {
  id: string | undefined;
}

const optionsStatus = [
  {
    value: 1,
    label: 'Ativo',
  },
  {
    value: 2,
    label: 'Inativo',
  },
];

const UserRegister: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const modalFormRef = useRef<FormHandles>(null);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [searchSalesman, setSearchSalesman] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [valueSearchSalesman] = useDebounce(searchSalesman, 1000);
  const [valueSearchClient] = useDebounce(searchClient, 1000);
  const [optionsSalesman, setOptionsSalesman] = useState<any>([]);
  const [optionsClient, setoptionsClient] = useState<any>([]);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [profilePic, setProfilePic] = useState<any>();
  const [profilePicture, setprofilePicture] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('Cadastrar');
  const [phoneNumberMask, setPhoneNumberMask] = useState<string>('');
  const [userClients, setUserClients] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [userType, setuserType] = useState<string>('');
  const history = useHistory();
  const { userId, users, salesmans, salesmanId } = useSelector(
    (state: RootStateOrAny) => ({
      userId: state.features.user.userId,
      users: state.entities.users,
      salesmans: state.entities.salesman,
      salesmanId: state.features.salesman,
    }),
  );

  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { params } = useRouteMatch<UserParams>();

  useEffect(() => {
    (async () => {
      if (params.id && !userId) {
        if (params.id !== 'login') {
          showLoading();
          const userSelected = await getUserById(Number(params.id));
          const { data: salesmanSelected } = await getSalesmanByUserId(
            Number(params.id),
          );
          const phoneNumber = userSelected.phoneNumber
            ? userSelected.phoneNumber.replace(/[^0-9]/g, '')
            : '';
          if (phoneNumber.length <= 10) {
            setPhoneNumberMask('(99) 9999-9999?');
          } else {
            setPhoneNumberMask('(99) 99999-9999');
          }
          if (userSelected.photo_link) {
            setprofilePicture(userSelected.photo_link);
          }
          setuserType(userSelected.type_code.toString());
          if (userSelected.type_code === 2) {
            if (salesmanSelected.length > 0) {
              (formRef.current as any).setFieldValue('salesman', {
                value: salesmanSelected[0].id,
                label: salesmanSelected[0].salesname,
              });
            }
            (formRef.current as any).setFieldValue(
              'type_code',
              userSelected.type_code.toString(),
            );
            (formRef.current as any).setFieldValue(
              'userName',
              userSelected.userName,
            );
            (formRef.current as any).setFieldValue('name', userSelected.name);
            (formRef.current as any).setFieldValue(
              'comments',
              userSelected.comments,
            );
            (formRef.current as any).setFieldValue('is_active', {
              value: userSelected.is_active ? 1 : 2,
              label: userSelected.is_active ? 'Ativo' : 'Inativo',
            });
            (formRef.current as any).setFieldValue(
              'phoneNumber',
              userSelected.phoneNumber,
            );
            (formRef.current as any).setFieldValue('email', userSelected.email);
          } else {
            (formRef.current as any).setData({
              type_code: userSelected.type_code.toString(),
              userName: userSelected.userName,
              name: userSelected.name,
              comments: userSelected.comments,
              phoneNumber: userSelected.phoneNumber,
            });
            (formRef.current as any).setFieldValue(
              'phoneNumber',
              userSelected.phoneNumber,
            );
            (formRef.current as any).setFieldValue('is_active', {
              value: userSelected.is_active ? 1 : 2,
              label: userSelected.is_active ? 'Ativo' : 'Inativo',
            });
            (formRef.current as any).setFieldValue('email', userSelected.email);
            if (userSelected.type_code === 3) {
              (async () => {
                await getUserClient(
                  (params.id as any) as number,
                ).then(({ data }) => setUserClients(data));
              })();
            }

            setuserType(userSelected.type_code.toString());
            (document.querySelector('#phoneNumber') as any).focus();
            (document.querySelector('#phoneNumber') as any).blur();
          }
          hideLoading();
        }

        if (userId || params.id) {
          setButtonText('Atualizar');
        }
      }
    })();
  }, [
    dispatch,
    params.id,
    salesmans.allIds,
    salesmans.byId,
    userId,
    salesmanId,
    users.allIds,
    users.byId,
    showLoading,
    hideLoading,
  ]);

  useEffect(() => {}, [userType, userClients, users.byId, params.id]);
  useEffect(() => {
    (async () => {
      const salesman = await fetchSalesmanByName({
        salesname: valueSearchSalesman,
      });
      const allSalesman: any = [];
      salesman.forEach((branch: any): any => {
        allSalesman.push({ value: branch.id, label: branch.salesname });
      });
      setOptionsSalesman(allSalesman);
      setIsLoadingSalesman(false);
    })();
  }, [valueSearchSalesman]);

  useEffect(() => {
    (async () => {
      const clients =
        valueSearchClient.replace(/[^0-9]/g, '').length > 1
          ? await fetchClientsByName({
              'clients.document_number': valueSearchClient,
            })
          : await fetchClientsByName({
              'clients.company_name': valueSearchClient,
            });
      const allClients: any = [];
      clients.forEach((client: any): any => {
        allClients.push({
          value: client.id,
          label: client.company_name
            ? `${client.company_name} - ${client.document_number}`
            : `${client.name} - ${client.document_number}`,
        });
      });
      setoptionsClient(allClients);
      setIsLoadingClient(false);
    })();
  }, [valueSearchClient]);

  useEffect(() => {}, [phoneNumberMask]);

  const handleuserType = (e: any): any => {
    setuserType(e.target.value);
  };

  const handleAddUserCliente = useCallback(
    async (data: FormInterface) => {
      try {
        const idEsc = userId || params.id;
        const dataPost = {
          user_id: idEsc,
          client_id: data.clients as any,
        };
        showLoading();
        await postUserClient(dataPost)
          .then(() => {
            addToast({
              title: 'Cliente vinculado ao usuário com sucesso!',
              type: 'success',
            });
          })
          .catch(() =>
            addToast({
              title: 'Erro ao vincular Cliente ao usuário',
              type: 'error',
            }),
          );
        await getUserClient(idEsc).then(response => {
          setUserClients(response.data);
        });
        hideLoading();
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (modalFormRef.current as any).setErrors(errors);
        }
      }
    },
    [addToast, hideLoading, params.id, showLoading, userId],
  );

  const handleSalesmanInputChange = (newValue: string): void => {
    if (newValue !== searchSalesman) {
      setIsLoadingSalesman(true);
    }
    setSearchSalesman(newValue);
  };

  const handleClientsInputChange = (newValue: string): void => {
    if (newValue !== searchSalesman) {
      setIsLoadingClient(true);
    }
    setSearchClient(newValue);
  };

  const handleChangePicture = (e: any): any => {
    if (e.target.files?.length > 0) {
      setprofilePicture(URL.createObjectURL(e.target.files[0]));
      setProfilePic(e.target.files[0]);
    }
  };

  const handleDeleteUserClient = async (id: any): Promise<any> => {
    showLoading();
    await deleteUserClient(id)
      .then(() => {
        addToast({
          title: 'Cliente desvinculado do usuário com sucesso!',
          type: 'success',
        });
      })
      .catch(() =>
        addToast({
          title: 'Erro ao desvincular Cliente do usuário',
          type: 'error',
        }),
      );
    await getUserClient((params.id as any) as number).then(({ data }) => {
      setUserClients(data);
    });
    hideLoading();
  };

  const handleChangeBrazilianPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMask('(99) 9999-9999?');
      } else {
        setPhoneNumberMask('(99) 99999-9999');
      }
    },
    [],
  );

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const handleSubmit = useCallback(
    async (data: FormInterface) => {
      try {
        (formRef.current as any).setErrors({});
        const schemaValidation = Yup.object().shape({
          userName: Yup.string().required('Nome de Usuário obrigatório'),

          password: !params.id
            ? Yup.string()
                .required('Senha obrigatória')
                .min(6, 'Mínimo de 6 dígitos')
            : Yup.string(),

          name: Yup.string().required('Nome Completo obrigatório'),

          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          is_active: Yup.string().required('Status obrigatório'),
          comments: Yup.string(),

          phoneNumber: Yup.string()
            .required('Telefone Obrigatorio')
            .min(8, 'Mínimo de 8 dígitos'),

          type_code: Yup.string()
            .nullable()
            .required('Tipo de Usuário é obrigatório'),

          salesman: Yup.string()
            .nullable()
            .when('type_code', {
              is: '2',
              then: Yup.string().required(
                'Necessário informar o usuário do vendedor',
              ),
            }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        showLoading();
        if (profilePic) {
          if (params.id) {
            const response = await dispatch(
              updateUserWithPhotoThunk({
                id: Number(params.id),
                user: data,
                pic: profilePic,
              }),
            );
            if (updateUserWithPhotoThunk.fulfilled.match(response)) {
              addToast({
                title: 'Usuário atualizado com sucesso',
                type: 'success',
              });
            } else {
              addToast({
                title: 'Erro ao atualizar usuário',
                type: 'error',
              });
            }
          } else {
            const response = await dispatch(
              addNewUserWithPhotoThunk({ id: 0, user: data, pic: profilePic }),
            );
            if (addNewUserWithPhotoThunk.fulfilled.match(response)) {
              addToast({
                title: 'Usuário inserido com sucesso',
                type: 'success',
              });
            } else {
              addToast({
                title: 'Erro ao inserir usuário',
                type: 'error',
              });
            }
          }
          setProfilePic(null);
        } else {
          if (params.id) {
            const response = await dispatch(
              updateUserThunk({ id: Number(params.id), user: data }),
            );
            if (updateUserThunk.fulfilled.match(response)) {
              addToast({
                title: 'Usuário atualizado com sucesso',
                type: 'success',
              });
            } else {
              addToast({
                title: 'Erro ao atualizar usuário',
                type: 'error',
              });
            }
          } else {
            const response = await dispatch(
              addUserThunk({ id: 0, user: data }),
            );
            if (addUserThunk.fulfilled.match(response)) {
              addToast({
                title: 'Usuário inserido com sucesso',
                type: 'success',
              });
            } else {
              addToast({
                title: 'Erro ao inserir usuário',
                type: 'error',
              });
            }
          }
          setProfilePic(null);
        }
        hideLoading();
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (formRef.current as any).setErrors(errors);
        }
      }
    },
    [addToast, dispatch, hideLoading, params.id, profilePic, showLoading],
  );

  useEffect(() => {
    const queryValues = queryString.parse(location.search);
    if (Object.keys(queryValues).length > 0) {
      setuserType('2');
      (formRef.current as any).setFieldValue('type_code', '2');
    }
  }, [location, setuserType]);

  useEffect(() => {
    const queryValues = queryString.parse(location.search);
    if (Object.keys(queryValues).length > 0) {
      (formRef.current as any).setFieldValue('salesman', {
        value: queryValues.salesman_id,
        label: queryValues.salesname,
      });
    }
  }, [location.search, userType]);

  return (
    <Background>
      <PageContainer>
        <MainTitle>
          <BackButton type="button" onClick={() => history.goBack()}>
            <FiArrowLeft size={30} />
          </BackButton>{' '}
          Cadastro de Usuário
        </MainTitle>
        <Form ref={formRef} initialData={{}} onSubmit={handleSubmit} noValidate>
          <Row>
            <Container>
              <Avatar>
                <img
                  src={profilePicture.length > 0 ? profilePicture : AvatarImg}
                  alt="Avatar"
                />
                <InputPic
                  type="file"
                  id="photo-upload"
                  accept="image/png, image/jpeg"
                  onChange={e => handleChangePicture(e)}
                />
                <label
                  className="edit-button"
                  htmlFor="photo-upload"
                  style={{ cursor: 'pointer' }}
                >
                  <FiEdit2 />
                </label>
              </Avatar>
            </Container>

            <ContainerSizeAutoComplete>
              {userType === '2' && (
                <Autocomplete
                  name="salesman"
                  options={optionsSalesman}
                  placeholder="Vendedor"
                  changeValue={null}
                  onInputChange={handleSalesmanInputChange}
                  isLoading={isLoadingSalesman}
                />
              )}
            </ContainerSizeAutoComplete>
            <Container>
              <Input name="userName" type="text" placeholder="Usuário *" />
            </Container>

            <Container>
              <Input name="password" type="password" placeholder="Senha *" />
            </Container>

            <Container>
              <Input name="name" type="text" placeholder="Nome Completo *" />
            </Container>

            <Container>
              <Input name="email" type="email" placeholder="E-mail *" />
            </Container>

            <Container>
              <InputMask
                mask={phoneNumberMask}
                formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                maskChar=""
                onKeyUp={handleChangeBrazilianPhone}
                name="phoneNumber"
                type="text"
                placeholder="Telefone *"
              />
            </Container>

            <ContainerSizeAutoComplete>
              <Autocomplete
                name="is_active"
                options={optionsStatus}
                placeholder="Status *"
                changeValue={null}
              />
            </ContainerSizeAutoComplete>

            <ContainerFull>
              <Input name="comments" type="text" placeholder="Observações" />
            </ContainerFull>
          </Row>

          <ContainerRadio>
            <Radio
              name="type_code"
              options={[
                { id: '2', label: 'Vendedor', icon: optionSales },
                { id: '1', label: 'Admin', icon: optionAdmin },
                { id: '4', label: 'Transportador', icon: optionTransport },
                { id: '3', label: 'Cliente', icon: optionClient },
              ]}
              changeType={handleuserType}
            />
          </ContainerRadio>
          <Row>
            {userType === '3' && (userId || params.id) && (
              <Container>
                <Button type="button" onClick={openModal}>
                  <FiClipboard size={24} />
                  Vincular Clientes
                </Button>
              </Container>
            )}
            <Container>
              <Button type="submit">
                <FiClipboard size={24} />
                {buttonText}
              </Button>
            </Container>
          </Row>
        </Form>

        <Modal
          isOpen={showModal}
          onClose={setShowModal}
          title="Vincular Clientes"
          backgroundColor="#F8F8FB"
          fontColor="#3a3a3a"
          id="modal-link-clients"
        >
          <div>
            <Form
              ref={modalFormRef}
              initialData={{}}
              onSubmit={handleAddUserCliente}
            >
              <Row style={{ alignItems: 'center' }}>
                <ContainerSize size="calc(100% - 46px)">
                  <Autocomplete
                    name="clients"
                    options={optionsClient}
                    placeholder="Cliente"
                    changeValue={null}
                    onInputChange={handleClientsInputChange}
                    isLoading={isLoadingClient}
                  />
                </ContainerSize>
                <ButtonAddClient type="submit">
                  <BiUserPlus size={20} />
                </ButtonAddClient>
              </Row>
            </Form>
            <ListFiles>
              {userClients ? (
                userClients.map((item: any) => (
                  <div key={item.id}>
                    <span>
                      {(item.client.name
                        ? `${item.client.name}-`
                        : `${item.client.company_name}-`) +
                        item.client.document_number}
                    </span>
                    <button
                      type="button"
                      onClick={() => handleDeleteUserClient(item.id)}
                    >
                      <FaTrash size={20} />
                    </button>
                  </div>
                ))
              ) : (
                <></>
              )}
            </ListFiles>
          </div>
        </Modal>
      </PageContainer>
    </Background>
  );
};
export default UserRegister;
