import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  collectionOrderId: 1,
};

export const collectionOrderSlice = createSlice({
  name: 'collection_order',
  initialState,
  reducers: {
    setCollectionOrderId: (state, { payload: collectionOrderId }) => {
      state.collectionOrderId = !collectionOrderId
        ? initialState.collectionOrderId
        : collectionOrderId;
    },
  },
});

export interface CollectionResponseData {
  id: number;
  client: {
    document_number: string;
  };
  origin: {
    name: string;
    uf: string;
  };
  destination: {
    name: string;
    uf: string;
  };
  volume: number;
  weight: number;
  cubage: number;
  value: number;
  nf_key: string;
  nfe_value: string;
}

interface ResponseCollections {
  data: {
    data: CollectionResponseData[];
    per_page: number;
    total_pages: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseInfosCollection {
  data: {
    total: number;
    opened: number;
    closed: number;
    average: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const { setCollectionOrderId } = collectionOrderSlice.actions;

export const fetchCollectionOrderWithParams = async (
  params: any,
): Promise<ResponseCollections> => {
  const response = await axios.get('/local/collection_order', {
    params,
  });
  return response;
};

export const getInfosCollections = async (
  params: any,
): Promise<ResponseInfosCollection> => {
  const response = await axios.get('/local/collection_order/infos', {
    params,
  });
  return response;
};

export const deleteCollection = async (id: number): Promise<any> => {
  const response = await axios.delete(`/local/collection_order/${id}`);
  return response;
};

export default collectionOrderSlice.reducer;
