import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const FilterButton = styled.button`
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #003CA6;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${shade(0.2, '#003CA6')};
  }
`;

interface Props {
  isVisible: boolean;
}

export const DialogContainer = styled.div<Props>`
  min-width: 320px;
  max-width: 400px;
  width: 95%;
  background-color: #F8F8FB;
  border-radius: 10px;
  position: fixed;
  padding: 20px;
  padding-top: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1074;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;

  ${props =>
    props.isVisible === false &&
    css`
      right: -320px;
      opacity: 0;
    `}
`;

export const DialogBackdrop = styled.div<Props>`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  position: fixed;
  z-index: 1073;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.25s ease;

  ${props =>
    props.isVisible === false &&
    css`
      opacity: 0;
      z-index: -5;
    `}
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: auto;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    color: #003CA6;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 22px;
  line-height: 26px;
  color: #3a3a3a;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: calc(50% - 5px);
    border-radius: 5px;
    border: 1px solid transparent;
    transition: 0.3s all ease;
  }

  button:first-child {
    background-color: transparent;
    color: #003CA6;

    &:hover {
      background-color: #003CA6;
      color: #fff;
    }
  }

  button:last-child {
    background-color: #003CA6;
    color: #fff;

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }
  }
`;
