import React from 'react';

import { Container, Section } from './styles';
import Menu from '../Menu';

const PageContainer: React.FC = ({ children }) => {
  return (
    <Container>
      <Menu />
      <Section>{children}</Section>
    </Container>
  );
};

export default PageContainer;
