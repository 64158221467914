import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  currentListPage: 1,
};

export const ClientInvoiceSlice = createSlice({
  name: 'clientInvoices',
  initialState,
  reducers: {
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});

export interface ClientInformationData {
  id: number;
  company_name: string;
  name: string;
  document_number: string;
}

export interface ClientInvoicesData {
  id: number;
  client: ClientInformationData;
  client_id: number;
  branch_id: number;
  invoice_date: Date;
  due_date: Date;
  contract: string;
  value: string;
  is_settled: boolean;
  pay_date: Date;
}

interface InvoicesResponse {
  data: {
    data: ClientInvoicesData[];
    per_page: number;
    total_pages: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ViewInvoicesResponse {
  data: ClientInvoicesData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getClientInvoiceByCode = async (
  code: string,
): Promise<ViewInvoicesResponse> => {
  const clientsInvoices = await axios.get(`/local/client_invoices/${code}`);
  return clientsInvoices;
};

export const getClientInvoices = async (
  params: any,
): Promise<InvoicesResponse> => {
  const clientsInvoices = await axios.get(`/local/client_invoices`, {
    params,
  });
  return clientsInvoices;
};

export const sendMailInvoice = async (
  id: number,
): Promise<{ status: number }> => {
  const send = await axios
    .get(`/local/client_invoices/send_email/${id}`)
    .then(res => res)
    .catch(ex => ex);
  return send;
};

export const getInvoiceDetailsInfo = async (
  id: number,
): Promise<{ status: number }> => {
  const send = await axios
    .get(`/local/client_invoices/details/${id}`)
    .then(res => res)
    .catch(ex => ex);
  return send;
};

export const downloadCSV = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/client_invoices/grouped_excel`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export const downloadPDF = async (params: any): Promise<any> => {
  const fileResponse = await axios.get(`/local/client_invoices/grouped_pdf`, {
    params,
    responseType: 'blob',
  });
  return fileResponse;
};

export const { setCurrentListPage } = ClientInvoiceSlice.actions;

export default ClientInvoiceSlice.reducer;
