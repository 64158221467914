/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { RiFileEditFill, RiMailAddLine } from 'react-icons/ri';
import { FaTrash } from 'react-icons/fa';
import { VscFileSymlinkFile } from 'react-icons/vsc';
import { useDebounce } from 'use-debounce';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiArrowLeft } from 'react-icons/fi';
import { cep } from '../../../utils/cep';
import { useToast } from '../../../hooks/toast';
import { useLoading } from '../../../hooks/loading';
import getValidationErrors from '../../../utils/getValidationErrors';
import { fetchSalesmanWithParams } from '../../../features/salesman';
import {
  addNewFile,
  fetchFilesByClient,
  removeFile,
} from '../../../features/clientFiles';

import { fetchBranchesByName } from '../../../features/branches';
import { fetchCityByNameAndUF } from '../../../features/city';
import { fetchCompanyTypeByName } from '../../../features/companyTypes';
import {
  addClientThunk,
  updateClientThunk,
  clientsSlice,
  getClient,
  addClientEmailThunk,
  setEmails,
} from '../../../features/clients';
import PageContainer from '../../../components/PageContainer';

import MainTitle from '../../../components/MainTitle';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import ButtonForm from '../../../components/ButtonForm';
import Autocomplete from '../../../components/Autocomplete';
import Modal from '../../../components/Modal';
import UploadArea from '../../../components/UploadArea';

import {
  Row,
  ContainerSize,
  ListFiles,
  ButtonAddFile,
  IdContainer,
  BackButton,
} from './styles';
import CurrencyInput from '../../../components/CurrencyInput';
import {
  formatValueFromCurrencyInput,
  formatValueToCurrencyInput,
} from '../../../utils/formatValueCurrencyInput';
import { AppDispatch } from '../../../app/store';
import RegisterClientEmail from '../ModalEmail';

interface ClientParams {
  id: string | undefined;
}

interface Documents {
  id: number;
  file_name: string;
  client_id: number;
  file_link: string;
}
const RegisterClient: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch: AppDispatch = useDispatch();
  const [fiscalSituation, setFiscalSituation] = useState(null);
  const [documentType, setDocumentType] = useState('cpf');
  const [optionsSalesmans, setOptionsSalesmans] = useState<any>([]);
  const [optionsBranches, setOptionsBranches] = useState<any>([]);
  const [optionsCity, setOptionsCity] = useState<any>([]);
  const [optionsCompanyType, setOptionsCompanyType] = useState<any>([]);
  const [searchUsers, setSearchUsers] = useState('');
  const [searchBranch, setSearchBranch] = useState('');
  const [searchCompanyType, setSearchCompanyType] = useState('');
  const [valueSearchSalesman] = useDebounce(searchUsers, 1000);
  const [valueSearchBranch] = useDebounce(searchBranch, 1000);
  const [valueSearchCompanyType] = useDebounce(searchCompanyType, 1000);
  const [isLoadingSalesmans, setIsLoadingSalesmans] = useState(false);
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  const [isLoadingCompanyType, setIsLoadingCompanyType] = useState(false);
  const [cepValid, setCepValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [phoneNumberMask, setPhoneNumberMask] = useState('(99) 9999-9999?');
  const [pageTitle, setPageTitle] = useState('Cadastro de Cliente');
  const [buttonText, setButtonText] = useState('Cadastrar');
  const [files, setFiles] = useState<Array<Documents>>([]);
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { setClientId } = clientsSlice.actions;
  const history = useHistory();

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const openEmailModal = useCallback((): void => {
    setShowEmailModal(true);
  }, []);

  const { clientId, userType, userTypeId, emails } = useSelector(
    (state: RootStateOrAny) => ({
      clientId: state.features.client.clientId,
      userType: state.features.auth.type,
      userTypeId: state.features.auth.userTypeId,
      userId: state.features.auth.userId,
      emails: state.features.client.emails,
    }),
  );

  const removeDocument = useCallback(
    async (id: number): Promise<void> => {
      showLoading();
      await removeFile(clientId, id);
      setFiles(state => state.filter(file => file.id !== id));
      addToast({
        title: 'Sucesso',
        type: 'success',
        description: 'Arquivo excluído com sucesso',
      });
      hideLoading();
    },
    [showLoading, clientId, addToast, hideLoading],
  );

  interface FormFields {
    salesman_id: number;
    branch_id: number;
    city: number;
    company_type: number;
    razao_social?: string;
    fiscal_situation: string;
    state_registration?: string;
    cep: string;
    street: string;
    number: string;
    phone_number: string;
    neighborhood: string;
    status: number;
    cpf: string;
    cnpj: string;
    name: string;
    cubage_factor: string;
    reference: string;
    complement: string;
  }

  const optionsStatus = [
    { value: 1, label: 'Ativo' },
    { value: 2, label: 'Inativo' },
  ];

  const optionsStates = [
    {
      value: 'AC',
      label: 'Acre',
    },
    {
      value: 'AL',
      label: 'Alagoas',
    },
    {
      value: 'AM',
      label: 'Amazonas',
    },
    {
      value: 'AP',
      label: 'Amapá',
    },
    {
      value: 'BA',
      label: 'Bahia',
    },
    {
      value: 'CE',
      label: 'Ceará',
    },
    {
      value: 'DF',
      label: 'Distrito Federal',
    },
    {
      value: 'ES',
      label: 'Espírito Santo',
    },
    {
      value: 'GO',
      label: 'Goiás',
    },
    {
      value: 'MA',
      label: 'Maranhão',
    },
    {
      value: 'MG',
      label: 'Minas Gerais',
    },
    {
      value: 'MS',
      label: 'Mato Grosso do Sul',
    },
    {
      value: 'MT',
      label: 'Mato Grosso',
    },
    {
      value: 'PA',
      label: 'Pará',
    },
    {
      value: 'PB',
      label: 'Paraíba',
    },
    {
      value: 'PE',
      label: 'Pernambuco',
    },
    {
      value: 'PI',
      label: 'Piauí',
    },
    {
      value: 'PR',
      label: 'Paraná',
    },
    {
      value: 'RJ',
      label: 'Rio de Janeiro',
    },
    {
      value: 'RN',
      label: 'Rio Grande do Norte',
    },
    {
      value: 'RO',
      label: 'Rondônia',
    },
    {
      value: 'RR',
      label: 'Roraima',
    },
    {
      value: 'RS',
      label: 'Rio Grande do Sul',
    },
    {
      value: 'SC',
      label: 'Santa Catarina',
    },
    {
      value: 'SE',
      label: 'Sergipe',
    },
    {
      value: 'SP',
      label: 'São Paulo',
    },
    {
      value: 'TO',
      label: 'Tocantins',
    },
  ];

  const optionsFiscalSituation = [
    { value: '2', label: 'Comércio' },
    { value: '3', label: 'Armazenagem' },
    { value: '4', label: 'Indústria' },
    { value: '5', label: 'Produto Rural' },
    { value: '6', label: 'Prestador Não Contribuinte' },
    { value: '7', label: 'Serviço de Comunicação' },
    { value: '8', label: 'Gerador/Prestador de Energia' },
    { value: '9', label: 'Prestador Contribuinte' },
  ];

  const handleSubmit = useCallback(
    async (data: FormFields): Promise<void | false> => {
      const cepMasked = data.cep;
      const phoneMasked = data.phone_number;
      try {
        (formRef.current as any).setErrors({});
        data.cep = data.cep.replace(/[^0-9]/g, '');
        data.phone_number = data.phone_number.replace(/[^0-9]/g, '');
        data.cubage_factor = formatValueFromCurrencyInput(data.cubage_factor);
        const schemaValidation = Yup.object().shape({
          salesman_id:
            userType === 'admin'
              ? Yup.string().required('Selecione o vendedor')
              : Yup.string().notRequired(),
          cep: Yup.string().min(7, 'Mínimo de 10 dígitos'),
          phone_number: Yup.string().min(10, 'Mínimo de 10 dígitos'),
          fiscal_situation: Yup.string().required(
            'Selecione a situação fiscal',
          ),
          street: Yup.string().required('Informe o endereço'),
          number: Yup.string().required('Informe o número'),
          neighborhood: Yup.string().required('Informe o bairro'),
          city: Yup.string().required('Informe a cidade'),
          state: Yup.string().required('Informe o estado'),
          branch_id: Yup.string().required('Informe a filial'),
          company_type: Yup.string().required('Informe o tipo da empresa'),
          status: Yup.string().required('Informe o status'),
          cubage_factor: Yup.number().required('Informe um valor'),
          complement: Yup.string(),
          reference: Yup.string(),
        });
        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        let document_number = '';
        if (data.fiscal_situation !== '') {
          try {
            let schemaValidation2;
            if (documentType === 'cpf') {
              document_number = data.cpf;
              data.cpf = data.cpf.replace(/[^0-9]/g, '');
              schemaValidation2 = Yup.object().shape({
                cpf: Yup.string().min(11, 'Mínimo de 11 dígitos'),
                name: Yup.string().required('Informe o nome'),
              });
            } else {
              document_number = data.cnpj;
              data.cnpj = data.cnpj.replace(/[^0-9]/g, '');
              schemaValidation2 = Yup.object().shape({
                cnpj: Yup.string().min(14, 'Mínimo de 14 dígitos'),
                cpf: Yup.string().min(11, 'Mínimo de 11 dígitos'),
                razao_social: Yup.string().required('Informe a razão social'),
                state_registration: Yup.string().required(
                  'Informe a inscrição estadual',
                ),
              });
            }
            await schemaValidation2.validate(data, {
              abortEarly: false,
            });
          } catch (err2) {
            const errors = getValidationErrors(err2);
            (formRef.current as any).setErrors(errors);
          }

          if (cepValid === true) {
            showLoading();
            try {
              const dataClient = {
                salesman_id:
                  userType === 'admin' ? data.salesman_id : userTypeId,
                branch_id: data.branch_id,
                city_id: data.city,
                company_type_id: data.company_type,
                company_name: data.razao_social,
                fiscal_situation: data.fiscal_situation,
                state_registration: data.state_registration,
                document_number,
                name: data.name,
                cep: cepMasked,
                address: data.street,
                number: data.number,
                phone_number: phoneMasked,
                district: data.neighborhood,
                is_active: data.status === 1,
                cubage_factor: data.cubage_factor,
                reference: data.reference,
                complement: data.complement,
              };
              if (!clientId) {
                const Response = await dispatch(
                  addClientThunk({ data: dataClient }),
                );

                if (addClientThunk.fulfilled.match(Response)) {
                  addToast({
                    type: 'success',
                    title: 'Sucesso',
                    description: 'Cliente cadastrado com sucesso',
                  });

                  if (emails.length > 0) {
                    const emailsRedux = emails;

                    for (const emailRedux of emailsRedux) {
                      const { type, email } = emailRedux;

                      const newEmail = {
                        type,
                        email,
                        client_id: Response.payload.id,
                      };

                      // eslint-disable-next-line no-await-in-loop
                      await dispatch(addClientEmailThunk({ data: newEmail }));
                    }
                    dispatch(setEmails([]));
                  }
                } else {
                  addToast({
                    type: 'error',
                    title: 'Erro',
                    description: 'Erro ao cadastrar o cliente',
                  });
                }
              } else {
                const Response = await dispatch(
                  updateClientThunk({ id: clientId, data: dataClient }),
                );
                if (updateClientThunk.fulfilled.match(Response)) {
                  addToast({
                    type: 'success',
                    title: 'Sucesso',
                    description: 'Cliente alterado com sucesso',
                  });
                } else {
                  addToast({
                    type: 'error',
                    title: 'Erro',
                    description: 'Erro ao cadastrar o cliente',
                  });
                }
              }
              hideLoading();
            } catch (error) {
              hideLoading();
              addToast({
                type: 'error',
                title: 'Erro',
                description: 'Erro ao cadastrar o cliente',
              });
            }
          } else {
            (formRef.current as any).setFieldError(
              'cep',
              'Digite um CEP válido',
            );
            (formRef.current as any).setFieldValue('city', {});
            (formRef.current as any).setFieldValue('state', {});
          }
        }
      } catch (err) {
        const errors = getValidationErrors(err);
        (formRef.current as any).setErrors(errors);
      }
    },
    [
      addToast,
      cepValid,
      clientId,
      dispatch,
      hideLoading,
      showLoading,
      userType,
      userTypeId,
      documentType,
      emails,
    ],
  );

  const handleSalesmanInputChange = (newValue: string): void => {
    if (newValue !== searchUsers) {
      setIsLoadingSalesmans(true);
    }
    setSearchUsers(newValue);
  };

  const handleBranchInputChange = (newValue: string): void => {
    if (newValue !== searchBranch) {
      setIsLoadingBranch(true);
    }
    setSearchBranch(newValue);
  };

  const handleCompanyInputChange = (newValue: string): void => {
    if (newValue !== searchCompanyType) {
      setIsLoadingCompanyType(true);
    }

    setSearchCompanyType(newValue);
  };

  useEffect(() => {
    (async () => {
      const salesmans = await fetchSalesmanWithParams({
        salesname: valueSearchSalesman,
      });
      const allSalesmans: any = [];
      salesmans.data.forEach((salesman: any): any => {
        allSalesmans.push({ value: salesman.id, label: salesman.salesname });
      });
      setOptionsSalesmans(allSalesmans);
      setIsLoadingSalesmans(false);
    })();
  }, [valueSearchSalesman]);

  useEffect(() => {
    (async () => {
      const branches = await fetchBranchesByName({ name: valueSearchBranch });
      const allBranches: any = [];
      branches.forEach((branch: any): any => {
        allBranches.push({
          value: branch.id,
          label: `${branch.code_rodopar} - ${branch.name}`,
        });
      });
      setOptionsBranches(allBranches);
      setIsLoadingBranch(false);
    })();
  }, [valueSearchBranch]);

  useEffect(() => {
    (async () => {
      const companyTypes = await fetchCompanyTypeByName({
        name: valueSearchCompanyType,
      });
      const allCompanyTypes: any = [];
      companyTypes.forEach((companyType: any): any => {
        allCompanyTypes.push({
          value: companyType.id,
          label: companyType.name,
        });
      });
      setOptionsCompanyType(allCompanyTypes);
      setIsLoadingCompanyType(false);
    })();
  }, [valueSearchCompanyType]);

  const handleInputCep = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const cepSearch = e.currentTarget.value.replace('_', '');
      if (cepSearch.length === 10) {
        e.currentTarget.blur();
        showLoading();
        const searchAddress = await cep(cepSearch);
        if (typeof searchAddress.erro === 'undefined') {
          (formRef.current as any).setFieldValue(
            'street',
            searchAddress.logradouro,
          );
          (formRef.current as any).setFieldValue(
            'neighborhood',
            searchAddress.bairro,
          );
          let selectedState = {};
          optionsStates.forEach(state => {
            if (state.value === searchAddress.uf) {
              selectedState = {
                value: searchAddress.uf,
                label: state.label,
              };
            }
          });
          (formRef.current as any).setFieldValue('state', selectedState);
          const cities = await fetchCityByNameAndUF({
            name: searchAddress.localidade,
            uf: searchAddress.uf,
          });
          const allCities: any = [];
          cities.forEach((city: any): any => {
            allCities.push({ value: city.id, label: city.name });
          });
          setOptionsCity(allCities);
          (formRef.current as any).setFieldValue('city', allCities[0]);
          hideLoading();
          (document.querySelector('#number') as any).focus();
          addToast({
            type: 'success',
            title: 'CEP encontrado',
          });
          (formRef.current as any).setFieldError(`cep`, '');

          setCepValid(true);
        } else {
          hideLoading();
          (formRef.current as any).setFieldError('cep', 'CEP não encontrado');
          (document.querySelector('#cep') as any).focus();
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'CEP não encontrado',
          });
          setCepValid(false);
        }
      } else {
        setCepValid(false);
      }
    },
    [addToast, hideLoading, optionsStates, showLoading],
  );

  const handlerChangeBrazilianPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length <= 10) {
        setPhoneNumberMask('(99) 9999-9999?');
      } else {
        setPhoneNumberMask('(99) 99999-9999');
      }
    },
    [],
  );

  useEffect(() => {}, [phoneNumberMask]);

  const submitFiles = useCallback((): void => {}, []);

  const handleFiscalSituationChange = useCallback((value: any): void => {
    setFiscalSituation(value.value);
  }, []);

  const handleCompanyTypeChange = useCallback((value: any): void => {
    if (value.label === 'Física') {
      setDocumentType('cpf');
    } else {
      setDocumentType('cnpj');
    }
  }, []);

  useEffect(() => {
    if (clientId) {
      setButtonText('Alterar');
      setPageTitle('Alterar Cliente');
    }
  }, [clientId]);

  useEffect(() => {}, [emails]);

  const handleChangeAddDocs = useCallback(
    async (e: any): Promise<any> => {
      if (e.target.files.length > 0) {
        showLoading();
        const promises = [];
        const fileNames = Array.from(e.target.files).map(
          ({ name }: any) => name,
        );
        for (let i = 0; i < fileNames.length; i++) {
          promises.push(addNewFile(clientId, e.target.files[i], fileNames[i]));
        }
        await Promise.all(promises);
        const clientFiles = await fetchFilesByClient({
          client_id: clientId,
        });
        setFiles(clientFiles);
        hideLoading();
      }
    },
    [clientId, hideLoading, showLoading],
  );

  const { params } = useRouteMatch<ClientParams>();

  useEffect(() => {
    if (params.id) {
      (async () => {
        showLoading();
        dispatch(setClientId(Number(params.id)));

        const { data: client } = await getClient(params.id);
        if ((client as any).salesman) {
          (formRef.current as any).setFieldValue('salesman_id', {
            value: (client as any).salesman_id,
            label: (client as any).salesman.salesname,
          });
        }

        setCepValid(true);
        const phoneNumber = client.phone_number.replace(/[^0-9]/g, '');
        if (phoneNumber.length <= 10) {
          setPhoneNumberMask('(99) 9999-9999?');
        } else {
          setPhoneNumberMask('(99) 99999-9999');
        }
        (formRef.current as any).setFieldValue('cep', client.cep);
        (formRef.current as any).setFieldValue(
          'cubage_factor',
          formatValueToCurrencyInput(client.cubage_factor),
        );
        (formRef.current as any).setFieldValue('street', client.address);
        (formRef.current as any).setFieldValue('number', client.number);
        (formRef.current as any).setFieldValue('reference', client.reference);
        (formRef.current as any).setFieldValue('complement', client.complement);
        (formRef.current as any).setFieldValue(
          'phone_number',
          client.phone_number,
        );
        (formRef.current as any).setFieldValue('neighborhood', client.district);
        (formRef.current as any).setFieldValue('city', {
          value: (client as any).city_id,
          label: (client as any).city.name,
        });
        (formRef.current as any).setFieldValue('state', {
          value: (client as any).city.uf,
          label: (client as any).city.uf,
        });
        (formRef.current as any).setFieldValue('branch_id', {
          value: (client as any).branch_id,
          label: (client as any).branch.name,
        });
        (formRef.current as any).setFieldValue('company_type', {
          value: (client as any).companyType.id,
          label: (client as any).companyType.name,
        });
        (formRef.current as any).setFieldValue('status', {
          value: (client as any).is_active ? 1 : 2,
          label: (client as any).is_active ? 'Ativo' : 'Inativo',
        });
        (formRef.current as any).setFieldValue('fiscal_situation', {
          value: (client as any).fiscal_situation,
          label: optionsFiscalSituation.filter(
            p => p.value === (client as any).fiscal_situation,
          )[0].label,
        });
        if ((client as any).companyType.name !== 'Física') {
          (formRef.current as any).setFieldValue(
            'cnpj',
            client.document_number,
          );
          (formRef.current as any).setFieldValue(
            'razao_social',
            client.company_name,
          );
          (formRef.current as any).setFieldValue(
            'state_registration',
            client.state_registration,
          );
        } else {
          (formRef.current as any).setFieldValue('cpf', client.document_number);
          (formRef.current as any).setFieldValue('name', client.name);
        }
        const clientFiles = await fetchFilesByClient({
          client_id: params.id,
        });
        setFiles(clientFiles);
        (document.querySelector('#cep') as any).focus();
        (document.querySelector('#phone_number') as any).focus();
        (document.querySelector('#phone_number') as any).blur();
        hideLoading();
      })();
    }
  }, [dispatch, hideLoading, params.id, setClientId, showLoading]);

  useEffect(() => {}, [fiscalSituation]);
  useEffect(() => {}, [optionsSalesmans]);
  useEffect(() => {
    if (cepValid === false) {
      (formRef.current as any).setFieldError('cep', 'Digite um CEP válido');
      (formRef.current as any).setFieldValue('street', '');
      (formRef.current as any).setFieldValue('neighborhood', '');
      (formRef.current as any).setFieldValue('city', {});
      (formRef.current as any).setFieldValue('state', {});
    } else {
      (formRef.current as any).setFieldError('cep', '');
    }
  }, [cepValid]);
  useEffect(() => {}, [optionsBranches]);
  useEffect(() => {}, [optionsCity]);
  useEffect(() => {}, [optionsCompanyType]);

  useEffect(() => {}, [files]);
  useEffect(() => {}, [documentType]);

  useEffect(() => {
    console.log(clientId === 0 && params.id);
    if (clientId === 0 && params.id) {
      dispatch(setClientId(Number(params.id)));
    }
  }, []);

  return (
    <PageContainer>
      <MainTitle>{pageTitle}</MainTitle>
      <Row>
        <ContainerSize size="20%">
          <IdContainer>Id Cliente: {clientId || ''}</IdContainer>
        </ContainerSize>
        <ContainerSize size="70%" />
        <ContainerSize size="10%">
          <BackButton onClick={() => history.goBack()}>
            {' '}
            <FiArrowLeft size={30} />
          </BackButton>
        </ContainerSize>
      </Row>
      <Form
        ref={formRef}
        initialData={{}}
        onSubmit={handleSubmit}
        noValidate
        autoComplete="new-password"
      >
        <Row>
          {userType === 'admin' && (
            <ContainerSize size="50%">
              <Autocomplete
                name="salesman_id"
                options={optionsSalesmans}
                placeholder="Vendedor *"
                changeValue={null}
                onInputChange={handleSalesmanInputChange}
                isLoading={isLoadingSalesmans}
              />
            </ContainerSize>
          )}
          <ContainerSize size="50%">
            <Autocomplete
              name="company_type"
              options={optionsCompanyType}
              placeholder="Tipo de empresa *"
              changeValue={handleCompanyTypeChange}
              onInputChange={handleCompanyInputChange}
              isLoading={isLoadingCompanyType}
            />
          </ContainerSize>
        </Row>
        {documentType === 'cnpj' && (
          <Row>
            <ContainerSize size="33.33%">
              <InputMask
                mask="99.999.999/9999-99"
                name="cnpj"
                type="text"
                placeholder="CNPJ *"
                autoFocus
              />
            </ContainerSize>

            <ContainerSize size="33.33%">
              <Input
                name="razao_social"
                type="text"
                placeholder="Razão social *"
              />
            </ContainerSize>

            <ContainerSize size="33.33%">
              <Input
                name="state_registration"
                type="text"
                placeholder="I.E *"
              />
            </ContainerSize>
          </Row>
        )}
        {documentType === 'cpf' && (
          <Row>
            <ContainerSize size="50%">
              <Input name="name" type="text" placeholder="Nome *" autoFocus />
            </ContainerSize>
            <ContainerSize size="50%">
              <InputMask
                mask="999.999.999-99"
                name="cpf"
                type="text"
                placeholder="CPF *"
              />
            </ContainerSize>
          </Row>
        )}

        <Row>
          <ContainerSize size="25%">
            <InputMask
              name="cep"
              type="text"
              placeholder="CEP *"
              onKeyUp={handleInputCep}
              mask="99.999-999"
            />
          </ContainerSize>
          <ContainerSize size="50%">
            <Input name="street" type="text" placeholder="Endereço *" />
          </ContainerSize>
          <ContainerSize size="25%">
            <Input name="number" type="text" placeholder="Número *" />
          </ContainerSize>
          <ContainerSize size="30%">
            <Input name="neighborhood" type="text" placeholder="Bairro *" />
          </ContainerSize>
          <ContainerSize size="45%">
            <Autocomplete
              name="city"
              options={optionsCity}
              placeholder="Município *"
              changeValue={null}
              isDisabled
            />
          </ContainerSize>
          <ContainerSize size="25%">
            <InputMask
              name="phone_number"
              type="text"
              placeholder="Telefone *"
              mask={phoneNumberMask}
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              onKeyUp={handlerChangeBrazilianPhone}
              maskChar=""
            />
          </ContainerSize>
          <ContainerSize size="50%">
            <Input name="complement" type="text" placeholder="Complemento" />
          </ContainerSize>
          <ContainerSize size="50%">
            <Input name="reference" type="text" placeholder="Referência" />
          </ContainerSize>

          <ContainerSize size="50%">
            <Autocomplete
              name="state"
              options={optionsStates}
              placeholder="Estado *"
              changeValue={null}
              isDisabled
            />
          </ContainerSize>

          <ContainerSize size="50%">
            <Autocomplete
              name="branch_id"
              options={optionsBranches}
              placeholder="Filial"
              changeValue={null}
              onInputChange={handleBranchInputChange}
              isLoading={isLoadingBranch}
            />
          </ContainerSize>

          <ContainerSize size="33.33%">
            <Autocomplete
              name="fiscal_situation"
              options={optionsFiscalSituation}
              placeholder="Situação Fiscal *"
              changeValue={handleFiscalSituationChange}
            />
          </ContainerSize>

          <ContainerSize size="33.33%">
            <Autocomplete
              name="status"
              options={optionsStatus}
              placeholder="Status *"
              changeValue={null}
            />
          </ContainerSize>

          <ContainerSize size="33.33%">
            <CurrencyInput
              name="cubage_factor"
              placeholder="Fator de cubagem *"
              defaultValue="300"
            />
          </ContainerSize>
        </Row>
        {clientId > 0 && (
          <Row>
            <ContainerSize size="30%" />
            <ContainerSize size="40%">
              <ButtonAddFile type="button" onClick={openModal}>
                <span>Arquivos</span>
                <VscFileSymlinkFile size={20} />
              </ButtonAddFile>
            </ContainerSize>
          </Row>
        )}
        <Row>
          <ContainerSize size="30%" />
          <ContainerSize size="40%">
            <ButtonAddFile type="button" onClick={openEmailModal}>
              <span>E-mails</span>
              <RiMailAddLine size={20} />
            </ButtonAddFile>
          </ContainerSize>
        </Row>
        <Row>
          <ContainerSize size="30%" />
          <ContainerSize size="40%">
            <ButtonForm type="submit">
              <RiFileEditFill size={20} />
              {buttonText}
            </ButtonForm>
          </ContainerSize>
        </Row>
      </Form>

      <Modal
        isOpen={showEmailModal}
        id="modal-emails"
        onClose={() => setShowEmailModal(false)}
        title="E-mails de Contato"
        backgroundColor="#F8F8FB"
        fontColor="#202020"
      >
        <div>
          <RegisterClientEmail clientId={clientId} />
        </div>
      </Modal>

      <Modal
        isOpen={showModal}
        id="modal-files"
        onClose={() => setShowModal(false)}
        title="Arquivos"
      >
        <div>
          <Form initialData={{}} onSubmit={submitFiles}>
            <UploadArea multiple name="anexos" onChange={handleChangeAddDocs} />
          </Form>
          <ListFiles>
            {files.map(file => (
              <div key={file.id}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  download={file.file_name}
                  href={file.file_link}
                >
                  {file.file_name}
                </a>
                <button
                  type="button"
                  onClick={() => {
                    removeDocument(file.id);
                  }}
                >
                  <FaTrash size={20} />
                </button>
              </div>
            ))}
          </ListFiles>
        </div>
      </Modal>
    </PageContainer>
  );
};

export default RegisterClient;
