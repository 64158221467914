/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { MdModeEdit } from 'react-icons/md';

import { IoIosAddCircle } from 'react-icons/io';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useDebounce } from 'use-debounce';
import PageContainer from '../../../components/PageContainer';

import MainTitle from '../../../components/MainTitle';
import Input from '../../../components/Input';

import { TitleContainer, SpecialContainer } from './styles';

import { useToast } from '../../../hooks/toast';
import { useLoading } from '../../../hooks/loading';
import Pagination from '../../../components/Pagination';

import {
  getClients,
  setClientId,
  clientsSlice,
} from '../../../features/clients';
import TableList from '../../../components/TableList';
import Filter from '../../../components/Filter';
import { fetchSalesmanByName } from '../../../features/salesman';
import Autocomplete from '../../../components/Autocomplete';
import { fetchCitiesByName } from '../../../features/city';
import InputMask from '../../../components/InputMask';
import { filterSlice } from '../../../features/filter';

const ListClient: React.FC = () => {
  const { currentListPage, formValue, locationFilter } = useSelector(
    (state: RootStateOrAny) => ({
      currentListPage: state.features.client.currentListPage,
      formValue: state.features.filter.formValue,
      locationFilter: state.features.filter.location,
    }),
  );
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [searchSalesman, setSearchSalesman] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [valueSearchSalesman] = useDebounce(searchSalesman, 1000);
  const [optionsSalesman, setOptionsSalesman] = useState<any>([]);
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const [valueSearchCities] = useDebounce(searchCity, 1000);
  const [optionsCity, setOptionsCity] = useState<any>([]);
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? [] : formValue,
  );
  const [totalClients, setTotalClients] = useState<number>(0);
  const [documentMask, setDocumentMask] = useState('999.999.999-99?');
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const dispatch = useDispatch();
  const { setCurrentListPage } = clientsSlice.actions;
  const { setFormValue } = filterSlice.actions;

  interface CityData {
    id: number;
    name: string;
    uf: string;
  }

  interface SalesmanData {
    id: number;
    salesname: string;
  }

  interface ClientData {
    id: number;
    fiscal_situation: string;
    company_name: string;
    name: string;
    created_at: Date;
    city: CityData;
    company_type_id: number;
    salesman: SalesmanData;
  }

  const optionsStates = [
    {
      value: 'AC',
      label: 'Acre',
    },
    {
      value: 'AL',
      label: 'Alagoas',
    },
    {
      value: 'AM',
      label: 'Amazonas',
    },
    {
      value: 'AP',
      label: 'Amapá',
    },
    {
      value: 'BA',
      label: 'Bahia',
    },
    {
      value: 'CE',
      label: 'Ceará',
    },
    {
      value: 'DF',
      label: 'Distrito Federal',
    },
    {
      value: 'ES',
      label: 'Espírito Santo',
    },
    {
      value: 'GO',
      label: 'Goiás',
    },
    {
      value: 'MA',
      label: 'Maranhão',
    },
    {
      value: 'MG',
      label: 'Minas Gerais',
    },
    {
      value: 'MS',
      label: 'Mato Grosso do Sul',
    },
    {
      value: 'MT',
      label: 'Mato Grosso',
    },
    {
      value: 'PA',
      label: 'Pará',
    },
    {
      value: 'PB',
      label: 'Paraíba',
    },
    {
      value: 'PE',
      label: 'Pernambuco',
    },
    {
      value: 'PI',
      label: 'Piauí',
    },
    {
      value: 'PR',
      label: 'Paraná',
    },
    {
      value: 'RJ',
      label: 'Rio de Janeiro',
    },
    {
      value: 'RN',
      label: 'Rio Grande do Norte',
    },
    {
      value: 'RO',
      label: 'Rondônia',
    },
    {
      value: 'RR',
      label: 'Roraima',
    },
    {
      value: 'RS',
      label: 'Rio Grande do Sul',
    },
    {
      value: 'SC',
      label: 'Santa Catarina',
    },
    {
      value: 'SE',
      label: 'Sergipe',
    },
    {
      value: 'SP',
      label: 'São Paulo',
    },
    {
      value: 'TO',
      label: 'Tocantins',
    },
  ];

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const clients = await getClients({ page, ...optionsSearch });
      const arrayClients: Array<Array<any>> = [];
      clients.data.forEach((client: ClientData) => {
        arrayClients.push([
          client.id,
          client.name ? client.name : client.company_name,
          client.city.name,
          client.city.uf,
          client.salesman && client.salesman.salesname,
          new Date(client.created_at).toLocaleString(),
          <div className="options-table-list">
            <Link to={`/cliente/${client.id}`}>
              <MdModeEdit size={20} color="#ffffff" />
            </Link>
          </div>,
        ]);
      });
      setResults(arrayClients);
      const arrayPages = [];
      for (let i = 0; i < clients.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setTotalClients(clients.total);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));
      hideLoading();
      addToast({ title: 'Clientes carregados com sucesso', type: 'success' });
      setIsLoadingPage(false);
    },
    [optionsSearch],
  );

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      setCurrentPage(1);
      const searchData: Obj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          searchData[key.replace('-', '.')] = value;
        }
      }
      setOptionsSearch(searchData);
      dispatch(setFormValue(searchData));
    },
    [setOptionsSearch],
  );

  const handleSalesmanInputChange = (newValue: string): void => {
    if (newValue !== searchSalesman) {
      setIsLoadingSalesman(true);
    }
    setSearchSalesman(newValue);
  };

  const handleCityInputChange = (newValue: string): void => {
    if (newValue !== searchSalesman) {
      setIsLoadingCity(true);
    }
    setSearchCity(newValue);
  };

  useEffect(() => {}, [documentMask]);

  useEffect(() => {
    (async () => {
      const salesman = await fetchSalesmanByName({
        salesname: valueSearchSalesman,
      });
      const allSalesman: any = [];
      salesman.forEach((branch: any): any => {
        allSalesman.push({ value: branch.id, label: branch.salesname });
      });
      setOptionsSalesman(allSalesman);
      setIsLoadingSalesman(false);
    })();
  }, [valueSearchSalesman]);

  useEffect(() => {
    (async () => {
      const city = await fetchCitiesByName({
        name: valueSearchCities,
      });
      const allCity: any = [];
      city.forEach((cityId: any): any => {
        allCity.push({
          value: cityId.id,
          label: `${cityId.name}-${cityId.uf}`,
        });
      });
      setOptionsCity(allCity);
      setIsLoadingCity(false);
    })();
  }, [valueSearchCities]);

  useEffect(() => {
    getData(currentPage);
  }, [optionsSearch]);

  useEffect(() => {
    dispatch(setClientId(0));
  }, [dispatch]);

  const handleDocumentMaskType = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const phoneNumber = e.currentTarget.value.replace(/[^0-9]/g, '');
      if (phoneNumber.length > 11) {
        setDocumentMask('99.999.999/9999-99');
      } else {
        setDocumentMask('999.999.999-99?');
      }
    },
    [],
  );

  return (
    <PageContainer>
      <TitleContainer>
        <MainTitle>Clientes Cadastrados</MainTitle>
      </TitleContainer>

      <SpecialContainer>
        <div className="form-content">
          <TableList
            header={[
              'Código',
              'Nome',
              'Cidade',
              'Estado',
              'Vendedor',
              'Data',
              'Opções',
            ]}
            data={results}
          />
          <Pagination
            pages={pages}
            currentPage={currentPage}
            onClickFunction={getData}
            isLoadingPage={isLoadingPage}
          />
        </div>

        <div className="client-total">
          <div className="span-total">
            <span className="number">{totalClients}</span>
            <span>Clientes</span>
          </div>

          <Link to="/cliente" className="add-button">
            <IoIosAddCircle size={22} />
            Adicionar
          </Link>
        </div>
      </SpecialContainer>
      <Filter onSubmit={handleSubmitForm}>
        <Autocomplete
          name="salesman_id"
          options={optionsSalesman}
          placeholder="Vendedor"
          changeValue={null}
          onInputChange={handleSalesmanInputChange}
          isLoading={isLoadingSalesman}
        />
        <Input name="clients-name" type="text" placeholder="Nome" />
        <InputMask
          name="clients-document_number"
          type="text"
          mask={documentMask}
          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
          onKeyUp={handleDocumentMaskType}
          placeholder="CPF/CNPJ"
          maskChar=""
        />
        <Autocomplete
          name="cities-id"
          options={optionsCity}
          placeholder="Cidade"
          changeValue={null}
          onInputChange={handleCityInputChange}
          isLoading={isLoadingCity}
        />
        <Autocomplete
          name="cities-uf"
          options={optionsStates}
          placeholder="Estado"
          changeValue={null}
        />
        <Input
          name="clients-created_at>"
          type="date"
          placeholder="Data Inicial"
        />
        <Input
          name="clients-created_at<"
          type="date"
          placeholder="Data Final"
        />
      </Filter>
    </PageContainer>
  );
};

export default ListClient;
