import React from 'react';
import { useSpring } from 'react-spring';

import TransrealLogo from '../../assets/transreal_logo.svg';

import { ContainerBackground, Spinner } from './styles';

const LoadingFull: React.FC = () => {
  const animated = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <ContainerBackground style={animated}>
      <img
        src={TransrealLogo}
        width="280px"
        style={{ marginTop: '-120px' }}
        alt="containerLoading"
      />
      <Spinner>
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </Spinner>
    </ContainerBackground>
  );
};

export default LoadingFull;
