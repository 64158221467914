/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLoading } from '../../hooks/loading';
import { logout, Authslice } from '../../features/auth';
import { patchUserLgpdAprove } from '../../features/users';

import {
  Modal,
  ModalDialog,
  TextTitle,
  TextSubtitle,
  ModalBackdrop,
  ModalContent,
  OkButton,
  YesNoContainer,
  NokButton,
  TermsContainer,
} from './styles';

interface Props {
  isOpen: boolean;
  width?: string;
  id?: string;
  onClose?: any;
  userId: number;
}

const ModalLgpd: React.FC<Props> = ({
  isOpen,
  id = 'modalinfo',
  onClose,
  userId,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { setLgpdsUser } = Authslice.actions;
  const { hideLoading } = useLoading();
  const dispatch = useDispatch();
  const history = useHistory();
  function closeModal(): void {
    setIsOpen(false);
    document.body.classList.remove('modal-open');
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (isOpen === true) {
      hideLoading();
      setIsOpen(true);
      document.body.classList.add('modal-open');
    } else {
      closeModal();
    }
  }, [isOpen]);

  function handleLogout(): any {
    dispatch(logout());
    history.push('login');
  }

  async function handleLgpdAprove(): Promise<any> {
    await patchUserLgpdAprove(userId);
    dispatch(setLgpdsUser(true));
  }

  return (
    <>
      <Modal id={id} isOpen={modalIsOpen}>
        <ModalDialog>
          <ModalContent>
            <TextTitle>Termos de Serviço</TextTitle>
            <TermsContainer>
              <div>
                <p>
                  <u>
                    <strong>
                      Termo de Uso e Consentimento para Tratamento de Dados
                      Pessoais
                    </strong>
                  </u>
                </p>
                <p>
                  Aceitando este documento, o TITULAR visa registrar a
                  manifesta&ccedil;&atilde;o livre, informada e
                  inequ&iacute;voca pela qual concorda com o tratamento de seus
                  dados pessoais para finalidade espec&iacute;fica, em
                  conformidade com a Lei n&ordm;. 13.709 &ndash; Lei Geral de
                  Prote&ccedil;&atilde;o de Dados Pessoais (LGPD), consentindo
                  que a Empresa REAL ROND&Ocirc;NIA TRANSPORTES E
                  LOG&Iacute;STICA LTDA, identificada aqui como TRANSREAL,
                  pessoa jur&iacute;dica de direito privado, inscrita no CNPJ
                  08.643.153/0001-69, com sede na Rua Jos&eacute; Ponce de
                  Azevedo n&ordm; 405, Sala 1, Distrito Industrial Doutor
                  Ulysses da Silva Guimar&atilde;es, Cidade S&atilde;o
                  Jos&eacute; do Rio Preto, estado de S&atilde;o Paulo,
                  doravante denominada CONTROLADOR, tome decis&otilde;es
                  referente ao tratamento de seus dados pessoais, bem como
                  realize o tratamento de seus dados pessoais, envolvendo
                  opera&ccedil;&otilde;es como as que se referem a coleta,
                  produ&ccedil;&atilde;o, recep&ccedil;&atilde;o,
                  classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso,
                  reprodu&ccedil;&atilde;o, transmiss&atilde;o,
                  distribui&ccedil;&atilde;o, processamento, arquivamento,
                  armazenamento, elimina&ccedil;&atilde;o,
                  avalia&ccedil;&atilde;o ou controle da
                  informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o,
                  comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o
                  ou extra&ccedil;&atilde;o.
                </p>
                <p>
                  <strong>
                    Caso n&atilde;o concorde com as cl&aacute;usulas deste termo
                    de aceite ou com as notas desta vers&atilde;o, n&atilde;o
                    preencha seus dados no formul&aacute;rio ou d&ecirc;
                    prosseguimento neste site.
                  </strong>
                </p>
                <p>
                  <strong>Dados Pessoais</strong>
                  <br />
                  O CONTROLADOR fica autorizado a tomar decis&otilde;es
                  referentes ao tratamento e a realizar o tratamento dos
                  seguintes dados pessoais do TITULAR:
                  <br />
                  <ul>
                    <li>Nome Completo.</li>
                    <li>RG, CPF e CNH.</li>
                    <li>Data de Nascimento.</li>
                    <li>Endere&ccedil;o Completo.</li>
                    <li>Telefone Fixo, M&oacute;vel e WhatsApp.</li>
                    <li>E-Mail.</li>
                    <li>
                      Nome de Usu&aacute;rio e senha espec&iacute;ficos para o
                      uso dos servi&ccedil;os do CONTROLADOR.
                    </li>
                    <li>
                      Comunica&ccedil;&atilde;o, verbal e escrita, mantida entre
                      o TITULAR e o CONTROLADOR.
                    </li>
                    <li>
                      Dados profissionais, como nome da empresa, CNPJ, cargo,
                      e-mail profissional.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Finalidade do Tratamento dos Dados</strong>
                  <br />O Tratamento dos dados pessoais listados neste termo tem
                  as seguintes finalidades:
                  <ul>
                    <li>
                      Possibilitar que o CONTROLADOR identifique e entre em
                      contato com o TITULAR para fins de relacionamento
                      comercial.
                    </li>
                    <li>
                      Possibilitar que o CONTROLADOR elabore contratos
                      comerciais e emita cobran&ccedil;as contra o TITULAR.
                    </li>
                    <li>
                      Possibilitar que o CONTROLADOR envie ou forne&ccedil;a ao
                      TITULAR seus Produtos e Servi&ccedil;os de forma
                      remunerada ou gratuita.
                    </li>
                    <li>
                      Possibilitar que o CONTROLADOR estruture, teste, promova e
                      fa&ccedil;a propaganda de produtos e servi&ccedil;os,
                      personalizados ou n&atilde;o ao perfil do TITULAR.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Obriga&ccedil;&otilde;es de Registro</strong>
                  <br />O Usu&aacute;rio dever&aacute; se registrar na TRANSREAL
                  para acessar e usar recursos do servi&ccedil;o. Se o
                  usu&aacute;rio optar por se registrar no servi&ccedil;o, o
                  usu&aacute;rio concorda em fornecer e manter
                  informa&ccedil;&otilde;es verdadeiras, precisas, atuais e
                  completas sobre o usu&aacute;rio, conforme solicitado pelo
                  Formul&aacute;rio de Registro do servi&ccedil;o. Dados de
                  registro e outras informa&ccedil;&otilde;es sobre o
                  usu&aacute;rio s&atilde;o regidos pela Pol&iacute;tica de
                  Privacidade.
                </p>
                <p>
                  <strong>Compartilhamento de Dados</strong>
                  <br />A Empresa TRANSREAL informa que n&atilde;o
                  compartilhar&aacute; seus dados pessoais, ficando armazenados
                  na empresa apenas para os fins acima.
                </p>
                <p>
                  <strong>
                    Pol&iacute;tica de Privacidade e Dados do Usu&aacute;rio
                  </strong>
                  <br />
                  <ul>
                    <li>
                      Sua privacidade &eacute; importante para a TRANSREAL. O
                      preenchimento de formul&aacute;rios ou acessos aos
                      servi&ccedil;os do Portal TRANSREAL, pode exigir o
                      processamento de dados pessoais incluindo, mas n&atilde;o
                      limitado a nome do contato, endere&ccedil;o pessoal,
                      telefone ou e-mail. Esses dados ser&atilde;o utilizados
                      pela TRANSREAL para fins indicados acima, mais
                      precisamente para comunica&ccedil;&atilde;o com o TITULAR
                      comercialmente e envio de conte&uacute;do ou
                      servi&ccedil;os. Com o objetivo de direcionar o
                      desenvolvimento e melhoria da ferramenta ou site,
                      poder&atilde;o ser coletados dados de
                      navega&ccedil;&atilde;o, incluindo, mas n&atilde;o
                      limitados ao sistema operacional/browser, n&uacute;mero de
                      acessos, a&ccedil;&otilde;es na ferramenta, &aacute;rvore
                      de navega&ccedil;&atilde;o, tempo na ferramenta, origem da
                      conex&atilde;o. A TRANSREAL s&oacute; usa esses Dados
                      Pessoais apenas para fornecer os servi&ccedil;os para o
                      usu&aacute;rio e para melhorar seus algoritmos e tudo em
                      conformidade com a Lei Geral de Prote&ccedil;&atilde;o de
                      Dados (Lei n&ordm;. 13.709/2018).
                    </li>
                    <li>
                      Para fins deste Termo de Uso, dados pessoais significa
                      quaisquer informa&ccedil;&otilde;es fornecidas e/ou
                      coletadas pela TRANSREAL e/ou suas afiliadas, por qualquer
                      meio, ainda que p&uacute;blicos, que: (I) identifiquem, ou
                      que, quando usadas em combina&ccedil;&atilde;o com outras
                      informa&ccedil;&otilde;es tratadas pela TRANSREAL,
                      identifiquem um indiv&iacute;duo; ou (II) por meio das
                      quais a identifica&ccedil;&atilde;o ou
                      informa&ccedil;&otilde;es de contato de uma pessoa
                      f&iacute;sica possam ser derivadas. Os Dados Pessoais
                      podem estar em qualquer m&iacute;dia ou formato, inclusive
                      registros eletr&ocirc;nicos ou computadorizados, bem como
                      em arquivos baseados em papel. Os dados Pessoais, no
                      entanto, n&atilde;o incluem telefone comercial,
                      n&uacute;mero de celular comercial, endere&ccedil;o
                      comercial e e-mail comercial.
                    </li>
                    <li>
                      O usu&aacute;rio concorda com as nossas pr&aacute;ticas
                      relativas a dados, inclusive com a coleta, o uso e o
                      processamento de suas informa&ccedil;&otilde;es, inclusive
                      Dados Pessoais sens&iacute;veis fornecidos pelo
                      Usu&aacute;rio quando do cadastro e uso dos
                      servi&ccedil;os da TRANSREAL.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Seguran&ccedil;a dos Dados</strong>
                  <br />
                  <ul>
                    <li>
                      O CONTROLADOR responsabiliza-se pela
                      manuten&ccedil;&atilde;o de medidas de seguran&ccedil;a,
                      t&eacute;cnicas e administrativas aptas a proteger os
                      Dados Pessoais de acesso n&atilde;o autorizados e de
                      situa&ccedil;&otilde;es acidentais ou il&iacute;citas de
                      destrui&ccedil;&atilde;o, perda, altera&ccedil;&atilde;o,
                      comunica&ccedil;&atilde;o ou qualquer forma de tratamento
                      inadequado ou il&iacute;cito.
                    </li>
                    <li>
                      Em conformidade com o art. 48 da Lei n&ordm;. 13.709, o
                      CONTROLADOR comunicar&aacute; ao TITULAR e &agrave;
                      Autoridade Nacional de Prote&ccedil;&atilde;o de Dados
                      (ANPD) a ocorr&ecirc;ncia de incidente de seguran&ccedil;a
                      que possa acarretar risco ou dano relevante ao TITULAR.
                    </li>
                    <li>
                      A TRANSREAL possui v&aacute;rias camadas de
                      seguran&ccedil;a, incluindo firewalls, sistemas de
                      prote&ccedil;&atilde;o contra intrus&atilde;o e
                      segrega&ccedil;&atilde;o de rede. Os servi&ccedil;os de
                      seguran&ccedil;a da TRANSREAL s&atilde;o configurados,
                      monitorados e mantidos de acordo com as melhores
                      pr&aacute;ticas de campo. A TRANSREAL toma medidas para
                      proteger os dados Pessoais fornecidos atrav&eacute;s dos
                      Servi&ccedil;os contra perda, uso indevido e acesso
                      n&atilde;o autorizado, divulga&ccedil;&atilde;o,
                      altera&ccedil;&atilde;o ou destrui&ccedil;&atilde;o. No
                      entanto, nenhuma transmiss&atilde;o de internet ou e-mail
                      &eacute; totalmente segura ou livre de erros. Em
                      particular, os e-mails enviados para ou a partir dos
                      Servi&ccedil;os podem n&atilde;o ser seguros. Portanto o
                      Usu&aacute;rio deve tomar um cuidado especial ao decidir
                      quais informa&ccedil;&otilde;es nos envia por e-mail ou
                      conte&uacute;dos que faz upload nos servidores da
                      TRANSREAL.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>T&eacute;rmino do Tratamento dos Dados</strong>
                  <br />
                  <ul>
                    <li>
                      O CONTROLADOR poder&aacute; manter e tratar os dados
                      Pessoais do TITULAR durante todo o per&iacute;odo em que,
                      os mesmos, forem pertinentes ao alcance das finalidades
                      listadas neste termo. Dados Pessoais anonimizados, sem
                      possibilidade de associa&ccedil;&atilde;o ao
                      indiv&iacute;duo, poder&atilde;o ser mantidos por
                      per&iacute;odo indefinido.
                    </li>
                    <li>
                      O TITULAR poder&aacute; solicitar via e-mail ou
                      correspond&ecirc;ncia ao CONTROLADOR, a qualquer momento,
                      que sejam eliminados os Dados Pessoais n&atilde;o
                      anonimizados do TITULAR. O TITULAR fica ciente de que
                      poder&aacute; ser invi&aacute;vel ao CONTROLADOR continuar
                      o fornecimento de produtos ou servi&ccedil;os ao TITULAR a
                      partir da elimina&ccedil;&atilde;o dos Dados Pessoais.
                    </li>
                    <li>
                      A TRANSREAL reter&aacute; os Dados Pessoais e
                      manter&aacute; os dados armazenados at&eacute; eventual
                      procedimento de exclus&atilde;o. A TRANSREAL poder&aacute;
                      vir a manter seus Dados Pessoais ap&oacute;s receber seu
                      pedido de exclus&atilde;o caso seja necess&aacute;rio para
                      cumprimento de obriga&ccedil;&otilde;es legais, resolver
                      disputas, manter a seguran&ccedil;a, evitar fraudes e
                      abuso e garantir o cumprimento de contratos.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Direitos do TITULAR</strong>
                  <br />O TITULAR tem direito a obter do CONTROLADOR, em
                  rela&ccedil;&atilde;o aos dados por ele tratados, a qualquer
                  momento e mediante requisi&ccedil;&atilde;o: (I)
                  confirma&ccedil;&atilde;o da exist&ecirc;ncia de Tratamento;
                  (II) acesso aos dados; (III) corre&ccedil;&atilde;o de dados
                  incompletos, inexatos ou desatualizados; (IV)
                  anonimiza&ccedil;&atilde;o, bloqueio ou
                  elimina&ccedil;&atilde;o de dados desnecess&aacute;rios,
                  excessivos ou tratados em desconformidade com o disposto na
                  Lei N&ordm;. 13.709; (V) portabilidade dos dados a outro
                  fornecedor de servi&ccedil;o ou produto, mediante
                  requisi&ccedil;&atilde;o expressa e observados os segredos
                  comercial e industrial, de acordo com a
                  regulamenta&ccedil;&atilde;o da autoridade nacional; (VI)
                  elimina&ccedil;&atilde;o dos dados pessoais tratados com o
                  consentimento do TITULAR, exceto nas hip&oacute;teses
                  previstas no art. 16 da Lei n&ordm;. 13.709; (VII)
                  informa&ccedil;&atilde;o das entidades p&uacute;blicas e
                  privadas com as quais o CONTROLADOR realizou uso compartilhado
                  de dados; (VIII) informa&ccedil;&atilde;o sobre a
                  possibilidade de n&atilde;o fornecer consentimento e sobre as
                  consequ&ecirc;ncias da negativa; (IX) revoga&ccedil;&atilde;o
                  do consentimento, nos termos do &sect;5&ordm; do art. 8&ordm;
                  da Lei n&ordm; 13.709.
                </p>
                <p>
                  <strong>
                    Direito de Revoga&ccedil;&atilde;o do Consentimento
                  </strong>
                  <br />
                  Este consentimento poder&aacute; ser revogado pelo TITULAR, a
                  qualquer momento, mediante solicita&ccedil;&atilde;o via
                  e-mail ou correspond&ecirc;ncia ao CONTROLADOR.
                </p>
                <p>
                  <strong>Por motivos Legais</strong>
                  <br />
                  Para cumprir a legisla&ccedil;&atilde;o brasileira ou por
                  solicita&ccedil;&atilde;o legal, a TRANSREAL se reserva no
                  direito de compartilhar informa&ccedil;&otilde;es pessoais dos
                  usu&aacute;rios, inclusive para cumprir
                  investiga&ccedil;&otilde;es de poss&iacute;veis
                  viola&ccedil;&otilde;es, detectar, impedir ou lidar com alguma
                  forma com fraudes, problemas t&eacute;cnicos ou de
                  seguran&ccedil;a, proteger de preju&iacute;zos aos direitos,
                  &agrave; propriedade ou &agrave; seguran&ccedil;a da
                  TRANSREAL, dos nossos usu&aacute;rios ou de terceiros,
                  conforme solicitado ou permitido em lei, bem manter os dados
                  dos usu&aacute;rios para cumprir com a
                  legisla&ccedil;&atilde;o anticorrup&ccedil;&atilde;o,
                  compliance e seguran&ccedil;a da Empresa.
                </p>
              </div>
            </TermsContainer>
            <TextSubtitle>Você leu e aceita os termos de serviço?</TextSubtitle>
            <YesNoContainer>
              <NokButton type="button" onClick={() => handleLogout()}>
                Não
              </NokButton>
              <OkButton type="button" onClick={() => handleLgpdAprove()}>
                Sim
              </OkButton>
            </YesNoContainer>
          </ModalContent>
        </ModalDialog>
      </Modal>
      <ModalBackdrop isOpen={modalIsOpen} />
    </>
  );
};

export default ModalLgpd;
