import { createSlice } from '@reduxjs/toolkit';
import { merge } from '../../entities/clients';
import axios from '../../app/axios';

const initialState = {
  clientId: 0,
  totalPages: 0,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setCityId: (state, { payload: companyId }) => {
      state.clientId = !companyId ? initialState.clientId : companyId;
    },
    setTotalPages: (state, { payload: totalPages }) => {
      state.totalPages = !totalPages ? initialState.totalPages : totalPages;
    },
  },
});

const { setTotalPages } = clientSlice.actions;

export const fetchClients = (params: any) => (dispatch: any): any => {
  axios.get('/local/clients', { params }).then(response => {
    dispatch(merge(response.data.data));
    dispatch(setTotalPages(response.data.total_pages));
  });
};

export const fetchClientsByName = async (params: any): Promise<any> => {
  const { data: clients } = await axios.get('/local/clients', {
    params,
  });
  return clients.data;
};

export const getClients = async (params: any): Promise<any> => {
  const { data: clients } = await axios.get('/local/clients', { params });
  return clients;
};

export default clientSlice.reducer;
