import { createEntitySlice } from '../utils/redux';
import axios from '../app/axios';

const name = 'transporterDrivers';
const endpoint = 'local/transporters_drivers';
const slice = createEntitySlice(name, endpoint);

export const {
  // fetch,
  // fetchById,
  dropAll,
  dropId,
  setData,
} = slice.actions;

export const merge = (data: any) => (dispatch: any): Promise<any> => {
  let promiseResult;
  if (Array.isArray(data)) {
    const incomingIds: Array<number> = [];
    data.forEach(item => {
      incomingIds.push(item.id);
    });
    promiseResult = incomingIds;
  } else {
    promiseResult = data.id;
  }

  dispatch(setData(data));
  return Promise.resolve(promiseResult);
};

// FIXME: trocar any para tipagens das entidades
export const fetch = (params = null) => async (dispatch: any): Promise<any> =>
  axios.get(endpoint, { params }).then(({ data }) => {
    dispatch(merge(data.data));
  });

export const fetchById = (id: number | Array<number>) => async (
  dispatch: any,
) => {
  const hasManyIds = Array.isArray(id);
  const url = !hasManyIds ? `${endpoint}/${id}` : endpoint;
  const body = !hasManyIds ? undefined : { params: { id } };
  return axios.get(url, body).then(({ data }) => dispatch(merge(data)));
};

export const addId = (id: number | Array<number>) => (
  dispatch: any,
  getState: any,
) => {
  let idToFetch;

  const { byId } = getState().entities[name];
  if (!Array.isArray(id)) {
    if (!byId[id]) idToFetch = id;
  } else {
    const missingIds: Array<number> = [];
    id.forEach((idItem: number) => {
      if (!byId[idItem]) missingIds.push(idItem);
    });
    if (missingIds.length > 1) {
      idToFetch = missingIds;
    } else if (missingIds.length > 0) {
      [idToFetch] = missingIds;
    }
  }

  return !idToFetch ? Promise.resolve([]) : dispatch(fetchById(idToFetch));
};

export default slice.reducer;
