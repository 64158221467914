import styled from 'styled-components';

export const Title = styled.h1`
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
`;

export const Separator = styled.hr`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  border-width: 3px;
  border-style: solid;
  border-radius: 5px;
  border-color: #003CA6;
`;

export const Panel = styled.div`
  margin-top: 80px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  a {
    width: 80%;
    text-decoration: none;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #3a3a3a;
    font-size: 30px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6px;
    transition: 0.3s all ease-in;

    @media (max-width: 768px) {
      width: 100%;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 6px;
    }
  }
`;
