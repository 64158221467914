import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ComponentProps {
  size: string;
}

export const SaveButton = styled.button`
  border: 1px solid transparent;
  background-color: #003ca6;
  color: #fff;
  border-radius: 25px;
  width: 100%;
  height: 40px;
  padding: 4px 20px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  font-size: 24px;
  font-weight: 500;

  cursor: pointer;
  &:hover {
    background: ${shade(0.2, '#003CA6')};
    color: #fff;

    .save-icon {
      filter: invert(1);
    }
  }

  .save-icon {
    width: 24px;
    height: 24px;
    vertical-align: text-bottom;
    filter: invert(1);

    margin: 2px 5px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 46px;
    padding: 12px;
  }
`;

export const AddNewButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #003ca6;
  color: #fff;
  border-radius: 15px;
  width: fit-content;
  padding: 2px 15px;

  margin: -3px 0px 0px 10px;

  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#003CA6')};
    color: #fff;

    img {
      filter: invert(1);
    }
  }

  img {
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    filter: invert(1);
  }
`;

export const OCSContainer = styled.div`
  background: #003ca6;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-top: 25px;
  border: 1px solid transparent;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.07);
  padding: 12px;

  color: #fff;
  display: inline-block;

  font-weight: 700;

  @media (max-width: 768px) {
    width: 100%;
    min-height: 46px;
    padding: 12px;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  display: flex;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .nfe {
    width: 90%;
  }

  .xml-icon {
    width: 24px;
    height: 24px;

    top: 36px;
    left: 10px;

    cursor: pointer;

    &:hover {
      background: ${shade(0.2, '#003CA6')};
      border-radius: 300%;
    }
  }

  .value-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .title {
      margin: auto;
      position: relative;
      top: 12px;
      right: 10px;
      font-size: 18px;
      font-weight: 700;
    }

    .valor-icon {
      width: 24px;
      height: 24px;
      margin: auto;
      position: relative;
      top: 10px;
      right: 10px;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding-right: 0;
      padding-left: 0;

      flex-direction: column;
    }
  }
`;

export const FormButton = styled.button`
  height: 44px;
  width: fit-content;
  padding: 8px 50px;
  background-color: #003ca6;
  color: #ffffff;
  border-radius: 29px;
  border: none;
  margin: 27px 15px 0 0px;
  display: flex;
  align-items: center;

  position: relative;

  font-size: 20px;

  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
    vertical-align: bottom;
    margin-right: 5px;
  }

  &:hover {
    background: ${shade(0.2, '#003CA6')};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

export const SpecialRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  position: relative;

  .special-title {
    width: auto;
    top: -10px;
    padding: 0 15px;
    position: absolute;

    display: flex;

    a {
      display: flex;
      align-items: center;
      background-color: #003ca6;
      color: #fff;
      border-radius: 15px;
      padding: 2px 15px;
      margin-left: 5px;

      &:hover {
        background: ${shade(0.2, '#003CA6')};
      }

      svg {
        margin-right: 3px;
      }
    }

    @media (max-width: 350px) {
      padding: 0;
    }
  }
`;

export const ContainerSizeAutoComplete = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;
