import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from '../../entities/businessPerson';
import axios from '../../app/axios';

const initialState = {
  bpId: 0,
  currentListPage: 1,
};

export const businessPersonSlice = createSlice({
  name: 'businessPerson',
  initialState,
  reducers: {
    setBusinessPersonId: (state, { payload: bpId }) => {
      state.bpId = !bpId ? initialState.bpId : bpId;
    },
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});
export const {
  setBusinessPersonId,
  setCurrentListPage,
} = businessPersonSlice.actions;

interface AsyncData {
  id?: number;
  data: any;
}

interface BusinessPersonResponseData {
  id: number;
  name: string;
  unique_code: string;
  type: string;
  status: string;
  cep: string;
  address: string;
  complement: string;
  fiscal_situation: string;
  code_rodopar: number;
  state_registration: string;
  number: string;
  phone_number: string;
  district: string;
  is_active: boolean;
  city_id: number;
  city?: any;
  reference: string;
}

interface ResponseBusinessPerson {
  data: BusinessPersonResponseData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

/* interface ResponseBusinessPeople {
  data: BusinessPersonResponseData[];
  status: number;
  statusText: string;
  headers: object;
  config: object;
} */

export const getBusinessPerson = async (
  id: number,
): Promise<ResponseBusinessPerson> => {
  const businessPerson = await axios.get(`/local/business_person/${id}`);
  return businessPerson;
};

export const getBusinessPersonbyName = async (params: any): Promise<any> => {
  const businessPerson = await axios.get(`/local/business_person`, {
    params,
  });
  return businessPerson;
};

export const addBusinessPerson = createAsyncThunk<any, AsyncData>(
  'businessPerson/addBusinessPerson',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const is_active = 1;
      const response = await axios.post('/local/business_person', {
        ...info.data,
        is_active,
      });
      dispatch(merge(response.data));
      dispatch(setBusinessPersonId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateBusinessPerson = createAsyncThunk<any, AsyncData>(
  'businessPerson/updateBusinessPerson',
  async (info: AsyncData, { dispatch, rejectWithValue }) => {
    try {
      const is_active = 1;
      const response = await axios.put(`/local/business_person/${info.id}`, {
        ...info.data,
        is_active,
      });
      dispatch(merge(response.data));
      dispatch(setBusinessPersonId(response.data.id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export default businessPersonSlice.reducer;
