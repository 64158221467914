/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { GrDocumentUpdate } from 'react-icons/gr';
import { FiTrash2 } from 'react-icons/fi';
import { BiShowAlt, BiHide } from 'react-icons/bi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FaEdit, FaQuestionCircle, FaPlusCircle } from 'react-icons/fa';
import * as xml from 'xml2js';
import { uuid } from 'uuidv4';
import { AppDispatch } from '../../../app/store';
import Pagination from '../../../components/Pagination';

import {
  ContainerOptions,
  ContainerSize,
  Row,
  NewButton,
  AddXmlButton,
} from './styles';
import Input from '../../../components/Input';
import TableList from '../../../components/TableList';

import { useLoading } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';
import { useDialog } from '../../../hooks/dialog';
import getValidationErrors from '../../../utils/getValidationErrors';
import {
  addQuotationItem,
  updateQuotationItem,
  getQuotationItemById,
  getQuotationItemByIdParam,
  deleteQuotationItem,
  quotationItemsSlice,
} from '../../../features/quotationItens';

import AddImg from '../../../assets/AddUser.png';
import XmlImg from '../../../assets/xml-icon.png';
import ReadOnlyInput from '../../../components/ReadOnlyInput';
import {
  formatValueToCurrencyInput,
  formatValueFromCurrencyInput,
} from '../../../utils/formatValueCurrencyInput';
import CurrencyInput from '../../../components/CurrencyInput';
import Modal from '../../../components/Modal';

interface FormInterface {
  [key: string]: string;
}
interface ItensProp {
  quotationId: number;
  cubageFactor: number;
  handleChangeUpdateQuotation: any;
  statusQuotation: string;
  disableAprove: any;
}

const GatheringOrderItems: React.FC<ItensProp> = ({
  quotationId,
  cubageFactor,
  handleChangeUpdateQuotation,
  statusQuotation,
  disableAprove,
}) => {
  const formRef = useRef<FormHandles>(null);
  const listRef = useRef<HTMLButtonElement>(null);
  const hiddenFileInput = useRef(null);
  const dispatch: AppDispatch = useDispatch();
  const { addToast } = useToast();
  const { createDialog } = useDialog();

  const [xmlFile, setXmlFile] = useState<any>();
  const [results, setResults] = useState<Array<any>>([]);
  const [gatheringOrderItemId, setGatheringOrderItemId] = useState(0);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [weigthCalculated, setWeightCalculated] = useState<number>(0);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showItens, setShowItens] = useState(false);
  const [idItemRedux, setIdItemRedux] = useState('');
  const [totalItens, setTotalItens] = useState(0);
  const { showLoading, hideLoading } = useLoading();
  const { setItens, setItensQuantity } = quotationItemsSlice.actions;

  const { itensQuotation, itensQuantity } = useSelector(
    (state: RootStateOrAny) => ({
      itensQuotation: state.features.quotationItem.itensQuotation,
      itensQuantity: state.features.quotationItem.itensQuantity,
    }),
  );

  const props = useSpring({
    opacity: showItens ? 1 : 0,
    display: showItens ? 'block' : 'none',
    config: { duration: 500 },
  });

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, []);

  const handleDeleteOrderItem = async (
    idItem: number | string,
  ): Promise<void> => {
    if (quotationId > 0) {
      showLoading();
      const Response = await dispatch(
        deleteQuotationItem({ id: Number(idItem), data: null }),
      );
      if (deleteQuotationItem.fulfilled.match(Response)) {
        addToast({
          title: 'Item removido com sucesso',
          type: 'success',
        });
        dispatch(setItensQuantity(itensQuantity - 1));
        (formRef.current as any).reset();
        setGatheringOrderItemId(0);
      } else {
        addToast({
          title: 'Erro ao remover item',
          type: 'error',
        });
      }
      handleChangeUpdateQuotation(true);
      hideLoading();
      setUpdatePage(true);
    } else {
      dispatch(
        setItens(itensQuotation.filter((item: any): any => item.id !== idItem)),
      );
    }
  };

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      if (quotationId > 0) {
        const gatheringOrderitens = await getQuotationItemById({
          page,
          quotation_id: quotationId,
        });
        const arrayUsers: Array<Array<any>> = [];
        gatheringOrderitens.data.data.forEach((item: any) => {
          arrayUsers.push([
            item.serial,
            item.nf_number,
            item.product_name,
            formatValueToCurrencyInput(item.value),
            formatValueToCurrencyInput(item.quantity),
            formatValueToCurrencyInput(item.weight),
            formatValueToCurrencyInput(item.weight_calculated),
            item.comments,
            statusQuotation !== 'A' && statusQuotation !== 'R' ? (
              <div className="options-table-list">
                <button
                  type="button"
                  onClick={() => setGatheringOrderItemId(item.id)}
                >
                  <FaEdit size={20} color="#ffffff" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    createDialog({
                      text: 'Excluir item?',
                      textButtonCancel: 'Não',
                      textButtonConfirm: 'Sim',
                      icon: FaQuestionCircle,
                      onConfirm: () => handleDeleteOrderItem(item.id),
                      onCancel: () => {},
                    });
                  }}
                >
                  <FiTrash2 size={20} color="#ffffff" />
                </button>
              </div>
            ) : (
              <></>
            ),
          ]);
        });
        setResults(arrayUsers);
        const arrayPages = [];
        for (let i = 0; i < gatheringOrderitens.data.total_pages; i++) {
          arrayPages.push(i + 1);
        }
        setPages(arrayPages);
        setTotalItens(gatheringOrderitens.data.total);
        dispatch(setItensQuantity(gatheringOrderitens.data.total));
        setCurrentPage(page);
      } else {
        const arrayUsers: Array<Array<any>> = [];
        itensQuotation.forEach((item: any) => {
          arrayUsers.push([
            item.serial,
            item.nf_number,
            item.product_name,
            formatValueToCurrencyInput(item.value),
            formatValueToCurrencyInput(item.quantity),
            formatValueToCurrencyInput(item.weight),
            formatValueToCurrencyInput(item.weight_calculated),
            item.comments,
            statusQuotation !== 'A' && statusQuotation !== 'R' ? (
              <div className="options-table-list">
                <button type="button" onClick={() => setIdItemRedux(item.id)}>
                  <FaEdit size={20} color="#ffffff" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    createDialog({
                      text: 'Excluir item?',
                      textButtonCancel: 'Não',
                      textButtonConfirm: 'Sim',
                      onConfirm: () => handleDeleteOrderItem(item.id),
                    });
                  }}
                >
                  <FiTrash2 size={20} color="#ffffff" />
                </button>
              </div>
            ) : (
              <></>
            ),
          ]);
        });
        setTotalItens(arrayUsers.length);
        setResults(arrayUsers);
      }
      hideLoading();
      setUpdatePage(false);
    },
    [
      showLoading,
      quotationId,
      hideLoading,
      dispatch,
      setItensQuantity,
      statusQuotation,
      createDialog,
      handleDeleteOrderItem,
      itensQuotation,
    ],
  );

  const handleSubmit = useCallback(
    async (data: FormInterface) => {
      try {
        (formRef.current as any).setErrors({});
        data.peso = formatValueFromCurrencyInput(data.peso);
        data.quantidade = formatValueFromCurrencyInput(data.quantidade);
        data['valor-mercadoria'] = formatValueFromCurrencyInput(
          data['valor-mercadoria'],
        );
        const schemaValidation = Yup.object().shape({
          serie: Yup.string(),
          'numero-nf': Yup.string(),
          produto: Yup.string().required('Produto obrigatório'),
          'valor-mercadoria': Yup.number()
            .min(0.01, 'Valor deve ser maior que 0')
            .required('Valor Obrigatório'),
          quantidade: Yup.number()
            .min(0.01, 'Quantidade deve ser maior que 0')
            .required('Quantidade obrigatória'),
          peso: Yup.number()
            .min(0.01, 'Peso deve ser maior que 0')
            .required('Peso obrigatório'),
          altura: Yup.string(),
          comprimento: Yup.string(),
          largura: Yup.string(),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        const gatheringOrderItemData = {
          quotation_id: quotationId,
          serial: data.serie,
          nf_number: data['numero-nf'],
          product_name: data.produto,
          value: data['valor-mercadoria'],
          quantity: data.quantidade,
          weight: data.peso,
          weight_calculated: weigthCalculated,
          width:
            data.largura.length > 0
              ? formatValueFromCurrencyInput(data.largura)
              : 0,
          length:
            data.comprimento.length > 0
              ? formatValueFromCurrencyInput(data.comprimento)
              : 0,
          height:
            data.altura.length > 0
              ? formatValueFromCurrencyInput(data.altura)
              : 0,
          comments: data['refcliente-ordemcompra'],
        };

        if (quotationId === 0) {
          if (idItemRedux === '') {
            dispatch(
              setItens([
                ...itensQuotation,
                { ...gatheringOrderItemData, id: uuid() },
              ]),
            );
            addToast({ title: 'Item criado com sucesso', type: 'success' });
            dispatch(setItensQuantity(itensQuantity + 1));
            disableAprove();
          } else {
            dispatch(
              setItens([
                ...itensQuotation.filter((x: any): any => x.id !== idItemRedux),
                { ...gatheringOrderItemData, id: idItemRedux },
              ]),
            );
            addToast({ title: 'Item atualizado com sucesso', type: 'success' });
            disableAprove();
          }
          setIdItemRedux('');
          setWeightCalculated(0);
          (formRef.current as any).reset();
        } else {
          showLoading();
          if (gatheringOrderItemId) {
            const Response = await dispatch(
              updateQuotationItem({
                id: Number(gatheringOrderItemId),
                data: gatheringOrderItemData,
              }),
            );
            if (updateQuotationItem.fulfilled.match(Response)) {
              addToast({
                title: 'Item atualizado com sucesso',
                type: 'success',
              });
              (formRef.current as any).reset();
              setGatheringOrderItemId(0);
              disableAprove();
            } else {
              addToast({ title: 'Erro na criação', type: 'error' });
            }
          } else {
            const Response = await dispatch(
              addQuotationItem({ data: gatheringOrderItemData }),
            );
            if (addQuotationItem.fulfilled.match(Response)) {
              addToast({ title: 'Item criado com sucesso', type: 'success' });
              (formRef.current as any).reset();
              setGatheringOrderItemId(0);
              dispatch(setItensQuantity(itensQuantity + 1));
              disableAprove();
            } else {
              addToast({ title: 'Erro na criação', type: 'error' });
            }
          }
          hideLoading();
          handleChangeUpdateQuotation(true);
          setWeightCalculated(0);
          getData(1);
        }
      } catch (err) {
        if (err.inner) {
          const errors = getValidationErrors(err);
          (formRef.current as any).setErrors(errors);
        }
      }
    },
    [
      quotationId,
      weigthCalculated,
      idItemRedux,
      dispatch,
      setItens,
      itensQuotation,
      addToast,
      setItensQuantity,
      itensQuantity,
      showLoading,
      gatheringOrderItemId,
      hideLoading,
      handleChangeUpdateQuotation,
      getData,
    ],
  );

  useEffect(() => {
    if (gatheringOrderItemId) {
      (async () => {
        showLoading();
        const { data: gatheringOrderItemSel } = await getQuotationItemByIdParam(
          Number(gatheringOrderItemId),
        );
        (formRef.current as any).setFieldValue(
          'serie',
          gatheringOrderItemSel?.serial,
        );
        (formRef.current as any).setFieldValue(
          'numero-nf',
          gatheringOrderItemSel?.nf_number,
        );
        (formRef.current as any).setFieldValue(
          'produto',
          gatheringOrderItemSel?.product_name,
        );
        (formRef.current as any).setFieldValue(
          'valor-mercadoria',
          formatValueToCurrencyInput(gatheringOrderItemSel?.value),
        );
        (formRef.current as any).setFieldValue(
          'quantidade',
          formatValueToCurrencyInput(gatheringOrderItemSel?.quantity),
        );
        (formRef.current as any).setFieldValue(
          'peso',
          formatValueToCurrencyInput(gatheringOrderItemSel?.weight),
        );
        (formRef.current as any).setFieldValue(
          'altura',
          formatValueToCurrencyInput(gatheringOrderItemSel?.height),
        );
        (formRef.current as any).setFieldValue(
          'largura',
          formatValueToCurrencyInput(gatheringOrderItemSel?.width),
        );
        (formRef.current as any).setFieldValue(
          'comprimento',
          formatValueToCurrencyInput(gatheringOrderItemSel?.length),
        );
        setWeightCalculated(gatheringOrderItemSel?.weight_calculated);
        (formRef.current as any).setFieldValue(
          'refcliente-ordemcompra',
          gatheringOrderItemSel?.comments,
        );
        openModal();
        hideLoading();
      })();
    }
  }, [gatheringOrderItemId, hideLoading, openModal, showLoading]);

  useEffect(() => {
    if (idItemRedux !== '') {
      const gatheringOrderItemSel = itensQuotation.find(
        (x: any): any => x.id === idItemRedux,
      );
      (formRef.current as any).setFieldValue(
        'serie',
        gatheringOrderItemSel?.serial,
      );
      (formRef.current as any).setFieldValue(
        'numero-nf',
        gatheringOrderItemSel?.nf_number,
      );
      (formRef.current as any).setFieldValue(
        'produto',
        gatheringOrderItemSel?.product_name,
      );
      (formRef.current as any).setFieldValue(
        'valor-mercadoria',
        formatValueToCurrencyInput(gatheringOrderItemSel?.value),
      );
      (formRef.current as any).setFieldValue(
        'quantidade',
        formatValueToCurrencyInput(gatheringOrderItemSel?.quantity),
      );
      (formRef.current as any).setFieldValue(
        'peso',
        formatValueToCurrencyInput(gatheringOrderItemSel?.weight),
      );
      (formRef.current as any).setFieldValue(
        'altura',
        formatValueToCurrencyInput(gatheringOrderItemSel?.height),
      );
      (formRef.current as any).setFieldValue(
        'largura',
        formatValueToCurrencyInput(gatheringOrderItemSel?.width),
      );
      (formRef.current as any).setFieldValue(
        'comprimento',
        formatValueToCurrencyInput(gatheringOrderItemSel?.length),
      );
      setWeightCalculated(gatheringOrderItemSel?.weight_calculated);
      (formRef.current as any).setFieldValue(
        'refcliente-ordemcompra',
        gatheringOrderItemSel?.comments,
      );
      openModal();
    }
  }, [idItemRedux, itensQuotation, openModal]);

  useEffect(() => {
    if (quotationId > 0) {
      getData(1);
    }
  }, [quotationId, statusQuotation]);

  useEffect(() => {
    if (itensQuotation && quotationId === 0) {
      getData(1);
    }
  }, [itensQuotation]);

  useEffect(() => {
    if (updatePage === true) {
      getData(1);
    }
  }, [updatePage]);

  const handlerCalculateWeight = (): void => {
    if (
      (formRef.current as any).getFieldValue('altura') &&
      (formRef.current as any).getFieldValue('comprimento') &&
      (formRef.current as any).getFieldValue('largura')
    ) {
      setWeightCalculated(
        Number(
          formatValueFromCurrencyInput(
            (formRef.current as any).getFieldValue('altura'),
          ),
        ) *
          Number(
            formatValueFromCurrencyInput(
              (formRef.current as any).getFieldValue('comprimento'),
            ),
          ) *
          Number(
            formatValueFromCurrencyInput(
              (formRef.current as any).getFieldValue('largura'),
            ),
          ) *
          Number(
            formatValueFromCurrencyInput(
              (formRef.current as any).getFieldValue('quantidade'),
            ),
          ) *
          cubageFactor,
      );
    }
  };

  const handleXmlClick = (): void => {
    (hiddenFileInput as any).current.click();
  };

  const handleAddXmlInfo = async (): Promise<void> => {
    const fileReader = new FileReader();
    fileReader.readAsText(xmlFile);
    fileReader.onloadend = () => {
      const parser = new xml.Parser();
      parser.parseString(
        fileReader.result as string,
        (err: any, result: any) => {
          try {
            const products = result.nfeProc?.NFe[0]?.infNFe[0]?.det;
            if (products) {
              showLoading();
              const itensQuotationXML: {
                id: string;
                quotation_id: number;
                serial: any;
                nf_number: any;
                product_name: any;
                value: any;
                quantity: any;
                weight: number;
                weight_calculated: number;
                length: number;
                height: number;
                width: number;
                comments: string;
              }[] = [];

              products.forEach(async (item: any) => {
                const gatheringOrderItemData = {
                  quotation_id: quotationId,
                  serial: result.nfeProc?.NFe[0]?.infNFe[0]?.ide[0]?.serie[0],
                  nf_number: result.nfeProc?.NFe[0]?.infNFe[0]?.ide[0]?.nNF[0],
                  product_name: item.prod[0]?.xProd[0],
                  value: Number(item.prod[0]?.vProd[0]),
                  quantity: Number(item.prod[0]?.qCom[0]),
                  weight: 0,
                  weight_calculated: 0,
                  length: 0,
                  height: 0,
                  width: 0,
                  comments: 'Importação Nota',
                };
                if (quotationId > 0) {
                  const Response = await dispatch(
                    addQuotationItem({ data: gatheringOrderItemData }),
                  );
                  if (addQuotationItem.fulfilled.match(Response)) {
                    addToast({
                      title: 'Item importado com sucesso',
                      type: 'success',
                    });
                    (formRef.current as any).reset();
                    setGatheringOrderItemId(0);
                    dispatch(setItensQuantity(itensQuantity + 1));
                    disableAprove();
                    setUpdatePage(true);
                  } else {
                    addToast({
                      title: 'Erro na importação do item',
                      type: 'error',
                    });
                  }
                } else {
                  itensQuotationXML.push({
                    ...gatheringOrderItemData,
                    id: uuid(),
                  });
                  addToast({
                    title: 'Item importado com sucesso',
                    type: 'success',
                  });
                  dispatch(setItensQuantity(itensQuantity + 1));
                  disableAprove();
                }
              });
              dispatch(setItens([...itensQuotation, ...itensQuotationXML]));
              hideLoading();
              handleChangeUpdateQuotation(true);
            }
          } catch {
            addToast({
              title: 'Erro ao importar item',
              type: 'success',
            });
          }
        },
      );
    };
    setIsConfirmed(false);
    setXmlFile(null);
  };

  useEffect(() => {
    if (xmlFile && isConfirmed) {
      handleAddXmlInfo();
    }
  }, [xmlFile, isConfirmed, handleAddXmlInfo]);

  useEffect(() => {}, [weigthCalculated]);

  const confirm = async (): Promise<void> => {
    setIsConfirmed(true);
  };

  const canceled = useCallback(() => {}, []);

  const handleChange = (event: any): void => {
    if (event.target.files.length > 0) {
      setXmlFile(event.target.files[0]);
      createDialog({
        text: 'Deseja importar as informações do XML para a Coleta?',
        textButtonCancel: 'Não',
        textButtonConfirm: 'Sim',
        icon: FaQuestionCircle,
        onConfirm: confirm,
        onCancel: canceled,
      });
    }
  };

  const handleClickAddItem = useCallback(() => {
    setGatheringOrderItemId(0);
    setIdItemRedux('');
    (formRef.current as any).reset();
    openModal();
  }, [setGatheringOrderItemId, setIdItemRedux, openModal]);

  const handleClickShowItens = useCallback(() => {
    if (showItens === true) {
      setShowItens(false);
    } else {
      setShowItens(true);
      setTimeout(() => {
        const position = (listRef.current as any).offsetTop + 35;
        window.scroll({ top: position, behavior: 'smooth' });
      }, 500);
    }
  }, [showItens, setShowItens, listRef]);

  return (
    <div style={{ width: '100%' }}>
      <ContainerOptions>
        <span>Itens ({totalItens})</span>
        {statusQuotation !== 'A' && statusQuotation !== 'R' ? (
          <button type="button" onClick={() => handleClickAddItem()}>
            <FaPlusCircle color="#ffffff" /> item
          </button>
        ) : (
          <></>
        )}
        {totalItens > 0 && (
          <button
            type="button"
            ref={listRef}
            onClick={() => handleClickShowItens()}
          >
            {showItens === false ? (
              <BiShowAlt color="#ffffff" />
            ) : (
              <BiHide color="#ffffff" />
            )}
            {showItens === false ? 'ver' : 'esconder'} itens
          </button>
        )}
      </ContainerOptions>
      <Modal
        isOpen={showModal}
        title={`${gatheringOrderItemId ? `Atualizar` : `Cadastrar`} Item`}
        backgroundColor="#F8F8FB"
        fontColor="#3a3a3a"
        id="modal-item"
        onClose={() => {
          setShowModal(false);
          setGatheringOrderItemId(0);
          setIdItemRedux('');
        }}
      >
        <Form ref={formRef} initialData={{}} onSubmit={handleSubmit} noValidate>
          <Row>
            <ContainerSize style={{ justifyContent: 'flex-start' }} size="100%">
              <AddXmlButton type="button" onClick={handleXmlClick}>
                <img src={XmlImg} alt="Importar" />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  accept="text/xml"
                />
                <span> Importar NFe </span>
              </AddXmlButton>
            </ContainerSize>
          </Row>

          <Row>
            <ContainerSize size="20%">
              <Input name="serie" type="text" placeholder="Série" />
            </ContainerSize>

            <ContainerSize size="35%">
              <Input name="numero-nf" type="text" placeholder="Número NF" />
            </ContainerSize>

            <ContainerSize size="100%">
              <Input name="produto" type="text" placeholder="Produto *" />
            </ContainerSize>
          </Row>

          <Row>
            <ContainerSize size="100%">
              <Input
                name="refcliente-ordemcompra"
                type="text"
                placeholder="Ref . Cliente/Ordem Compra"
              />
            </ContainerSize>

            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="valor-mercadoria"
                type="text"
                placeholder="Valor Mercadoria *"
                onBlur={handlerCalculateWeight}
              />
            </ContainerSize>

            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="quantidade"
                type="text"
                placeholder="Quantidade *"
              />
            </ContainerSize>

            <ContainerSize size="33.333333333%">
              <CurrencyInput name="peso" type="text" placeholder="Peso KG *" />
            </ContainerSize>
          </Row>
          <Row>
            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="altura"
                type="text"
                placeholder="Altura (m)"
                onBlurParent={handlerCalculateWeight}
              />
            </ContainerSize>
            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="largura"
                type="text"
                placeholder="Largura (m)"
                onBlurParent={handlerCalculateWeight}
              />
            </ContainerSize>
            <ContainerSize size="33.333333333%">
              <CurrencyInput
                name="comprimento"
                type="text"
                placeholder="Comprimento (m)"
                onBlurParent={handlerCalculateWeight}
              />
            </ContainerSize>
            <ContainerSize
              size="100%"
              style={{
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <h4
                  className="title"
                  style={{
                    width: 'auto',
                    marginRight: '5px',
                    marginLeft: '15px',
                  }}
                >
                  Peso Calculado
                </h4>
                <ContainerSize
                  size="33.333333333%"
                  style={{ paddingRight: '0' }}
                >
                  <ReadOnlyInput
                    valor={formatValueToCurrencyInput(weigthCalculated)}
                  />
                </ContainerSize>
              </div>
              <NewButton
                type="button"
                onClick={() => handleSubmit((formRef.current as any).getData())}
              >
                {gatheringOrderItemId ? (
                  <>
                    <GrDocumentUpdate size={20} color="#ffffff" />
                    Atualizar
                  </>
                ) : (
                  <>
                    <img src={AddImg} alt="Novo Item" />
                    Salvar
                  </>
                )}
              </NewButton>
            </ContainerSize>
          </Row>
        </Form>
      </Modal>

      <animated.div style={props}>
        <Row style={{ marginLeft: '0', marginRight: '0' }}>
          <ContainerSize size="100%">
            <TableList
              header={[
                'Série',
                'Nº NF',
                'Produto',
                'Valor Mercadoria',
                'Quantidade',
                'Peso KG',
                'Cubagem',
                'Ref. Cliente/Ordem Compra',
                '',
              ]}
              data={results}
            />
          </ContainerSize>
        </Row>
        <Row style={{ marginLeft: '0', marginRight: '0' }}>
          <ContainerSize size="100%">
            <Pagination
              pages={pages}
              currentPage={currentPage}
              onClickFunction={getData}
            />
          </ContainerSize>
        </Row>
      </animated.div>
    </div>
  );
};
export default GatheringOrderItems;
