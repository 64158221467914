import { createSlice } from '@reduxjs/toolkit';
import axios from '../../app/axios';

const initialState = {
  transporterDocumentId: 0,
};

export const transporterDocumentSlice = createSlice({
  name: 'eventsEntry',
  initialState,
  reducers: {
    setTransporterDocumentId: (state, { payload: transporterDocumentId }) => {
      state.transporterDocumentId = !transporterDocumentId
        ? initialState.transporterDocumentId
        : transporterDocumentId;
    },
  },
});
export const { setTransporterDocumentId } = transporterDocumentSlice.actions;

interface TransporterDocumentResponseData {
  id: number;
  transporter_id: number | null;
  num_doc: number;
  doc_key: string;
  date_issuance: Date;
  value_doc: number;
  instructions: string;
  transporter: TransporterData | null;
}

interface TransporterData {
  id: number;
  user_id: number;
  num_doc: number;
  name: string;
}

export interface StackDocumentsData {
  documents: Array<string>;
  transporter_id: number;
}

export interface UnstackDocumentsData {
  documents: Array<string>;
}

interface ResponseStackDocument {
  data: {
    errors: Array<string>;
    success: Array<string>;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDocumentsData {
  data: {
    data: TransporterDocumentResponseData[];
    per_page: number;
    total_pages: number;
    total: number;
  };
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getNotLinkedDocuments = async (
  params: any,
): Promise<ResponseDocumentsData> => {
  const documents = await axios.get(
    `/local/transporters_documents/not_linked`,
    {
      params,
    },
  );
  return documents;
};

export const getLinkedDocuments = async (
  params: any,
): Promise<ResponseDocumentsData> => {
  const documents = await axios.get(`/local/transporters_documents/linked`, {
    params,
  });
  return documents;
};

export const getDocuments = async (
  params: any,
): Promise<ResponseDocumentsData> => {
  const documents = await axios.get(`/local/transporters_documents`, {
    params,
  });
  return documents;
};

export const updateDocument = async (
  id: number,
  transporter_id: number,
): Promise<any> => {
  const response = await axios.put(`/local/transporters_documents/${id}`, {
    transporter_id,
  });
  return response;
};

export const unlinkDocument = async (
  id: number,
  transporter_id: number,
): Promise<any> => {
  const response = await axios.put(
    `/local/transporters_documents/unlink/${id}`,
    {
      transporter_id,
    },
  );
  return response;
};

export const stackDocumentsTransporter = async ({
  documents,
  transporter_id,
}: StackDocumentsData): Promise<ResponseStackDocument> => {
  const response = await axios.patch(`/local/transporters_documents/stack`, {
    documents,
    transporter_id,
  });
  return response;
};

export const stackUnlinkDocumentsTransporter = async ({
  documents,
}: UnstackDocumentsData): Promise<ResponseStackDocument> => {
  const response = await axios.patch(`/local/transporters_documents/unstack`, {
    documents,
  });
  return response;
};

export default transporterDocumentSlice.reducer;
