import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ComponentProps {
  size: string;
}

export const OptionButton = styled.button`
  border: 1px solid #003ca6;
  background-color: transparent;
  border-radius: 25px;
  width: 108px;
  height: fit-content;
  padding: 4px 10px;
  margin: 0 5px;
  color: #003ca6;
  display: flex;
  justify-content: center;
  align-self: center;

  cursor: pointer;
  &:hover {
    background-color: #003ca6;
    color: #fff;
  }
`;

export const OptionButtonContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}
  padding: 0px 15px;

  display: flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}
  padding: 0 15px;

  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ContainerSizeNoPadding = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}

  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;

  .attachments-btn {
  }
`;

export const ButtonAddFile = styled.button`
  margin-top: 35px;
  background: transparent;
  color: #262626;
  border: 1px solid #90dcec;
  border-radius: 29px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 40px;
  font-size: 18px;
  text-transform: uppercase;
  transition: background-color 0.3s linear;

  &:hover {
    background-color: #90dcec;
  }
`;

export const ListFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    margin-top: 15px;
    a {
      flex: 1;
      background-color: #afd2ff;
      border-radius: 25px;
      color: #003ca6;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    span {
      flex: 1;
      background-color: #afd2ff;
      border-radius: 25px;
      color: #003ca6;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    button {
      background: transparent;
      border-radius: 50%;
      margin-left: 5px;
      border: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: all 0.3s linear;

      &:hover {
        background-color: #afd2ff;
        color: #003ca6;
      }
    }
  }
`;

export const Bar = styled.div`
  width: 100%;
  height: fit-content;
  border-bottom: 5px solid #003ca6;

  font-size: 24px;
  font-weight: 400;
  vertical-align: sub;

  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background-color: #003ca6;

    margin: 0 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;

    padding: 8px 20px;
    border-radius: 25px;

    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    transition: background-color 0.2s ease;

    cursor: pointer;

    &:first-child {
      width: 45px;
      height: 45px;
      padding: 0;
      border-radius: 50%;
    }

    &:hover {
      background-color: ${shade(0.2, '#003CA6')};
    }
  }
`;
export const AttachmentContainerSize = styled.div<ComponentProps>`
  ${props => css`
    width: ${props.size};
  `}
  padding: 0 15px;

  display: flex;
  position: absolute;
  left: 68.4%;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;
