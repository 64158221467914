import { createSlice } from '@reduxjs/toolkit';
import { fetch } from '../../entities/cities';
import axios from '../../app/axios';

const initialState = {
  cityId: 0,
};

export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    setCityId: (state, { payload: companyId }) => {
      state.cityId = !companyId ? initialState.cityId : companyId;
    },
  },
});

export const fetchCities = () => (dispatch: any): any => {
  dispatch(fetch());
};

export const fetchCityByNameAndUF = async (params: any): Promise<any> => {
  const { data: cities } = await axios.get('/local/cities', {
    params,
  });
  return cities.data;
};

export const fetchCitiesByName = async (params: any): Promise<any> => {
  const { data: cities } = await axios.get('/local/cities', {
    params,
  });
  return cities.data;
};

export default citySlice.reducer;
