/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { useDispatch, RootStateOrAny, useSelector } from 'react-redux';
import PageContainer from '../../components/PageContainer';

import MainTitle from '../../components/MainTitle';
import Input from '../../components/Input';
import {
  TitleContainer,
  SpecialContainer,
  ContainerButton,
  ButtonType,
  AddUserButton,
} from './styles';
import { useLoading } from '../../hooks/loading';
import Pagination from '../../components/Pagination';
import { fetchUsersWithParams, usersSlice } from '../../features/users';
import TableList from '../../components/TableList';
import Filter from '../../components/Filter';
import AddUser from '../../assets/person_add-24px.svg';
import { filterSlice } from '../../features/filter';

const ListClient: React.FC = () => {
  const { currentListPage, formValue, locationFilter } = useSelector(
    (state: RootStateOrAny) => ({
      currentListPage: state.features.user.currentListPage,
      formValue: state.features.filter.formValue,
      locationFilter: state.features.filter.location,
    }),
  );
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [userType, setUserType] = useState<string>('administrador');
  const [userTypeId, setUserTypeId] = useState<number>(1);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? {} : formValue,
  );
  const { showLoading, hideLoading } = useLoading();
  const dispatch = useDispatch();
  const { setUserId, setCurrentListPage } = usersSlice.actions;
  const { setFormValue } = filterSlice.actions;

  interface UserData {
    id: number;
    name: string;
    email: string;
    created_at: Date;
  }

  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const users = await fetchUsersWithParams({
        page,
        type_code: userTypeId,
        ...optionsSearch,
      });
      const arrayUsers: Array<Array<any>> = [];
      users.data.forEach((user: UserData) => {
        arrayUsers.push([
          user.id,
          user.name,
          user.email,
          new Date(user.created_at).toLocaleString('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }),
          <div className="options-table-list">
            <Link to={`/usuario/${user.id}`}>
              <FaEdit size={20} color="#ffffff" />
            </Link>
          </div>,
        ]);
      });
      setResults(arrayUsers);
      const arrayPages = [];
      for (let i = 0; i < users.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));
      setIsLoadingPage(false);
      hideLoading();
    },
    [showLoading, userTypeId, optionsSearch, hideLoading],
  );

  const handleChangeUserType = useCallback(
    (typeId: number): void => {
      switch (typeId) {
        case 1:
          setUserType('administrador');
          break;
        case 2:
          setUserType('vendedor');
          break;
        case 3:
          setUserType('cliente');
          break;
        default:
          setUserType('transportador');
          break;
      }
      setUserTypeId(typeId);
    },
    [setUserType, setUserTypeId],
  );

  const handleAddUser = (): void => {
    history.push('usuario');
  };

  interface Obj {
    [key: string]: string;
  }
  const handleSubmitForm = useCallback((data: Obj): void => {
    setCurrentPage(1);
    const searchData: Obj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        searchData[key] = value;
      }
    }
    setOptionsSearch(searchData);
    dispatch(setFormValue(searchData));
  }, []);

  useEffect(() => {
    dispatch(setUserId(0));
    getData(currentPage);
  }, [userType, optionsSearch]);

  return (
    <PageContainer>
      <TitleContainer>
        <MainTitle>Consulta de Usuários</MainTitle>
        <ContainerButton>
          <ButtonType
            type="button"
            disabled={userTypeId === 1}
            onClick={() => handleChangeUserType(1)}
          >
            Administrador
          </ButtonType>
          <ButtonType
            type="button"
            disabled={userTypeId === 2}
            onClick={() => handleChangeUserType(2)}
          >
            Vendedor
          </ButtonType>
          <ButtonType
            type="button"
            disabled={userTypeId === 3}
            onClick={() => handleChangeUserType(3)}
          >
            Cliente
          </ButtonType>
          <ButtonType
            type="button"
            disabled={userTypeId === 4}
            onClick={() => handleChangeUserType(4)}
          >
            Transportador
          </ButtonType>
        </ContainerButton>
      </TitleContainer>

      <SpecialContainer>
        <TableList
          header={['Código', 'Nome', 'E-mail', 'Data', 'Opções']}
          data={results}
        />
        <Pagination
          pages={pages}
          currentPage={currentPage}
          onClickFunction={getData}
          isLoadingPage={isLoadingPage}
        />
      </SpecialContainer>
      <>
        <AddUserButton type="button" onClick={handleAddUser}>
          <img
            src={AddUser}
            width="30"
            height="25"
            style={{ marginRight: '5px', filter: 'invert(100%)' }}
            alt="Adicionar Usuário"
          />
        </AddUserButton>
      </>
      <Filter onSubmit={handleSubmitForm}>
        <Input name="name" type="text" placeholder="Nome" />
        <Input name="email" type="text" placeholder="E-mail" />
        <Input name="created_at>" type="date" placeholder="Data Inicial" />
        <Input name="created_at<" type="date" placeholder="Data Final" />
      </Filter>
    </PageContainer>
  );
};

export default ListClient;
