import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(0, 60, 160, 1);
  border-radius: 10px;
  width: 100%;
  margin-top: 25px;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.07);
  padding: 12px;
  display: flex;
  justify-content: center;

  span {
    color: #fff;
    text-align: center;

    font-weight: 700;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 46px;
    padding: 12px;
  }
`;
