import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axios from '../../app/axios';

const initialState = {
  freightTableId: 0,
  currentListPage: 1,
};

export const freightTableSlice = createSlice({
  name: 'freightTable',
  initialState,
  reducers: {
    setFreightTableId: (state, { payload: freightTableId }) => {
      state.freightTableId = !freightTableId
        ? initialState.freightTableId
        : freightTableId;
    },
    setCurrentListPage: (state, { payload: currentListPage }) => {
      state.currentListPage = !currentListPage
        ? initialState.currentListPage
        : currentListPage;
    },
  },
});

export interface ClientFreightTableData {
  id?: number;
  client_id: number;
  ft_id: number;
  client?: {
    name: string;
    document_number: string;
  };
}

export interface FreightTableData {
  id?: number;
  salesman_id: number;
  description: string;
  comments: string;
  icms_type: string;
  client_insurance: boolean;
  is_region: string | boolean;
  insurance_ddr?: string;
}

interface ResponseData {
  data: FreightTableData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseClientData {
  data: ClientFreightTableData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

interface ResponseDataGet {
  data: FreightTableData;
  status: number;
  statusText: string;
  headers: object;
  config: object;
}

export const getFreightTable = async (
  freight_table_id: number,
): Promise<ResponseDataGet> => {
  const response = await axios.get(`/local/freight_tables/${freight_table_id}`);
  return response;
};

export const addNewFreightTable = async (
  freight_table: FreightTableData,
): Promise<ResponseData> => {
  const response = await axios.post('/local/freight_tables', freight_table);
  return response;
};

export const addClientFreightTable = async (
  data: ClientFreightTableData,
): Promise<ResponseClientData> => {
  const response = await axios.post('/local/freight_tables_clients', data);
  return response;
};

export const removeClientFreightTable = async (
  id: number,
): Promise<AxiosResponse> => {
  const response = await axios.delete(`/local/freight_tables_clients/${id}`);
  return response;
};

export const getClientsFreightTable = async (
  ft_id: number,
): Promise<ClientFreightTableData[]> => {
  const { data: response } = await axios.get(`/local/freight_tables_clients`, {
    params: { ft_id },
  });
  return response;
};

export const updateFreightTable = async (
  id: number,
  freightTable: FreightTableData,
): Promise<ResponseData> => {
  const response = await axios.put(`/local/freight_tables/${id}`, freightTable);
  return response;
};

export const aproveFreightTable = async (id: number): Promise<ResponseData> => {
  const response = await axios.patch(`/local/freight_tables/aprove/${id}`);
  return response;
};

export const getFreightTables = async (params: any): Promise<any> => {
  const { data: clients } = await axios.get('/local/freight_tables', {
    params,
  });
  return clients;
};

export const { setFreightTableId } = freightTableSlice.actions;

export default freightTableSlice.reducer;
