import React, { ButtonHTMLAttributes } from 'react';

import { Container, Spinner } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

const ButtonTracking: React.FC<ButtonProps> = ({
  children,
  isLoading = false,
  ...rest
}) => (
  <Container
    type="button"
    isLoading={isLoading}
    disabled={isLoading === true}
    {...rest}
  >
    {isLoading === false ? (
      children
    ) : (
      <Spinner>
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </Spinner>
    )}
  </Container>
);

export default ButtonTracking;
