import React, { useEffect, useState } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

import { init as initAuth } from '../features/auth';

import LoadingFull from '../components/LoadingFull';

interface RouteProps extends ReactDOMRouteProps {
  userTypeAccess?: Array<string>;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  userTypeAccess,
  component: Component,
  ...rest
}) => {
  const { user, authLoadingStep, userType } = useSelector(
    (state: RootStateOrAny) => ({
      user: state.features.auth.user,
      userType: state.features.auth.type,
      authLoadingStep: state.features.auth.loadingStep,
    }),
  );
  const [isLoged, setIsLoged] = useState<boolean>(false);
  const hasFechedAuthUser = !!user;
  const dispatch = useDispatch();
  // eslint-disable-next-line no-undef
  let partToRender: JSX.Element;

  if (rest.path === '/login' && authLoadingStep === 2 && isLoged) {
    partToRender = <Redirect to="/" />;
  } else if (rest.path === '/rastreio') {
    partToRender = <Component />;
  } else if (
    authLoadingStep === 2 &&
    !isLoged &&
    rest.path !== '/login' &&
    rest.path !== '/esqueceu-senha'
  ) {
    partToRender = <Redirect to="/login" />;
  } else if (
    authLoadingStep === 2 &&
    !isLoged &&
    (rest.path === '/login' || rest.path === '/esqueceu-senha')
  ) {
    partToRender = <Component />;
  } else if (authLoadingStep === 2 && isLoged) {
    if (userTypeAccess && userTypeAccess.includes(userType)) {
      partToRender = <Component />;
    } else {
      partToRender = <Redirect to="/" />;
    }
    partToRender = <Component />;
  } else {
    partToRender = <LoadingFull />;
  }

  useEffect(() => {
    dispatch(initAuth());
  }, [dispatch]);

  useEffect(() => {
    setIsLoged(hasFechedAuthUser);
  }, [hasFechedAuthUser, user]);

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return <> {partToRender} </>;
      }}
    />
  );
};

export default Route;
