/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaTruck } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { IoIosAddCircle } from 'react-icons/io';
import { useDebounce } from 'use-debounce/lib';
import moment from 'moment';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import PageContainer from '../../../components/PageContainer';

import MainTitle from '../../../components/MainTitle';
import Input from '../../../components/Input';
import { Row, TitleContainer, SpecialContainer, ContainerSize } from './styles';
import { useToast } from '../../../hooks/toast';
import { useLoading } from '../../../hooks/loading';
import Pagination from '../../../components/Pagination';
import Filter from '../../../components/Filter';

import {
  getFreightTables,
  freightTableSlice,
} from '../../../features/freightTable';
import Autocomplete from '../../../components/Autocomplete';
import { fetchSalesmanByName } from '../../../features/salesman';
import TableList from '../../../components/TableList';
import { filterSlice } from '../../../features/filter';

const statusTypes = [
  {
    value: 'IG',
    label: 'Aguardando Aprovação',
  },
  {
    value: 'IUG',
    label: 'Aguardando Aprovação',
  },
  {
    value: 'I',
    label: 'Aguardando Aprovação',
  },
  {
    value: 'AG',
    label: 'Aguardando Integração',
  },
  {
    value: 'UG',
    label: 'Aguardando Integração',
  },
  {
    value: 'A',
    label: 'Integrado',
  },
  {
    value: 'U',
    label: 'Integrado',
  },
];

const ListFreightTable: React.FC = () => {
  const { currentListPage, formValue, locationFilter } = useSelector(
    (state: RootStateOrAny) => ({
      currentListPage: state.features.freightTable.currentListPage,
      formValue: state.features.filter.formValue,
      locationFilter: state.features.filter.location,
    }),
  );
  const history = useHistory();
  const [results, setResults] = useState<Array<any>>([]);
  const [pages, setPages] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(currentListPage);
  const [optionsSalesmans, setOptionsSalesmans] = useState<any>([]);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [searchSalesmans, setSearchSalesmans] = useState('');
  const [valueSearchSalesman] = useDebounce(searchSalesmans, 1000);
  const [isLoadingSalesmans, setIsLoadingSalesmans] = useState(false);
  const [totalFreightTables, setTotalFreightTables] = useState<number>(0);
  const [optionsSearch, setOptionsSearch] = useState<object>(
    history.location.pathname !== locationFilter ? {} : formValue,
  );
  const { addToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { setCurrentListPage, setFreightTableId } = freightTableSlice.actions;
  const { setFormValue } = filterSlice.actions;
  const dispatch = useDispatch();

  const optionsStatus = [
    {
      value: 'true',
      label: 'Ativa',
    },
    {
      value: 'false',
      label: 'Inativa',
    },
  ];
  const getData = useCallback(
    async (page: number): Promise<void> => {
      showLoading();
      const freighTables = await getFreightTables({
        page,
        ...optionsSearch,
      });
      const arrayFreighTable: Array<Array<any>> = [];
      freighTables.data.forEach((item: any) => {
        arrayFreighTable.push([
          <>
            {item.id}
            <br />
            {moment.utc(item.created_at).format('DD/MM/YYYY')}
          </>,
          item.salesman !== null && item.salesman.salesname,
          item.description,
          <>
            {item.code_rodopar}
            <br />
            {statusTypes.filter(p => p.value === item.status).length > 0
              ? statusTypes.filter(p => p.value === item.status)[0].label
              : 'Não identificado'}
          </>,
          <>
            <Link to={`/tabela-de-frete/${item.id}`}>
              <MdEdit color="#ffffff" size={20} />
            </Link>
          </>,
        ]);
      });
      setResults(arrayFreighTable);
      const arrayPages = [];
      for (let i = 0; i < freighTables.total_pages; i++) {
        arrayPages.push(i + 1);
      }
      setPages(arrayPages);
      setTotalFreightTables(freighTables.total);
      setCurrentPage(page);
      dispatch(setCurrentListPage(page));
      hideLoading();
      addToast({
        title: 'Tabelas de Frete carregadas com sucesso',
        type: 'success',
      });
      setIsLoadingPage(false);
    },
    [showLoading, optionsSearch, hideLoading, addToast],
  );

  useEffect(() => {
    (async () => {
      const salesmans = await fetchSalesmanByName({
        salesname: valueSearchSalesman,
      });
      const allSalesmans: any = [];
      salesmans.forEach((salesman: any): any => {
        allSalesmans.push({
          value: salesman.id,
          label: salesman.salesname,
        });
      });
      setOptionsSalesmans(allSalesmans);
      setIsLoadingSalesmans(false);
    })();
  }, [valueSearchSalesman]);

  useEffect(() => {
    getData(currentPage);
  }, [optionsSearch]);

  const handleSalesmanInputChange = (newValue: string): void => {
    if (newValue !== searchSalesmans) {
      setIsLoadingSalesmans(true);
    }
    setSearchSalesmans(newValue);
  };

  interface Obj {
    [key: string]: string;
  }

  useEffect(() => {
    dispatch(setFreightTableId(0));
  }, []);

  const handleSubmitForm = useCallback(
    (data: Obj): void => {
      setCurrentPage(1);
      const searchData: Obj = {};
      if (JSON.stringify(data) !== '{}') {
        for (const [key, value] of Object.entries(data.freight_table)) {
          if (value) {
            searchData[`freight_table.${key}`] = value;
          }
        }
      }
      dispatch(setFormValue(searchData));
      setOptionsSearch(searchData);
    },
    [setOptionsSearch],
  );

  return (
    <PageContainer>
      <TitleContainer>
        <MainTitle>
          <FaTruck size={25} />
          Tabelas de Frete Cadastradas
        </MainTitle>
      </TitleContainer>
      <SpecialContainer>
        <div className="form-content">
          <Row>
            <ContainerSize size="100%">
              <TableList
                header={[
                  'Código - Data',
                  'Vendedor',
                  'Nome',
                  'Código Integração - Status',
                  'Opções',
                ]}
                data={results}
              />
            </ContainerSize>
          </Row>

          <Pagination
            pages={pages}
            currentPage={currentPage}
            onClickFunction={getData}
            isLoadingPage={isLoadingPage}
          />
        </div>

        <div className="client-total">
          <div className="span-total">
            <span className="number">{totalFreightTables}</span>
            <span>Tabelas de Frete</span>
          </div>

          <Link to="/tabela-de-frete" className="add-button">
            <IoIosAddCircle size={22} />
            Adicionar
          </Link>
        </div>
      </SpecialContainer>
      <Filter onSubmit={handleSubmitForm}>
        <Input type="text" name="freight_table.id" placeholder="ID" />
        <Input
          type="text"
          name="freight_table.code_rodopar"
          placeholder="Código integração"
        />
        <Autocomplete
          name="freight_table.salesman_id"
          options={optionsSalesmans}
          placeholder="Vendedor"
          changeValue={null}
          onInputChange={handleSalesmanInputChange}
          isLoading={isLoadingSalesmans}
        />
        <Autocomplete
          name="freight_table.is_active"
          options={optionsStatus}
          placeholder="Status"
          changeValue={null}
        />
        <Input
          name="freight_table.description"
          type="text"
          placeholder="Nome"
        />
        <Input
          name="freight_table.created_at>"
          type="date"
          placeholder="Data Inicial"
        />
        <Input
          name="freight_table.created_at<"
          type="date"
          placeholder="Data Final"
        />
      </Filter>
    </PageContainer>
  );
};

export default ListFreightTable;
