/* eslint-disable no-undef */
import React from 'react';
import moment from 'moment';

import {
  FaBoxOpen,
  FaTruckLoading,
  FaWarehouse,
  FaCalendarAlt,
} from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';
import { BsFileCheck, BsFillInfoCircleFill } from 'react-icons/bs';
import { MdAssignmentReturn } from 'react-icons/md';
import { RiTruckLine, RiTreasureMapLine } from 'react-icons/ri';
import {
  CardContainer,
  TrackingCodeDiv,
  TrackingCodeSpan,
  TrackingTitleFlex,
  StatusDiv,
  DateSpan,
} from './styles';

interface PropsTrackingCard {
  trackInfo: any;
  setCodeTrack: any;
}

const TrackingInfoCard: React.FC<PropsTrackingCard> = ({
  trackInfo,
  setCodeTrack,
}) => {
  const getIcon = (status: string): JSX.Element => {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus.includes('coleta solicitada')) {
      return <FaBoxOpen />;
    }
    if (lowerCaseStatus.includes('remessa criada')) {
      return <BsFileCheck />;
    }
    if (
      lowerCaseStatus.includes('em transferência') ||
      lowerCaseStatus.includes('em transferencia')
    ) {
      return <RiTruckLine />;
    }
    if (lowerCaseStatus.includes('em rota de entrega')) {
      return <RiTreasureMapLine />;
    }
    if (lowerCaseStatus.includes('entregue')) {
      return <FaTruckLoading />;
    }
    if (lowerCaseStatus.includes('outros')) {
      return <BsFillInfoCircleFill />;
    }
    if (
      lowerCaseStatus.includes('filial') ||
      lowerCaseStatus.includes('matriz')
    ) {
      return <FaWarehouse />;
    }
    if (lowerCaseStatus.includes('agendamento')) {
      return <FaCalendarAlt />;
    }
    if (lowerCaseStatus.includes('devolvida')) {
      return <MdAssignmentReturn />;
    }
    if (lowerCaseStatus.includes('entrega realizada')) {
      return <FiCheck />;
    }
    return <BsFillInfoCircleFill />;
  };

  return (
    <CardContainer
      type="button"
      onClick={() => setCodeTrack(trackInfo.code_rodopar)}
      title="Visualizar detalhes"
    >
      <TrackingTitleFlex>
        <TrackingCodeDiv>
          <TrackingCodeSpan>NF {trackInfo.code}</TrackingCodeSpan>
        </TrackingCodeDiv>
        <DateSpan>
          {moment.utc(trackInfo.date_hour).format('YYYY-MM-DD HH:mm:ss')}
        </DateSpan>
      </TrackingTitleFlex>
      <StatusDiv>
        {getIcon(trackInfo.status)} <span>{trackInfo.status}</span>
      </StatusDiv>
    </CardContainer>
  );
};

export default TrackingInfoCard;
