/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import { IconBaseProps } from 'react-icons';
import { uuid } from 'uuidv4';

import Dialog from '../components/Dialog';

export interface DialogMessage {
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  text: string;
  textButtonCancel: string;
  textButtonConfirm: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

interface DialogContextData {
  createDialog(message: DialogMessage): void;
  removeDialog(): void;
}

const DialogContext = createContext<DialogContextData>({} as DialogContextData);

const DialogProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState<DialogMessage>();

  const removeDialog = useCallback(() => {
    if (message) {
      setMessage({} as DialogMessage);
    }
  }, [message, setMessage]);

  const createDialog = useCallback(
    ({
      icon,
      text,
      textButtonCancel,
      textButtonConfirm,
      onCancel,
      onConfirm,
    }: DialogMessage) => {
      removeDialog();
      setMessage({
        id: uuid(),
        icon,
        text,
        textButtonCancel,
        textButtonConfirm,
        onCancel,
        onConfirm,
      });
    },
    [setMessage],
  );

  useEffect(() => {}, [message]);

  return (
    <DialogContext.Provider value={{ createDialog, removeDialog }}>
      {children}
      {message && (
        <Dialog
          id={message.id}
          icon={message.icon}
          text={message.text}
          textButtonCancel={message.textButtonCancel}
          textButtonConfirm={message.textButtonConfirm}
          onCancel={message.onCancel}
          onConfirm={message.onConfirm}
        />
      )}
    </DialogContext.Provider>
  );
};

function useDialog(): DialogContextData {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  return context;
}

export { DialogProvider, useDialog };
